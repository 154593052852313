import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { load } from "@cashfreepayments/cashfree-js";
import styles from "./BookBuyButton.module.css";

const BookBuyButton = ({
  productId,
  userDetails,
  buttonText = "Buy Now",
  className = "",
  style = {},
}) => {
  const [cashfree, setCashfree] = useState(null);

  useEffect(() => {
    const initializeSDK = async () => {
      const cashfreeInstance = await load({
        mode: "production",
        // mode: "sandbox",
      });
      setCashfree(cashfreeInstance);
    };
    initializeSDK();
  }, []); // Empty dependency array ensures this runs only once

  const doPayment = async (id, orderId) => {
    if (cashfree) {
      cashfree.checkout({
        paymentSessionId: id,
        returnUrl: `https://auth.ssccglpinnacle.com/api/book_payment_status/${orderId}`,
        redirectTarget: "_blank",
        onSuccess: (paymentResponse) => {
          console.log("Payment successful", paymentResponse);
          window.clevertap.event.push("Purchased Completed", {
            userId: userDetails.userId,
            productId: productId,
            orderId: orderId,
            status: "Success",
          });
        },
        onFailure: (paymentResponse) => {
          console.error("PAYMENT FAILED", paymentResponse);
          window.clevertap.event.push("Book Purchase Failed", {
            userId: userDetails.userId,
            productId: productId,
            orderId: orderId,
            status: "Failed",
          });
        },
      });
    } else {
      console.error("Cashfree SDK not initialized");
    }
  };

  // const payMe = async () => {
  //    try {
  //       if (!userDetails || !userDetails.userId) {
  //          Swal.fire({
  //             icon: "error",
  //             title: "Error",
  //             text: "Please log in to make a purchase.",
  //          });
  //          return;
  //       }

  //       const data = {
  //          userId: userDetails.userId,
  //          productId,
  //          userName: userDetails.userName,
  //          userEmail: userDetails.userEmail,
  //          userMobileNo: userDetails.userMobileNo,
  //       };

  //       console.log("DATA", data);
  //       const url = "https://auth.ssccglpinnacle.com/api/book_payment";
  //       const response = await fetch(url, {
  //          method: "POST",
  //          headers: {
  //             "Content-Type": "application/json",
  //          },
  //          body: JSON.stringify(data),
  //       });

  //       if (response.ok) {
  //          const responseData = await response.json();
  //          console.log("RESPONSEDATA", responseData);
  //          if (responseData.error) {
  //             Swal.fire({
  //                icon: "error",
  //                title: "Error",
  //                text: responseData.error,
  //             });
  //          } else {
  //             doPayment(responseData.cftoken, responseData.orderId);
  //          }
  //       } else {
  //          Swal.fire({
  //             icon: "error",
  //             title: "Error",
  //             text: "Failed to initiate payment. Please try again.",
  //          });
  //       }
  //    } catch (err) {
  //       console.error("Error fetching data:", err);
  //       Swal.fire({
  //          icon: "error",
  //          title: "Error",
  //          text: "An error occurred. Please try again.",
  //       });
  //    }
  // };

  const payMe = async () => {
    try {
      console.log("User Details:", userDetails); // Log userDetails to console

      if (!userDetails || !userDetails.userId) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to make a purchase.",
        });
        return;
      }

      const data = {
        userId: userDetails.userId,
        productId,
        userName: userDetails.userName,
        userEmail: userDetails.userEmail,
        userMobileNo: userDetails.userMobileNo,
      };

      console.log("DATA", data);
      // const url = "https://auth.ssccglpinnacle.com/api/book_payment";
      const url = "https://auth.ssccglpinnacle.com/api/book_payment";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("RESPONSEDATA", responseData);
        if (responseData.error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: responseData.error,
          });
        } else {
          doPayment(responseData.cftoken, responseData.orderId);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to initiate payment. Please try again.",
        });
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <button
      onClick={payMe}
      className={`${styles.buyNowButton} ${className}`}
      style={style}
    >
      {buttonText}
    </button>
  );
};

export default BookBuyButton;

import React from "react";
import Navbar from "./Navbar";
import classes from "./Header.module.css";
import pic from "./images/pinnacleWhiteLogo.png";
import pic1 from "./images/gmail.svg";
import SearchBar from "./SearchBar";
import Navbar1 from "./Navbar2";

function Header(props) {
  return (
    <div className={classes.Headercontainer1}>
      <header className={classes["header4"]}>
        <div className={classes.headerimage}>
          <img src={pic} />
          <div className={classes["Support-email"]}>
            <img className={classes["Support-emailgmail"]} src={pic1} />
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@ssccglpinnacle.com
            </a>
          </div>
        </div>

        <SearchBar />
        <Navbar isLoggedIn={props.isAuthenticated} onLogout={props.onLogout} />
      </header>
      <Navbar1 />
    </div>
  );
}

export default Header;

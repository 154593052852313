import React, { useState, useEffect } from 'react';
import './MylearningRow3.css'; // You can reuse the same CSS
import { BsArrowRight } from 'react-icons/bs';
import pic from '../i/book.jpg';
import { useAuth } from '../../Context/AuthContext';
import Header from "../../component/DigitalcontentHeader/Header";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoadingSpinner from "../LoadingSpinner";

// Define the truncateText function
function truncateText(text, maxWords) {
  const words = (text || '').split(' '); // Add fallback for undefined text

  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  }
  return text;
}

function ShowAllEbooks() {
  const [chaptersData, setChaptersData] = useState([]);
  const [ebooksData, setEbooksData] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]); // To store purchased eBooks
  const { isLoggedIn, user, token } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [cookies] = useCookies(['token', 'email_id']);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {


    const fetchCartItems = async () => {
        try {
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/cart-ebook/${user.id}`, {
            headers: {
              "Authorization": `Bearer ${cookies.token}`
            }
          });
          if (!response.ok) {
            throw new Error("Error fetching cart data");
          }
          const data = await response.json();
          setCartItems(data);
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      };

    const fetchData = async () => {
      try {
        if (isLoggedIn && user) {
          const userId = user.id;
          const useremail = user.email_id;
          setLoading(true);

          // Fetch chapters, ebooks, and purchase history simultaneously
          const [chaptersRes, ebooksRes, purchaseHistoryRes] = await Promise.all([
            fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
            fetch('https://auth.ssccglpinnacle.com/api/ebooks', {
              // Add necessary headers if needed
            }),
            fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${useremail}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          ]);

          if (!chaptersRes.ok || !ebooksRes.ok || !purchaseHistoryRes.ok) {
            throw new Error('Network response was not ok');
          }

          const [chaptersData, ebooksData, purchaseHistoryData] = await Promise.all([
            chaptersRes.json(),
            ebooksRes.json(),
            purchaseHistoryRes.json(),
          ]);

          setChaptersData(chaptersData);
          setEbooksData(ebooksData);
          setPurchaseHistory(purchaseHistoryData.ebooksPurchased); // Store purchased eBooks
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchCartItems();
  }, [isLoggedIn, user, token]);

  // Function to count the total chapters for each eBook
  const countTotalChapters = (chapters) => {
    return chapters.length;
  };

  // Filter out the eBooks that have already been purchased by the user
  const filterPurchasedEbooks = (ebooks, purchasedEbooks) => {
    const purchasedEbookIds = purchasedEbooks.map((ebook) => ebook._id); // Get purchased eBook IDs
    return ebooks.filter((ebook) => !purchasedEbookIds.includes(ebook._id)); // Filter out purchased eBooks
  };
  const formatTitleForUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  const info = (category, subject, title, ebook) => {
    const formattedExamCategory = formatTitleForUrl(category);
    const formattedSubjects = formatTitleForUrl(subject);
    const formattedTitle = formatTitleForUrl(title);
    // navigate(`/ebook/${formattedExamCategory}/${formattedSubjects}/${formattedTitle}`);
    navigate(`ebook${ebook.permalink}`);

};
  const handleAddToCart = async (ebookId) => {
    if (!isLoggedIn || !user || !user._id) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to add items to your cart.",
      });
      return;
    }

    setIsAddingToCart(true);

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
        body: JSON.stringify({ ebookId, fullName: user.email_id }),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        navigate("/ebooks/cart");
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          navigate("/ebooks/cart");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to add item to cart. Error: ${errorMessage.error}`,
          });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  if (loading) {
    return <><LoadingSpinner/></>;
  }

  return (
    <>
      <Header />
      <div className="my-learning-row2-showall">
        <div className="divider">
          <svg className="svg-divider" style={{ fill: "white" }}>
            <path d="M 0 31 C 313 -17 359 19 530 29 S 905 -20 1303 21 S 1677 -28 2537 29 L 2537 0 L 0 0 L 0 31"></path>
          </svg>
        </div>
        <div className="showallebooks">
          <div className="inprogress-header-ebook-purchased">
            <h2>Show other Ebooks</h2>
          </div>
          <div className="show-all-ebooks">
            <div className="card-for-progress-showall">
              {filterPurchasedEbooks(ebooksData, purchaseHistory).map((ebook) => {
                // Filter related chapters for each eBook
                const relatedChapters = chaptersData.filter(
                  (chapter) => chapter.ebook?._id === ebook._id
                );
                const totalChapters = countTotalChapters(relatedChapters); // Count total chapters
                const isInCart = cartItems.some(cartItem => cartItem.product._id === ebook._id);
                return (
                  <div className="custom-card-showall digital-content-card-progress-showall" key={ebook._id}>
                    <div className="class-for-img-progress-new">
                      <div className="classes-for-pro-img-new">
                        <img className="card-image-progress-new" src={ebook.image} alt={ebook.title} />
                      </div>
                    </div>
                    <div className="card-content-progress">
                      <div className="title-progress-report">
                        <h6>{ebook.title}</h6>
                      </div>
                      <div className="lesson-completed">
                        <p><b>{totalChapters}</b> lessons</p> {/* Display total chapters */}
                      </div>
                      <div className="ebook-information-progress">
                        <p>{truncateText(ebook.details, 20)}</p>
                      </div>
                      <div className="read-more-process-wishlist">
                        {isInCart ? (
                          <button className="buy-now-button-wishlist">Already in the cart</button>
                        ) : (
                          <button className="buy-now-button-wishlist" onClick={() => handleAddToCart(ebook._id)}>Buy Now</button>
                        )}
                        <p className="read-more-para-wishlist"onClick={() => info(ebook.category, ebook.subject, ebook.title, ebook)}>Read more</p>
                        <BsArrowRight className="arrow-process-wishlist" size={18} color="black" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <svg className="svg-divider-2" style={{ fill: "#e0f5e8" }}>
            <path d="M 0 31 C 313 -17 359 19 530 29 S 905 -20 1303 21 S 1677 -28 2537 29 L 2537 0 L 0 0 L 0 31"></path>
          </svg>
        </div>
      </div>
    </>
  );
}

export default ShowAllEbooks;

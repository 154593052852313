import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  FaTrash,
  FaEdit,
  FaPlay,
  FaEllipsisV,
  FaArchive,
} from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import styles from "./ArchievedList.module.css";
import Card from "../BodyContent/Card/Card";
import Loading from "../BodyContent/ProgressBar/ProgressBar";

function ArchivedList({ userId }) {
  const [myLists, setMyLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArchivedCourses = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/archive/${userId}`
        );
        setMyLists(response.data);
      } catch (error) {
        console.error("Failed to fetch archived courses:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchArchivedCourses();
  }, [userId]);

  // Handle unarchiving a course
  const handleUnarchive = async (courseId) => {
    try {
      await axios.post(
        `https://auth.ssccglpinnacle.com/api/unarchive/${courseId}`
      );
      setMyLists((prevCourses) =>
        prevCourses.filter((course) => course.courseId !== courseId)
      );
      setActiveMenu(null);
    } catch (error) {
      console.error("Failed to unarchive course:", error);
    }
  };

  // Handle deleting a course
  const handleDelete = async (courseId) => {
    try {
      await axios.delete(
        `https://auth.ssccglpinnacle.com/api/archive/${courseId}`
      );
      setMyLists((prevCourses) =>
        prevCourses.filter((course) => course.courseId !== courseId)
      );
      setActiveMenu(null);
    } catch (error) {
      console.error("Failed to delete course from archive:", error);
    }
  };

  const toggleMenu = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActiveMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <img src="/Bubble-Preloader.gif" alt="Loading..." />
      </div>
    );
  }

  return (
    <div className={styles.listContainer1}>
      {myLists.length > 0 ? (
        myLists.map((course, index) => (
          <Card key={course._id} className={styles.card}>
            <div className={styles.cardMain}>
              <div
                className={styles.three_dots}
                onClick={() => toggleMenu(index)}
              >
                <FaEllipsisV />
              </div>
              {activeMenu === index && (
                <div className={styles.options_menu} ref={menuRef}>
                  <div
                    className={styles.menu_item}
                    onClick={() => handleDelete(course.courseId)}
                  >
                    <FaArchive /> Unarchive
                  </div>
                </div>
              )}
              <div className={styles.card_img_container}>
                <div className={styles.card_img}>
                  <img
                    src={course.hindiCoverImage || "default-image-hindi.png"}
                    alt={course.courseTitle}
                    width="95"
                    height="95"
                  />
                  <img
                    src={
                      course.englishCoverImage || "default-image-english.png"
                    }
                    alt={course.courseTitle}
                    width="95"
                    height="95"
                  />
                  <div
                    onClick={() =>
                      navigate(`/videos/mylearning/${course.courseId}`)
                    }
                    className={styles.play_icon}
                  >
                    <FaPlay size={30} />
                  </div>
                </div>
              </div>
              <div className={styles.heading}>
                <Link
                  to={`/videos/mylearning/${course.courseId}`}
                  className={styles.title}
                >
                  {course.courseTitle}
                </Link>
                <div className={styles.instructor}>
                  {course.instructorName || "Unknown Instructor"}
                </div>
              </div>
              <Loading userId={userId} courseId={course.courseId} />
            </div>
          </Card>
        ))
      ) : (
        <div className={styles.nocourses}>
          <h3>Focus on only the courses that matter to you.</h3>
          <p>
            <span>
              <Link to={`/videos/mylearning/${userId}`}>
                Go to the All Courses{" "}
              </Link>
              tab to archive.
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

export default ArchivedList;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Button, TextField, MenuItem, CircularProgress } from "@mui/material";
import JoditRichTextEditor from "../../BlogProject/BlogContainer/JoditRichTextEditor";
import "./EditBlogForm.css";

const EditBlogForm = () => {
    // const id = blogId; 
  const { id } = useParams();  // Get blog ID from URL
  const navigate = useNavigate();
  

  // State variables
  const [author, setAuthor] = useState({ name: "", details: "" });
  const [category, setCategory] = useState("");
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState("");
  const [seo, setSeo] = useState({ keywords: "", description: "", title: "" });
  const [permalink, setPermalink] = useState("");
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [blog, setBlog] = useState("");
//    const [permalink, setPermalink] = useState(blogData.permalink || "");
    const [image, setImage] = useState(null);
    const [imagePreviews, setImagePreviews] = useState([]);

  // Fetch blog data on component mount
//   useEffect(() => {
//     const fetchBlogData = async () => {
//       try {
//         console.log(`Fetching blog with ID: ${id}`);  // Debug log
//         const response = await axios.get(`http://localhost:5000/api/blogs/${id}`);
  
//         console.log("Raw API Response:", response);  // Full API response
  
//         const data = response.data;
  
//         if (data) {
//           console.log("Parsed Data:", data);  // Parsed data check
  
//           setBlog({
//             author: {
//               name: data.blog.author?.name || "",
//               details: data.blog.author?.details || ""
//             },
//             category: data.blog.category || "",
//             heading: data.blog.heading || "",
//             content: data.blog.content || "",
//             seo: {
//               title: data.blog.seo?.title || "",
//               description: data.blog.seo?.description || "",
//               keywords: data.blog.seo?.keywords || ""
//             },
//             permalink: data.blog.permalink || "",
//             image: data.blog.image || ""
//           });
          
//           console.log("Author Name:", data.blog.author.name);
//           console.log("author name:", data.blog.author.details);

//         } else {
//           console.error("No data received from API");
//         }
//       } catch (error) {
//         console.error("Error fetching blog data:", error);
//         Swal.fire("Error", "Failed to load blog data.", "error");
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchBlogData();
//   }, [id]);

useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`https://auth.ssccglpinnacle.com/api/blogs/${id}`);
        const data = response.data.blog;
  
        if (data) {
          setAuthor({
            name: data.author?.name || "",
            details: data.author?.details || ""
          });
          setCategory(data.category || "");
          setHeading(data.heading || "");
          setContent(data.content || "");
          setSeo({
            title: data.seo?.title || "",
            description: data.seo?.description || "",
            keywords: data.seo?.keywords || ""
          });
          setPermalink(data.permalink || "");
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
        Swal.fire("Error", "Failed to load blog data.", "error");
      } finally {
        setLoading(false);
      }
    };
  
    fetchBlogData();
  }, [id]);
  
  const generateSlug = (text) => {
    return text
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+$/, "");
  };

  const handleHeadingChange = (value) => {
    setHeading(value);
    setPermalink(generateSlug(value));
  };

  // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setUpdating(true);

//     try {
//         console.log("Sending PUT request to update blog");
//       await axios.put(`http://localhost:5000/api/blogs/${id}`, {
//         author,
//         category,
//         heading,
//         content,
//         seo,
//         permalink,
//       });
//       console.log("Blog updated successfully!");

//       Swal.fire("Success", "Blog updated successfully!", "success");
//     //   navigate("/blogs");
//     } catch (error) {
//       console.error("Error updating blog:", error);
//       Swal.fire("Error", "Failed to update the blog.", "error");
//     } finally {
//       setUpdating(false);
//     }
//   };
const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
  
    try {
      const formData = new FormData();
      formData.append("authorName", author.name);
      formData.append("authorDetails", author.details);
      formData.append("category", category);
      formData.append("heading", heading);
      formData.append("content", content);
      formData.append("seoKeywords", seo.keywords);
      formData.append("seoDescription", seo.description);
      formData.append("seoTitle", seo.title);
      formData.append("permalink", permalink);
  
      if (image) {
        formData.append("image", image);
      }
  
      await axios.put(`https://auth.ssccglpinnacle.com/api/blogs/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      Swal.fire("Success", "Blog updated successfully!", "success");
      navigate("/blogs");
    } catch (error) {
      console.error("Error updating blog:", error);
      Swal.fire("Error", "Failed to update the blog.", "error");
    } finally {
      setUpdating(false);
    }
  };
  
  // Show loading spinner while fetching data
  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <CircularProgress />
        <p>Loading blog data...</p>
      </div>
    );
  }

  return (
    <div className="edit-blog-container">
      <h2 className="add-ebook-form">Edit Blog</h2>
      <form className="unique-blog-form" onSubmit={handleSubmit}>
        <div className="unique-form-group">
        <label className="unique-form-label">Author Name:</label>
        <input
            className="unique-form-input"
            type="text"
            value={author.name}
            onChange={(e) => setAuthor({ ...author, name: e.target.value })}
            required
        />
        </div>
        {/* Author Name */}
        {/* <TextField
          label="Author Name"
        //   value={author.name}
          value ={blog.author}
          onChange={(e) => setAuthor({ ...author, name: e.target.value })}
          fullWidth
          margin="normal"
          required
        /> */}

        {/* Author Details */}
        <div className="unique-form-group">
          <label className="unique-form-label">Author Details:</label>
          <input
            className="unique-form-input"
            type="text"
            value={author.details}
            onChange={(e) => setAuthor({ ...author, details: e.target.value })}
            required
            />
        </div>
        {/* <TextField
          label="Author Details"
        //   value={author.details}
          value={blog.author}
          onChange={(e) => setAuthor({ ...author, details: e.target.value })}
          fullWidth
          margin="normal"
          required
        /> */}

        {/* Blog Category */}
        <div className="unique-form-group">
          <label className="unique-form-label">Category:</label>
          <select
  className="unique-form-input"
  value={category}
  onChange={(e) => setCategory(e.target.value)}
  required
>
  <option value="">Select a category</option>
  <option value="SSC">SSC</option>
  <option value="RRB NTPC">RRB NTPC</option>
  <option value="Daily current affairs">Daily current affairs</option>
  <option value="Topic wise current affairs">Topic wise current affairs</option>
  <option value="Last one year current affairs">Last one year current affairs</option>
  <option value="Monthly current affairs">Monthly current affairs</option>
  <option value="Up police">Up police</option>
  <option value="HSSC">HSSC</option>
</select>
        </div>
        {/* <TextField
          select
          label="Category"
          value={blog.category}
          onChange={(e) => setCategory(e.target.value)}
          fullWidth
          margin="normal"
          required
        >
          <MenuItem value="SSC">SSC</MenuItem>
          <MenuItem value="RRB NTPC">RRB NTPC</MenuItem>
          <MenuItem value="Current Affairs">Current Affairs</MenuItem>
        </TextField> */}

        {/* Blog Heading */}
        <div className="unique-form-group">
          <label className="unique-form-label">Heading:</label>
          <input
            className="unique-form-input"
            type="text"
            value={heading}
            onChange={(e) => handleHeadingChange(e.target.value)}
            required
          />
        </div>
        
        {/* <TextField
          label="Heading"
          value={blog.heading}
          onChange={(e) => setHeading(e.target.value)}
          fullWidth
          margin="normal"
          required
        /> */}

        {/* SEO Title */}
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Keywords:</label>
          <input
  className="unique-form-input"
  type="text"
  value={seo.keywords}
  onChange={(e) => setSeo({ ...seo, keywords: e.target.value })}
  required
/>
        </div>
        {/* <TextField
          label="SEO Title"
          value={seo.title}
          onChange={(e) => setSeo({ ...seo, title: e.target.value })}
          fullWidth
          margin="normal"
          required
        /> */}

        {/* Permalink */}
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Description:</label>
          <input
  className="unique-form-input"
  type="text"
  value={seo.description}
  onChange={(e) => setSeo({ ...seo, description: e.target.value })}
  required
/>
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Title:</label>
          <input
  className="unique-form-input"
  type="text"
  value={seo.title}
  onChange={(e) => setSeo({ ...seo, title: e.target.value })}
  required
/>
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Permalink:</label>
          <input
            className="unique-form-input"
            type="text"
            value={permalink}
            onChange={(e) => setPermalink(e.target.value)}
            required
          />
        </div>
        {/* <TextField
          label="Permalink"
          value={blog.permalink}
          onChange={(e) => setPermalink(e.target.value)}
          fullWidth
          margin="normal"
          required
        /> */}

        {/* Content Editor */}
        <div className="unique-form-group">
          <label className="unique-form-label">Content:</label>
          <JoditRichTextEditor
  content={content}
  onContentChange={setContent}
/>
        </div>

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          disabled={updating}
          onClick={handleSubmit}
        >
          {updating ? "Updating..." : "Update Blog"}
        </Button>
      </form>
    </div>
  );
};

export default EditBlogForm;



// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { Button, CircularProgress } from "@mui/material";
// import JoditRichTextEditor from "../../BlogProject/BlogContainer/JoditRichTextEditor";
// import "./EditBlogForm.css";

// const EditBlogForm = () => {
//   const { id } = useParams();  // Get blog ID from URL
//   const navigate = useNavigate();

//   // State variables
//   const [author, setAuthor] = useState({ name: "", details: "" });
//   const [category, setCategory] = useState("");
//   const [heading, setHeading] = useState("");
//   const [content, setContent] = useState("");
//   const [seo, setSeo] = useState({ keywords: "", description: "", title: "" });
//   const [permalink, setPermalink] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [updating, setUpdating] = useState(false);

//   // Fetch blog data on component mount
//   useEffect(() => {
//     const fetchBlogData = async () => {
//       try {
//         const response = await axios.get(`http://localhost:5000/api/blogs/${id}`);
//         const data = response.data.blog;
//         console.log("RESPONCE DATA",response);

//         if (data) {
//           setAuthor({
//             name: data.author?.name || "",
//             details: data.author?.details || ""
//           });
//           setCategory(data.category || "");
//           setHeading(data.heading || "");
//           setContent(data.content || "");
//           setSeo({
//             title: data.seo?.title || "",
//             description: data.seo?.description || "",
//             keywords: data.seo?.keywords || ""
//           });
//           setPermalink(data.permalink || "");
//         }
//       } catch (error) {
//         console.error("Error fetching blog data:", error);
//         Swal.fire("Error", "Failed to load blog data.", "error");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchBlogData();
//   }, [id]);

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setUpdating(true);
  
//     // Validation Check
//     if (!seo.title || !seo.description || !seo.keywords || !permalink) {
//       Swal.fire("Error", "Please fill in all SEO fields and permalink.", "error");
//       setUpdating(false);
//       return;
//     }
  
//     try {
//       console.log("Data being sent:", { author, category, heading, content, seo, permalink });
  
//       await axios.put(`http://localhost:5000/api/blogs/${id}`, {
//         author,
//         category,
//         heading,
//         content,
//         seo,
//         permalink,
//       });
  
//       Swal.fire("Success", "Blog updated successfully!", "success");
//     //   navigate("/blogs");
//     } catch (error) {
//       console.error("Error updating blog:", error);
//       Swal.fire("Error", "Failed to update the blog.", "error");
//     } finally {
//       setUpdating(false);
//     }
//   };

//   // Show loading spinner while fetching data
//   if (loading) {
//     return (
//       <div style={{ textAlign: "center", marginTop: "20px" }}>
//         <CircularProgress />
//         <p>Loading blog data...</p>
//       </div>
//     );
//   }

//   return (
//     <div className="edit-blog-container">
//       <h2 className="add-ebook-form">Edit Blog</h2>
//       <form className="unique-blog-form" onSubmit={handleSubmit}>
//         {/* Author Name */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">Author Name:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={author.name}
//             onChange={(e) => setAuthor({ ...author, name: e.target.value })}
//             required
//           />
//         </div>

//         {/* Author Details */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">Author Details:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={author.details}
//             onChange={(e) => setAuthor({ ...author, details: e.target.value })}
//             required
//           />
//         </div>

//         {/* Blog Category */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">Category:</label>
//           <select
//             className="unique-form-input"
//             value={category}
//             onChange={(e) => setCategory(e.target.value)}
//             required
//           >
//             <option value="">Select a category</option>
//             <option value="SSC">SSC</option>
//             <option value="RRB NTPC">RRB NTPC</option>
//             <option value="Daily current affairs">Daily current affairs</option>
//             <option value="Topic wise current affairs">Topic wise current affairs</option>
//             <option value="Last one year current affairs">Last one year current affairs</option>
//             <option value="Monthly current affairs">Monthly current affairs</option>
//             <option value="Up police">Up police</option>
//             <option value="HSSC">HSSC</option>
//           </select>
//         </div>

//         {/* Blog Heading */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">Heading:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={heading}
//             onChange={(e) => setHeading(e.target.value)}
//             required
//           />
//         </div>

//         {/* SEO Title */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">SEO Title:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={seo.title}
//             onChange={(e) => setSeo({ ...seo, title: e.target.value })}
//             required
//           />
//         </div>

//         {/* SEO Description */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">SEO Description:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={seo.description}
//             onChange={(e) => setSeo({ ...seo, description: e.target.value })}
//             required
//           />
//         </div>

//         {/* SEO Keywords */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">SEO Keywords:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={seo.keywords}
//             onChange={(e) => setSeo({ ...seo, keywords: e.target.value })}
//             required
//           />
//         </div>

//         {/* Permalink */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">Permalink:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={permalink}
//             onChange={(e) => setPermalink(e.target.value)}
//             required
//           />
//         </div>

//         {/* Content Editor */}
//         <div className="unique-form-group">
//           <label className="unique-form-label">Content:</label>
//           <JoditRichTextEditor
//             content={content}
//             onContentChange={setContent}
//           />
//         </div>

//         {/* Submit Button */}
//         <Button
//           type="submit"
//           variant="contained"
//           color="primary"
//           style={{ marginTop: "20px" }}
//           disabled={updating}
//         >
//           {updating ? "Updating..." : "Update Blog"}
//         </Button>
//       </form>
//     </div>
//   );
// };

// export default EditBlogForm;

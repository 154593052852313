// **********Final Code for github ***************

import React, { useRef, useMemo,} from "react";
import JoditEditor from "jodit-react";

const JoditRichTextEditor = ({ content, onContentChange }) => {
  const editor = useRef(null);
  const config = useMemo(
    () => ({
        uploader: {         
            insertImageAsBase64URI: true,
            imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'] 
          },
      readonly: false, 
      placeholder: "Start typing...",
      toolbarAdaptive: true,
      toolbarSticky: true,
      minHeight: 400, 
    }),
    []
  );

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1}
        onBlur={(newContent) => onContentChange(newContent)}
      />
    </div>
  );
};

export default JoditRichTextEditor;



// import React, { useRef, useMemo, useEffect, useState } from "react";
// import JoditEditor from "jodit-react";

// const JoditRichTextEditor = ({ content, onContentChange }) => {
//   const editor = useRef(null);
//   const [editorContent, setEditorContent] = useState("");

//   // Load draft from localStorage on component mount
//   useEffect(() => {
//     const savedDraft = localStorage.getItem("joditDraft");
//     if (savedDraft) {
//       setEditorContent(savedDraft); // Load saved draft
//     } else if (content) {
//       setEditorContent(content); // Use initial content if no draft exists
//     }
//   }, [content]);

//   // Save draft to localStorage when content changes
//   const handleContentChange = (newContent) => {
//     setEditorContent(newContent);
//     localStorage.setItem("joditDraft", newContent); // Save draft to localStorage
//     if (onContentChange) {
//       onContentChange(newContent); // Call parent's onContentChange
//     }
//   };

//   const config = useMemo(
//     () => ({
//       uploader: {
//         insertImageAsBase64URI: true,
//         imagesExtensions: ["jpg", "png", "jpeg", "gif", "svg", "webp"], // Allowed image formats
//       },
//       readonly: false,
//       placeholder: "Start typing...",
//       toolbarAdaptive: true,
//       toolbarSticky: true,
//       minHeight: 400,
//     }),
//     []
//   );

//   return (
//     <div>
//       <JoditEditor
//         ref={editor}
//         value={editorContent}
//         config={config}
//         tabIndex={1}
//         onBlur={(newContent) => handleContentChange(newContent)} // Save draft on blur
//       />
//     </div>
//   );
// };

// export default JoditRichTextEditor;


// ******************* tring code ********



// import React, { useRef, useMemo, useState, useEffect } from "react";
// import JoditEditor from "jodit-react";
// import axios from "axios";

// const JoditRichTextEditor = ({ userId, blogId, content, onContentChange }) => {
//   const editor = useRef(null);
// console.log("userI:",userId);
// console.log("blogId:",blogId);
// console.log("content:",content);
//   // Load draft when the component mounts
//   useEffect(() => {
//     const loadDraft = async () => {
//       try {
//         const response = await axios.get(`http://localhost:5000/api/draft/${userId}`);
//         if (response.data.draft) {
//           onContentChange(response.data.draft.content || "");
//         }
//       } catch (error) {
//         console.error("Error loading draft:", error);
//       }
//     };

//     loadDraft();
//   }, [userId, onContentChange]);

//   // Auto-save every 5 seconds
//   useEffect(() => {
//     const interval = setInterval(() => {
//       saveDraft(content);
//     }, 5000); // Auto-save every 5 seconds

//     return () => clearInterval(interval);
//   }, [content]);

//   // Save draft to backend
//   const saveDraft = async (newContent) => {
//     if (!newContent.trim()) return; // Avoid saving empty drafts
//     try {
//       await axios.post(`http://localhost:5000//api/draft`, {
//         userId,
//         content: newContent,
//       });
//       console.log("Draft auto-saved!");
//     } catch (error) {
//       console.error("Error saving draft:", error);
//     }
//   };

//   // Update blog status (Example API Call)
//   const updateBlogStatus = async (status) => {
//     try {
//       await axios.post(`http://localhost:5000/api/edit-blog/${blogId}/status`, {
//         status,
//       });
//       console.log("Blog status updated!");
//     } catch (error) {
//       console.error("Error updating blog status:", error);
//     }
//   };

//   // Editor configuration
//   const config = useMemo(
//     () => ({
//       uploader: {
//         insertImageAsBase64URI: true,
//         imagesExtensions: ["jpg", "png", "jpeg", "gif", "svg", "webp"],
//       },
//       readonly: false,
//       placeholder: "Start typing...",
//       toolbarAdaptive: true,
//       toolbarSticky: true,
//       minHeight: 400,
//     }),
//     []
//   );

//   return (
//     <div>
//       <JoditEditor
//         ref={editor}
//         value={content}
//         config={config}
//         tabIndex={1}
//         onBlur={(newContent) => onContentChange(newContent)}
//       />

//       {/* Example Button to Update Blog Status */}
//       <button onClick={() => updateBlogStatus("published")}>
//         Publish Blog
//       </button>
//     </div>
//   );
// };

// export default JoditRichTextEditor;




// import React, { useRef, useMemo, useEffect } from "react";
// import JoditEditor from "jodit-react";
// // import { useAuth } from "../../Context/AuthContext";
// import { useAuth } from "../../Context/AuthContext";
// import { useCookies } from "react-cookie";

// const JoditRichTextEditor = ({ content, onContentChange }) => {
//     // const [cookies, setCookie, removeCookie] = useCookies(["myadmin"]);
//   const editor = useRef(null);
//    const [cookies] = useCookies(["email_id", "token"]);

//    console.log("cookies", cookies.email_id_admin);

//    const email_id = cookies.email_id_admin;



//     const { isLoggedIn, user } = useAuth();

//       console.log("user", user);

//   const config = useMemo(() => ({
//     uploader: {
//       insertImageAsBase64URI: true,
//     },
//     readonly: false,
//     placeholder: "Start typing...",
//     toolbarAdaptive: true,
//     toolbarSticky: true,
//     minHeight: 400,
//   }), []);

//   // Fetch draft on component load
//   useEffect(() => {
//     const fetchDraft = async () => {
//       try {
//         const response = await fetch(`http://localhost:5000/api/draft/${email_id}`);
//         if (response.ok) {
//           const data = await response.json();
//           onContentChange(data?.content || "");
//         }
//         console.log("response:", response);
//       } catch (error) {
//         console.error("Error fetching draft:", error);
//       }
//     };
//     fetchDraft();
//   }, [email_id]);

//   // Save draft to backend (real-time)
//   const saveDraft = async (newContent) => {
//     try {
//       await fetch("http://localhost:5000/api/draft", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ email_id, content: newContent }),
//       });
//       console.log("email_id", email_id);
//       console.log("content", content);
//       console.log("Draft auto-saved!");
//     } catch (error) {
//       console.error("Error saving draft:", error);
//     }
//   };

//   // Save on every keystroke
//   const handleEditorChange = (newContent) => {
//     onContentChange(newContent);
//     saveDraft(newContent);  // Save on every change
//   };

//   return (
//     <div>
//       <JoditEditor
//         ref={editor}
//         value={content}
//         config={config}
//         tabIndex={1}
//         onChange={handleEditorChange}
//       />
//     </div>
//   );
// };

// export default JoditRichTextEditor;


// import React, { useRef, useMemo, useEffect } from "react";
// import JoditEditor from "jodit-react";
// import { useAuth } from "../../Context/AuthContext";
// import { useCookies } from "react-cookie";

// const JoditRichTextEditor = ({ content, onContentChange }) => {
//   const editor = useRef(null);
//   const [cookies] = useCookies(["email_id_admin", "token"]);

//   const email_id = cookies.email_id_admin;

//   const { isLoggedIn, user } = useAuth();

//   const config = useMemo(() => ({
//     uploader: {
//       insertImageAsBase64URI: true,
//     },
//     readonly: false,
//     placeholder: "Start typing...",
//     toolbarAdaptive: true,
//     toolbarSticky: true,
//     minHeight: 400,
//   }), []);

//   // Fetch draft on component load
//   useEffect(() => {
//     const fetchDraft = async () => {
//       if (!email_id) return;
//       try {
//         const response = await fetch(`http://localhost:5000/api/draft/${email_id}`);
//         if (response.ok) {
//           const data = await response.json();
//           onContentChange(data?.content || "");
//         }
//       } catch (error) {
//         console.error("Error fetching draft:", error);
//       }
//     };
//     fetchDraft();
//   }, [email_id]);

//   // Save draft to backend
//   const saveDraft = async (newContent) => {
//     if (!email_id || !newContent) return;

//     try {
//       await fetch("http://localhost:5000/api/draft", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ email_id, content: newContent }),
//       });
//       console.log("Draft auto-saved!");
//     } catch (error) {
//       console.error("Error saving draft:", error);
//     }
//   };

//   // Save on every keystroke
//   const handleEditorChange = (newContent) => {
//     onContentChange(newContent);
//     saveDraft(newContent);  // Save on every change
//   };

//   // Save content before page unloads (refresh or close)
//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       saveDraft(editor.current?.value || content);
//       event.preventDefault();
//       event.returnValue = '';
//     };

//     window.addEventListener("beforeunload", handleBeforeUnload);

//     return () => {
//       window.removeEventListener("beforeunload", handleBeforeUnload);
//     };
//   }, [content]);

//   return (
//     <div>
//       <JoditEditor
//         ref={editor}
//         value={content}
//         config={config}
//         tabIndex={1}
//         onChange={handleEditorChange}
//       />
//     </div>
//   );
// };

// export default JoditRichTextEditor;
// import React, { useState, useEffect } from "react";
// import { useAuth } from "../Context/AuthContext";
// import "../InfoBook/Intro.css";
// import ShareModal from "../InfoBook/ShareModalBook";
// import { GiCheckMark } from "react-icons/gi";
// import Footer from "../FooterMain/Footer";
// import Header from "../componentbook/HeaderFilesBook/HeaderBook";
// import LoadingSpinner from "../LoadingSpinner";
// import { useNavigate } from "react-router-dom";
// import { useCookies } from "react-cookie";
// import MetaTags from "../DynamicMetaData/DynamicMetadata";
// // Image imports
// import typing1 from "../Ebook/i/typing1.png";
// import typing2 from "../Ebook/i/typing2.png";
// import typing3 from "../Ebook/i/typing3.png";
// import typing4 from "../Ebook/i/typing4.png";
// import typing5 from "../Ebook/i/typing5.png";
// import typing6 from "../Ebook/i/typing6.png";
// import typing7 from "../Ebook/i/typing7.png";
// import typing8 from "../Ebook/i/typing8.png";
// import typing9 from "../Ebook/i/typing9.png";

// import BuyButtonTyping from "./BuyButtonTyping"; // Import the BuyButtonTyping component
// import Modal from "react-bootstrap/Modal";
// import { IoIosChatbubbles } from "react-icons/io";
// import Chatbot from "../chatBot/Chatbot";

// function Typing1() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const { user } = useAuth(); // Get user details from Auth context
//   const [cookies] = useCookies(["token"]);
//   const [showChatbot, setShowChatbot] = useState(false);
//     const handleChatbotOpen = () => setShowChatbot(true);
//     const handleChatbotClose = () => setShowChatbot(false);
  

//   const images = [
//     typing1,
//     typing2,
//     typing3,
//     typing4,
//     typing5,
//     typing6,
//     typing7,
//     typing8,
//     typing9,
//   ];

//   useEffect(() => {
//     // Slider logic
//     const slideInterval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
//     }, 3000);

//     return () => clearInterval(slideInterval);
//   }, [images.length]);

//   useEffect(() => {
//     // Simulate loading completion
//     setLoading(false);
//   }, []);

//   useEffect(() => {
//     // Check product access on component load
//     const checkAccessTypingProduct = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Accept: "application/json",
//               Authorization: `Bearer ${cookies.token}`,
//             },
//             body: JSON.stringify({ product_id: "999" }), // Product ID is 999
//           }
//         );

//         if (response.ok) {
//           const { access: productAccess } = await response.json();
//           if (productAccess === "access") {
//             navigate("/typingexamselection");
//           }
//         } else {
//           console.log("Product access check failed");
//         }
//       } catch (error) {
//         console.error("Error checking product access:", error);
//       }
//     };

//     checkAccessTypingProduct();
//     setLoading(false); // Stop loading after access check
//   }, [navigate]);

//   if (loading) {
//     return <LoadingSpinner />;
//   }

//   // Debug log to check user details

//   return (
//     <>
//       <MetaTags type="typing" />
//       <Header />
//       <div className="container-body-intro">
//         <div className="container-body-for-intro">
//           <div className="for-intro-row1-image-introproduct">
//             <div className="row2-for-intro-product-typing">
//               <div className="for-image-intro-product-typing">
//                 <div className="image-container-typing">
//                   <img
//                     className="image-contaner-book-image-typing"
//                     src={images[currentSlide]}
//                     alt={`Slide ${currentSlide + 1}`}
//                   />
//                 </div>
//                 <div className="buy-it-now-from-buy-model-intro">
//                   <BuyButtonTyping userDetails={user} />{" "}
//                   {/* Passing user details to BuyButtonTyping */}
//                 </div>
//               </div>
//             </div>
//             <div className="row2-for-intro-product-typing">
//               <div className="name-of-the-product">
//                 <h2>
//                   Pinnacle Typing Software based on TCS Pattern: SSC CGL I CHSL
//                   Tier 2
//                 </h2>
//               </div>
//               <div className="price-details-intro">
//                 <p className="original-price-intro">₹999</p>
//                 <p className="discounted-price-intro"> ₹299</p>
//                 <div className="Value-deal">
//                   <p>VALUE DEAL! Save &nbsp;</p>
//                 </div>
//                 <div className="share-product">
//                   <ShareModal />
//                 </div>
//               </div>
//               <div className="intro-of-the-product">
//                 <p className="intro-1-typing">
//                   This is unique typing software designed specially for SSC CGL
//                   Tier 2 and SSC CHSL Tier 2 exam. Important features of this
//                   typing software are:
//                 </p>
//                 <p className="intro-2">
//                   <span
//                     style={{
//                       color: "rgb(224, 62, 45)",
//                       fontSize: "16px",
//                       fontWeight: "600",
//                     }}
//                   >
//                     Please note: Rs 299 fee is for one year subscription as a
//                     special offer. For those who could not purchase till today
//                     11:59 pm, fee will be Rs 999/- for one year i.e. yearly
//                     subscription.&nbsp;
//                   </span>
//                 </p>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   <a
//                     href="https://testseries.ssccglpinnacle.com"
//                     target="_blank"
//                     rel="noopener"
//                   >
//                     <strong>TCS like interface typing software</strong>
//                   </a>
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   Exam wise typing like SSC CGL Tier 2, SSC CHSL Tier 2
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   Category wise report like UR, OBC, SC etc
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   Previous year papers /paragraph for typing
//                 </div>
//                 {/* Chatbot Icon */}
//                 <div className='chatbot_icon'>
//                           <IoIosChatbubbles
//                             onClick={handleChatbotOpen}
//                             size={50}
//                             style={{
//                               cursor: "pointer",
//                               position: "fixed",
//                               bottom: "20px",
//                               right: "20px",
//                               color: "#007bff",
//                             }}
//                           />
//                         </div>
//                                   {/* Chatbot Modal */}
//           <Modal show={showChatbot} onHide={handleChatbotClose} centered>
//             <Modal.Header closeButton></Modal.Header>
//             <Modal.Body>
//               <Chatbot />
//             </Modal.Body>
//             {/* <Modal.Footer>
//           <Button variant="secondary" onClick={handleChatbotClose}>
//             Close
//           </Button>
//         </Modal.Footer> */}
//           </Modal>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   Practice sets based on real exam pattern typing
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />8 types analysis report
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   Timing, error as per ssc syllabus and instructions.
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   Can do typing through{" "}
//                   <a
//                     href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle"
//                     target="_blank"
//                     rel="noopener"
//                   >
//                     Pinnacle exam preparation mobile app
//                   </a>{" "}
//                   also if anybody does not have laptop/desktop
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <Footer />
//       </div>
//     </>
//   );
// }

// export default Typing1;


import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import "../InfoBook/Intro.css";
import ShareModal from "../InfoBook/ShareModalBook";
import { GiCheckMark } from "react-icons/gi";
import Footer from "../FooterMain/Footer";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import LoadingSpinner from "../LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import MetaTags from "../DynamicMetaData/DynamicMetadata";
// Image imports
import typing1 from "../Ebook/i/typing1.png";
import typing2 from "../Ebook/i/typing2.png";
import typing3 from "../Ebook/i/typing3.png";
import typing4 from "../Ebook/i/typing4.png";
import typing5 from "../Ebook/i/typing5.png";
import typing6 from "../Ebook/i/typing6.png";
import typing7 from "../Ebook/i/typing7.png";
import typing8 from "../Ebook/i/typing8.png";
import typing9 from "../Ebook/i/typing9.png";
import swal from 'sweetalert';
import BuyButtonTyping from "./BuyButtonTyping"; // Import the BuyButtonTyping component
import Modal from "react-bootstrap/Modal";
import { IoIosChatbubbles } from "react-icons/io";
import Chatbot from "../chatBot/Chatbot";

function Typing1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { user } = useAuth(); // Get user details from Auth context
  const [cookies] = useCookies(["token"]);
  const [showChatbot, setShowChatbot] = useState(false);
    const handleChatbotOpen = () => setShowChatbot(true);
    const handleChatbotClose = () => setShowChatbot(false);
    const [finalPrice, setFinalPrice] = useState(299);
    const [totalCoins, setTotalCoins] = useState(0);
    const [redeemCoins, setRedeemCoins] = useState(0);
    const [savedAmount, setSavedAmount] = useState(0);
    const [showMessage, setShowMessage] = useState(false);

  const images = [
    typing1,
    typing2,
    typing3,
    typing4,
    typing5,
    typing6,
    typing7,
    typing8,
    typing9,
  ];



  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/coins/${user?._id}`,
          {
            headers: {
              Authorization: `Bearer ${cookies.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch coin data");
        }

        const result = await response.json();
        const coins =
          result.data?.reduce((sum, item) => sum + (item.coins || 0), 0) || 0;
        setTotalCoins(coins);
      } catch (error) {
        console.error("Error fetching coins:", error.message);
      }
    };

    if (user?._id) {
      fetchCoins();
    }
  }, [user, cookies.token]);


  useEffect(() => {
    // Slider logic
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [images.length]);

  useEffect(() => {
    // Simulate loading completion
    setLoading(false);
  }, []);

  useEffect(() => {
    // Check product access on component load
    const checkAccessTypingProduct = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
            body: JSON.stringify({ product_id: "999" }), // Product ID is 999
          }
        );

        if (response.ok) {
          const { access: productAccess } = await response.json();
          if (productAccess === "access") {
            navigate("/typingexamselection");
          }
        } else {
          console.log("Product access check failed");
        }
      } catch (error) {
        console.error("Error checking product access:", error);
      }
    };

    checkAccessTypingProduct();
    setLoading(false); // Stop loading after access check
  }, [navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  // const handleRedeemCoins = () => {
  //   if (
  //     redeemCoins <= totalCoins &&
  //     redeemCoins >= 0 &&
  //     finalPrice >= redeemCoins
  //   ) {
  //     setTotalCoins(totalCoins - redeemCoins); // Deduct redeemed coins from total coins
  //     setSavedAmount(redeemCoins); // Set the saved amount
  //     setFinalPrice(finalPrice - redeemCoins); // Reduce the final price
  //     setRedeemCoins(0); // Reset input field
  //     setShowMessage(true); // Show the celebration message

  //     // Hide the message after 3 seconds
  //     setTimeout(() => {
  //       setShowMessage(false);
  //     }, 3000);
  //   } else {
  //     alert("Invalid redeem amount. Please enter a valid number of coins.");
  //   }
  // };
  
  const handleRedeemCoins = () => {
    if (
      redeemCoins <= totalCoins &&
      redeemCoins >= 0 &&
      finalPrice - redeemCoins >= 1 
    ) {
      setTotalCoins(totalCoins - redeemCoins); 
      setSavedAmount(redeemCoins); 
      setFinalPrice(finalPrice - redeemCoins); 
      setRedeemCoins(0); 
      setShowMessage(true); 
  
      
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } else {
      swal({
        title: "Payment Error!",
        text: "At least 1 rupee is required to complete the payment.",
        icon: "warning",
        button: "OK",
      });
    }
  };
  
  return (
    <>
      <MetaTags type="typing" />
      <Header />
      <div className="container-body-intro">
        <div className="container-body-for-intro">
          <div className="for-intro-row1-image-introproduct">
            <div className="row2-for-intro-product-typing">
              <div className="for-image-intro-product-typing">
                <div className="image-container-typing">
                  <img
                    className="image-contaner-book-image-typing"
                    src={images[currentSlide]}
                    alt={`Slide ${currentSlide + 1}`}
                  />
                </div>
                <div className="buy-it-now-from-buy-model-intro">
                  <BuyButtonTyping userDetails={user} 
                  redeemcoins={savedAmount} />{" "}
                  {/* Passing user details to BuyButtonTyping */}
                </div>
              </div>
            </div>
            <div className="row2-for-intro-product-typing">
              <div className="name-of-the-product">
                <h2>
                  Pinnacle Typing Software based on TCS Pattern: SSC CGL I CHSL
                  Tier 2
                </h2>
              </div>
              <div className="price-details-intro">
                <p className="original-price-intro">₹999</p>
                <p className="discounted-price-intro">₹{finalPrice}</p>
                <div className="Value-deal">
                  <p>VALUE DEAL! Save &nbsp;</p>
                </div>
                <div className="share-product">
                  <ShareModal />
                </div>
              </div>
              <h5
  style={{
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#DAA520",
    textShadow: "1px 1px 2px #555",
    fontFamily: "'Poppins', sans-serif",
  }}
>
  {user ? (
    <>
      Total Coins Earned: {totalCoins}
      {totalCoins === 0 ? (
        <span
          style={{
            color: "blue",
            marginLeft: "10px",
            fontSize: "1rem",
            fontWeight: "normal",
          }}
        >
          Scan Pinnacle Book barcode to earn coins!
        </span>
      ) : (
        <a
          href="https://ssccglpinnacle.com/coins"
          target="_blank"
          style={{
            marginLeft: "10px",
            fontSize: "1rem",
            color: "#008080",
            fontWeight: "normal",
          }}
        >
          Check Coins Detail
        </a>
      )}
    </>
  ) : (
    <a
      href="https://testportal.ssccglpinnacle.com/login"
      style={{
        color: "red",
        fontSize: "1.2rem",
        fontWeight: "normal",
        textDecoration: "none",
      }}
    >
      login and use your coins!
    </a>
  )}
</h5>


              <div className="redeem-section">
                <input
                  type="number"
                  className="redeem-input"
                  value={redeemCoins || ""}
                  onChange={(e) =>
                    setRedeemCoins(Number(e.target.value) || 0)
                  }
                  placeholder="Coins to redeem"
                  onWheel={(e) => e.target.blur()}
                />
                <button
                  className="redeem-button"
                  onClick={handleRedeemCoins}
                  disabled={redeemCoins <= 0 || redeemCoins > totalCoins}
                >
                  Redeem Coins
                </button>
              </div>
              {showMessage && (
                <div className="celebration-message">
                  🎉 Congrats! You have saved ₹{savedAmount}! 🎉
                </div>
              )}
              <div className="intro-of-the-product">
                <p className="intro-1-typing">
                  This is unique typing software designed specially for SSC CGL
                  Tier 2 and SSC CHSL Tier 2 exam. Important features of this
                  typing software are:
                </p>
                <p className="intro-2">
                  <span
                    style={{
                      color: "rgb(224, 62, 45)",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Please note: Rs 299 fee is for one year subscription as a
                    special offer. For those who could not purchase till today
                    11:59 pm, fee will be Rs 999/- for one year i.e. yearly
                    subscription.&nbsp;
                  </span>
                </p>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  <a
                    href="https://testseries.ssccglpinnacle.com"
                    target="_blank"
                    rel="noopener"
                  >
                    <strong>TCS like interface typing software</strong>
                  </a>
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Exam wise typing like SSC CGL Tier 2, SSC CHSL Tier 2
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Category wise report like UR, OBC, SC etc
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Previous year papers /paragraph for typing
                </div>
                {/* Chatbot Icon */}
                <div className='chatbot_icon'>
                          <IoIosChatbubbles
                            onClick={handleChatbotOpen}
                            size={50}
                            style={{
                              cursor: "pointer",
                              position: "fixed",
                              bottom: "20px",
                              right: "20px",
                              color: "#007bff",
                            }}
                          />
                        </div>
                                  {/* Chatbot Modal */}
          <Modal show={showChatbot} onHide={handleChatbotClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Chatbot />
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleChatbotClose}>
            Close
          </Button>
        </Modal.Footer> */}
          </Modal>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Practice sets based on real exam pattern typing
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />8 types analysis report
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Timing, error as per ssc syllabus and instructions.
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  Can do typing through{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle"
                    target="_blank"
                    rel="noopener"
                  >
                    Pinnacle exam preparation mobile app
                  </a>{" "}
                  also if anybody does not have laptop/desktop
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Typing1;

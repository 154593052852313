import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./TypingTransactionModal.module.css";
import { useAuth } from "../Context/AuthContext";

const TypingTransactionModal = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [countdown, setCountdown] = useState(8); // Countdown timer
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");

  const closeModal = () => navigate(-1);
  const redirectTo = (path) => navigate(path);

  // Fetch transaction details
  useEffect(() => {
    if (!orderId) {
      setError("Order ID not found in URL");
      setLoading(false);
      return;
    }

    fetch(`https://auth.ssccglpinnacle.com/api/typing-transaction/${orderId}`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Transaction not found");
          }
          throw new Error("Server error. Please try again later.");
        }
        return response.json();
      })
      .then((data) => {
        setTransactionData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transaction:", error);
        setError(error.message || "Failed to fetch transaction details.");
        setLoading(false);
      });
  }, [orderId]);

  // Countdown logic for redirection
  useEffect(() => {
    if (!transactionData) return;

    const redirectPath = ["Completed", "Paid", "SUCCESS"].includes(
      transactionData.paymentStatus
    )
      ? "/typingexamselection"
      : "/typing";

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          redirectTo(redirectPath);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [transactionData]);

  // Loading state
  if (loading) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2>Loading transaction details...</h2>
          <div className={styles.spinner}></div>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2>Error</h2>
          <p>{error}</p>
          <button
            className={styles.redirectButton}
            aria-label="Close Modal"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  const paymentStatus = transactionData?.paymentStatus;
  const isPaymentSuccessful = ["Completed", "Paid", "PAID", "SUCCESS"].includes(
    paymentStatus
  );
  const getStatusIconClass = () =>
    isPaymentSuccessful ? styles.successIcon : styles.failedIcon;

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.iconWrapper}>
          <div className={getStatusIconClass()}>
            <span>{isPaymentSuccessful ? "✓" : "✗"}</span>
          </div>
        </div>
        <h2
          className={`${styles.statusText} ${
            isPaymentSuccessful ? styles.success : styles.failed
          }`}
        >
          {isPaymentSuccessful ? "Payment Successful" : "Payment Failed"}
        </h2>
        {isPaymentSuccessful ? (
          <div>
            <p>Thank you for choosing Pinnacle!</p>
            <p>₹{transactionData.orderAmount}/-</p>
            <p>
              Redirecting to Typing Dashboard in <span>{countdown}</span>{" "}
              seconds...
            </p>
            <button
              className={styles.redirectButton}
              onClick={() => redirectTo("/typingexamselection")}
            >
              Go to Typing Dashboard
            </button>
          </div>
        ) : (
          <div>
            <p>
              Redirecting to Home in <span>{countdown}</span> seconds...
            </p>
            <div className={styles.spinner}></div>
            <button
              className={styles.redirectButton}
              onClick={() => redirectTo("/typing")}
            >
              Go back to Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TypingTransactionModal;

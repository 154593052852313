import React, { useState, useRef, useEffect } from "react";
import "./Row5.css";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import gsap from "gsap";

function Row5() {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);
  const [courseData, setcourseData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/rc/courses"
        );
        const data = await response.json();

        // Ensure data is set correctly
        setcourseData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const slide = (shiftMultiplier) => {
    const cardWidth =
      scrl.current.querySelector(".card-for-course").offsetWidth; // Width of one card
    const shift = cardWidth * 3 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;

    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;

    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }

    if (targetScroll < 0) {
      targetScroll = 0;
    }

    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);

        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);

    // Check if the scroll has reached the end
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <>
      <section className="section-suggest-course">
        <div className="container-fluid-course">
          <div className="foruppersection">
            <div className="col-md-8 section-suggest-course-col-8">
              <p className="col-md-8 section-suggest-course-p">
                Suggested for You: Courses
              </p>
            </div>
          </div>
          <div className="container-for-button">
            {scrollX !== 5 && (
              <button className="prev" onClick={() => slide(-1)}>
                <AiOutlineLeft color="black" fontSize="1.5em" />
              </button>
            )}

            <div className="forcontainercourse">
              <ul
                className="cards-for-course"
                ref={scrl}
                onScroll={scrollCheck}
              >
                {courseData.map((course, index) => (
                  <li className="card-for-course" key={index}>
                    <div className="list-img-container">
                      <img
                        src={course.englishCoverImage}
                        className="list-img"
                        alt={course.altText}
                        title={course.courseTitle}
                      />
                      <img
                        src={course.hindiCoverImage}
                        className="list-img"
                        alt={course.altText}
                        title={course.courseTitle}
                      />
                    </div>

                    <div className="list-title">
                      <p className="detail-title-web">{course.courseTitle}</p>
                      <div className="detail-card-meta-container">
                        <div className="details-card">
                          <span
                            className="all-listing-price"
                            style={{
                              color: "#888",
                              textDecoration: "line-through",
                            }}
                          >
                            Price: ₹{course.mrp}&nbsp;
                          </span>
                          <span
                            className="maximum-retail-price"
                            style={{
                              color: "#4CAF50",
                              fontWeight: "bold",
                              fontSize: "1.2em",
                            }}
                          >
                            ₹{course.price}
                          </span>
                        </div>

                        {course._id && (
                          <Link to={`/videos/${course.SEOPermalink}`}>
                            <button className="buy_now open_modal_click">
                              Buy Now
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {!scrolEnd && (
              <button className="next" onClick={() => slide(1)}>
                <AiOutlineRight color="black" fontSize="1.5em" />
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Row5;

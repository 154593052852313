import React, { useEffect, useState } from "react";
import styles from "./NavBar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { TbHome } from "react-icons/tb";

export default function NavbarVC() {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/categories"
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <nav className={styles.navbar}>
      <button
        onClick={() => navigate("/videos")}
        className={`${styles.navItem} ${styles.homeButton}`}
      >
        <TbHome size={22} /> Home
      </button>

      <div className={styles.categories}>
        {categories.map((category) => (
          <Link
            key={category._id}
            to={`/videos/category/${category._id}`}
            className={styles.navItem}
          >
            {category.categoryTitle}
          </Link>
        ))}
      </div>
    </nav>
  );
}


// import React, { useEffect, useState } from 'react';
// import styles from './NavBar.module.css';
// import { Link, useNavigate } from "react-router-dom";
// import { MdOutlineArrowForwardIos } from "react-icons/md";
// import { TbHome } from "react-icons/tb";

// export default function NavbarVC() {
//   const [categories, setCategories] = useState([]);
//   const navigate = useNavigate();

//   // Fetch categories from API
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         // const response = await fetch('https://auth.ssccglpinnacle.com/categories');
//         const response = await fetch('https://auth.ssccglpinnacle.com/categories');
//         const data = await response.json();
//         setCategories(data);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   return (
//     <div className={styles["EM-NavBar"]}>
//       <div className={styles["EM-navLinks"]}>
//         <button onClick={() => navigate('/videos')} className={styles["Video-home-button"]}>
//           <TbHome size={30} /> Home
//         </button>
//       </div>

//       {categories.map((category) => (
//         <React.Fragment key={category._id}>
//           <div><MdOutlineArrowForwardIos /></div>
//           <div className={styles["EM-navLinks"]}>
//             <Link 
//               to={`/videos/category/${category._id}`}  // Navigate using the category ID
//               className={styles["EM-navLink"]}
//             >
//               {category.categoryTitle}
//             </Link>
//           </div>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// }


// import React, { useEffect, useState } from 'react';
// import styles from './NavBar.module.css';
// import { Link } from "react-router-dom";
// import { MdOutlineArrowForwardIos } from "react-icons/md";
// import { TbHome } from "react-icons/tb";
// import { useNavigate } from 'react-router-dom';

// export default function NavbarVC() {
//   const [categories, setCategories] = useState([]);
//   const navigate = useNavigate();

//   // Fetch categories from API
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await fetch('https://auth.ssccglpinnacle.com/categories');
//         const data = await response.json();
//         setCategories(data);  // Store the fetched categories in state
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   return (
//     <div className={styles["EM-NavBar"]}>
//       <div className={styles["EM-navLinks"]}>
//         <button onClick={() => navigate('/videos')} className={styles["Video-home-button"]}>
//           <TbHome size={30} /> Home
//         </button>
//       </div>

//       {categories.map((category, index) => (
//         <React.Fragment key={category._id}>
//           <div><MdOutlineArrowForwardIos /></div>
//           <div className={styles["EM-navLinks"]}>
//             <Link to={`/videos/category/${category.categoryTitle}`} className={styles["EM-navLink"]}>
//               {category.categoryTitle}
//             </Link>
//           </div>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// }


// import React from 'react'
// import styles from './NavBar.module.css'
// import { Link } from "react-router-dom";
// import { MdOutlineArrowForwardIos } from "react-icons/md";
// import { TbHome } from "react-icons/tb";
// import { useNavigate} from 'react-router-dom';

// export default function NavbarVC() {
//   const navigate = useNavigate();
//   return (
//     <div className={styles["EM-NavBar"]}>
//     <div className={styles["EM-navLinks"]}>
//       {/* <Link to="/videos"className={styles["EM-navLink"]} ><TbHome className={styles[""]} size={30}/></Link>*/}
//       <button onClick={() => navigate('/videos')} className={styles["Video-home-button"]}>Home</button>
//     </div>
//     <div><MdOutlineArrowForwardIos/></div>
//     <div className={styles["EM-navLinks"]}><Link to="/videos/category/SSC" className={styles["EM-navLink"]}>SSC</Link></div>
//     <div><MdOutlineArrowForwardIos/></div>
//     <div className={styles["EM-navLinks"]}><Link to='/videos/category/Railway' className={styles["EM-navLink"]}>Railway</Link></div>
//     <div><MdOutlineArrowForwardIos/></div>
//     <div className={styles["EM-navLinks"]}><Link to='/videos/category/DelhiPolice' className={styles["EM-navLink"]}>Delhi Police</Link></div>
//     <div><MdOutlineArrowForwardIos/></div>
//     <div className={styles["EM-navLinks"]}><Link to='/videos/category/UpPolice' className={styles["EM-navLink"]}>Up Police</Link></div>
//     <div><MdOutlineArrowForwardIos/></div>
//     <div className={styles["EM-navLinks"]}><Link to='/videos/category/Ctet' className={styles["EM-navLink"]}>CTET</Link></div>
//     </div>
//   )
// }
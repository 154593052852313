import React, { useEffect, useState } from "react";
import styles from "./PramotionSlider.module.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { BiShareAlt } from "react-icons/bi";
import {
  IoIosShareAlt,
  IoLogoFacebook,
  IoLogoTwitter,
  IoIosMail,
  IoLogoWhatsapp,
  IoMdClose,
} from "react-icons/io";
import VideoTimer from "../component/HomePage01/Header/Timer";

const PramotionSlider = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const baseURL = "https://auth.ssccglpinnacle.com";
  // const baseURL = "https://auth.ssccglpinnacle.com";

  const handleShareClick = () => {
    setShareModalOpen(true);
    console.log("Share button clicked");
  };

  const closeShareModal = () => {
    setShareModalOpen(false);
  };
  const handleCopyClick = () => {
    navigator.clipboard.writeText(shareUrl);
    alert("Link copied to clipboard");
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        // Make the API call to fetch banners
        const response = await fetch(`${baseURL}/api/banners`);
        const result = await response.json();

        // Log the entire result to inspect its structure
        console.log("Fetched banners:", result);

        if (response.ok) {
          // If 'result' is an array, use it; otherwise, try to access 'banners'
          const bannersData = Array.isArray(result) ? result : result.banners;

          if (bannersData && Array.isArray(bannersData)) {
            // Sort the banners by 'order' (if not already sorted by the backend)
            const sortedData = bannersData.sort((a, b) => a.order - b.order);

            // Update the state with the sorted data
            setData(sortedData);
          } else {
            console.error(
              "Banners data is undefined, not an array, or not in the expected format."
            );
          }
        } else {
          console.error(
            `Failed to fetch banners. Server responded with status: ${response.status}`
          );
        }
      } catch (error) {
        // Log any network or parsing errors
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, [baseURL]);
  const shareUrl =
    data[currentIndex]?.course &&
    `https://ssccglpinnacle.com/videos/${data[currentIndex].course.SEOPermalink}`;

  const shareText = data[currentIndex]?.courseTitle
    ? `Check out this course I'm taking on ${data[currentIndex].courseTitle}! ${shareUrl}`
    : "Check out this amazing course!";

  const carouselInfiniteScroll = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(carouselInfiniteScroll, 10000);
    return () => clearInterval(interval);
  }, [data]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareText
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareText
    )}`;
    window.open(twitterUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}&quote=${encodeURIComponent(shareText)}`;
    window.open(facebookUrl, "_blank");
  };
  return (
    <div className={styles["Pramotion-slider-fullpage"]}>
      <div className={styles.slider}>
        <div className={styles["carousel-container"]}>
          <div onClick={goToPrevious} className={styles.leftArrowStyles}>
            <AiOutlineLeft />
          </div>
          <div onClick={goToNext} className={styles.rightArrowStyles}>
            <AiOutlineRight />
          </div>

          <div className={styles["carousel-inner1"]}>
            <div className={styles["carousel-inner"]}>
              {data.map((item, index) => (
                <div
                  className={`${styles["carousel-item"]} ${
                    index === currentIndex ? styles.active : ""
                  }`}
                  key={index}
                  style={{
                    display: index === currentIndex ? "flex" : "none",
                  }}
                >
                  <div className={styles.description}>
                    {/* Clickable Course Title */}
                    <h1
                      onClick={() =>
                        navigate(`/videos/${item.course.SEOPermalink}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {item.courseTitle}
                    </h1>

                    {/* Clickable Launching Offer Button */}
                    <div className={styles.buttonText1}>
                      <button
                        onClick={() =>
                          navigate(`/videos/${item.course.SEOPermalink}`)
                        }
                        className={styles.buttonText}
                      >
                        {item.buttonText || "Launching Offer"}
                      </button>
                      <button
                        className={styles["Share-button"]}
                        onClick={handleShareClick}
                      >
                        <BiShareAlt
                          size={25}
                          className={styles["Share-icon"]}
                        />
                      </button>

                      {isShareModalOpen && (
                        <div
                          className={styles.shareModalOverlay}
                          onClick={(e) => {
                            if (
                              e.target.classList.contains(
                                styles.shareModalOverlay
                              )
                            ) {
                              closeShareModal();
                            }
                          }}
                        >
                          <div className={styles.shareModal}>
                            <div className={styles.shareModalHeader}>
                              <h2>Share this course</h2>
                              <button
                                onClick={closeShareModal}
                                className={styles.closeButton}
                              >
                                <IoMdClose size={20} />
                              </button>
                            </div>
                            <div className={styles.urlCopy}>
                              <input
                                type="text"
                                value={shareUrl}
                                readOnly
                                className={styles.shareInput}
                              />
                              <button
                                onClick={handleCopyClick}
                                className={styles.copyButton}
                              >
                                Copy
                              </button>
                            </div>
                            <div className={styles.socialMediaIcons}>
                              <button
                                className={styles.iconButton}
                                onClick={shareOnFacebook}
                              >
                                <IoLogoFacebook
                                  className={styles.mediaIcons}
                                  size={20}
                                />
                              </button>
                              <button
                                className={styles.iconButton}
                                onClick={shareOnTwitter}
                              >
                                <IoLogoTwitter
                                  className={styles.mediaIcons}
                                  size={20}
                                />
                              </button>
                              <button
                                className={styles.iconButton}
                                onClick={shareOnWhatsApp}
                              >
                                <IoLogoWhatsapp
                                  className={styles.mediaIcons}
                                  size={20}
                                />
                              </button>
                              <button className={styles.iconButton}>
                                <IoIosMail
                                  size={20}
                                  className={styles.mediaIcons}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Clickable Images */}
                  <div className={styles.imagediv}>
                    <img
                      src={item.hindiCoverImage}
                      alt={item.courseTitle}
                      className={styles.image}
                      onClick={() =>
                        navigate(`/videos/${item.course.SEOPermalink}`)
                      }
                      style={{ cursor: "pointer" }} // Add pointer cursor
                    />
                    <img
                      src={item.englishCoverImage}
                      alt={item.courseTitle}
                      className={styles.image}
                      onClick={() =>
                        navigate(`/videos/${item.course.SEOPermalink}`)
                      }
                      style={{ cursor: "pointer" }} // Add pointer cursor
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.dotsContainerStyles}>
            {data.map((_, slideIndex) => (
              <div
                className={`${styles.dotStyles} ${
                  currentIndex === slideIndex ? styles.activeDot : ""
                }`}
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PramotionSlider;

import React, { useState } from "react";
import Modal from "react-modal";
import {
  FaWhatsapp,
  FaFacebook,
  FaTelegram,
  FaEnvelope,
  FaTimes,
} from "react-icons/fa";
import "./ShareModal.css";
import {
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

const ShareModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const url = window.location.href;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const shareLink = () => {
    console.log("Shared URL:", url);
  };

  return (
    <>
      <button className="share-button" onClick={openModal}>
        Share
      </button>

      <Modal
        className="modal-for-intro"
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <div className="model-open-intro">
          <h2>Share</h2>
          <input
            className="input-field"
            type="text"
            placeholder="Enter URL"
            value={url}
          />
          <div>
            <WhatsappShareButton url={url}>
              <button className="share-button">
                <FaWhatsapp className="icon-for-sharing" /> Share on WhatsApp
              </button>
            </WhatsappShareButton>

            <FacebookShareButton url={url}>
              <button className="share-button" onClick={shareLink}>
                <FaFacebook className="icon-for-sharing" /> Share on Facebook
              </button>
            </FacebookShareButton>

            <TelegramShareButton url={url}>
              <button className="share-button" onClick={shareLink}>
                <FaTelegram className="icon-for-sharing" /> Share on Telegram
              </button>
            </TelegramShareButton>

            <EmailShareButton url={url}>
              <button className="share-button">
                <FaEnvelope className="icon-for-sharing" /> Share via Email
              </button>
            </EmailShareButton>
          </div>
          <button className="close-button-for-share" onClick={closeModal}>
            <FaTimes className="close-icon-intro" />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ShareModal;

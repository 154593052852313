// import React, { useState, useEffect } from "react";
// import { useCart } from "../../Context/CartContext";
// import "./Intro.css";
// import { useParams, useNavigate } from "react-router-dom";
// import Row2intro from "./Row2intro2";
// import Row3intro from "./Row3intro";
// import Row4intro from "./Row4intro";
// import ShareModal from "./ShareModal";
// import { GiCheckMark } from "react-icons/gi";
// import RatingReview from "./RatingReview";
// import Row7intro from "./Row7intro";

// import Footer from "../../FooterMain/Footer";
// import Header from "../../component/HeaderFilesEbook/Header";
// import Swal from "sweetalert2";
// import { useAuth } from "../../Context/AuthContext";
// import LoadingSpinner from "../LoadingSpinner";
// import { useCookies } from "react-cookie";
// import ReactImageMagnify from "react-image-magnify";
// import axios from "axios";

// function Intro({ onClose, onAddToCart }) {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const [cookies] = useCookies(["token", "email_id"]);
//   const [loading, setLoading] = useState(true);
//   const { cartItems, cartCount, removeItem } = useCart();
//   const [isAddingToCart, setIsAddingToCart] = useState(false);
//   const { isLoggedIn, user } = useAuth();
//   const [isZoomed, setIsZoomed] = useState(false);
//   const [infoData, setInfoData] = useState([]);
//   const [cardData, setCardData] = useState([]);
//   const [selectedInfoData, setselectedInfoData] = useState([]);
//   const [selectedCardData, setselectedCardData] = useState([]);
//   const [purchase, setPurchase] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(
//     "SSC CGL Tier 2 English | 40 Tests"
//   );
//   const [imagesfor, setImagesfor] = useState([]);
//   const [selectedImagefor, setSelectedImagefor] = useState(null);

//   const {cardId, category, subject, title} = useParams();

//   console.log("category: " , category);
//   console.log("subject: " , subject);
//   console.log("title: " , title);

//   const { addToCart } = useCart();
//   const navigate = useNavigate();

//   const formatForComparison = (value) => {
//     return value?.toLowerCase().replace(/[-\s]/g, "") || "";
//   };

//     const fetchEbookData = async () => {
//       const payload = {
//         user,
//         isLoggedIn,
//         category,
//         subject,
//         title,
//         permalink: `/${category}/${subject}/${title}`,
//       };

//       console.log("USER", user)
//       console.log("isLoggedIn", isLoggedIn)
//       console.log("category", category)
//       console.log("subject", subject)
//       console.log("title", title)

//       try {
//         setLoading(true);

//         const response = await axios.post("http://localhost:5000/api/v1/getEbookDetail",
//           payload // Pass payload as query parameters
//         );

//         console.log("RESPONSE", response);
//         if (!response.data) {
//             Swal.fire({
//               icon: "warning",
//               title: "No Data Found",
//               text: "The requested book details are not available.",
//               confirmButtonText: "Close",
//             });
//             return ;
//         } else {

//             setselectedCardData(response.data.combinedData)
//             // If response contains data, show success message
//             Swal.fire({
//               icon: "success",
//               title: "Thank you!",
//               text: response.data.message || "Book details retrieved successfully!",
//               confirmButtonText: "Close",
//             });
//           // If response.data is not available, show an alert
//         }
//       } catch (error) {
//         // Handle error
//         const errorMessage =
//           error.response?.data?.message || "Something went wrong. Please try again later.";

//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: errorMessage,
//           confirmButtonText: "Retry",
//         });
//       } finally {
//         setLoading(false);

//       }
//     };

//     useEffect(() => {
//       fetchEbookData();
//     }, [title]);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         setLoading(true);

// //         // Fetch the ebook data
// //         const ebookResponse = await fetch(
// //           "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
// //         );
// //         if (!ebookResponse.ok) {
// //           throw new Error(
// //             `Failed to fetch ebooks. Status: ${ebookResponse.status}`
// //           );
// //         }
// //         const ebookData = await ebookResponse.json();
// //         setCardData(ebookData);

// //         // Fetch the book data
// //         const bookResponse = await fetch(
// //           "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
// //         );
// //         if (!bookResponse.ok) {
// //           throw new Error(
// //             `Failed to fetch books. Status: ${bookResponse.status}`
// //           );
// //         }
// //         const bookData = await bookResponse.json();

// //         // Format the parameters for comparison
// //         const formattedCategory = formatForComparison(category);
// //         const formattedSubject = formatForComparison(subject);
// //         const formattedTitle = formatForComparison(title);

// //         // Find the ebook that matches the parameters
// //         const selectedEbook = ebookData.find((item) => {
// //           const formattedItemCategory = formatForComparison(item.category);
// //           const formattedItemSubject = formatForComparison(item.subject);
// //           const formattedItemTitle = formatForComparison(item.title);

// //           return (
// //             formattedItemCategory === formattedCategory &&
// //             formattedItemSubject === formattedSubject &&
// //             formattedItemTitle === formattedTitle
// //           );
// //         });

// //         // Set the selected ebook data
// //         setselectedCardData(selectedEbook ? [selectedEbook] : []);

// //         // Find the book with matching SKU
// //         if (selectedEbook) {
// //           const matchedBook = bookData.find(
// //             (book) => book.sku === selectedEbook.sku
// //           );
// //           if (matchedBook) {
// //             setselectedInfoData([matchedBook]);
// //           } else {
// //             setselectedInfoData([]);
// //           }
// //         }

// //         // console.log("my info data extracted from book", selectedInfoData);

// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching data:", error);
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, [title]);

// //   useEffect(() => {
// //     const imagesArray = [
// //       selectedInfoData[0]?.image1,
// //       selectedInfoData[0]?.image2,
// //       selectedInfoData[0]?.image3,
// //       selectedInfoData[0]?.image4,
// //       selectedInfoData[0]?.image5,
// //     ].filter((image) => image !== undefined);

// //     setImagesfor(imagesArray);
// //   }, [selectedInfoData]);

//   useEffect(() => {
//     const imagesArray = [
//       selectedCardData?.image1,
//       selectedCardData?.image2,
//       selectedCardData?.image3,
//       selectedCardData?.image4,
//       selectedCardData?.image5,
//     ].filter((image) => image !== undefined);

//     setImagesfor(imagesArray);
//   }, [selectedCardData]);

//   useEffect(() => {
//     setSelectedImagefor(imagesfor[0] || null);
//   }, [imagesfor]);

//   const handleThumbnailClick = (image1) => {
//     setSelectedImagefor(image1);
//   };

//   const handleAddToCart = async (ebookId) => {
//     try {
//       console.log("isLoggedIn",isLoggedIn);
//       console.log("ebookId",ebookId);
//       console.log("user",user);
//       if (!isLoggedIn || !user || !user._id) {
//         Swal.fire({
//           icon: "info", // You can change this to 'info', 'success', 'warning', etc.
//           title: "Note",
//           text: "Please log in to add items to your cart.",
//         });
//         return;
//       }

//       setIsAddingToCart(true);

//       const response = await fetch(
//         // `https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`,
//         `http://localhost:5000/api/add-to-cart-ebook/${user.email_id}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${cookies.token}`,
//           },
//           body: JSON.stringify({ ebookId }),
//         }
//       );

//       const data = await response.json();

//       if (!response.ok) {
//         console.log("Error adding to cart:", data);
//         Swal.fire({
//           icon: "info", // You can change this to 'info', 'success', 'warning', etc.
//           title: "Note",
//           text: `Failed to add item to cart. ${data.error}.`,
//         });
//         navigate("/ebooks/cart");
//       } else {
//         console.log("data response is Ok", data);
//         Swal.fire({
//           icon: "success",
//           title: "Success",
//           text: "Item added to cart successfully!",
//         });
//         navigate("/ebooks/cart");
//       }
//       // if (response.ok) {
//       //   Swal.fire({
//       //     icon: "success",
//       //     title: "Success",
//       //     text: "Item added to cart successfully!",
//       //   });
//       // } else {
//       //   const errorMessage = await response.json();
//       //   console.error("Error adding to cart:", errorMessage);

//       //   if (errorMessage.error === "Item already in the cart") {
//       //     navigate("/ebooks/cart");
//       //   // } else {
//       //   //   Swal.fire({
//       //   //     icon: "error",
//       //   //     title: "Error",
//       //   //     text: `Failed to add item to cart. Error: ${errorMessage.error}`,
//       //   //   });
//       //   }
//       // }
//     } catch (error) {
//       console.error("Error adding to cart:", error);
//       Swal.fire({
//         icon: "info", // You can change this to 'info', 'success', 'warning', etc.
//         title: "Note",
//         text: "Failed to add item to cart. Please try again.",
//       });
//     } finally {
//       setIsAddingToCart(false);
//     }
//   };

//   useEffect(() => {
//     const fetchPurchaseHistory = async () => {
//       try {
//         const purchaseHistoryResponse = await fetch(
//           `https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`,
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Accept: "application/json",
//               Authorization: `Bearer ${cookies.token}`,
//             },
//           }
//         );
//         const purchaseHistoryData = await purchaseHistoryResponse.json();
//         console.log("PURCHASEhISTORY", purchaseHistoryData);
//         setPurchase(purchaseHistoryData.ebooksPurchased || []); // Ensure it's always an array
//       } catch (error) {
//         console.error("Error fetching purchase history:", error);
//         setPurchase([]); // Ensure it's always an array in case of error
//       }
//     };

//     fetchPurchaseHistory();
//   }, [user]);

//   console.log("mypurchase", purchase);

//   if (loading) {
//     return <LoadingSpinner />;
//   }

//   const calculateDiscount = (sellingPrice, printingPrice) => {
//     const savingAmount = printingPrice - sellingPrice;
//     const discountPercentage = (savingAmount / printingPrice) * 100;
//     return { savingAmount, discountPercentage };
//   };

//   const twelveMonthSubscription =
//     selectedCardData
//       ? selectedCardData.subscriptionPrices.find(
//           (sub) => sub.duration === "12 Months"
//         )
//       : { printingPrice: 0, sellingPrice: 0 };

//   const { savingAmount, discountPercentage } = calculateDiscount(
//     twelveMonthSubscription.sellingPrice || 0,
//     twelveMonthSubscription.printingPrice || 0
//   );

//   const currentOriginalPrice = twelveMonthSubscription.printingPrice;
//   const currentDiscountedPrice = twelveMonthSubscription.sellingPrice;

//   let valuePrice = currentOriginalPrice - currentDiscountedPrice;

//   const handleMouseEnter = () => {
//     setIsZoomed(true);
//   };

//   const handleMouseLeave = () => {
//     setIsZoomed(false);
//   };

//   return (
//     <>
//       <div className="main-body-container">
//         <Header />
//         <div className="container-body-for-intro">
//           <div className="for-intro-row1-image-introproduct">
//             <div className="row1-for-intro">
//               <div className="for-image-intro">
//                 <div className="image-container">
//                   <div className="image-container-book-show">
//                     {selectedCardData.image ? (
//                       <ReactImageMagnify
//                         {...{
//                           smallImage: {
//                             alt: "Selected",
//                             isFluidWidth: true,
//                             src: selectedCardData.image,
//                           },
//                           largeImage: {
//                             src: selectedCardData.image,
//                             width: 1200,
//                             height: 1800,
//                           },
//                           enlargedImageContainerDimensions: {
//                             width: "250%",
//                             height: "200%",
//                           },
//                         }}
//                       />
//                     ) : (
//                       <div className="placeholder">Select an image</div>
//                     )}
//                     {/* {selectedImagefor ? (
//                       <ReactImageMagnify
//                         {...{
//                           smallImage: {
//                             alt: "Selected",
//                             isFluidWidth: true,
//                             src: selectedImagefor,
//                           },
//                           largeImage: {
//                             src: selectedImagefor,
//                             width: 1200,
//                             height: 1800,
//                           },
//                           enlargedImageContainerDimensions: {
//                             width: "250%",
//                             height: "200%",
//                           },
//                         }}
//                       />
//                     ) : (
//                       <div className="placeholder">Select an image</div>
//                     )} */}
//                   </div>
//                 </div>
//                 <div className="thumbnails">
//                   {imagesfor.map((image, index) => (
//                     <img
//                       key={index}
//                       src={image}
//                       alt={`Thumbnail ${index + 1}`}
//                       className="thumbnail"
//                       onClick={() => handleThumbnailClick(image)}
//                     />
//                   ))}
//                 </div>
//               </div>
//             </div>
//             <div className="row2-for-intro">
//               <div className="name-of-the-product">
//                 <h2>
//                   {/* {selectedCardData.length > 0 && selectedCardData[0].title}{" "} */}
//                   { selectedCardData.title}
//                   {/* {selectedCardData.length > 0 && selectedCardData[0].edition}{" "}
//                   edition {selectedCardData.length > 0 && selectedCardData[0].medium}{" "}
//                   medium */}
//                 </h2>
//               </div>
//               <div className="price-details-intro">
//                 <div className="price-for-billing">
//                   <span className="price-for-billing__selling-price">
//                     ₹{currentDiscountedPrice} incl. GST
//                   </span>
//                   <div className="price-for-billing__details">
//                     <span className="price-for-billing__mrp">
//                       M.R.P.: <del>₹{currentOriginalPrice}</del>
//                     </span>
//                     <span className="price-for-billing__saving-amount">
//                       Save: ₹{savingAmount.toFixed(2)} (
//                       {discountPercentage.toFixed(0)}%)
//                     </span>
//                   </div>
//                 </div>

//                 <div className="Value-deal">
//                   <p>
//                     VALUE DEAL! Save &nbsp;
//                     <span
//                       className="money"
//                       doubly-currency-usd="15500"
//                       doubly-currency-inr="1281207.5290036453"
//                       doubly-currency="INR"
//                     >
//                       ₹{valuePrice}
//                     </span>
//                   </p>
//                 </div>
//                 <div className="share-product">
//                   <ShareModal />
//                 </div>
//               </div>
//               <div className="intro-of-the-product">
//                 <p className="intro-1">
//                   {selectedCardData?.para1}
//                 </p>
//                 <div className="intro-Ebook-description">
//                   <GiCheckMark className="tick" />
//                   {selectedCardData?.para2}
//                 </div>
//                 <div className="intro-Ebook-description">
//                   <GiCheckMark className="tick" />
//                   {selectedCardData?.para3}
//                 </div>
//                 <div className="intro-Ebook-description">
//                   <GiCheckMark className="tick" />
//                   {selectedCardData?.para4}
//                 </div>
//               </div>
//               <div className="for-duration-buy-modal-intro">
//                 {/* {Array.isArray(purchase) &&
//                 purchase.some((item) => item._id === cardId) ? (
//                   <div className="for-purchase-card-info">
//                     <p>This product is already purchased</p>
//                   </div>
//                 ) : ( */}
//                   <>
//                     <div
//                       className="add-to-cart-buy-modal-intro"
//                       onClick={() => handleAddToCart(cardId)}
//                     >
//                       Add to cart
//                     </div>
//                     <div className="buy-it-now-from-buy-model-intro">
//                       <button
//                         id="buyButton-intro"
//                         onClick={() => handleAddToCart(cardId)}
//                       >
//                         Buy It Now
//                       </button>
//                     </div>
//                   </>
//                 {/* )} */}
//               </div>
//               <div className="show-preview-pdf-book">
//                 <div className="preview-book-pdf-title">Preview</div>
//                 <div className="show preview">
//                   <div className="pdf-showpreview">
//                     <div className="pdf1-preview">
//                       { selectedCardData.pdf1 && (
//                           <a
//                             href={selectedCardData.pdf1}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                           >
//                             Preview 1
//                           </a>
//                         )}
//                     </div>
//                     <div className="pdf2-preview">
//                       {selectedCardData.pdf2 && (
//                           <a
//                             href={selectedCardData.pdf2}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                           >
//                             Preview 2
//                           </a>
//                         )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <Row2intro whytakecourse={selectedCardData.whytakecourse}/>
//         </div>
//         <Row3intro
//           currentOriginalPrice={currentOriginalPrice}
//           currentDiscountedPrice={currentDiscountedPrice}
//           selectedCardData={selectedCardData}
//         />
//         <Row4intro />
//         <RatingReview />
//         <Row7intro />
//         <Footer />
//       </div>
//     </>
//   );
// }

// export default Intro;

import React, { useState, useEffect } from "react";
import styles from "./Intro2.module.css"; // Import the CSS Module
import { useCart } from "../../Context/CartContext";
import { useParams, useNavigate } from "react-router-dom";
import Row2intro from "./Row2intro2";
import Row3intro from "./Row3intro";
import Row4intro from "./Row4intro";
import ShareModal from "./ShareModal";
import { GiCheckMark } from "react-icons/gi";
import RatingReview from "./RatingReview";
import Row7intro from "./Row7intro";

import Footer from "../../FooterMain/Footer";
import Header from "../../component/HeaderFilesEbook/Header";
import Swal from "sweetalert2";
import { useAuth } from "../../Context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { useCookies } from "react-cookie";
import ReactImageMagnify from "react-image-magnify";
import axios from "axios";
import HeaderBar from "../../component/HeaderBar"

function Intro({ onClose, onAddToCart }) {
  // Existing state and logic...

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [cookies] = useCookies(["token", "email_id"]);
  const [loading, setLoading] = useState(true);
  const { cartItems, cartCount, removeItem } = useCart();
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const [isZoomed, setIsZoomed] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [selectedInfoData, setselectedInfoData] = useState([]);
  const [selectedCardData, setselectedCardData] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [purchaseStatus, setPurchaseStatus] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(
    "SSC CGL Tier 2 English | 40 Tests"
  );
  const [imagesfor, setImagesfor] = useState([]);
  const [selectedImagefor, setSelectedImagefor] = useState(null);

  const { cardId, category, subject, title } = useParams();

  console.log("category: ", category);
  console.log("subject: ", subject);
  console.log("title: ", title);

  const { addToCart } = useCart();
  const navigate = useNavigate();

  const formatForComparison = (value) => {
    return value?.toLowerCase().replace(/[-\s]/g, "") || "";
  };

  const fetchEbookData = async () => {
    const payload = {
      user,
      isLoggedIn,
      category,
      subject,
      title,
      // permalink: `/${category}/${subject}/${title}`,
      permalink: `/${category}/${subject}/${title}`,
    };

    console.log("USER", user);
    console.log("isLoggedIn", isLoggedIn);
    console.log("category", category);
    console.log("subject", subject);
    console.log("title", title);

    try {
      setLoading(true);

      const response = await axios.post(
        "https://auth.ssccglpinnacle.com/api/v1/getEbookDetail",
        // "http://localhost:5000/api/v1/getEbookDetail",
        payload // Pass payload as query parameters
      );

      console.log("RESPONSE", response);
      if (!response.data) {
        Swal.fire({
          icon: "warning",
          title: "No Data Found",
          text: "The requested book details are not available.",
          confirmButtonText: "Close",
        });
        return;
      } else {
        setselectedCardData(response.data.combinedData);
        // If response contains data, show success message
        // Swal.fire({
        //   icon: "success",
        //   title: "Thank you!",
        //   text: response.data.message || "Book details retrieved successfully!",
        //   confirmButtonText: "Close",
        // });
        // If response.data is not available, show an alert
      }
    } catch (error) {
      // Handle error
      const errorMessage =
        error.response?.data?.message ||
        "Something went wrong. Please try again later.";

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage,
        confirmButtonText: "Retry",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEbookData();
  }, [title]);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         setLoading(true);

  //         // Fetch the ebook data
  //         const ebookResponse = await fetch(
  //           "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
  //         );
  //         if (!ebookResponse.ok) {
  //           throw new Error(
  //             `Failed to fetch ebooks. Status: ${ebookResponse.status}`
  //           );
  //         }
  //         const ebookData = await ebookResponse.json();
  //         setCardData(ebookData);

  //         // Fetch the book data
  //         const bookResponse = await fetch(
  //           "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
  //         );
  //         if (!bookResponse.ok) {
  //           throw new Error(
  //             `Failed to fetch books. Status: ${bookResponse.status}`
  //           );
  //         }
  //         const bookData = await bookResponse.json();

  //         // Format the parameters for comparison
  //         const formattedCategory = formatForComparison(category);
  //         const formattedSubject = formatForComparison(subject);
  //         const formattedTitle = formatForComparison(title);

  //         // Find the ebook that matches the parameters
  //         const selectedEbook = ebookData.find((item) => {
  //           const formattedItemCategory = formatForComparison(item.category);
  //           const formattedItemSubject = formatForComparison(item.subject);
  //           const formattedItemTitle = formatForComparison(item.title);

  //           return (
  //             formattedItemCategory === formattedCategory &&
  //             formattedItemSubject === formattedSubject &&
  //             formattedItemTitle === formattedTitle
  //           );
  //         });

  //         // Set the selected ebook data
  //         setselectedCardData(selectedEbook ? [selectedEbook] : []);

  //         // Find the book with matching SKU
  //         if (selectedEbook) {
  //           const matchedBook = bookData.find(
  //             (book) => book.sku === selectedEbook.sku
  //           );
  //           if (matchedBook) {
  //             setselectedInfoData([matchedBook]);
  //           } else {
  //             setselectedInfoData([]);
  //           }
  //         }

  //         // console.log("my info data extracted from book", selectedInfoData);

  //         setLoading(false);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //         setLoading(false);
  //       }
  //     };

  //     fetchData();
  //   }, [title]);

  //   useEffect(() => {
  //     const imagesArray = [
  //       selectedInfoData[0]?.image1,
  //       selectedInfoData[0]?.image2,
  //       selectedInfoData[0]?.image3,
  //       selectedInfoData[0]?.image4,
  //       selectedInfoData[0]?.image5,
  //     ].filter((image) => image !== undefined);

  //     setImagesfor(imagesArray);
  //   }, [selectedInfoData]);

  // useEffect(() => {
  //   const imagesArray = [
  //     // selectedCardData?.image,
  //     selectedCardData?.image1 && selectedCardData?.image1,
  //     selectedCardData?.image2 && selectedCardData?.image2,
  //     selectedCardData?.image3 && selectedCardData?.image3,
  //     selectedCardData?.image4 && selectedCardData?.image4,
  //     selectedCardData?.image5 && selectedCardData?.image5,
  //   ].filter((image) => image !== undefined);

  //   console.log("IMAGE_ARRAY", imagesArray);

  //   setImagesfor(imagesArray);
  // }, [selectedCardData]);

  useEffect(() => {
    // Dynamically add images only if they exist
    const imagesArray = [
      // selectedCardData?.image,
      selectedCardData?.image1,
      selectedCardData?.image2,
      selectedCardData?.image3,
      selectedCardData?.image4,
      selectedCardData?.image5,
    ].filter(Boolean); // `Boolean` filters out falsy values like `undefined`, `null`, etc.

    console.log("IMAGE_ARRAY", imagesArray);

    setImagesfor(imagesArray);
  }, [selectedCardData]);

  useEffect(() => {
    setSelectedImagefor(imagesfor[0] || null);
  }, [imagesfor]);

  const handleThumbnailClick = (image1) => {
    console.log("IMAGE1", image1);
    setSelectedImagefor(image1);
  };

  const handleAddToCart = async (ebookId) => {
    try {
      // console.log("isLoggedIn",isLoggedIn);
      // console.log("ebookId",ebookId);
      // console.log("user",user);
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "info", // You can change this to 'info', 'success', 'warning', etc.
          title: "Note",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      setIsAddingToCart(true);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`,
        // `http://localhost:5000/api/add-to-cart-ebook/${user.email_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
          body: JSON.stringify({ ebookId }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        console.log("Error adding to cart:", data);
        Swal.fire({
          icon: "info", // You can change this to 'info', 'success', 'warning', etc.
          title: "Note",
          text: `Failed to add item to cart. ${data.error}.`,
        });
        navigate("/ebooks/cart");
      } else {
        console.log("data response is Ok", data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        navigate("/ebooks/cart");
      }
      // if (response.ok) {
      //   Swal.fire({
      //     icon: "success",
      //     title: "Success",
      //     text: "Item added to cart successfully!",
      //   });
      // } else {
      //   const errorMessage = await response.json();
      //   console.error("Error adding to cart:", errorMessage);

      //   if (errorMessage.error === "Item already in the cart") {
      //     navigate("/ebooks/cart");
      //   // } else {
      //   //   Swal.fire({
      //   //     icon: "error",
      //   //     title: "Error",
      //   //     text: `Failed to add item to cart. Error: ${errorMessage.error}`,
      //   //   });
      //   }
      // }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "info", // You can change this to 'info', 'success', 'warning', etc.
        title: "Note",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        const purchaseHistoryResponse = await fetch(
          `https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
          }
        );
        const purchaseHistoryData = await purchaseHistoryResponse.json();
        console.log("PURCHASEhISTORY", purchaseHistoryData);
        setPurchase(purchaseHistoryData.ebooksPurchased || []); // Ensure it's always an array
        const isPurchased = purchaseHistoryData.ebooksPurchased.some(
          (ebook) => ebook._id === selectedCardData._id
        );
        if (isPurchased) {
          setPurchaseStatus(1);
        }
      } catch (error) {
        console.error("Error fetching purchase history:", error);
        setPurchase([]); // Ensure it's always an array in case of error
      }
    };

    fetchPurchaseHistory();
  }, [user]);

  // console.log("mypurchase", purchase);

  if (loading) {
    return <LoadingSpinner />;
  }

  const calculateDiscount = (sellingPrice, printingPrice) => {
    const savingAmount = printingPrice - sellingPrice;
    const discountPercentage = (savingAmount / printingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  const twelveMonthSubscription = selectedCardData
    ? selectedCardData.subscriptionPrices.find(
        (sub) => sub.duration === "12 Months"
      )
    : { printingPrice: 0, sellingPrice: 0 };

  const { savingAmount, discountPercentage } = calculateDiscount(
    twelveMonthSubscription.sellingPrice || 0,
    twelveMonthSubscription.printingPrice || 0
  );

  const currentOriginalPrice = twelveMonthSubscription.printingPrice;
  const currentDiscountedPrice = twelveMonthSubscription.sellingPrice;

  let valuePrice = currentOriginalPrice - currentDiscountedPrice;

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  console.log("purchaseStatus", purchaseStatus);
  console.log("selectedCardData.productAccess", selectedCardData.productAccess);
  return (
    <>
      <div className={styles.mainBodyContainer}>
        {/* <Header /> */}
        <HeaderBar />
        <div className={styles.containerBodyForIntro}>
          <div className={styles.forIntroRow1ImageIntroproduct}>
            <div className={styles.row1ForIntro}>
              <div className={styles.forImageIntro}>
                <div className={styles.imageContainer}>
                  <div className={styles.imageContainerBookShow}>
                    {selectedImagefor ? (
                      // {selectedCardData.image ? (
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "Selected",
                            isFluidWidth: true,
                            src: selectedImagefor,
                            // height: 394,
                            className: "magnify-image", // Add a class name
                            // imageClassName: {
                            //   height: "394px", // Set the height to 394px
                            //   width: "auto", // Ensure the width adjusts automatically to maintain aspect ratio
                            // },
                          },
                          largeImage: {
                            src: selectedImagefor,
                            width: 1200,
                            height: 1800,
                          },
                          enlargedImageContainerDimensions: {
                            width: "250%",
                            height: "200%",
                          },
                        }}
                      />
                    ) : (
                      <div className={styles.placeholder}>Select an image</div>
                    )}
                  </div>
                  <div className={styles.contentCategory}>EBOOK</div>
                  {selectedCardData.productAccess ? (
                    <div className="PurchasedTag">Purchased</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.thumbnails}>
                  {imagesfor &&
                    imagesfor.length > 1 &&
                    imagesfor.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className={styles.thumbnail}
                        onClick={() => handleThumbnailClick(image)}
                      />
                    ))}
                </div>
              </div>
            </div>

            <div className={styles.row2ForIntro}>
              <div className={styles.nameOfTheProduct}>
                <h2>{selectedCardData.title}</h2>
              </div>
              <div className={styles.priceDetailsIntro}>
                <div className={styles.priceForBilling}>
                  <span className={styles.priceForBillingSellingPrice}>
                    ₹{currentDiscountedPrice} incl. GST
                  </span>
                  <div className={styles.priceForBillingDetails}>
                    <span className={styles.priceForBillingMrp}>
                      M.R.P.: <del>₹{currentOriginalPrice}</del>
                    </span>
                    <span className={styles.priceForBillingSavingAmount}>
                      Save: ₹{savingAmount.toFixed(2)} (
                      {discountPercentage.toFixed(0)}%)
                    </span>
                  </div>
                </div>
                <div className={styles.valueDeal}>
                  <p>
                    VALUE DEAL! Save &nbsp;
                    <span className={styles.money}>₹{valuePrice}</span>
                  </p>
                </div>
                <div className={styles.shareProduct}>
                  <ShareModal />
                </div>
              </div>
              <div className={styles.introOfTheProduct}>
                <p className={styles.intro1}>{selectedCardData.para1}</p>
                <div className={styles.introEbookDescription}>
                  <GiCheckMark className={styles.tick} />
                  {selectedCardData.para2}
                </div>
                <div className={styles.introEbookDescription}>
                  <GiCheckMark className={styles.tick} />
                  {selectedCardData.para3}
                </div>
                <div className={styles.introEbookDescription}>
                  <GiCheckMark className={styles.tick} />
                  {selectedCardData.para4}
                </div>
              </div>
              <div className={styles.forDurationBuyModalIntro}>
                <div
                  className={styles.functionalityButton}
                  // className={styles.addToCartBuyModalIntro}
                  // onClick={() => handleAddToCart(selectedCardData._id)}
                >
                  <button
                    className={styles.addToCartBuyModalIntro}
                    onClick={() => handleAddToCart(selectedCardData._id)}
                  >
                    Add to cart
                  </button>
                  {selectedCardData.productAccess ? (
                    <button
                      className={styles.addToCartBuyModalIntro}
                      // onClick={() => handleAddToCart(selectedCardData._id)}
                      onClick={() =>
                        navigate(
                          `/ebook/${selectedCardData._id}/digitalMainPage`
                        )
                      }
                    >
                      Read
                    </button>
                  ) : (
                    ""
                  )}
                  {/* Add to cart */}
                </div>
                <div className={styles.buyItNowFromBuyModelIntro}>
                  <button
                    id={styles.buyButtonIntro}
                    onClick={() => handleAddToCart(selectedCardData._id)}
                  >
                    Buy It Now
                  </button>
                </div>
              </div>
              {/* For now we need to remove the preview as per requirement  */}
              {/* {selectedCardData.pdf1 && (
                <div className={styles.showPreviewPdfBook}>
                  <div className={styles.previewBookPdfTitle}>Preview</div>
                  <div className={styles.showPreview}>
                    <div className={styles.pdfShowpreview}>
                      <div className={styles.pdf1Preview}>
                        {selectedCardData.pdf1 && (
                          <a
                            href={selectedCardData.pdf1}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Preview 1
                          </a>
                        )}
                      </div>
                      <div className={styles.pdf2Preview}>
                        {selectedCardData.pdf2 && (
                          <a
                            href={selectedCardData.pdf2}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Preview 2
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {/* <div className={styles.ebookDetails}>
            <div className={styles.ebookImages}>
              <div className={styles.ebookPrimaryImage}>
                {selectedImagefor ? (
                  // {selectedCardData.image ? (
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Selected",
                        isFluidWidth: true,
                        src: selectedImagefor,
                      },
                      largeImage: {
                        src: selectedImagefor,
                        width: 1200,
                        height: 1800,
                      },
                      enlargedImageContainerDimensions: {
                        width: "250%",
                        height: "200%",
                      },
                    }}
                  />
                ) : (
                  <div className={styles.placeholder}>Select an image</div>
                )}
              </div>
              <div className={styles.ebookSecondaryimages}></div>
            </div>
            <div className={styles.ebookTextDetails}></div>
          </div> */}
          <Row2intro selectedEbookInfo={selectedCardData} />
        </div>
        <Row3intro
          currentOriginalPrice={currentOriginalPrice}
          currentDiscountedPrice={currentDiscountedPrice}
          selectedCardData={selectedCardData}
        />
        <Row4intro />
        <RatingReview />
        <Row7intro />
        <Footer />
      </div>
    </>
  );
}

export default Intro;

import React, { useRef, useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx"; // Fix the import statement
import "./Page1.css";
import Swal from "sweetalert2";
import { FaBars } from "react-icons/fa";
import "./sidebar2.css";
import { useAuth } from "../../../Context/AuthContext";
import { ThemeContext } from "../../../Context/ThemeContext";
import Header from "../../../component/DigitalcontentHeader/Header";
import Forsolutionpdf from "./Forsolutionpdf";
import "./ForSolution.css";
import pic from "./Solution/a.webp";
import picanswer from "./Answer/d.webp";
import page from "./Page/e.webp";
import "./ForAnswer.css";
import ForAnswerpdf from "./ForAnswerpdf";
import LoadingSpinner from "../../LoadingSpinner";
import NewPdfShow1 from "../NewPdfShow1";
import FabricEditor from "./FabricEditor";

const Sidebar = ({ chapterData, onChapterSelect, showSidebar }) => {
  const navigate = useNavigate();
  const { cardId } = useParams();
  const [activeChapterId, setActiveChapterId] = useState(null); // Track the active chapter

  useEffect(() => {
    const lastVisitedChapter = localStorage.getItem("lastVisitedChapter");
    if (lastVisitedChapter) {
      const chapter = chapterData.find((ch) => ch._id === lastVisitedChapter);
      if (chapter) {
        onChapterSelect(chapter);
        setActiveChapterId(lastVisitedChapter); // Set the last visited chapter as active
      }
    }
  }, [chapterData, onChapterSelect]);

  const handleClick10 = () => {
    navigate(`/ebook/${cardId}/digitalMainPage`);
  };

  if (!chapterData || chapterData.length === 0) {
    return <LoadingSpinner />;
  }
  const sortedChapters = chapterData.sort((a, b) => a.sequence - b.sequence);

  const handleChapterSelect = (chapter) => {
    localStorage.setItem("lastVisitedChapter", chapter._id); // Store the last visited chapter
    console.log(
      `Chapter selected: ${chapter.title}, chapter sequence: ${chapter.sequence}, Chapter ID: ${chapter._id}`
    );
    setActiveChapterId(chapter._id); // Set the clicked chapter as active
    onChapterSelect(chapter);
  };

  return (
    <div
      className={`sidebar5 ${showSidebar ? "show-sidebar" : "hide-sidebar"}`}
    >
      <div className="home-for-sidebar-page1" onClick={handleClick10}>
        Home
      </div>
      {sortedChapters.map((chapter, index) => (
        <div
          // className="heading"
          className={`heading ${
            activeChapterId === chapter._id ? "active" : ""
          }`} // Highlight active chapter
          key={index}
          onClick={() => {
            console.log("activeChapterId", activeChapterId, chapter._id);
            console.log(`Chapter ${index} selected: ${chapter.title}`);
            handleChapterSelect(chapter);
          }}
        >
          <div className="m-header">
            <h3>{chapter.title}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

function Page1() {
  const { theme } = useContext(ThemeContext);
  const [filteredChapters, setFilterChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const { cardId } = useParams();
  const { user, token } = useAuth();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [topicCompleted, setTopicCompleted] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [shownotes, setShownotes] = useState(false);
  const [showAb, setShowAb] = useState(true);
  const [showForsolutionpdf, setShowForsolutionpdf] = useState(false);
  const [showForAnswerpdf, setShowForAnswerpdf] = useState(false);
  const [purchase, setPurchase] = useState(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showHeader, setShowHeader] = useState(true); // New state for header visibility
  const [userPurchase, setUserPurchase] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const bodyRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Function to prevent right-click
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    // Function to prevent cut, copy, and paste
    const disableCutCopyPaste = (event) => {
      if (event.ctrlKey || event.metaKey) {
        // Allow Ctrl or Command key
        return;
      }

      event.preventDefault();
    };

    const disableKeyCombinations = (event) => {
      if (
        (event.ctrlKey && event.shiftKey && event.code === "KeyI") ||
        (event.ctrlKey && event.shiftKey && event.code === "KeyC") ||
        (event.ctrlKey && event.shiftKey && event.code === "KeyJ") ||
        (event.ctrlKey && event.shiftKey && event.code === "KeyS") ||
        (event.keyCode === 121 && event.shiftKey === true) ||
        (event.ctrlKey && event.code === "KeyU") ||
        (event.ctrlKey && event.code === "KeyP") // Add Ctrl+P check
      ) {
        event.preventDefault();
      }
    };

    // Add event listeners when the component mounts
    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("cut", disableCutCopyPaste);
    document.addEventListener("copy", disableCutCopyPaste);
    document.addEventListener("paste", disableCutCopyPaste);
    document.addEventListener("keydown", disableKeyCombinations);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("cut", disableCutCopyPaste);
      document.removeEventListener("copy", disableCutCopyPaste);
      document.removeEventListener("paste", disableCutCopyPaste);
      document.removeEventListener("keydown", disableKeyCombinations);
    };
  }, []);

  useEffect(() => {
    const isReload = sessionStorage.getItem("reloadPrevention");
    sessionStorage.setItem("reloadPrevention", "true");

    const hasRedirected = localStorage.getItem(`redirect_${cardId}`);

    if (!cardId && !isReload) {
      navigate("/ebooks");
      return;
    }

    const checkAccess = async () => {
      if (!user) {
        setIsLoading(false);
        throw new Error("User not logged in");
      }

      const productResponse = await fetch(
        "https://auth.ssccglpinnacle.com/api/checkAccessProduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ product_id: cardId }),
        }
      );

      if (!productResponse.ok) {
        throw new Error(
          `Failed to check product access. Status: ${productResponse.status}`
        );
      }

      const { access } = await productResponse.json();

      // If user has access to the product
      if (access === "access") {
        return true;
      } else if (hasRedirected) {
        navigate(`/ebooks?cardId=${cardId}`);
        return false;
      } else {
        // Start free trial
        const timer = setTimeout(async () => {
          await updateTryForFreeStatus();
          localStorage.setItem(`redirect_${cardId}`, "true"); // Set redirect flag
          navigate(`/ebooks?cardId=${cardId}`);
        }, 5 * 60 * 1000); // 5 minutes

        timerRef.current = timer;
        return () => clearTimeout(timer);
      }
    };

    const updateTryForFreeStatus = async () => {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/updateTryForFree-ebook",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: user._id,
            cardId: cardId,
            tryForFree: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    };

    const fetchChapterData = async () => {
      try {
        // First, check access before proceeding
        const hasAccess = await checkAccess();
        if (!hasAccess) {
          return; // If the user doesn't have access, exit the function
        }

        // Fetch chapter data
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/chapters-ebook?ebook=${cardId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the authorization token
            },
          }
        );

        // Check for a valid response
        if (!response.ok) {
          throw new Error("Error fetching chapter data");
        }

        const data = await response.json();

        // Filter and sort chapters based on sequence
        const filteredChapters = data
          .filter((chapter) => chapter.ebook._id === cardId)
          .sort((a, b) => a.sequence - b.sequence);

        // Set the filtered chapters to state
        setFilterChapters(filteredChapters);

        // If no chapters are found or title is missing, show warning and redirect
        if (filteredChapters.length === 0 || !filteredChapters[0].title) {
          Swal.fire({
            icon: "warning",
            title: "Data Missing",
            text: "You must add the data because the title is not available.",
          });

          navigate("/ebooks"); // Redirect the user if no valid chapters
        } else {
          // Set the first chapter as the default selected chapter
          setSelectedChapter(filteredChapters[0]);
        }
      } catch (error) {
        // console.error("Error fetching chapter data:", error);
      }
    };

    if (cardId) {
      fetchChapterData();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Clear timeout on component unmount
        timerRef.current = null;
      }
    };
  }, [cardId, navigate, user, token]);

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch purchase history");
        }
        const data = await response.json();
        setUserPurchase(data);
        setPurchase(data);
      } catch (error) {
        // console.error('Error fetching purchase history:', error);
      }
    };

    fetchPurchaseHistory();
  }, [user, token]);

  const handleChapterSelect = (chapter) => {
    // console.log(`Selected Chapter: ${chapter.title}`);
    setSelectedChapter(chapter);
    // setShowAb(true); // Ensure PDF view is shown when chapter is selected
    // setShowPdf(false);
    // setShowForsolutionpdf(false);
    // setShowForAnswerpdf(false);
  };

  const handleScroll = () => {
    const selectedChapterData = selectedChapter;
    // console.log("Selected Chapter Data:", selectedChapterData);

    if (
      selectedChapterData &&
      purchase &&
      purchase.ebooksPurchased &&
      purchase.ebooksPurchased.length > 0
    ) {
      const hasPurchased = purchase.ebooksPurchased.some((ebook) => {
        return ebook._id === selectedChapterData.ebook._id;
      });

      if (hasPurchased) {
        const scrollableElement = document.querySelector(
          ".rpv-core__inner-pages"
        );
        if (scrollableElement) {
          const scrollTop = scrollableElement.scrollTop;
          const scrollHeight =
            scrollableElement.scrollHeight - scrollableElement.clientHeight;
          const scrollPercentage = (scrollTop / scrollHeight) * 100;

          let completed;

          if (scrollPercentage >= 80) {
            completed = "complete";
          } else if (scrollPercentage >= 20) {
            completed = "inprogress";
          } else {
            completed = "";
          }

          const chapterId = selectedChapterData._id;
          const user1 = user._id;

          fetch(
            `https://auth.ssccglpinnacle.com/api/chaptersss-ebook/${chapterId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ completed, user1 }),
            }
          )
            .then((response) => {
              if (response.ok) {
                setTopicCompleted(completed === "complete");
              } else {
                // console.error("Failed to update chapter completion status in the database.");
              }
            })
            .catch((error) => {
              // console.error("Error updating chapter completion status:", error);
            });
        }
      }
    }
  };

  const handleClicksolution = () => {
    setShowPdf(false);
    setShowForsolutionpdf(true); // Set this to true
    setShowForAnswerpdf(false);
    setShowAb(false);
  };

  const handleAbButtonClick = () => {
    setShowPdf(false);
    setShowAb(true);
    setShowForsolutionpdf(false);
    setShowForAnswerpdf(false);
  };

  const handleClickanswer = () => {
    setShowPdf(false);
    setShowForsolutionpdf(false);
    setShowForAnswerpdf(true);
    setShowAb(false);
  };

  const handlenotes = () => {
    // console.log("Notes clicked"); // Track button click
    setShowPdf(false);
    setShowForsolutionpdf(false);
    setShowForAnswerpdf(false);
    setShownotes((prevShownotes) => !prevShownotes); // Toggle shownotes
  };

  // console.log("for chapter", user)
  const handleNotesOpen = () => {
    if (selectedChapter && user.email_id) {
      navigate(
        `/notes/${selectedChapter._id}/${selectedChapter.title}/${cardId}/${user.email_id}`
      );
    } else {
      console.error("Chapter ID or email is missing");
    }
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setShowHeader(!showHeader); // Toggle header visibility
  };

  useEffect(() => {
    const scrollableElement = document.querySelector(".rpv-core__inner-pages");
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [selectedChapter]);

  return (
    <>
      {shownotes && selectedChapter && (
        <FabricEditor
          chapterData={selectedChapter}
          cardId={cardId}
          onClose={() => setShownotes(false)}
        />
      )}
      <div className="main-body-container">
        <div
          className={`theme-for ${
            theme === "dark" ? "dark-theme" : "light-theme"
          }`}
        >
          {showHeader && <Header cardId={cardId} />}
          {/* 
          <button
            className={`toggle-sidebar-button-notes ${
              !showSidebar ? "hide-sidebar" : ""
            }`}
            onClick={handleNotesOpen}
          >
            Your Notes
          </button>
           */}
          <button className="toggle-sidebar-button" onClick={toggleSidebar}>
            {showSidebar ? (
              <RxCross2
                size={20}
                className="menu-icon-side-button"
                title="Hide the Sidebar"
              />
            ) : (
              <div
                className={`container-for-open-close ${
                  showContent ? "show" : ""
                }`}
                onMouseEnter={() => setShowContent(true)}
                onMouseLeave={() => setShowContent(false)}
              >
                {showContent && (
                  <div className="course-content">
                    <p>Course Content</p>
                  </div>
                )}
                <FaArrowRight className="left-arrow-course-content" size={20} />
              </div>
            )}
          </button>
          <Sidebar
            chapterData={filteredChapters}
            onChapterSelect={handleChapterSelect}
            showSidebar={showSidebar}
          />
          <div
            className={`body-for-page1 ${
              showSidebar ? "show-sidebar" : "hide-sidebar"
            }`}
          >
            {showAb && selectedChapter && (
              <NewPdfShow1
                chapterData={selectedChapter}
                isFullScreen={isFullScreen}
                handleScroll={handleScroll}
              />
            )}

            {showForsolutionpdf && selectedChapter && (
              <Forsolutionpdf
                chapterData={selectedChapter}
                isFullScreen={isFullScreen}
              />
            )}

            {showForAnswerpdf && selectedChapter && (
              <ForAnswerpdf
                chapterData={selectedChapter}
                isFullScreen={isFullScreen}
              />
            )}
          </div>

          <div className="modal-for-solution">
            <div
              className="modal-content-solution"
              onClick={handleClicksolution}
            >
              Solution
              {/* <img
                className="image-for-solution"
                src={pic}
                onClick={handleClicksolution}
              /> */}
            </div>
          </div>
          <div className="modal-for-answer">
            <div className="modal-content-answer" onClick={handleClickanswer}>
              Answer
              {/* <img
                className="image-for-answer"
                src={picanswer}
                onClick={handleClickanswer}
              /> */}
            </div>
          </div>
          <div className="modal-for-page">
            <div className="modal-content-answer" onClick={handleAbButtonClick}>
              Content
            </div>
            {/* <img
              className="image-for-page"
              src={page}
              onClick={handleAbButtonClick}
            /> */}
          </div>
          <div className="modal-for-notes">
            {/* <div class="note-button" onClick={handlenotes}>
              <span class="plus-sign">+</span>
              <span class="note-text">Notes</span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Page1;

import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import { useAuth } from "../../../Context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Rate from "../../../componentbook/StarBook/RateBook";
import Swal from "sweetalert2";
import BuyNow from "../../../BuyNowBook/BuyNowBook";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import amazon from "../../../iBook/amazon.svg";
import flipkart from "../../../iBook/flipkart.svg";
import snapdeal from "../../../iBook/snapdeal.png";
import pinnacle from "../../../iBook/pinnaclelogo.png";
import { FcApproval } from "react-icons/fc";
import "./AllEbook.css";
import BillingForm from "../BillingForm";
import Header from "../../../componentbook/HeaderFilesBook/HeaderBook";
// import Header from "../../../component/HeaderFiles/Header";
// import Loader from "../../../components/Loader"; // Import the Loader component
import LoadingSpinner from "../../../Ebook/LoadingSpinner";
import captureLink from "../../../component/HomePage01/utils/captureLinks";

function AllBook() {
  const [cardData, setCardData] = useState(null); // Initialize with null to show loader initially
  const { isLoggedIn, user } = useAuth();
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading

  const navigate = useNavigate();
  const location = useLocation();
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [StarData, setStarData] = useState([]);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [successMessage1, setSuccessMessage1] = useState(null);
  const [showBillingForm, setShowBillingForm] = useState(false);
  const [billingInfo, setBillingInfo] = useState(null);
  const [currentBookId, setCurrentBookId] = useState(null);

  const handleMouseOver = (_id) => {
    setHoveredId(_id);
  };

  const handleMouseOut = () => {
    setHoveredId(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }

        const ebookData = await ebookResponse.json();
        ebookData.sort((a, b) => a.order - b.order);
        setCardData(ebookData);

        const ratings = ebookData.map((card) => card.rating || []); // Assuming rating is an array
        setStarData(ratings.flat());

        if (isLoggedIn && user && user._id) {
          const purchaseHistoryResponse = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchase-history-book/${user._id}`
          );
          if (!purchaseHistoryResponse.ok) {
            throw new Error(
              `Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`
            );
          }

          const purchaseHistoryData = await purchaseHistoryResponse.json();
          setPurchaseHistory(purchaseHistoryData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, [isLoggedIn, user]);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      if (isLoggedIn && user && user.id) {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/bookBilling/${user.id}`
          );
          if (response.ok) {
            const data = await response.json();
            setBillingInfo(data);
          } else {
            setBillingInfo(null);
          }
        } catch (error) {
          console.error("Error fetching billing info:", error);
          setBillingInfo(null);
        }
      }
    };

    fetchBillingInfo();
  }, [user, isLoggedIn]);

  useEffect(() => {
    const fetchEbooksData = async () => {
      try {
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }
        const ebookData = await ebookResponse.json();
        ebookData.sort((a, b) => a.order - b.order);
        setCardData(ebookData);
        const ratings = ebookData.map((ebook) => ebook.rating || []);
        setStarData(ratings.flat());
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };

    fetchEbooksData();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccessMessage1(null);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [successMessage1]);

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        if (isLoggedIn && user && user._id) {
          const purchaseHistoryResponse = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchase-history-book/${user._id}`
          );
          if (!purchaseHistoryResponse.ok) {
            throw new Error(
              `Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`
            );
          }
          const purchaseHistoryData = await purchaseHistoryResponse.json();
          setPurchaseHistory(purchaseHistoryData);
        }
      } catch (error) {
        console.error("Error fetching purchase history:", error);
      }
    };

    fetchPurchaseHistory();
  }, [isLoggedIn, user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryCardId = queryParams.get("cardId");

    const checkUserPurchased = async () => {
      try {
        if (isLoggedIn && user && user._id && queryCardId) {
          const purchaseHistoryResponse = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchase-history-book/${user._id}`
          );
          if (purchaseHistoryResponse.ok) {
            const purchaseHistoryData = await purchaseHistoryResponse.json();
            const hasPurchased = purchaseHistoryData.some(
              (purchase) => purchase.ebooksPurchased.ebook._id === queryCardId
            );

            if (hasPurchased) {
              setShowBuyNowModal(false);
              document.body.style.overflow = "";
            } else {
              setShowBuyNowModal(true);
              document.body.style.overflow = "hidden";
            }
          }
        }
      } catch (error) {
        console.error("Error checking user purchase history:", error);
      }
    };

    checkUserPurchased();
  }, [isLoggedIn, user, location]);

  const closeModal = () => {
    setShowBuyNowModal(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    console.log(cardData); // Inspect the API response
  }, [cardData]);

  // const info = (card) => {
  //   const permalink = card.permalink;
  //   navigate(`/books${permalink}`);
  // };
  const info = async (card) => {
    const permalink = card.permalink;
    const courseUrl = `/books${permalink}`;
    const courseData = {
      page: window.location.pathname,
      url: courseUrl,
      text: card.title,
      id: card._id,
    };

    try {
      const linkId = await captureLink(courseData);
      if (linkId) {
        console.log("Captured link ID:", linkId);
      } else {
        console.error("Failed to capture link.");
      }
      navigate(courseUrl);
    } catch (error) {
      console.error("Error handling course click:", error);
    }
  };

  const viewAll = () => {
    navigate("/view-all");
  };

  const isEbookPurchased = (ebookId) => {
    return (
      isLoggedIn &&
      purchaseHistory.some(
        (purchase) =>
          purchase.ebooksPurchased &&
          purchase.ebooksPurchased.some(
            (ebook) =>
              ebook.ebook &&
              ebook.ebook._id === ebookId &&
              purchase.user === user?._id
          )
      )
    );
  };

  const handleBuyNow = async (bookId) => {
    if (!isLoggedIn) {
      Swal.fire({
        icon: "info",
        title: "Note",
        text: "Please log in to buy this book.",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          navigate(`/verify-address/${bookId}`);
        } else {
          setShowBillingForm(true);
          document.body.style.overflow = "hidden";
        }
      } else {
        console.error("Failed to fetch billing information");
        setShowBillingForm(true);
        document.body.style.overflow = "hidden";
      }
    } catch (error) {
      console.error("Error fetching billing information:", error);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden";
    }
  };

  const closeModalbiling = () => {
    setShowBuyNowModal(false);
    document.body.style.overflow = "";
  };

  <BillingForm
    onClose={() => {
      setShowBillingForm(false);
      document.body.style.overflow = "";
    }}
  />;

  const handleAddToCart = async (bookId) => {
    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "info",
          title: "Note",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      setIsAddingToCart(true);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-cart-book/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ bookId }),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          navigate("/cart-book");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to add item to cart. Error: ${errorMessage.error}`,
          });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  const isItemInCart = (productId) => {
    if (!user || !user.cart) {
      return false;
    }

    return user.cart.some((item) => item.product === productId);
  };

  const getRatingForCard = (cardId) => {
    const card = cardData.find((card) => card._id === cardId);

    if (!card || !card.rating || card.rating.length === 0) {
      return 0;
    }

    return calculateAverageRating(card.rating);
  };

  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce(
      (sum, rating) => sum + rating.starRate,
      0
    );
    return totalRating / ratings.length;
  };

  const handleWishlistClick = async (event, cardId) => {
    event.stopPropagation();

    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "info",
          title: "Note",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      const updatedWishlist = isWishlistItem(cardId)
        ? wishlist.filter((id) => id !== cardId)
        : [...wishlist, cardId];
      setWishlist(updatedWishlist);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-wishlist-book/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cardId }),
        }
      );

      if (!response.ok) {
        console.error(
          "Failed to update wishlist. Server response:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        if (!user || !user._id) {
          console.error("User is null or _id is undefined.");
          return;
        }

        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/get-wishlist-book/${user._id}`
        );

        if (response.ok) {
          const data = await response.json();
          const wishlist = data.wishlist || [];
          setWishlist(wishlist);
        } else {
          console.error("Failed to fetch wishlist:", await response.text());
        }
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    fetchWishlist();
  }, [user, cardData]);

  const handleRemoveFromWishlist = async (event, cardId) => {
    event.stopPropagation();

    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to remove items from your wishlist.",
        });
        return;
      }

      const updatedWishlist = wishlist.filter((id) => id !== cardId);
      setWishlist(updatedWishlist);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/remove-from-wishlist-book/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cardId }),
        }
      );

      if (!response.ok) {
        console.error(
          "Failed to remove from wishlist. Server response:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error removing from wishlist:", error);
    }
  };

  const isWishlistItem = (cardId) => wishlist.includes(cardId);

  const calculateDiscount = (BookSellingPrice, BookPrintingPrice) => {
    const savingAmount = BookPrintingPrice - BookSellingPrice;
    const discountPercentage = (savingAmount / BookPrintingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  if (loading) {
    return <LoadingSpinner />; // Show the loader while loading
  }

  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = ""; // Enable background scrolling
  };

  return (
    <>
      <Header />
      <div className="container-digital-view-all-link-newbook">
        <ul className="cards-digital-view-all-link-newbook">
          {Array.isArray(cardData) &&
            cardData.map((card) => {
              const { savingAmount, discountPercentage } = calculateDiscount(
                card.BookSellingPrice,
                card.BookPrintingPrice
              );
              return (
                <li
                  className="card-digital-view-all-link-newbook"
                  key={card._id}
                >
                  <div className="upper-card-digital-view-all-link-newbook">
                    <div className="card-content-digital-view-all-link-newbook">
                      <div className="wishlist-book-for-image-newbook">
                        <div
                          className={`wishlist-book ${
                            isWishlistItem(card._id) ? "wishlist-active" : ""
                          }`}
                          onClick={(e) => {
                            if (!isWishlistItem(card._id)) {
                              setSuccessMessage1("Added to your Wishlist");
                              handleWishlistClick(e, card._id);
                            } else {
                              setSuccessMessage1("Removed from your Wishlist");
                              handleRemoveFromWishlist(e, card._id);
                            }
                          }}
                          style={{ transition: "color 0.2s ease-in-out" }}
                        >
                          {isWishlistItem(card._id) ? (
                            <AiFillHeart
                              className="wishlist-heart-first"
                              size={25}
                              style={{
                                color: "#ff0000",
                                transition: "color 0.2s ease-in-out",
                              }}
                            />
                          ) : (
                            <AiFillHeart
                              className="wishlist-heart"
                              size={25}
                              style={{
                                color: "inherit",
                                transition: "color 0.2s ease-in-out",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {successMessage1 && (
                        <div className="success-message-wishlist">
                          <div className="for-sm-wish">
                            <div className="tick-icon-wish">
                              <FcApproval size={26} />
                            </div>

                            <p className="sm-wish">{successMessage1}</p>
                          </div>{" "}
                        </div>
                      )}
                      <div
                        className="for-image-size-view-all-link-newbook"
                        onClick={() => info(card)}
                      >
                        <img
                          className="card-title-digital-view-all-link-newbook"
                          src={card.image1}
                          alt={card.title}
                        />
                      </div>
                    </div>

                    <div className="card-link-wrapper-digital-view-all-link-newbook">
                      {/* <p> {card.title} {card.medium} medium {card.edition} edition{" "}</p> */}
                      <p>{card.title} </p>
                    </div>

                    <div className="buttons-for-buying-products-view-all-link-newbook">
                      <div className="button-for-everything-view-all-link-newbook">
                        <div className="buttons-for-buying-products-books-view-all-link-newbook">
                          <div className="try-fot-free-books-only-view-all-link-newbook">
                            <div className="try-for-free-view-all-link-newbook">
                              <div
                                className="Rating"
                                onMouseOver={() => handleMouseOver(card._id)}
                                onMouseOut={handleMouseOut}
                              >
                                <div className="Star-Rating">
                                  {getRatingForCard(card._id).toFixed(1)}
                                  {Array.from({ length: 1 }).map((_, index) => (
                                    <AiFillStar className="Star-Rating-1" />
                                  ))}
                                </div>
                                <div className="Total-Rating">
                                  ({card.rating.length})
                                </div>
                              </div>
                            </div>
                            <button
                              className="buynow-for-user-cart-view-all-link-newbook"
                              onClick={() => handleAddToCart(card._id)}
                            >
                              {isItemInCart(card._id)
                                ? "Go to Cart"
                                : "Add to Cart"}
                            </button>
                          </div>
                          <div className="StarRating">
                            {hoveredId === card._id && <Rate cardData={card} />}
                          </div>
                        </div>
                        <div className="price-for-billing-view-all-link-newbook">
                          <span className="price-for-billing__selling-price-view-all-link-newbook">
                            ₹ {card.BookSellingPrice} incl. GST
                          </span>
                          <div className="price-for-billing__details-view-all-link-newbook">
                            <span className="price-for-billing__mrp-view-all-link-newbook">
                              M.R.P: <del>₹ {card.BookPrintingPrice}</del>
                            </span>
                            <span className="price-for-billing__saving-amount-view-all-link-newbook">
                              Save: ₹ {savingAmount.toFixed(2)} (
                              {discountPercentage.toFixed(0)}%)
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="buttons-for-important-view-all-link-newbook">
                        <div className="button-for-try-view-amazon-link-view-all-link-newbook">
                          <div className="amazon-link-row3-view-all-link-newbook">
                            <img
                              src={amazon}
                              className="amazon-link-row3-videoicon-view-all-link-newbook"
                            />
                          </div>
                          <div className="amazon-link-button-view-all-link-newbook">
                            <a
                              className="buynow-for-user1-book-view-all-link-newbook"
                              href={card.amazonLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Buy Now
                            </a>
                          </div>
                        </div>
                        <div className="button-for-try-view-flipkart-link-view-all-link-newbook">
                          <div className="flipkart-link-row3-view-all-link-newbook">
                            <img
                              src={flipkart}
                              className="flipkart-link-row3-videoicon-view-all-link-newbook"
                            />
                          </div>
                          <div className="amazon-link-button-view-all-link-newbook">
                            <a
                              className="buynow-for-user1-book-view-all-link-newbook"
                              href={card.flipkartLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Buy Now
                            </a>
                          </div>
                        </div>



                        <div className="button-for-try-view-flipkart-link-view-all-link-newbook">
                          <div className="flipkart-link-row3-view-all-link-newbook">
                            <img
                              src={snapdeal}
                              className="flipkart-link-row3-videoicon-view-all-link-newbook"
                            />
                          </div>
                          <div className="amazon-link-button-view-all-link-newbook">
                            <a
                              className="buynow-for-user1-book-view-all-link-newbook"
                              href={card.snapdealLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Buy Now
                            </a>
                          </div>
                        </div>



                        <div className="button-for-try-view-pinnacle-link-view-all-link-newbook">
                          <div className="pinnacle-link-row3-view-all-link-newbook">
                            <img
                              src={pinnacle}
                              className="pinnacle-link-row3-videoicon-view-all-link-newbook"
                            />
                          </div>
                          <button
                            className="buynow-for-user1-book-view-all-link-newbook"
                            onClick={() => {
                              if (!isLoggedIn) {
                                window.location.href =
                                  "https://testportal.ssccglpinnacle.com/login";
                              } else {
                                handleBuyNow(card.id);
                              }
                            }}
                          >
                            Buy Now
                          </button>
                        </div>
                      </div> */}

{/* 
<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  
  <div style={{ display: 'flex', gap: '60px', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <img src={amazon} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={flipkart} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.flipkartLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>
  </div>

 
  <div style={{ display: 'flex', gap: '60px', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={snapdeal} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.snapdealLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={pinnacle} style={{ width: '60px', height: '40px' }} />
      <button
        onClick={() => {
          if (!isLoggedIn) {
            window.location.href = "https://testportal.ssccglpinnacle.com/login";
          } else {
            handleBuyNow(card.id);
          }
        }}
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
      >
        Buy Now
      </button>
    </div>
  </div>
</div> */}


<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  
  <div style={{ display: 'flex', gap: '60px', justifyContent: 'center' }}>
    {/* Pinnacle First */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={pinnacle} style={{ width: '60px', height: '40px' }} />
      <button
        onClick={() => {
          if (!isLoggedIn) {
            window.location.href = "https://testportal.ssccglpinnacle.com/login";
          } else {
            handleBuyNow(card.id);
          }
        }}
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
      >
        Buy Now
      </button>
    </div>

    {/* Flipkart Second */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={flipkart} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.flipkartLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>
  </div>

  <div style={{ display: 'flex', gap: '60px', justifyContent: 'center' }}>
    {/* Amazon Third */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={amazon} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    {/* Snapdeal Fourth */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={snapdeal} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.snapdealLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>
  </div>
</div>





                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default AllBook;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaMicrophone, FaSpinner } from "react-icons/fa";
import { TbSend2 } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
import DOMPurify from "dompurify";
import styles from "./Chatbot.module.css";

function Chatbot() {
  const [query, setQuery] = useState("");
  const [chat, setChat] = useState([]);
  const [greeting, setGreeting] = useState("Good Morning");
  const [activeSection, setActiveSection] = useState("Products and Services");
  const [isChatActive, setIsChatActive] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const chatWindowRef = useRef(null);

  const predefinedOptions = {
    "What's New": [
      {
        icon: "https://d3m4h509gttb94.cloudfront.net/Image/7thMathEng.png",
        label: "7th Edition SSC Maths Book",
        message: "Tell me about new books released.",
      },
      {
        icon: "https://d3m4h509gttb94.cloudfront.net/Image/7thGSENG.png",
        label: "7th Edition SSC General Studies Book",
        message: "What are the latest ebooks added?",
      },
      {
        icon: "https://d3m4h509gttb94.cloudfront.net/Image/CA2025.png",
        label: "Current Affaires 2025",
        message: "What are the latest ebooks added?",
      },
    ],
    "Products and Services": [
      {
        icon: "https://d3m4h509gttb94.cloudfront.net/frontend_images/testPortal.webp",
        label: "Test Portal",
        message: "Tell me about the test portal.",
      },
      {
        icon: "https://dzdx39zg243ni.cloudfront.net/images/b84fce9e-19cd-402d-8ce9-a1c9f8c228bb.png",
        label: "Books",
        message: "What books are available?",
      },
      {
        icon: "https://d3m4h509gttb94.cloudfront.net/frontend_images/eBooksImage.webp",
        label: "E-Books",
        message: "What e-books are available?",
      },
      {
        icon: "https://d3m4h509gttb94.cloudfront.net/frontend_images/videoCourseImage.webp",
        label: "Video-Course",
        message: "What video courses are available?",
      },
      {
        icon: "https://d3m4h509gttb94.cloudfront.net/WebPImage/1735709196627.webp",
        label: "Android Application",
        message: "Give me Android Application Link",
      },
    ],
  };

  const updateGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  };

  useEffect(() => {
    updateGreeting();
  }, []);

  const handleSectionToggle = (section) => {
    setActiveSection((prev) => (prev === section ? null : section));
  };

  const handlePredefinedClick = (message) => {
    setQuery(message);
    setIsChatActive(true);
    handleSend(message);
  };

  const handleSend = async (userQuery = query) => {
    if (!userQuery.trim()) return;
    const newChat = [...chat, { role: "user", message: userQuery }];
    setChat(newChat);
    setQuery("");
    setIsLoading(true);
    setIsChatActive(true);

    try {
      const response = await axios.post(
        "https://auth.ssccglpinnacle.com/chat", // Replace with your endpoint
        { query: userQuery }
      );
      const sanitizedMessage = DOMPurify.sanitize(response.data.response);
      setChat([...newChat, { role: "bot", message: sanitizedMessage }]);
    } catch (error) {
      setChat([
        ...newChat,
        { role: "bot", message: "Error: Could not get a response." },
      ]);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isLoading) {
      handleSend();
    }
  };

  const handleBackToWelcome = () => {
    setIsChatActive(false);
  };

  const startListening = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Speech recognition is not supported in this browser.");
      return;
    }

    const SpeechRecognition =
      window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;

    recognition.start();
    setIsListening(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setQuery(transcript);
      setIsListening(false);
    };

    recognition.onerror = () => {
      alert("Speech recognition error. Please try again.");
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const openLinksInNewTab = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      const href = e.target.getAttribute("href");
      if (href) {
        window.open(href, "_blank", "noopener noreferrer");
      }
    }
  };

  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.addEventListener("click", openLinksInNewTab);
    }
    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener("click", openLinksInNewTab);
      }
    };
  }, []);

  return (
    <div className={styles.chatbotContainer}>
      {!isChatActive && (
        <div className={styles.welcomeScreen}>
          <h3>{greeting}</h3>
          <p>
            Welcome to the path of success with Pinnacle Publications! 📚 Let’s
            conquer challenges together. 🌟🚀
          </p>
          {Object.keys(predefinedOptions).map((category, idx) => (
            <div key={idx} className={styles.category}>
              <div
                className={styles.categoryHeader}
                onClick={() => handleSectionToggle(category)}
              >
                <span>{category}</span>
                <span>{activeSection === category ? "👇" : "👈"}</span>
              </div>
              {activeSection === category && (
                <div className={styles.categoryContent}>
                  <div className={styles.grid}>
                    {predefinedOptions[category].map((item, itemIdx) => (
                      <button
                        key={itemIdx}
                        className={styles.serviceButton}
                        onClick={() => handlePredefinedClick(item.message)}
                      >
                        <img
                          src={item.icon}
                          alt={item.label}
                          className={styles.serviceIcon}
                        />
                        <span>{item.label}</span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
          <button
            className={styles.chatWithUsButton}
            onClick={() => setIsChatActive(true)}
          >
            <div className={styles.iconCircle}>
              <span>?</span>
            </div>
            Chat with us
          </button>
        </div>
      )}

      {isChatActive && (
        <>
          <div className={styles.chatHeader}>
            <IoIosArrowBack
              size={24}
              className={styles.backButton}
              onClick={handleBackToWelcome}
            />
            <span>Chat</span>
          </div>
          <div className={styles.chatWindow} ref={chatWindowRef}>
            {chat.map((entry, index) => (
              <div
                key={index}
                className={
                  entry.role === "user"
                    ? `${styles.userMessage} ${styles.selectable}`
                    : `${styles.botMessage} ${styles.selectable}`
                }
              >
                {entry.role === "bot" && (
                  <img
                    src="https://d3m4h509gttb94.cloudfront.net/Image/pinnacle_favicon.svg"
                    alt="Bot"
                    className={styles.robotIcon}
                  />
                )}
                {entry.role === "bot" ? (
                  <span
                    className={styles.messageText}
                    dangerouslySetInnerHTML={{ __html: entry.message }}
                  />
                ) : (
                  <span className={styles.messageText}>{entry.message}</span>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      <div className={styles.inputArea}>
        <div className={styles.micButton} onClick={startListening}>
          <FaMicrophone
            className={`${styles.micIcon} ${
              isListening ? styles.listening : ""
            }`}
          />
        </div>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          className={styles.inputBox}
          placeholder="Type your message..."
        />
        <button
          onClick={() => handleSend()}
          className={styles.sendButton}
          disabled={isLoading}
        >
          {isLoading ? (
            <FaSpinner className={styles.spinner} />
          ) : (
            <TbSend2 size={20} />
          )}
        </button>
      </div>
    </div>
  );
}

export default Chatbot;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaEdit } from "react-icons/fa";
// import { TbSend2 } from "react-icons/tb";
// import { IoIosArrowBack } from "react-icons/io";
// import DOMPurify from "dompurify";
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([]);
//   const [greeting, setGreeting] = useState("Good Morning");
//   const [activeSection, setActiveSection] = useState("Products and Services");
//   const [isChatActive, setIsChatActive] = useState(false);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const predefinedOptions = {
//     "What's New": [
//       {
//         icon: "https://dzdx39zg243ni.cloudfront.net/images/b84fce9e-19cd-402d-8ce9-a1c9f8c228bb.png",
//         label: "New Books Released",
//         message: "Tell me about new books released.",
//       },
//       {
//         icon: "https://dzdx39zg243ni.cloudfront.net/images/b84fce9e-19cd-402d-8ce9-a1c9f8c228bb.png",
//         label: "Latest Ebooks Added",
//         message: "What are the latest ebooks added?",
//       },
//     ],
//     "You May Like": [
//       {
//         icon: "https://dzdx39zg243ni.cloudfront.net/images/b84fce9e-19cd-402d-8ce9-a1c9f8c228bb.png",
//         label: "BestSeller Books For SSC",
//         message: "Tell me about new books released.",
//       },
//       {
//         icon: "https://dzdx39zg243ni.cloudfront.net/images/b84fce9e-19cd-402d-8ce9-a1c9f8c228bb.png",
//         label: "BestSeller E-Books For SSC",
//         message: "What are the latest ebooks added?",
//       },
//     ],
//     "Products and Services": [
//       {
//         icon: "https://d3m4h509gttb94.cloudfront.net/frontend_images/testPortal.webp",
//         label: "Test Portal",
//         message: "Tell me about the test portal.",
//       },
//       {
//         icon: "https://dzdx39zg243ni.cloudfront.net/images/b84fce9e-19cd-402d-8ce9-a1c9f8c228bb.png",
//         label: "Books",
//         message: "What books are available?",
//       },
//       {
//         icon: "https://d3m4h509gttb94.cloudfront.net/frontend_images/eBooksImage.webp",
//         label: "E-Books",
//         message: "What e-books are available?",
//       },
//       {
//         icon: "https://d3m4h509gttb94.cloudfront.net/frontend_images/videoCourseImage.webp",
//         label: "Video-Course",
//         message: "What video courses are available?",
//       },
//       {
//         icon: "https://d3m4h509gttb94.cloudfront.net/frontend_images/typingImage.webp",
//         label: "Typing",
//         message: "What books are available?",
//       },
//     ],
//   };

//   const updateGreeting = () => {
//     const currentHour = new Date().getHours();
//     if (currentHour < 12) {
//       setGreeting("Good Morning");
//     } else if (currentHour < 18) {
//       setGreeting("Good Afternoon");
//     } else {
//       setGreeting("Good Evening");
//     }
//   };

//   useEffect(() => {
//     updateGreeting();
//   }, []);

//   const handleSectionToggle = (section) => {
//     setActiveSection((prev) => (prev === section ? null : section));
//   };

//   const handlePredefinedClick = (message) => {
//     setQuery(message);
//     setIsChatActive(true);
//     handleSend(message);
//   };

//   const handleSend = async (userQuery = query) => {
//     if (!userQuery.trim()) return;
//     const newChat = [...chat, { role: "user", message: userQuery }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);
//     setIsChatActive(true);

//     try {
//       const response = await axios.post(
//         // "https://auth.ssccglpinnacle.com/chat",
//         "http://localhost:5000/chat",
//         { query: userQuery }
//       );
//       const sanitizedMessage = DOMPurify.sanitize(response.data.response);
//       setChat([...newChat, { role: "bot", message: sanitizedMessage }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !isLoading) {
//       handleSend();
//     }
//   };

//   const handleBackToWelcome = () => {
//     setIsChatActive(false);
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser.");
//       return;
//     }

//     const SpeechRecognition =
//       window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Speech recognition error. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   const openLinksInNewTab = (e) => {
//     if (e.target.tagName === "A") {
//       e.preventDefault();
//       const href = e.target.getAttribute("href");
//       if (href) {
//         window.open(href, "_blank", "noopener noreferrer");
//       }
//     }
//   };

//   useEffect(() => {
//     const chatWindow = chatWindowRef.current;
//     if (chatWindow) {
//       chatWindow.addEventListener("click", openLinksInNewTab);
//     }
//     return () => {
//       if (chatWindow) {
//         chatWindow.removeEventListener("click", openLinksInNewTab);
//       }
//     };
//   }, []);

//   return (
//     <div className={styles.chatbotContainer}>
//       {!isChatActive && (
//         <div className={styles.welcomeScreen}>
//           <h3>{greeting}</h3>
//           <p>
//             Welcome to the path of success with Pinnacle Publications! 📚 Let’s
//             conquer challenges together. 🌟🚀
//           </p>
//           {Object.keys(predefinedOptions).map((category, idx) => (
//             <div key={idx} className={styles.category}>
//               <div
//                 className={styles.categoryHeader}
//                 onClick={() => handleSectionToggle(category)}
//               >
//                 <span>{category}</span>
//                 <span>{activeSection === category ? "👇" : "👈"}</span>
//               </div>
//               {activeSection === category && (
//                 <div className={styles.categoryContent}>
//                   <div className={styles.grid}>
//                     {predefinedOptions[category].map((item, itemIdx) => (
//                       <button
//                         key={itemIdx}
//                         className={styles.serviceButton}
//                         onClick={() => handlePredefinedClick(item.message)}
//                       >
//                         <img
//                           src={item.icon}
//                           alt={""}
//                           className={styles.serviceIcon}
//                         />
//                         <span>{item.label}</span>
//                       </button>
//                     ))}
//                   </div>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       )}

//       {isChatActive && (
//         <>
//           <div className={styles.chatHeader}>
//             <IoIosArrowBack
//               size={24}
//               className={styles.backButton}
//               onClick={handleBackToWelcome}
//             />
//             <span>Chat</span>
//           </div>
//           <div className={styles.chatWindow} ref={chatWindowRef}>
//             {chat.map((entry, index) => (
//               <div
//                 key={index}
//                 className={
//                   entry.role === "user"
//                     ? `${styles.userMessage} ${styles.selectable}`
//                     : `${styles.botMessage} ${styles.selectable}`
//                 }
//               >
//                 {entry.role === "bot" && (
//                   <img
//                     src="https://d3m4h509gttb94.cloudfront.net/Image/pinnacle_favicon.svg"
//                     alt="Pinnacle"
//                     className={styles.robotIcon}
//                   />
//                 )}
//                 {entry.role === "bot" ? (
//                   <span
//                     className={styles.messageText}
//                     dangerouslySetInnerHTML={{ __html: entry.message }}
//                   />
//                 ) : (
//                   <span className={styles.messageText}>{entry.message}</span>
//                 )}
//               </div>
//             ))}
//           </div>
//         </>
//       )}

//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${
//               isListening ? styles.listening : ""
//             }`}
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button
//           onClick={() => handleSend()}
//           className={styles.sendButton}
//           disabled={isLoading}
//         >
//           {isLoading ? (
//             <FaSpinner className={styles.spinner} />
//           ) : (
//             <TbSend2 size={20} />
//           )}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaEdit } from "react-icons/fa";
// import { TbSend2 } from "react-icons/tb";
// import { IoIosArrowBack } from "react-icons/io";
// import DOMPurify from "dompurify";
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([]);
//   const [greeting, setGreeting] = useState("Good Morning");
//   const [activeSection, setActiveSection] = useState("Products and Services");
//   const [isChatActive, setIsChatActive] = useState(false);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const predefinedOptions = {
//     "What's New": [
//       { icon: "📚", label: "New Books Released", message: "Tell me about new books released." },
//       { icon: "📖", label: "Latest Ebooks Added", message: "What are the latest ebooks added?" },
//       { icon: "🎥", label: "New Video Courses Launched", message: "Any new video courses launched?" },
//       { icon: "⌨️", label: "Typing Practice Updates", message: "What are the latest updates for typing practice?" },
//     ],
//     "You May Like": [
//       { icon: "📘", label: "Popular Books for SSC CGL", message: "Suggest popular books for SSC CGL." },
//       { icon: "📙", label: "Top Ebooks for Competitive Exams", message: "What are the top ebooks for competitive exams?" },
//       { icon: "📹", label: "Recommended Video Courses", message: "Which video courses do you recommend?" },
//       { icon: "✍️", label: "Typing Practice for Govt Exams", message: "Can you help with typing practice for government exams?" },
//     ],
//     "Products and Services": [
//       { icon: "💻", label: "Test Portal", message: "Tell me about the test portal." },
//       { icon: "📚", label: "Books", message: "What books are available?" },
//       { icon: "📖", label: "Ebooks", message: "What ebooks are available?" },
//       { icon: "🎥", label: "Video Courses", message: "What video courses do you offer?" },
//       { icon: "⌨️", label: "Typing", message: "Can you guide me on typing practice?" },
//     ],
//   };

//   const updateGreeting = () => {
//     const currentHour = new Date().getHours();
//     if (currentHour < 12) {
//       setGreeting("Good Morning");
//     } else if (currentHour < 18) {
//       setGreeting("Good Afternoon");
//     } else {
//       setGreeting("Good Evening");
//     }
//   };

//   useEffect(() => {
//     updateGreeting();
//   }, []);

//   const handleSectionToggle = (section) => {
//     setActiveSection((prev) => (prev === section ? null : section));
//   };

//   const handlePredefinedClick = (message) => {
//     setQuery(message);
//     setIsChatActive(true);
//     handleSend(message);
//   };

//   const handleSend = async (userQuery = query) => {
//     if (!userQuery.trim()) return;
//     const newChat = [...chat, { role: "user", message: userQuery }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);
//     setIsChatActive(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query: userQuery,
//       });
//       const sanitizedMessage = DOMPurify.sanitize(response.data.response);
//       setChat([...newChat, { role: "bot", message: sanitizedMessage }]);
//     } catch (error) {
//       setChat([...newChat, { role: "bot", message: "Error: Could not get a response." }]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   const handleEditQuery = (message) => {
//     setQuery(message);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !isLoading) {
//       handleSend();
//     }
//   };

//   const handleBackToWelcome = () => {
//     setIsChatActive(false);
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   const openLinksInNewTab = (e) => {
//     if (e.target.tagName === "A") {
//       e.preventDefault();
//       const href = e.target.getAttribute("href");
//       if (href) {
//         window.open(href, "_blank", "noopener noreferrer");
//       }
//     }
//   };

//   useEffect(() => {
//     const chatWindow = chatWindowRef.current;
//     if (chatWindow) {
//       chatWindow.addEventListener("click", openLinksInNewTab);
//     }
//     return () => {
//       if (chatWindow) {
//         chatWindow.removeEventListener("click", openLinksInNewTab);
//       }
//     };
//   }, []);

//   return (
//     <div className={styles.chatbotContainer}>
//       {!isChatActive && (
//         <div className={styles.welcomeScreen}>
//           <h3>{greeting}</h3>
//           <p>
//             Welcome to the path of success with Pinnacle Publications! 📚 Let’s
//             conquer challenges together. 🌟🚀
//           </p>
//           {Object.keys(predefinedOptions).map((category, idx) => (
//             <div key={idx} className={styles.category}>
//               <div
//                 className={styles.categoryHeader}
//                 onClick={() => handleSectionToggle(category)}
//               >
//                 <span>{category}</span>
//                 <span>{activeSection === category ? "👇" : "👉"}</span>
//               </div>
//               {activeSection === category && (
//                 <div className={styles.categoryContent}>
//                   <div className={styles.grid}>
//                     {predefinedOptions[category].map((item, itemIdx) => (
//                       <button
//                         key={itemIdx}
//                         className={styles.serviceButton}
//                         onClick={() => handlePredefinedClick(item.message)}
//                       >
//                         <span className={styles.serviceIcon}>{item.icon}</span>
//                         <span>{item.label}</span>
//                       </button>
//                     ))}
//                   </div>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       )}

//       {isChatActive && (
//         <>
//           <div className={styles.chatHeader}>
//             <IoIosArrowBack
//               size={24}
//               className={styles.backButton}
//               onClick={handleBackToWelcome}
//             />
//             <span>Chat</span>
//           </div>
//           <div className={styles.chatWindow} ref={chatWindowRef}>
//             {chat.map((entry, index) => (
//               <div
//                 key={index}
//                 className={
//                   entry.role === "user"
//                     ? `${styles.userMessage} ${styles.selectable}`
//                     : `${styles.botMessage} ${styles.selectable}`
//                 }
//               >
//                 {entry.role === "bot" ? (
//                   <span
//                     className={styles.messageText}
//                     dangerouslySetInnerHTML={{ __html: entry.message }}
//                   />
//                 ) : (
//                   <span className={styles.messageText}>{entry.message}</span>
//                 )}
//                 {entry.role === "user" && (
//                   <FaEdit
//                     className={styles.editIcon}
//                     onClick={() => handleEditQuery(entry.message)}
//                   />
//                 )}
//               </div>
//             ))}
//           </div>
//         </>
//       )}

//       <div className={styles.inputArea}>
//         <div className={styles.micButton}>
//           <FaMicrophone className={`${styles.micIcon} ${isListening ? styles.listening : ""}`} />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={() => handleSend()} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : <TbSend2 size={20} />}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaEdit, FaArrowLeft } from "react-icons/fa";
// import { TbSend2 } from "react-icons/tb";
// import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([]);
//   const [greeting, setGreeting] = useState("Good Morning");
//   const [activeSection, setActiveSection] = useState("Products and Services");
//   const [isChatActive, setIsChatActive] = useState(false); // Toggles between welcome screen and chat screen
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const predefinedOptions = {
//     "What's New": [
//       { icon: "📚", label: "New Books Released", message: "Tell me about new books released." },
//       { icon: "📖", label: "Latest Ebooks Added", message: "What are the latest ebooks available?" },
//       { icon: "🎥", label: "New Video Courses Launched", message: "What are the new video courses launched?" },
//       { icon: "⌨️", label: "Typing Practice Updates", message: "Tell me about typing practice updates." },
//     ],
//     "You May Like": [
//       { icon: "📘", label: "Popular Books for SSC CGL", message: "What are the popular books for SSC CGL?" },
//       { icon: "📙", label: "Top Ebooks for Competitive Exams", message: "What are the top ebooks for exams?" },
//       { icon: "📹", label: "Recommended Video Courses", message: "Recommend video courses for exams." },
//       { icon: "✍️", label: "Typing Practice for Govt Exams", message: "Guide me for typing practice for govt exams." },
//     ],
//     "Products and Services": [
//       { icon: "💻", label: "Test Portal", message: "Tell me about the Test Portal." },
//       { icon: "📚", label: "Books", message: "Tell me about available books." },
//       { icon: "📖", label: "Ebooks", message: "Tell me about available ebooks." },
//       { icon: "🎥", label: "Video Courses", message: "Tell me about video courses." },
//       { icon: "⌨️", label: "Typing", message: "Guide me about typing practice." },
//     ],
//   };

//   // Update greeting based on time of day
//   const updateGreeting = () => {
//     const currentHour = new Date().getHours();
//     if (currentHour < 12) {
//       setGreeting("Good Morning");
//     } else if (currentHour < 18) {
//       setGreeting("Good Afternoon");
//     } else {
//       setGreeting("Good Evening");
//     }
//   };

//   useEffect(() => {
//     updateGreeting();
//   }, []);

//   const handleSectionToggle = (section) => {
//     setActiveSection((prev) => (prev === section ? null : section));
//   };

//   const handlePredefinedClick = (message) => {
//     setQuery(message);
//     setIsChatActive(true); // Switch to chat mode
//     handleSend(message);
//   };

//   const handleSend = async (userQuery = query) => {
//     if (!userQuery.trim()) return;
//     const newChat = [...chat, { role: "user", message: userQuery }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);
//     setIsChatActive(true); // Switch to chat mode

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query: userQuery,
//       });
//       const sanitizedMessage = DOMPurify.sanitize(response.data.response); // Sanitize bot response
//       setChat([...newChat, { role: "bot", message: sanitizedMessage }]);
//     } catch (error) {
//       setChat([...newChat, { role: "bot", message: "Error: Could not get a response." }]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   const handleBackToWelcome = () => {
//     setIsChatActive(false);
//   };

//   return (
//     <div className={styles.chatbotContainer}>
//       {!isChatActive && (
//         <div className={styles.welcomeScreen}>
//           <h3>{greeting}</h3>
//           <p>Welcome to the path of success with Pinnacle Publications! 📚 Let’s conquer challenges together. 🌟🚀</p>
//           {Object.keys(predefinedOptions).map((category, idx) => (
//             <div key={idx} className={styles.category}>
//               <div
//                 className={styles.categoryHeader}
//                 onClick={() => handleSectionToggle(category)}
//               >
//                 <span>{category}</span>
//                 <span>{activeSection === category ? "👇" : "👉"}</span>
//               </div>
//               {activeSection === category && (
//                 <div className={styles.categoryContent}>
//                   <div className={styles.grid}>
//                     {predefinedOptions[category].map((item, itemIdx) => (
//                       <button
//                         key={itemIdx}
//                         className={styles.serviceButton}
//                         onClick={() => handlePredefinedClick(item.message)}
//                       >
//                         <span className={styles.serviceIcon}>{item.icon}</span>
//                         <span>{item.label}</span>
//                       </button>
//                     ))}
//                   </div>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       )}

//       {isChatActive && (
//         <div className={styles.chatWindow} ref={chatWindowRef}>
//           <button onClick={handleBackToWelcome} className={styles.backButton}>
//             <FaArrowLeft /> Back
//           </button>
//           {chat.map((entry, index) => (
//             <div
//               key={index}
//               className={
//                 entry.role === "user"
//                   ? `${styles.userMessage} ${styles.selectable}`
//                   : `${styles.botMessage} ${styles.selectable}`
//               }
//             >
//               {entry.role === "bot" ? (
//                 <span
//                   className={styles.messageText}
//                   dangerouslySetInnerHTML={{ __html: entry.message }}
//                 />
//               ) : (
//                 <span className={styles.messageText}>{entry.message}</span>
//               )}
//             </div>
//           ))}
//         </div>
//       )}

//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone className={`${styles.micIcon} ${isListening ? styles.listening : ""}`} />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyPress={(e) => e.key === "Enter" && handleSend()}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={() => handleSend()} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : <TbSend2 size={20} />}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaEdit } from "react-icons/fa";
// import { TbSend2 } from "react-icons/tb";
// import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([]);
//   const [greeting, setGreeting] = useState("Good Morning");
//   const [activeSection, setActiveSection] = useState("Products and Services");
//   const [isChatActive, setIsChatActive] = useState(false); // Toggles between welcome screen and chat screen
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const predefinedOptions = {
//     "What's New": [
//       { icon: "📚", label: "New Books Released" },
//       { icon: "📖", label: "Latest Ebooks Added" },
//       { icon: "🎥", label: "New Video Courses Launched" },
//       { icon: "⌨️", label: "Typing Practice Updates" },
//     ],
//     "You May Like": [
//       { icon: "📘", label: "Popular Books for SSC CGL" },
//       { icon: "📙", label: "Top Ebooks for Competitive Exams" },
//       { icon: "📹", label: "Recommended Video Courses" },
//       { icon: "✍️", label: "Typing Practice for Govt Exams" },
//     ],
//     "Products and Services": [
//       { icon: "💻", label: "Test Portal" },
//       { icon: "📚", label: "Books" },
//       { icon: "📖", label: "Ebooks" },
//       { icon: "🎥", label: "Video Courses" },
//       { icon: "⌨️", label: "Typing" },
//     ],
//   };

//   // Update greeting based on time of day
//   const updateGreeting = () => {
//     const currentHour = new Date().getHours();
//     if (currentHour < 12) {
//       setGreeting("Good Morning");
//     } else if (currentHour < 18) {
//       setGreeting("Good Afternoon");
//     } else {
//       setGreeting("Good Evening");
//     }
//   };

//   useEffect(() => {
//     updateGreeting();
//   }, []);

//   const handleSectionToggle = (section) => {
//     setActiveSection((prev) => (prev === section ? null : section));
//   };

//   const handlePredefinedClick = (message) => {
//     setQuery(message);
//     setIsChatActive(true); // Switch to chat mode
//     handleSend(message);
//   };

//   const handleSend = async (userQuery = query) => {
//     if (!userQuery.trim()) return;
//     const newChat = [...chat, { role: "user", message: userQuery }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);
//     setIsChatActive(true); // Switch to chat mode

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query: userQuery,
//       });
//       const sanitizedMessage = DOMPurify.sanitize(response.data.response); // Sanitize bot response
//       setChat([...newChat, { role: "bot", message: sanitizedMessage }]);
//     } catch (error) {
//       setChat([...newChat, { role: "bot", message: "Error: Could not get a response." }]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       {!isChatActive && (
//         <div className={styles.welcomeScreen}>
//           <h3>{greeting}</h3>
//           <p>Welcome to the path of success with Pinnacle Publications! 📚 Let’s conquer challenges together. 🌟🚀</p>
//           {Object.keys(predefinedOptions).map((category, idx) => (
//             <div key={idx} className={styles.category}>
//               <div
//                 className={styles.categoryHeader}
//                 onClick={() => handleSectionToggle(category)}
//               >
//                 <span>{category}</span>
//                 <span>{activeSection === category ? "👇" : "👉"}</span>
//               </div>
//               {activeSection === category && (
//                 <div className={styles.categoryContent}>
//                   {typeof predefinedOptions[category][0] === "string" ? (
//                     predefinedOptions[category].map((item, itemIdx) => (
//                       <button
//                         key={itemIdx}
//                         className={styles.questionButton}
//                         onClick={() => handlePredefinedClick(item)}
//                       >
//                         {item}
//                       </button>
//                     ))
//                   ) : (
//                     <div className={styles.grid}>
//                       {predefinedOptions[category].map((item, itemIdx) => (
//                         <button
//                           key={itemIdx}
//                           className={styles.serviceButton}
//                           onClick={() => handlePredefinedClick(item.label)}
//                         >
//                           <span className={styles.serviceIcon}>{item.icon}</span>
//                           <span>{item.label}</span>
//                         </button>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       )}

//       {isChatActive && (
//         <div className={styles.chatWindow} ref={chatWindowRef}>
//           {chat.map((entry, index) => (
//             <div
//               key={index}
//               className={
//                 entry.role === "user"
//                   ? `${styles.userMessage} ${styles.selectable}`
//                   : `${styles.botMessage} ${styles.selectable}`
//               }
//             >
//               {entry.role === "bot" ? (
//                 <span
//                   className={styles.messageText}
//                   dangerouslySetInnerHTML={{ __html: entry.message }}
//                 />
//               ) : (
//                 <span className={styles.messageText}>{entry.message}</span>
//               )}
//             </div>
//           ))}
//         </div>
//       )}

//       <div className={styles.inputArea}>
//         <div className={styles.micButton}>
//           <FaMicrophone className={`${styles.micIcon} ${isListening ? styles.listening : ""}`} />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={() => handleSend()} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : <TbSend2 size={20} />}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaEdit } from "react-icons/fa";
// import { TbSend2 } from "react-icons/tb";
// import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([
//     {
//       role: "bot",
//       message:
//         "⚠️ Our chatbot is experiencing issues. For urgent help, contact <a href='mailto:support@ssccglpinnacle.com' target='_blank' rel='noopener noreferrer'>support@ssccglpinnacle.com</a>.",
//     },
//   ]);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [greeting, setGreeting] = useState("Good Morning");
//   const chatWindowRef = useRef(null);

//   const predefinedQuestions = {
//     "Products and Services": [
//       "What are your account services?",
//       "How do I open a savings account?",
//       "Tell me about credit card services.",
//     ],
//     "What's New": ["What are the latest offers?", "Any updates on new products?"],
//     "You May Like": ["Can I apply for a car loan?", "Tell me about home loans."],
//   };

//   // Function to determine the greeting based on the time of day
//   const updateGreeting = () => {
//     const currentHour = new Date().getHours();
//     if (currentHour < 12) {
//       setGreeting("Good Morning");
//     } else if (currentHour < 18) {
//       setGreeting("Good Afternoon");
//     } else {
//       setGreeting("Good Evening");
//     }
//   };

//   useEffect(() => {
//     updateGreeting();
//   }, []);

//   const handlePredefinedClick = (question) => {
//     setQuery(question);
//     handleSend(question);
//   };

//   const handleSend = async (userQuery = query) => {
//     if (!userQuery.trim()) return;
//     const newChat = [...chat, { role: "user", message: userQuery }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query: userQuery,
//       });
//       const sanitizedMessage = DOMPurify.sanitize(response.data.response); // Sanitize bot response
//       setChat([...newChat, { role: "bot", message: sanitizedMessage }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       {/* Predefined Questions Section */}
//       <div className={styles.predefinedSection}>
//         <h3>{greeting}</h3>
//         <p>I'm your assistant. How can I help you today?</p>
//         {Object.keys(predefinedQuestions).map((category, idx) => (
//           <div key={idx} className={styles.category}>
//             <h4>{category}</h4>
//             <div className={styles.questionList}>
//               {predefinedQuestions[category].map((question, qIdx) => (
//                 <button
//                   key={qIdx}
//                   className={styles.questionButton}
//                   onClick={() => handlePredefinedClick(question)}
//                 >
//                   {question}
//                 </button>
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Chat Window */}
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user"
//                 ? `${styles.userMessage} ${styles.selectable}`
//                 : `${styles.botMessage} ${styles.selectable}`
//             }
//           >
//             {entry.role === "bot" ? (
//               <span
//                 className={styles.messageText}
//                 dangerouslySetInnerHTML={{ __html: entry.message }}
//               />
//             ) : (
//               <span className={styles.messageText}>{entry.message}</span>
//             )}
//           </div>
//         ))}
//       </div>

//       {/* Input Section */}
//       <div className={styles.inputArea}>
//         <div className={styles.micButton}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button
//           onClick={() => handleSend()}
//           className={styles.sendButton}
//           disabled={isLoading}
//         >
//           {isLoading ? <FaSpinner className={styles.spinner} /> : <TbSend2 size={20} />}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaEdit } from "react-icons/fa";
// import { TbSend2 } from "react-icons/tb";
// import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([
//     {
//       role: "bot",
//       message:
//         "⚠️ Our chatbot is experiencing issues. For urgent help, contact <a href='mailto:support@ssccglpinnacle.com' target='_blank' rel='noopener noreferrer'>support@ssccglpinnacle.com</a>.",
//     },
//   ]);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       const sanitizedMessage = DOMPurify.sanitize(response.data.response); // Sanitize bot response
//       setChat([...newChat, { role: "bot", message: sanitizedMessage }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !isLoading) {
//       handleSend();
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   const handleEditQuery = (message) => {
//     setQuery(message);
//   };

//   // Add event listener for links in bot messages
//   const handleLinkClicks = (e) => {
//     const target = e.target;
//     if (target.tagName === "A") {
//       const href = target.getAttribute("href");
//       if (href) {
//         e.preventDefault();
//         window.open(href, "_blank", "noopener,noreferrer");
//       }
//     }
//   };

//   useEffect(() => {
//     const chatWindow = chatWindowRef.current;

//     if (chatWindow) {
//       chatWindow.addEventListener("click", handleLinkClicks);
//     }

//     return () => {
//       if (chatWindow) {
//         chatWindow.removeEventListener("click", handleLinkClicks);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user" ? `${styles.userMessage} ${styles.selectable}` : `${styles.botMessage} ${styles.selectable}`
//             }
//             onMouseEnter={(e) => {
//               e.currentTarget.querySelectorAll(".action-icon").forEach((icon) => {
//                 icon.style.display = "inline";
//               });
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.querySelectorAll(".action-icon").forEach((icon) => {
//                 icon.style.display = "none";
//               });
//             }}
//           >
//             {entry.role === "bot" ? (
//               <span
//                 className={styles.messageText}
//                 dangerouslySetInnerHTML={{ __html: entry.message }} // Render sanitized HTML
//               />
//             ) : (
//               <span className={styles.messageText}>{entry.message}</span>
//             )}
//             {entry.role === "user" && (
//               <FaEdit
//                 className={`${styles.editIcon} action-icon`}
//                 onClick={() => handleEditQuery(entry.message)}
//                 title="Edit Query"
//                 style={{ display: "none" }}
//               />
//             )}
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : <TbSend2 size={20} />}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaEdit } from "react-icons/fa";
// import styles from "./Chatbot.module.css";
// import { TbSend2 } from "react-icons/tb";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([
//     {
//       role: "bot",
//       message:
//         "⚠️ Our chatbot is experiencing issues. For urgent help, contact support@ssccglpinnacle.com.",
//     },
//   ]);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       setChat([...newChat, { role: "bot", message: response.data.response }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !isLoading) {
//       handleSend();
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   const handleEditQuery = (message) => {
//     setQuery(message);
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user" ? `${styles.userMessage} ${styles.selectable}` : `${styles.botMessage} ${styles.selectable}`
//             }
//             onMouseEnter={(e) => {
//               e.currentTarget.querySelectorAll(".action-icon").forEach((icon) => {
//                 icon.style.display = "inline";
//               });
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.querySelectorAll(".action-icon").forEach((icon) => {
//                 icon.style.display = "none";
//               });
//             }}
//           >
//             <span className={styles.messageText}>{entry.message}</span>
//             {entry.role === "user" && (
//               <FaEdit
//                 className={`${styles.editIcon} action-icon`}
//                 onClick={() => handleEditQuery(entry.message)}
//                 title="Edit Query"
//                 style={{ display: "none" }}
//               />
//             )}
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : <TbSend2 size={20} />}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import { FaMicrophone, FaSpinner, FaCopy, FaEdit } from "react-icons/fa";
// import styles from "./Chatbot.module.css";
// import { TbSend2 } from "react-icons/tb";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([
//     {
//       role: "bot",
//       message:
//         "⚠️ Our chatbot is experiencing issues. For urgent help, contact support@ssccglpinnacle.com.",
//     },
//   ]);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       setChat([...newChat, { role: "bot", message: response.data.response }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !isLoading) {
//       handleSend();
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   const handleCopyMessage = (message) => {
//     navigator.clipboard.writeText(message);
//   };

//   const handleEditQuery = (message) => {
//     setQuery(message);
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user" ? styles.userMessage : styles.botMessage
//             }
//             onMouseEnter={(e) => {
//               e.currentTarget.querySelectorAll(".action-icon").forEach((icon) => {
//                 icon.style.display = "inline";
//               });
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.querySelectorAll(".action-icon").forEach((icon) => {
//                 icon.style.display = "none";
//               });
//             }}
//           >
//             <span className={styles.messageText}>{entry.message}</span>
//             {entry.role === "bot" && (
//               <FaCopy
//                 className={`${styles.copyIcon} action-icon`}
//                 onClick={() => handleCopyMessage(entry.message)}
//                 title="Copy Message"
//                 style={{ display: "none" }}
//               />
//             )}
//             {entry.role === "user" && (
//               <FaEdit
//                 className={`${styles.editIcon} action-icon`}
//                 onClick={() => handleEditQuery(entry.message)}
//                 title="Edit Query"
//                 style={{ display: "none" }}
//               />
//             )}
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : <TbSend2 size={20}/>}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import { FaMicrophone } from "react-icons/fa";
// import { FaSpinner } from "react-icons/fa";
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([{ role: "bot", message: "⚠️ Important Note for Students ⚠️ Our ChatBot is currently facing technical difficulties and may not function as expected. We apologize for any inconvenience this may cause.For urgent assistance or queries, please reach out to us via email at support@ssccglpinnacle.com.Team Pinnacle Coaching 🌟" }]);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       setChat([...newChat, { role: "bot", message: response.data.response }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !isLoading) {
//       handleSend();
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user"
//                 ? styles.userMessage
//                 : styles.botMessage
//             }
//           >
//             <span className={styles.messageText}>{entry.message}</span>
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyPress={handleKeyPress} // Trigger handleSend on Enter key
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : "Send"}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import { FaMicrophone } from "react-icons/fa";
// import { FaSpinner } from "react-icons/fa";
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([{ role: "bot", message: "⚠️ Important Note for Students ⚠️ Our ChatBot is currently facing technical difficulties and may not function as expected. We apologize for any inconvenience this may cause.For urgent assistance or queries, please reach out to us via email at support@ssccglpinnacle.com.Team Pinnacle Coaching 🌟" }]);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);

//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       setChat([...newChat, { role: "bot", message: response.data.response }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//   }, [chat]);

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user"
//                 ? styles.userMessage
//                 : styles.botMessage
//             }
//           >
//             <span className={styles.messageText}>{entry.message}</span>
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : "Send"}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

// import React, { useState, useRef } from "react";
// import axios from "axios";
// import { FaMicrophone } from "react-icons/fa";
// import { FaSpinner } from "react-icons/fa";
// import styles from "./Chatbot.module.css";

// function Chatbot() {
//   const [query, setQuery] = useState("");
//   const [chat, setChat] = useState([]);
//   const [isListening, setIsListening] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const chatWindowRef = useRef(null);
//   const handleSend = async () => {
//     if (!query.trim()) return;
//     const newChat = [...chat, { role: "user", message: query }];
//     setChat(newChat);
//     setQuery("");
//     setIsLoading(true);

//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/chat", {
//         query,
//       });
//       setChat([...newChat, { role: "bot", message: response.data.response }]);
//     } catch (error) {
//       setChat([
//         ...newChat,
//         { role: "bot", message: "Error: Could not get a response." },
//       ]);
//     } finally {
//       setIsLoading(false);
//       scrollToBottom();
//     }
//   };
//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = () => {
//       alert("Error with speech recognition. Please try again.");
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };
//   const scrollToBottom = () => {
//     if (chatWindowRef.current) {
//       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
//     }
//   };

//   return (
//     <div className={styles.chatbotContainer}>
//       <div className={styles.chatWindow} ref={chatWindowRef}>
//         {chat.map((entry, index) => (
//           <div
//             key={index}
//             className={
//               entry.role === "user"
//                 ? styles.userMessage
//                 : styles.botMessage
//             }
//           >
//             <span className={styles.messageText}>{entry.message}</span>
//           </div>
//         ))}
//       </div>
//       <div className={styles.inputArea}>
//         <div className={styles.micButton} onClick={startListening}>
//           <FaMicrophone
//             className={`${styles.micIcon} ${isListening ? styles.listening : ""}`}
//             color={isListening ? "red" : "white"}
//             fontSize="1.5em"
//           />
//         </div>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           className={styles.inputBox}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} className={styles.sendButton} disabled={isLoading}>
//           {isLoading ? <FaSpinner className={styles.spinner} /> : "Send"}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Chatbot;

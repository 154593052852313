// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YoutubeModal_modalOverlay__SV2CG {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.YoutubeModal_modalContent__lTKpO {
  position: relative;
  /* width: 560px; */
  /* max-width: 90%; */
  background-color: #413f3f;
  height:35rem;
  width:60rem;
  padding: 15px;
  /* border-radius: 8px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.YoutubeModal_closeButton__ANX7t {
  position: absolute;
  top: 0px;
  right: 3px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  /* width:10px;
  height:10px; */
  color: white;
}

.YoutubeModal_closeButton__ANX7t:hover {
  color: #d8dee4;
}

.YoutubeModal_modalContent__lTKpO iframe {
  width:100%;
  height: 100%;
  /* border-radius: 8px; */
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/component/Page02/YoutubeModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,wBAAwB;EACxB,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,eAAe;EACf;gBACc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,wBAAwB;EACxB,YAAY;AACd","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.7);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modalContent {\n  position: relative;\n  /* width: 560px; */\n  /* max-width: 90%; */\n  background-color: #413f3f;\n  height:35rem;\n  width:60rem;\n  padding: 15px;\n  /* border-radius: 8px; */\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n}\n\n.closeButton {\n  position: absolute;\n  top: 0px;\n  right: 3px;\n  background-color: transparent;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n  /* width:10px;\n  height:10px; */\n  color: white;\n}\n\n.closeButton:hover {\n  color: #d8dee4;\n}\n\n.modalContent iframe {\n  width:100%;\n  height: 100%;\n  /* border-radius: 8px; */\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `YoutubeModal_modalOverlay__SV2CG`,
	"modalContent": `YoutubeModal_modalContent__lTKpO`,
	"closeButton": `YoutubeModal_closeButton__ANX7t`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseDetails from "./CourseDetails";
import styles from "./Page.module.css";
import Header from "../HomePage01/Header/Header";
import Footer from "../Footer/Footer";
import ProductMetaTags from "../../DynamicMetaData/ProductMetaTags";

export default function Page02() {
  const { category, subject, seoPermalink } = useParams();
  const [product, setProduct] = useState(null); // Store product details
  const [error, setError] = useState(""); // Handle errors
  const [loading, setLoading] = useState(true); // Handle loading state

  useEffect(() => {
    // console.log("Fetching course details..."); // Check if useEffect runs
    const getCourseDetails = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/get-seo/${category}/${subject}/${seoPermalink}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch course details");
        }
        const result = await response.json();
        // console.log("API Response:", result); // Check API response

        const productData = {
          courseTitle: result.courseTitle || "Course Title Not Available",
          SEOTitle:
            result.SEOTitle || result.courseTitle || "SEO Title Not Available",
          SEODescription: result.SEODescription || "No description available",
          SEOKeyword: result.SEOKeyword || "",
          englishCoverImage:
            result.englishCoverImage || result.hindiCoverImage || "",
          SEOPermalink: result.SEOPermalink,
          price: result.price || 0,
          averageRating: Math.max(0, Math.min(result.averageRating || 0, 5)),
          totalRatings: result.totalRatings || 0,
          rating: result.rating || result.averageRating || 0,
          instructorName: result.instructorName || "Instructor not available",
        };

        // console.log("Mapped Product Data:", productData); // Check mapped data
        setProduct(productData);
      } catch (error) {
        console.error("Error fetching course details:", error);
        setError("Failed to load course details.");
      } finally {
        setLoading(false);
      }
    };

    getCourseDetails();
  }, [category, subject, seoPermalink]);
  // console.log("Route Params:", { category, subject, seoPermalink });

  if (loading) {
    return (
      <div className={styles["loading-message"]}>
        <img
          src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
          alt="Loading..."
        />
      </div>
    );
  }

  if (error) {
    return <div className={styles["error-message"]}>{error}</div>;
  }

  // console.log(product);
  return (
    <>
      {product && <ProductMetaTags product={product} />}
      <div className={styles["main-body-course"]}>
        <Header />
        <CourseDetails />
        <div className={styles["footer"]}>
          <Footer />
        </div>
      </div>
    </>
  );
}

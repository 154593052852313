// import React, { useState } from "react";
// import { useAuth } from "../Context/AuthContext";
// import { useNavigate, Link } from "react-router-dom";
// import Header from "../component/HomePage01/Header/Header";
// import Footer from "../component/Footer/Footer";
// import axios from "axios";
// import classes from "./MyOrders.module.css";

// const MyOrders = () => {
//   const { isLoggedIn, user } = useAuth();
//   const navigate = useNavigate();
//   const [productData, setProductData] = useState({ purchasedVideos: [] });
//   const [coursesData, setCoursesData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [message, setMessage] = useState("");

//   // Redirect if not logged in
//   if (!isLoggedIn) {
//     navigate("/");
//     return null; // Return null to avoid rendering the rest of the component
//   }

//   // Fetch data if user exists
//   const fetchOrders = async () => {
//     if (!user) return;

//     try {
//       const response = await axios.get(
//         `https://auth.ssccglpinnacle.com/api/purchased_videos/${user._id}`
//       );

//       const coursesResponse = await axios.get(
//         "https://auth.ssccglpinnacle.com/api/courses"
//       );

//       if (response.data.purchasedVideos.length === 0) {
//         setMessage("You have not bought any product yet.");
//       } else {
//         setProductData(response.data);
//         setCoursesData(coursesResponse.data);
//       }
//     } catch (error) {
//       console.error("An internal server error occurred:", error);
//       setMessage("An error occurred while fetching data.");
//     } finally {
//       setIsLoading(false);
//     }
//   };
// //   console.log("coursesData:", coursesData);
// //   console.log("productData:", productData);

//   if (isLoading) {
//     // Trigger fetchOrders only once when loading is true
//     fetchOrders();
//     return <div className={classes.loader}>Loading...</div>;
//   }

//   const getCourseDetails = (productId) => {
//     const course = coursesData.find((course) => course._id === productId);
//     return course ? course : { courseTitle: "Unknown Product", price: "N/A", duration: "N/A" };
//   };

//   const handleOrderClick = (productId) => {
//     navigate(`/videos/mylearning/${productId}`);
//   };

//   return (
//     <>
//     <Header />
//     <div className={classes.container}>
//       <div className={classes.header}>
//         <div className={classes.heading_title}>
//         <h1 className={classes.title}>Purchased Video Course</h1>
//         </div>
//         <div className={classes.btn_main_div}>
//         <button className={classes.button_link}>
//           <Link
//             to="https://testportal.ssccglpinnacle.com/myorders"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Purchased Test Series
//           </Link>
//         </button>
//         <button className={classes.button_link}>
//           <Link
//             to="https://ssccglpinnacle.com/typing"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Purchased Typing Software
//           </Link>
//         </button>
//         <button className={classes.button_link}>
//           <Link
//             to="https://ssccglpinnacle.com/ebook/myorder"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Purchased E-Books
//           </Link>
//         </button>
//         <button className={classes.button_books}>
//           <Link
//             to="https://ssccglpinnacle.com/book/myorder"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Purchased Books
//           </Link>
//         </button>
//         </div>
//       </div>
//       {message && <p className={classes.message}>{message}</p>}
//       <table className={classes.table}>
//         <thead>
//           <tr>
//             <th>Order ID</th>
//             <th>Product Name</th>
//             <th>Product Price</th>
//             <th>Product Validation Period</th>
//             <th>Expiry Date</th>
//           </tr>
//         </thead>
//         <tbody>
//           {productData.purchasedVideos.map((purchasedVideo) => {
//             const courseDetails = getCourseDetails(purchasedVideo.productId);
//             return (
//               <tr key={purchasedVideo.productId}>
//                 <td>{purchasedVideo.productId}</td>
//                 <td onClick={() => handleOrderClick(purchasedVideo.productId)} style={{ cursor: "pointer", color: "blue" }}>{courseDetails.courseTitle}</td>
//                 <td>{courseDetails.price}</td>
//                 <td>{purchasedVideo.duration}</td>
//                 <td>
//                   {new Date(purchasedVideo.expirationDate).toLocaleDateString()}
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//     <Footer />
//     </>
//   );
// };

// export default MyOrders;

import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Header from "../component/HomePage01/Header/Header";
import Footer from "../component/Footer/Footer";
import axios from "axios";
import classes from "./MyOrders.module.css";

const MyOrders = () => {
  const { isLoggedIn, user, isAuthLoading } = useAuth();
  const navigate = useNavigate();
  const [mergedData, setMergedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!isAuthLoading && !isLoggedIn) {
      navigate("/");
    }
  }, [isAuthLoading, isLoggedIn, navigate]);

  const fetchOrders = async () => {
    try {
      if (!user?.email_id) throw new Error("Login is required.");

      const userPurchasedResponse = await axios.get(
        `https://auth.ssccglpinnacle.com/api/user_purchased_videos_email/${user.email_id}`
      );

      const purchasedVideos = userPurchasedResponse.data?.purchasedVideos || [];

      if (!purchasedVideos.length) {
        setMessage("You have not purchased any video courses yet.");
        return;
      }

      const uniqueVideos = Array.from(
        new Map(
          purchasedVideos.map((video) => [video.productId, video])
        ).values()
      );

      const courseDetailsPromises = uniqueVideos.map(async (video) => {
        const courseResponse = await axios.get(
          `https://auth.ssccglpinnacle.com/course/${video.productId}`
        );
        return {
          ...video,
          courseTitle: courseResponse.data?.courseTitle || "Unknown Course",
          price: courseResponse.data?.price || "N/A",
        };
      });

      const combinedData = await Promise.all(courseDetailsPromises);
      setMergedData(combinedData);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setMessage(
        "An error occurred while fetching your orders. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuthLoading && isLoggedIn) {
      fetchOrders();
    }
  }, [isAuthLoading, isLoggedIn, user?.email_id]);

  if (isAuthLoading || isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <img src="/Bubble-Preloader.gif" alt="Loading..." />
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className={classes.container}>
        <div className={classes.header}>
          <h1 className={classes.title}>Purchased Video Courses</h1>
        </div>
        {message ? (
          <p className={classes.message}>{message}</p>
        ) : (
          <table className={classes.table}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Course Title</th>
                <th>Price</th>
                <th>Duration</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {mergedData.map((course, index) => (
                <tr key={course.productId}>
                  <td>{index + 1}</td>
                  <td
                    onClick={() =>
                      navigate(`/videos/mylearning/${course.productId}`)
                    }
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {course.courseTitle}
                  </td>
                  <td>{course.price}</td>
                  <td>{course.duration}</td>
                  <td>
                    {new Date(course.expirationDate).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MyOrders;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import Swal from "sweetalert2";
import styles from "./AddCourseForm.module.css";
import VideoContentEditor from "./VideoContentEditor";

const AddCourseForm = () => {
  const initialCourseData = {
    courseTitle: "",
    shortDescription: "",
    category: "",
    instructorName: "",
    price: "",
    mrp: "",
    SEOTitle: "",
    SEOKeyword: "",
    SEOPermalink: "",
    SEODescription: "",
    longDescription: "",
    subject: "",
    edition: "",
    hindiCoverImage: null,
    englishCoverImage: null,
  };

  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [courseData, setCourseData] = useState(initialCourseData);
  const [generating, setGenerating] = useState({
    seoTitle: false,
    seoKeyword: false,
    seoDescription: false,
    shortDescription: false,
  });
  const [seoLimits] = useState({
    title: 60,
    // keywords: 120,
    description: 160,
  });
  useEffect(() => {
    const fetchCategoriesInstructorsAndSubjects = async () => {
      try {
        const [categoryRes, instructorRes, subjectRes] = await Promise.all([
          axios.get("https://auth.ssccglpinnacle.com/categories"),
          axios.get("https://auth.ssccglpinnacle.com/api/instructors"),
          axios.get("https://auth.ssccglpinnacle.com/api/subjects"),
        ]);

        setCategories(categoryRes.data);
        setInstructors(instructorRes.data.instructors || []);
        setSubjects(subjectRes.data.subjects || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategoriesInstructorsAndSubjects();
  }, []);
  // Generate permalink dynamically
  useEffect(() => {
    const { category, subject, courseTitle } = courseData;

    const combinedValue = `${(category || "").trim()}/${(
      subject || ""
    ).trim()}/${(courseTitle || "").trim()}`;

    const sanitizedPermalink = combinedValue
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-z0-9/-]/g, "") // Remove invalid characters
      .replace(/--+/g, "-") // Replace multiple hyphens with a single one
      .replace(/\/+/g, "/") // Prevent duplicate slashes
      .replace(/^\/|\/$/g, ""); // Remove leading and trailing slashes

    setCourseData((prev) => ({
      ...prev,
      SEOPermalink: sanitizedPermalink,
    }));
    console.log("Generated permalink:", sanitizedPermalink);
  }, [courseData.category, courseData.subject, courseData.courseTitle]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value.trimStart() });
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    setCourseData({
      ...courseData,
      longDescription: stateToHTML(state.getCurrentContent()),
    });
  };

  const { getRootProps: getRootPropsHindi, getInputProps: getInputPropsHindi } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, "hindiCoverImage"),
    });

  const {
    getRootProps: getRootPropsEnglish,
    getInputProps: getInputPropsEnglish,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, "englishCoverImage"),
  });

  const onDrop = (acceptedFiles, name) => {
    setCourseData({ ...courseData, [name]: acceptedFiles[0] });
  };

  // const generateSEOContent = async (type) => {
  //   if (
  //     !["shortDescription", "seoKeyword", "seoDescription", "all"].includes(
  //       type
  //     )
  //   ) {
  //     Swal.fire("Error", `Invalid type: ${type}`, "error");
  //     return;
  //   }

  //   const { courseTitle, subject, edition, category } = courseData;

  //   if (!courseTitle || !subject || !category) {
  //     Swal.fire(
  //       "Error",
  //       "Please provide Course Title, Subject, and Category",
  //       "error"
  //     );
  //     return;
  //   }
  //   setGenerating((prev) => ({ ...prev, [type]: true }));
  //   try {
  //     setLoading(true);
  //     const response = await axios.post(
  //       "https://auth.ssccglpinnacle.com/api/seo/video/generate",
  //       {
  //         courseTitle,
  //         subject,
  //         edition,
  //         category,
  //         type,
  //       }
  //     );

  //     const content = response.data?.content || "No content generated.";

  //     // Update the corresponding field in state dynamically
  //     if (type === "seoKeyword") {
  //       setCourseData((prev) => ({ ...prev, SEOKeyword: content }));
  //     } else if (type === "seoDescription") {
  //       setCourseData((prev) => ({ ...prev, SEODescription: content }));
  //     } else if (type === "shortDescription") {
  //       setCourseData((prev) => ({ ...prev, shortDescription: content }));
  //     }

  //     Swal.fire("Success", `${type} generated successfully`, "success");
  //   } catch (error) {
  //     console.error(
  //       "Error generating SEO content:",
  //       error.response?.data || error.message
  //     );
  //     Swal.fire(
  //       "Error",
  //       `Failed to generate ${type}: ${
  //         error.response?.data?.error || error.message
  //       }`,
  //       "error"
  //     );
  //   } finally {
  //     setGenerating((prev) => ({ ...prev, [type]: false }));
  //   }
  // };
  const generateSEOContent = async (type) => {
    if (
      ![
        "shortDescription",
        "seoKeyword",
        "seoDescription",
        "seoTitle",
        "all",
      ].includes(type)
    ) {
      Swal.fire("Error", `Invalid type: ${type}`, "error");
      return;
    }

    const { courseTitle, subject, edition, category } = courseData;

    if (!courseTitle || !subject || !category) {
      Swal.fire(
        "Error",
        "Please provide Course Title, Subject, and Category",
        "error"
      );
      return;
    }

    setGenerating((prev) => ({ ...prev, [type]: true }));
    try {
      const response = await axios.post(
        "https://auth.ssccglpinnacle.com/api/seo/video/generate",
        {
          courseTitle,
          subject,
          edition,
          category,
          type,
        }
      );

      const content = response.data?.content || "No content generated.";

      // Update the corresponding field in state dynamically
      if (type === "seoKeyword") {
        setCourseData((prev) => ({ ...prev, SEOKeyword: content }));
      } else if (type === "seoDescription") {
        setCourseData((prev) => ({ ...prev, SEODescription: content }));
      } else if (type === "seoTitle") {
        setCourseData((prev) => ({ ...prev, SEOTitle: content }));
      } else if (type === "shortDescription") {
        setCourseData((prev) => ({ ...prev, shortDescription: content }));
      }

      Swal.fire("Success", `${type} generated successfully`, "success");
    } catch (error) {
      console.error("Error generating SEO content:", error);
      Swal.fire(
        "Error",
        `Failed to generate ${type}: ${
          error.response?.data?.error || error.message
        }`,
        "error"
      );
    } finally {
      setGenerating((prev) => ({ ...prev, [type]: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.keys(courseData).forEach((key) => {
      if (key === "hindiCoverImage" || key === "englishCoverImage") {
        if (courseData[key])
          formData.append(key, courseData[key], courseData[key].name);
      } else {
        formData.append(key, courseData[key]);
      }
    });

    try {
      await axios.post(
        "https://auth.ssccglpinnacle.com/create-course",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      Swal.fire("Success", "Course created successfully", "success");
      setCourseData(initialCourseData);
    } catch (error) {
      console.error("Error creating course:", error);
      Swal.fire("Error", "Failed to create course", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleContentChange = (field, value) => {
    setCourseData({ ...courseData, [field]: value });
  };

  return (
    <form onSubmit={handleSubmit} className={styles.addCourseContainer}>
      <h2 className={styles.addCourseHeading}>Add Course</h2>

      <label className={styles.addCourseLabel}>Course Title:</label>
      <input
        type="text"
        name="courseTitle"
        value={courseData.courseTitle}
        onChange={handleChange}
        className={styles.addCourseInput}
        required
      />

      <label className={styles.addCourseLabel}>Category:</label>
      <select
        name="category"
        value={courseData.category}
        onChange={handleChange}
        className={styles.addCourseSelect}
        required
      >
        <option value="">Select a category</option>
        {categories.map((cat) => (
          <option key={cat._id} value={cat.categoryTitle}>
            {cat.categoryTitle}
          </option>
        ))}
      </select>

      <label className={styles.addCourseLabel}>Subject:</label>
      <select
        name="subject"
        value={courseData.subject}
        onChange={handleChange}
        className={styles.addCourseSelect}
        required
      >
        <option value="">Select a subject</option>
        {subjects.map((sub) => (
          <option key={sub._id} value={sub.title}>
            {sub.title}
          </option>
        ))}
      </select>

      <label className={styles.addCourseLabel}>Instructor Name:</label>
      <select
        name="instructorName"
        value={courseData.instructorName}
        onChange={handleChange}
        className={styles.addCourseSelect}
        required
      >
        <option value="">Select an instructor</option>
        {instructors.map((ins) => (
          <option key={ins._id} value={ins.instructorName}>
            {ins.instructorName}
          </option>
        ))}
      </select>

      <label className={styles.addCourseLabel}>Hindi Cover Image:</label>
      <div {...getRootPropsHindi()} className={styles.addCourseDropzone}>
        <input {...getInputPropsHindi()} />
        <p>Drag 'n drop an image here, or click to select one</p>
      </div>
      {courseData.hindiCoverImage && (
        <img
          src={URL.createObjectURL(courseData.hindiCoverImage)}
          alt="Hindi Cover"
          className={styles.addCoursePreviewImage}
        />
      )}

      <label className={styles.addCourseLabel}>English Cover Image:</label>
      <div {...getRootPropsEnglish()} className={styles.addCourseDropzone}>
        <input {...getInputPropsEnglish()} />
        <p>Drag 'n drop an image here, or click to select one</p>
      </div>
      {courseData.englishCoverImage && (
        <img
          src={URL.createObjectURL(courseData.englishCoverImage)}
          alt="English Cover"
          className={styles.addCoursePreviewImage}
        />
      )}

      <label className={styles.addCourseLabel}>Price:</label>
      <input
        type="number"
        name="price"
        value={courseData.price}
        onChange={handleChange}
        className={styles.addCourseInput}
        required
      />

      <label className={styles.addCourseLabel}>MRP:</label>
      <input
        type="number"
        name="mrp"
        value={courseData.mrp}
        onChange={handleChange}
        className={styles.addCourseInput}
        required
      />

      <label className={styles.addCourseLabel}>Edition:</label>
      <input
        type="text"
        name="edition"
        value={courseData.edition}
        onChange={handleChange}
        className={styles.addCourseInput}
        required
      />

      <label className={styles.addCourseLabel}>Short Description:</label>
      <textarea
        name="shortDescription"
        value={courseData.shortDescription}
        onChange={handleChange}
        className={styles.addCourseTextarea}
      />
      <button
        type="button"
        onClick={() => generateSEOContent("shortDescription")}
        className={styles.addCourseButton}
        disabled={generating.shortDescription} // Use the correct state
      >
        {generating.shortDescription
          ? "Generating..."
          : "Generate Short Description"}
      </button>

      <label className={styles.addCourseLabel}>Long Description:</label>
      <div className={styles.addCourseEditorContainer}>
        <VideoContentEditor
          content={courseData.longDescription}
          onContentChange={(value) => {
            handleContentChange("longDescription", value);
            setCourseData((prev) => ({ ...prev, longDescription: value }));
          }}
        />
      </div>

      <label className={styles.addCourseLabel}>SEO Title:</label>
      <input
        type="text"
        name="SEOTitle"
        value={courseData.SEOTitle}
        onChange={handleChange}
        className={styles.addCourseInput}
        seoKeyword
      />
      <small className={styles.characterCount}>
        {courseData.SEOTitle.length}/{seoLimits.title} characters
      </small>
      <button
        type="button"
        onClick={() => generateSEOContent("seoTitle")}
        className={styles.addCourseButton}
        disabled={generating.seoTitle}
      >
        {generating.seoTitle ? "Generating..." : "Generate SEO Title"}
      </button>

      <label className={styles.addCourseLabel}>SEO Keyword:</label>
      <textarea
        type="text"
        name="SEOKeyword"
        value={courseData.SEOKeyword}
        onChange={handleChange}
        className={styles.addCourseTextarea}
        // style={{
        //   color:
        //     courseData.SEOKeyword.length > seoLimits.keywords ? "red" : "black",
        // }}
        placeholder="Enter or Generate SEO Keywords"
      />
      <button
        type="button"
        onClick={() => generateSEOContent("seoKeyword")}
        className={styles.addCourseButton}
        disabled={generating.seoKeyword}
      >
        {generating.seoKeyword ? "Generating..." : "Generate Keywords"}
      </button>

      <label className={styles.addCourseLabel}>SEO Description:</label>
      <textarea
        name="SEODescription"
        value={courseData.SEODescription}
        onChange={handleChange}
        className={styles.addCourseTextarea}
        style={{
          color:
            courseData.SEODescription.length > seoLimits.description
              ? "red"
              : "black",
        }}
        placeholder="Enter or Generate SEO Description"
      />
      <small className={styles.characterCount}>
        {courseData.SEODescription.length}/{seoLimits.description} characters
      </small>
      <button
        type="button"
        onClick={() => generateSEOContent("seoDescription")}
        className={styles.addCourseButton}
        disabled={generating.seoDescription}
      >
        {generating.seoDescription ? "Generating..." : "Generate Description"}
      </button>

      <label className={styles.addCourseLabel}>Permalink:</label>
      <input
        type="text"
        value={courseData.SEOPermalink}
        className={styles.addCourseInput}
        readOnly
      />

      <div className={styles.addCourseSubmitButton}>
        <button type="submit" className={styles.addCourseSubmitButton}>
          Save Course
        </button>
      </div>
    </form>
  );
};

export default AddCourseForm;

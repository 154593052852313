import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
// import Header from '../../../component/HomePage01/Header/Header';
import Header from '../../../component/HeaderFilesEbook/Header';
import Footer from "../../../FooterMain/Footer";
// import Footer from '../../../component/HomePage01/Footer/Footer';
import classes from './EbookMyOrder.module.css';
import {useAuth} from "../../../Context/AuthContext";
import { useNavigate, Link } from 'react-router-dom';
import HeaderBar from "../../../component/HeaderBar"

const EbookMyOrder = () => {
  const [cookies] = useCookies(['token']);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate()
  console.log("User",user);

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      if (isLoggedIn && user ) {
        try {
          const purchaseHistoryResponse = await fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`, {
                
                headers: {
                    "Authorization": `Bearer ${cookies.token}`
                  },
            }
          );

          console.log("purchaseHistoryResponse",purchaseHistoryResponse);

          if (!purchaseHistoryResponse.ok) {
            throw new Error(
              `Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`
            );
          }

          const purchaseHistoryData = await purchaseHistoryResponse.json();

          console.log("Purchase History Data:", purchaseHistoryData);
          setPurchaseHistory(purchaseHistoryData.ebooksPurchased || []);
        } catch (error) {
          console.error('Error fetching purchase history:', error);
        }
      }
    };

    fetchPurchaseHistory();
  }, [isLoggedIn, user, cookies.token]);

  const clickHandler = (id) => {
    navigate(`/ebook/${id}/digitalMainPage`);
  };
  


  return (
    <>
      {/* <Header /> */}
      <HeaderBar />
      <div className={classes.main_div}>
      <div className={classes.heading_div}>
        <h1>Purchased E-books</h1>
      </div>
      <div className={classes.button_group}>
        <button className={classes.purchased_test_series_btn}>
        <Link
          to="https://testportal.ssccglpinnacle.com/myorders"
          target="_blank"
          rel="noopener noreferrer"
        >
          Purchased Test Series
          </Link>
        </button>
        
        <button className={classes.purchased_typing_btn}>
        <Link
            to="https://ssccglpinnacle.com/myorders_typing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchased Typing Software
          </Link>
          </button>
        <button className={classes.purchased_video_course_btn}> 
          <Link
              to="https://ssccglpinnacle.com/videos/myorders"
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased Video Course
            </Link></button>
        <button className={classes.purchased_books_btn}>
          <Link
          to="https://ssccglpinnacle.com/book/myorder"
          target="_blank"
          rel="noopener noreferrer"
          >
          Purchased Books
          </Link>
          </button>
      </div>
      </div>
      <table className={classes.main_table}>
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Product Name</th>
            <th>Medium</th>
            <th>Edition</th>
          </tr>
        </thead>
        <tbody>
          {purchaseHistory.length > 0 ? (
            purchaseHistory.map((ebook, index) => (
              <tr key={index}>
                <td>{ebook.id}</td>
                <td
                  onClick={() => clickHandler(ebook._id)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {ebook.title}
                </td>
                <td>{ebook.medium}</td>
                <td>{ebook.edition}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className={classes.no_data}>
                No purchased e-books found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={classes.footer_div}>
        <Footer />
      </div>
    </>
  );
};

export default EbookMyOrder;

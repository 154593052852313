import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SearchResult.module.css";
import {
  Box,
  Typography,
  Grid,
  Rating,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const SearchResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialSearchTerm = queryParams.get("query") || "";

  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [rating, setRating] = useState(0);
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/api/active-courses"
        );
        const courseData = response.data;

        // Filter courses based on initial search term
        const filteredData = courseData.filter((course) =>
          course.courseTitle.toLowerCase().includes(initialSearchTerm.toLowerCase())
        );

        setCourses(courseData);
        setFilteredCourses(filteredData);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [initialSearchTerm]);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = courses;

      if (searchTerm) {
        filtered = filtered.filter((course) =>
          course.courseTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      if (rating) {
        filtered = filtered.filter((course) => (course.averageRating || 0) >= rating);
      }
      filtered = filtered.sort((a, b) =>
        order === "asc"
          ? new Date(a.created_at) - new Date(b.created_at)
          : new Date(b.created_at) - new Date(a.created_at)
      );

      setFilteredCourses(filtered);
    };

    applyFilters();
  }, [searchTerm, rating, order, courses]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigateToCourse = (course) => {
    window.open(`/videos/${course.SEOPermalink}`, "_blank");
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow", fontWeight: "bold" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className={styles.searchResultContainer}>
      {/* Header Section */}
      <div className={styles.searchResultHeader}>
        <IconButton onClick={() => navigate("/videos")}>
          <HomeIcon fontSize="large" />
        </IconButton>
        <TextField
          variant="outlined"
          placeholder="Search for courses..."
          value={searchTerm}
          onChange={(e) => handleSearch(e)}
          fullWidth
        />
      </div>

      {/* Filter Options */}
      <div className={styles.searchResultFilterContainer}>
        <Select
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          displayEmpty
          variant="outlined"
        >
          <MenuItem value={0}>All Ratings</MenuItem>
          <MenuItem value={4}>4 & above</MenuItem>
          <MenuItem value={3}>3 & above</MenuItem>
        </Select>

        <Select
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          displayEmpty
          variant="outlined"
        >
          <MenuItem value="desc">Newest First</MenuItem>
          <MenuItem value="asc">Oldest First</MenuItem>
        </Select>
      </div>

      {/* Search Result Count */}
      <Typography className={styles.searchResultCount}>
        {filteredCourses.length} results for "{searchTerm}"
      </Typography>

      {/* Course List */}
      <Grid container spacing={2} className={styles.searchResultCourseList}>
        {filteredCourses.map((course) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={course._id}
            className={styles.searchResultCourseCard}
          >
            <Box className={styles.courseCard}>
              <Box
                className={styles.courseImageContainer}
                onClick={() => handleNavigateToCourse(course)}
              >
                <img
                  src={course.englishCoverImage || course.hindiCoverImage}
                  alt={course.courseTitle}
                  className={styles.courseImage}
                />
              </Box>
              <Box className={styles.courseDetails}>
                <Typography variant="h6" className={styles.courseTitle}>
                  {highlightText(course.courseTitle, searchTerm)}
                </Typography>
                <Typography variant="body2" className={styles.courseCategory}>
                  {course.category}
                </Typography>
                <Rating
                  value={course.averageRating || 4.0}
                  readOnly
                  precision={0.5}
                  className={styles.courseRating}
                />
                <Typography variant="h6" className={styles.coursePrice}>
                  ₹{course.price}{" "}
                  <span className={styles.courseMRP}>₹{course.mrp}</span>
                </Typography>
                <Button
                  variant="outlined"
                  className={styles.saveButtonSB}
                  onClick={() => handleNavigateToCourse(course)}
                >
                  View Details
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SearchResult;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import styles from "./SearchResult.module.css";
// import { Box, Typography, Grid, Rating, Select, MenuItem, IconButton } from "@mui/material";
// import HomeIcon from "@mui/icons-material/Home";

// const SearchResult = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const queryParams = new URLSearchParams(location.search);
//   const initialSearchTerm = queryParams.get("query") || "";

//   const [courses, setCourses] = useState([]);
//   const [filteredCourses, setFilteredCourses] = useState([]);
//   const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
//   const [categories, setCategories] = useState([]);
//   const [category, setCategory] = useState("");
//   const [edition, setEdition] = useState("");
//   const [rating, setRating] = useState(0);
//   const [order, setOrder] = useState("desc");

//   useEffect(() => {
//     const fetchInitialCourses = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/api/active-courses");
//         const courseData = response.data;
        
//         // Filter courses based on initial search term
//         const filteredData = courseData.filter((course) =>
//           course.courseTitle.toLowerCase().includes(initialSearchTerm.toLowerCase())
//         );

//         setCourses(courseData);
//         setFilteredCourses(filteredData);
//       } catch (error) {
//         console.error("Error fetching initial courses:", error);
//       }
//     };

//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/categories");
//         setCategories(response.data);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchInitialCourses();
//     fetchCategories();
//   }, [initialSearchTerm]);

//   useEffect(() => {
//     // Apply filters and sorting whenever any filter or search term changes
//     const applyFilters = () => {
//       let filtered = courses;

//       if (searchTerm) {
//         filtered = filtered.filter((course) =>
//           course.courseTitle.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//       }
//       if (category) {
//         filtered = filtered.filter((course) => course.category === category);
//       }
//       if (edition) {
//         filtered = filtered.filter((course) => course.edition === edition);
//       }
//       if (rating) {
//         filtered = filtered.filter((course) => (course.averageRating || 0) >= rating);
//       }
//       filtered = filtered.sort((a, b) =>
//         order === "asc"
//           ? new Date(a.created_at) - new Date(b.created_at)
//           : new Date(b.created_at) - new Date(a.created_at)
//       );

//       setFilteredCourses(filtered);
//     };

//     applyFilters();
//   }, [searchTerm, category, edition, rating, order, courses]);

//   useEffect(() => {
//     // Update search term from URL whenever it changes
//     const newSearchTerm = queryParams.get("query") || "";
//     setSearchTerm(newSearchTerm);
//   }, [location.search]);

//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   return (
//     <div className={styles.searchResultContainer}>
//       {/* Header with home icon */}
//       <div className={styles.searchResultHeader}>
//         <IconButton onClick={() => navigate("/videos")}>
//           <HomeIcon fontSize="large" />
//         </IconButton>
//         <input
//           type="text"
//           placeholder="Search for courses..."
//           value={searchTerm}
//           onChange={handleSearch}
//           className={styles.searchResultInput}
//         />
//       </div>

//       {/* Filter Options */}
//       <div className={styles.searchResultFilterContainer}>
//         {/* <Select value={category} onChange={(e) => setCategory(e.target.value)} displayEmpty>
//           <MenuItem value="">All Categories</MenuItem>
//           {categories.map((cat) => (
//             <MenuItem key={cat.id} value={cat.name}>
//               {cat.categoryTitle}
//             </MenuItem>
//           ))}
//         </Select> */}

//         {/* <Select value={edition} onChange={(e) => setEdition(e.target.value)} displayEmpty>
//           <MenuItem value="">All Editions</MenuItem>
//           {[1, 2, 3, 4, 5, 6].map((num) => (
//             <MenuItem key={num} value={`${num}st Edition`}>
//               {`${num}st Edition`}
//             </MenuItem>
//           ))}
//         </Select> */}

//         <Select value={rating} onChange={(e) => setRating(e.target.value)} displayEmpty>
//           <MenuItem value={0}>All Ratings</MenuItem>
//           <MenuItem value={4}>4 & above</MenuItem>
//           <MenuItem value={3}>3 & above</MenuItem>
//         </Select>

//         <Select value={order} onChange={(e) => setOrder(e.target.value)} displayEmpty>
//           <MenuItem value="desc">Newest First</MenuItem>
//           <MenuItem value="asc">Oldest First</MenuItem>
//         </Select>
//       </div>

//       <div className={styles.searchResultCount}>
//         {filteredCourses.length} results for "{searchTerm}"
//       </div>
//       <Grid container spacing={2} className={styles.searchResultCourseList}>
//         {filteredCourses.map((course) => (
//           <Grid 
//             item 
//             xs={12} 
//             key={course._id} 
//             className={styles.searchResultCourseCard} 
//             onClick={() => navigate(`/course/description/${course._id}`)}
//           >
//             <Box className={styles.searchResultImageContainer}>
//               <img
//                 src={course.englishCoverImage || course.hindiCoverImage}
//                 alt={course.courseTitle}
//                 className={styles.searchResultCourseImage}
//               />
//             </Box>
//             <Box className={styles.searchResultCourseInfo}>
//               <Typography variant="h6" className={styles.searchResultCourseTitle}>
//                 {course.courseTitle}
//               </Typography>
//               <Typography className={styles.searchResultCourseCategory}>
//                 {course.category}
//               </Typography>
//               <Rating
//                 value={course.averageRating || 4.0}
//                 readOnly
//                 precision={0.5}
//                 className={styles.searchResultRating}
//               />
//               <Typography className={styles.searchResultCoursePrice}>
//                 ₹{course.price} <span className={styles.searchResultMrp}>₹{course.mrp}</span>
//               </Typography>
//             </Box>
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// };

// export default SearchResult;




// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import styles from "./SearchResult.module.css";
// import { Box, Typography, Button, Grid, Rating, Select, MenuItem, IconButton } from "@mui/material";
// import HomeIcon from "@mui/icons-material/Home";

// const SearchResult = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const queryParams = new URLSearchParams(location.search);
//   const initialSearchTerm = queryParams.get("query") || "";

//   const [courses, setCourses] = useState([]);
//   const [filteredCourses, setFilteredCourses] = useState([]);
//   const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
//   const [categories, setCategories] = useState([]);
//   const [category, setCategory] = useState("");
//   const [edition, setEdition] = useState("");
//   const [rating, setRating] = useState(0);
//   const [order, setOrder] = useState("desc");

//   useEffect(() => {
//     const fetchInitialCourses = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/api/active-courses");
//         setCourses(response.data);
//         filterCourses(response.data, initialSearchTerm);
//       } catch (error) {
//         console.error("Error fetching initial courses:", error);
//       }
//     };

//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/categories");
//         setCategories(response.data);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchInitialCourses();
//     fetchCategories();
//   }, [initialSearchTerm]);

//   useEffect(() => {
//     applyFilters();
//   }, [category, edition, rating, order, searchTerm]);

//   const filterCourses = (courseList, term) => {
//     return courseList.filter((course) =>
//       course.courseTitle.toLowerCase().includes(term.toLowerCase())
//     );
//   };

//   const applyFilters = () => {
//     let filtered = [...courses];

//     if (searchTerm) {
//       filtered = filterCourses(filtered, searchTerm);
//     }

//     if (category) {
//       filtered = filtered.filter((course) => course.category === category);
//     }

//     if (edition) {
//       filtered = filtered.filter((course) => course.edition === edition);
//     }

//     if (rating) {
//       filtered = filtered.filter((course) => (course.averageRating || 0) >= rating);
//     }

//     filtered = filtered.sort((a, b) => {
//       return order === "asc"
//         ? new Date(a.publishedDate) - new Date(b.publishedDate)
//         : new Date(b.publishedDate) - new Date(a.publishedDate);
//     });

//     setFilteredCourses(filtered);
//   };

//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   return (
//     <div className={styles.searchResultContainer}>
//       <div className={styles.searchResultHeader}>
//         <IconButton onClick={() => navigate("/videos")}>
//           <HomeIcon fontSize="large" />
//         </IconButton>
//         <input
//           type="text"
//           placeholder="Search for courses..."
//           value={searchTerm}
//           onChange={handleSearch}
//           className={styles.searchResultInput}
//         />
//       </div>

//       <div className={styles.searchResultFilterContainer}>
//         <Select value={category} onChange={(e) => setCategory(e.target.value)} displayEmpty>
//           <MenuItem value="">All Categories</MenuItem>
//           {categories.map((cat) => (
//             <MenuItem key={cat.id} value={cat.name}>
//               {cat.categoryTitle}
//             </MenuItem>
//           ))}
//         </Select>

//         <Select value={edition} onChange={(e) => setEdition(e.target.value)} displayEmpty>
//           <MenuItem value="">All Editions</MenuItem>
//           {[1, 2, 3, 4, 5, 6].map((num) => (
//             <MenuItem key={num} value={`${num}st Edition`}>
//               {`${num}st Edition`}
//             </MenuItem>
//           ))}
//         </Select>

//         <Select value={rating} onChange={(e) => setRating(e.target.value)} displayEmpty>
//           <MenuItem value={0}>All Ratings</MenuItem>
//           <MenuItem value={4}>4 & above</MenuItem>
//           <MenuItem value={3}>3 & above</MenuItem>
//         </Select>

//         <Select value={order} onChange={(e) => setOrder(e.target.value)} displayEmpty>
//           <MenuItem value="desc">Newest First</MenuItem>
//           <MenuItem value="asc">Oldest First</MenuItem>
//         </Select>
//       </div>

//       <div className={styles.searchResultCount}>{filteredCourses.length} results for "{searchTerm}"</div>
//       <Grid container spacing={2} className={styles.searchResultCourseList}>
//         {filteredCourses.map((course) => (
//           <Grid 
//             item 
//             xs={12} 
//             key={course._id} 
//             className={styles.searchResultCourseCard} 
//             onClick={() => navigate(`/course/description/${course._id}`)}
//           >
//             <Box className={styles.searchResultImageContainer}>
//               <img
//                 src={course.englishCoverImage || course.hindiCoverImage}
//                 alt={course.courseTitle}
//                 className={styles.searchResultCourseImage}
//               />
//             </Box>
//             <Box className={styles.searchResultCourseInfo}>
//               <Typography variant="h6" className={styles.searchResultCourseTitle}>
//                 {course.courseTitle}
//               </Typography>
//               <Typography className={styles.searchResultCourseCategory}>
//                 {course.category}
//               </Typography>
//               <Rating
//                 value={course.averageRating || 4.0} 
//                 readOnly
//                 precision={0.5}
//                 className={styles.searchResultRating}
//               />
//               <Typography className={styles.searchResultCoursePrice}>
//                 ₹{course.price} <span className={styles.searchResultMrp}>₹{course.mrp}</span>
//               </Typography>
//             </Box>
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// };

// export default SearchResult;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import styles from "./SearchResult.module.css";
// import { Box, Typography, Button, Grid, Rating, Select, MenuItem, IconButton } from "@mui/material";
// import HomeIcon from "@mui/icons-material/Home";

// const SearchResult = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const queryParams = new URLSearchParams(location.search);
//   const initialSearchTerm = queryParams.get("query") || "";

//   const [courses, setCourses] = useState([]);
//   const [filteredCourses, setFilteredCourses] = useState([]);
//   const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
//   const [categories, setCategories] = useState([]); 
//   const [category, setCategory] = useState("");
//   const [edition, setEdition] = useState("");
//   const [rating, setRating] = useState(0);
//   const [order, setOrder] = useState("desc");

//   useEffect(() => {
//     // Fetch initial courses based on search term from the first API
//     const fetchInitialCourses = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/api/active-courses");
//         setCourses(response.data);
//         filterCourses(response.data, initialSearchTerm);
//       } catch (error) {
//         console.error("Error fetching initial courses:", error);
//       }
//     };

//     // Fetch categories for filtering options
//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/categories");
//         setCategories(response.data);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchInitialCourses();
//     fetchCategories();
//   }, [initialSearchTerm]);

//   useEffect(() => {
//     // Apply filtering whenever category, edition, rating, or order changes
//     const fetchFilteredCourses = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/vc/studentSearch", {
//           params: {
//             category,
//             edition,
//             rating,
//             order,
//           },
//         });
//         setFilteredCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching filtered courses:", error);
//       }
//     };

//     fetchFilteredCourses();
//   }, [category, edition, rating, order]);

//   const filterCourses = (courseList, term) => {
//     const filtered = courseList.filter(course =>
//       course.courseTitle.toLowerCase().includes(term.toLowerCase())
//     );
//     setFilteredCourses(filtered);
//   };

//   const handleSearch = (event) => {
//     const value = event.target.value;
//     setSearchTerm(value);
//     filterCourses(courses, value);
//   };

//   return (
//     <div className={styles.searchResultContainer}>
//       {/* Header with home icon */}
//       <div className={styles.searchResultHeader}>
//         <IconButton onClick={() => navigate("/videos")}>
//           <HomeIcon fontSize="large" />
//         </IconButton>
//         <input
//           type="text"
//           placeholder="Search for courses..."
//           value={searchTerm}
//           onChange={handleSearch}
//           className={styles.searchResultInput}
//         />
//       </div>

//       {/* Filter Options  */}
//       <div className={styles.searchResultFilterContainer}>
//         <Select value={category} onChange={(e) => setCategory(e.target.value)} displayEmpty>
//           <MenuItem value="">All Categories</MenuItem>
//           {categories.map((cat) => (
//             <MenuItem key={cat.id} value={cat.name}>
//               {cat.categoryTitle}
//             </MenuItem>
//           ))}
//         </Select>

//         <Select value={edition} onChange={(e) => setEdition(e.target.value)} displayEmpty>
//           <MenuItem value="">All Editions</MenuItem>
//           <MenuItem value="First">First</MenuItem>
//           <MenuItem value="Second">Second</MenuItem>
//         </Select>

//         <Select value={rating} onChange={(e) => setRating(e.target.value)} displayEmpty>
//           <MenuItem value={0}>All Ratings</MenuItem>
//           <MenuItem value={4}>4 & above</MenuItem>
//           <MenuItem value={3}>3 & above</MenuItem>
//         </Select>

//         <Select value={order} onChange={(e) => setOrder(e.target.value)} displayEmpty>
//           <MenuItem value="desc">Newest First</MenuItem>
//           <MenuItem value="asc">Oldest First</MenuItem>
//         </Select>
//       </div>

//       <div className={styles.searchResultCount}>{filteredCourses.length} results for "{searchTerm}"</div>
//       <Grid container spacing={2} className={styles.searchResultCourseList}>
//         {filteredCourses.map((course) => (
//           <Grid 
//             item 
//             xs={12} 
//             key={course._id} 
//             className={styles.searchResultCourseCard} 
//             onClick={() => navigate(`/course/description/${course._id}`)}
//           >
//             <Box className={styles.searchResultImageContainer}>
//               <img
//                 src={course.englishCoverImage || course.hindiCoverImage}
//                 alt={course.courseTitle}
//                 className={styles.searchResultCourseImage}
//               />
//             </Box>
//             <Box className={styles.searchResultCourseInfo}>
//               <Typography variant="h6" className={styles.searchResultCourseTitle}>
//                 {course.courseTitle}
//               </Typography>
//               <Typography className={styles.searchResultCourseCategory}>
//                 {course.category}
//               </Typography>
//               <Rating
//                 value={course.averageRating || 4.0} // Default rating if not provided
//                 readOnly
//                 precision={0.5}
//                 className={styles.searchResultRating}
//               />
//               <Typography className={styles.searchResultCoursePrice}>
//                 ₹{course.price} <span className={styles.searchResultMrp}>₹{course.mrp}</span>
//               </Typography>
//             </Box>
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// };

// export default SearchResult;

import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import './NextPage1.css';
 // Ensure you have this CSS file
// import ssc from "../"
import HSSC from "../../../Ebook/i/HSSC.png"
import { useNavigate } from 'react-router-dom';

const NextPageHSSC = ({ blogs }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 16;
   
  const truncateText = (text, maxWords) => {
    const wordsArray = text.split(' ');
    if (wordsArray.length > maxWords) {
      return wordsArray.slice(0, maxWords).json(' ') + '...';
    }
    return text;
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOffFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOffFirstBlog, indexOfLastBlog);

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageSelect = (event) => {
    setCurrentPage(Number(event.target.value));
  };

  const handleCardClick = (permalink) => {
    if (permalink) {
      navigate(`/blog/${permalink}`);
    } else {
      console.error('Permalink is missing for this blog');
    }
  }
  
  return (
    <>
<div className="container-fluid-blog1">
        <div className="blog-row1">
          <div className="content-left1">
            <div className='content-left1-text-block'>
              <h1>HSSC</h1>
              <p>Excel in HSSC exams with comprehensive preparation and determination.</p>
            </div>
          </div>
          <div className="content-right1">
            <img src={HSSC} alt="Placeholder" />
          </div>
        </div>
      </div>
      <div className='row2-blog'>
        <h2>Popular Articles</h2>
        <div className="blog-cards">
          {blogs.slice(0, 3).map(blog => (
            <div className="blog-card" key={blog.permalink} onClick={() => handleCardClick(blog.permalink)}>
              <h4>{blog.category}</h4>
              <h3>{blog.heading}</h3>
              <div className='margin-blog'></div>
              <div className='margin-blog'></div>
              <div className="authorname"><p>{blog.author.name}</p></div>
            </div>
          ))}
        </div>
      </div>
      <div className='row3-blog'>
              <div className='for-blog'>
                <div className="blog2-cards-wrapper-ssc">
                  <div className='blog2-cards-blog-ssc'>
                    <div className="blog2-cards-ssc">
                      {currentBlogs.map(blog => (
                        <div className="blog2-card-ssc" key={blog.permalink} onClick={() => handleCardClick(blog.permalink)}>
                          <h3>{truncateText(blog.heading, 11)}</h3>
                          <p>By {blog.author.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="pagination-blog-content">
                  <button onClick={handleFirstPage} disabled={currentPage === 1}>
                    <FaAngleDoubleLeft />
                  </button>
                  <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    <FaAngleLeft />
                  </button>
                  <select onChange={handlePageSelect} value={currentPage}>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                  <p className='pageno'>&nbsp;of&nbsp;&nbsp;{totalPages}</p>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    <FaAngleRight />
                  </button>
                  <button onClick={handleLastPage} disabled={currentPage === totalPages}>
                    <FaAngleDoubleRight />
                  </button>
                </div>
              </div>
            </div>
    </>
  );
};

export default NextPageHSSC;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./YT_PlayerHeader.module.css";
import { IoIosShareAlt, IoMdClose } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Logo from "../../assests/pinnacleWhiteLogo.svg";

const YTPlayerHeader = ({ courseId }) => {
  const [courseData, setCourseData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const navigate = useNavigate();

  const shareUrl = `https://ssccglpinnacle.com/videos/${
    courseData.SEOPermalink || ""
  }`;
  const shareText = `Check out this course I'm taking on ${
    courseData.courseTitle || ""
  }! ${shareUrl}`;

  const handleShareClick = () => {
    setShareModalOpen(true);
  };

  const closeShareModal = () => {
    setShareModalOpen(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(shareUrl);
    alert("Link copied to clipboard");
  };

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/course/${courseId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch course details");
        }
        const data = await response.json();
        setCourseData(data);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (courseId) {
      fetchCourseData();
    }
  }, [courseId]);

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareText
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnTelegram = () => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
      shareUrl
    )}&text=${encodeURIComponent(shareText)}`;
    window.open(telegramUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}&quote=${encodeURIComponent(shareText)}`;
    window.open(facebookUrl, "_blank");
  };

  return (
    <div className={styles.courseHeader}>
      <img
        src={Logo}
        alt="Logo"
        className={styles.logo}
        onClick={() => navigate("/videos")}
        style={{ cursor: "pointer" }}
      />
      <h3 className={styles.courseTitle}>
        {isLoading ? (
          <Skeleton width="100%" />
        ) : (
          courseData.courseTitle || "Course Title"
        )}
      </h3>
      <button onClick={handleShareClick} className={styles.shareButton}>
        Share <IoIosShareAlt size={20} />
      </button>

      {isShareModalOpen && (
        <div
          className={styles.shareModalOverlay}
          onClick={(e) => {
            if (e.target.classList.contains(styles.shareModalOverlay)) {
              closeShareModal(); // Close the modal if the backdrop is clicked
            }
          }}
        >
          <div className={styles.shareModal}>
            <div className={styles.shareModalHeader}>
              <h2 className={styles.modalHeading}>Share this course</h2>
              <button onClick={closeShareModal} className={styles.closeButton}>
                <IoMdClose size={20} />
              </button>
            </div>
            <div className={styles.urlCopy}>
              <input
                type="text"
                value={shareUrl}
                readOnly
                className={styles.shareInput}
              />
              <button onClick={handleCopyClick} className={styles.copyButton}>
                Copy
              </button>
            </div>
            <div className={styles.socialMediaIcons}>
              <button className={styles.iconButton} onClick={shareOnFacebook}>
                <img
                  src="/facebook.svg"
                  className={styles.mediaIcons}
                  alt="Facebook"
                />
              </button>
              <button className={styles.iconButton} onClick={shareOnTelegram}>
                <img
                  src="/telegram.svg"
                  className={styles.mediaIcons}
                  alt="Telegram"
                />
              </button>
              <button className={styles.iconButton} onClick={shareOnWhatsApp}>
                <img
                  src="/whatsapp.svg"
                  className={styles.mediaIcons}
                  alt="WhatsApp"
                />
              </button>
              <button className={styles.iconButton}>
                <img
                  src="/mail.svg"
                  className={styles.mediaIcons}
                  alt="Email"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YTPlayerHeader;

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { load } from "@cashfreepayments/cashfree-js";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const BuyButtonTyping = ({
  productId = "999", // Product ID for the typing module
  userDetails, // User details passed from the parent component
  // Default amount for typing module
  buttonText = "Buy Now",
  className = "",
  redeemcoins
}) => {
  const [cashfree, setCashfree] = useState(null);
  const [cookies] = useCookies(["token"]);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeSDK = async () => {
      const cashfreeInstance = await load({
        mode: "production",
        // mode: "sandbox",
      });
      setCashfree(cashfreeInstance);
    };
    initializeSDK();
  }, []);

  const doPayment = async (id, orderId) => {
    if (cashfree) {
      cashfree.checkout({
        paymentSessionId: id,
        returnUrl: `https://auth.ssccglpinnacle.com/api/typing_payment_status/${orderId}`, // Typing-specific return URL
        redirectTarget: "_blank",
        onSuccess: (paymentResponse) => {
          console.log("Payment successful", paymentResponse);
        },
        onFailure: (paymentResponse) => {
          console.error("Payment failed", paymentResponse);
        },
      });
    } else {
      console.error("Cashfree SDK not initialized");
    }
  };

  const checkAccess = async () => {
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/checkAccessTyping",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );

      if (!response.ok) {
        const error = await response.json();
        Swal.fire("Error", error.message || "Access check failed", "error");
        window.location.href = "https://testportal.ssccglpinnacle.com/login";
        return false;
      }

      const { access } = await response.json();
      if (access === "access") {
        const productResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
            body: JSON.stringify({ product_id: "999" }), // Product ID is 999
          }
        );

        if (!productResponse.ok) {
          const { message } = await productResponse.json();
          Swal.fire("Error", message || "Product access check failed", "error");
          window.location.href = "https://testportal.ssccglpinnacle.com/login";
          return false;
        }

        const { access: productAccess } = await productResponse.json();
        if (productAccess === "access") {
          navigate("/typingexamselection");
          return true;
        }
      }
      return true;
    } catch (error) {
      Swal.fire("Error", "Access check failed", "error");
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
      return false;
    }
  };

  // const payMe = async () => {
  //   try {
  //     if (!userDetails || !userDetails._id) {
  //       window.location.href = "https://testportal.ssccglpinnacle.com/login"; // Redirect to login
  //       return;
  //     }

  //     // Perform access check
  //     const accessGranted = await checkAccess();
  //     if (!accessGranted) return;

  //     const data = {
  //       email_id: userDetails.email_id, // Email ID of the user
  //       userId: userDetails._id, // MongoDB ObjectId of the user
  //       full_name: userDetails.full_name, // Full name of the user
  //       mobile_number: userDetails.mobile_number, // Mobile number of the user
  //       productId, // Typing module product ID
  //       // Fixed order amount for the typing module
  //     };

  //     console.log("Sending payment request data:", data);
  //     const url = "https://auth.ssccglpinnacle.com/api/typing_payment"; // Typing-specific endpoint
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       console.log("Response data from server:", responseData);
  //       if (responseData.error) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error",
  //           text: responseData.error,
  //         });
  //       } else {
  //         doPayment(responseData.cftoken, responseData.orderId);
  //       }
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error",
  //         text: "Failed to initiate payment. Please try again.",
  //       });
  //     }
  //   } catch (err) {
  //     console.error("Error during payment process:", err);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "An error occurred. Please try again.",
  //     });
  //   }
  // };


  const payMe = async () => {
    try {
      if (!userDetails || !userDetails._id) {
        window.location.href = "https://testportal.ssccglpinnacle.com/login"; // Redirect to login
        return;
      }
  
      // Perform access check
      const accessGranted = await checkAccess();
      if (!accessGranted) return;
  
      const data = {
        email_id: userDetails.email_id, // Email ID of the user
        userId: userDetails._id, // MongoDB ObjectId of the user
        full_name: userDetails.full_name, // Full name of the user
        mobile_number: userDetails.mobile_number, // Mobile number of the user
        productId,
        redeemcoins,
      };
  
      console.log("Sending payment request data:", data);
   //   const url = "https://39cc-103-87-59-236.ngrok-free.app/api/typing_payment"; // Typing-specific endpoint
   const url = "https://auth.ssccglpinnacle.com/api/typing_payment";
   
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log("Response data from server:", responseData);
        if (responseData.error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: responseData.error,
          });
        } else {
          // Start the payment process
          doPayment(responseData.cftoken, responseData.orderId);
  
          // Call the redeem-coinstyping API after 1 minute
          setTimeout(async () => {
            try {
             // const redeemUrl = `https://39cc-103-87-59-236.ngrok-free.app/api/redeem-coinstyping/${responseData.orderId}`;
             
             const redeemUrl = `https://auth.ssccglpinnacle.com/api/redeem-coinstyping/${responseData.orderId}`;
             const redeemResponse = await fetch(redeemUrl, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userId: userDetails._id,
                  coinsToRedeem: redeemcoins,
                }),
              });
  
              if (redeemResponse.ok) {
                const redeemResult = await redeemResponse.json();
                console.log("Redeem coins result:", redeemResult);
              } else {
                console.error("Failed to redeem coins", await redeemResponse.json());
              }
            } catch (err) {
              console.error("Error redeeming coins:", err);
            }
          }, 60000); // 60000 ms = 1 minute
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to initiate payment. Please try again.",
        });
      }
    } catch (err) {
      console.error("Error during payment process:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <button onClick={payMe} className={`buyButton-intro ${className}`}>
      {buttonText}
    </button>
  );
};

export default BuyButtonTyping;

import React, { useState, useEffect } from "react";
import "./EbookList.css"; // Replace with the correct path to your CSS file
import { useNavigate } from "react-router-dom";

const EbookList = () => {
  const [loading, setLoading] = useState(false);
  const [ebooks, setEbooks] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const fetchAllEbooks = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/ebooks"
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch ebook data. Status: ${response.status}`
        );
      }

      const ebooksData = await response.json();
      setEbooks(ebooksData);
    } catch (error) {
      console.error("Error fetching ebook data:", error);
      setError("Error fetching ebook data.");
    } finally {
      setLoading(false);
    }
  };

  const toggleActiveStatus = async (ebookId, currentActiveStatus) => {
    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/ebooks/activestatus/${ebookId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ active: !currentActiveStatus }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to update active status. Status: ${response.status}`
        );
      }

      // Update the local state after a successful update
      setEbooks((prevEbooks) =>
        prevEbooks.map((ebook) =>
          ebook._id === ebookId
            ? { ...ebook, active: !currentActiveStatus }
            : ebook
        )
      );
    } catch (error) {
      console.error("Error updating active status:", error);
      setError("Error updating active status.");
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredEbooks = ebooks.filter((ebook) =>
    ebook.sku.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchAllEbooks();
  }, []);

  return (
    <div className="ebook-status">
      <input
        type="text"
        placeholder="Search by SKU..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-bar"
      />
      {loading && <p className="ebook-status-message loading">Loading...</p>}
      {error && <p className="ebook-status-message error">{error}</p>}
      <table className="ebook-status-table">
        <thead>
          <tr className="ebook-status-tr">
            <th className="ebook-status-th">ID</th>
            <th className="ebook-status-th">SKU</th>
            <th className="ebook-status-th">Title</th>
            <th className="ebook-status-th">Status</th>
            <th className="ebook-status-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredEbooks.map((ebook) => (
            <tr key={ebook._id} className="ebook-status-tr">
              <td className="ebook-status-td">{ebook.id}</td>
              <td className="ebook-status-td">{ebook.sku}</td>
              <td className="ebook-status-td">{ebook.title}</td>
              <td
                className={`ebook-status-td ${
                  ebook.active ? "active" : "inactive"
                }`}
              >
                {ebook.active ? "Active" : "Inactive"}
              </td>
              <td className="ebook-status-td">
                <button
                  onClick={() => toggleActiveStatus(ebook._id, ebook.active)}
                  className={`ebook-status-button ${
                    ebook.active ? "active-button" : "inactive-button"
                  }`}
                >
                  {ebook.active ? "Deactivate" : "Activate"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EbookList;

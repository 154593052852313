// import React, { useState, useEffect } from 'react';
// import './BookList.css'; // Replace with the correct path to your CSS file
// import { Link, useNavigate } from 'react-router-dom';

// const BookList = () => {
//   const [loading, setLoading] = useState(false);
//   const [books, setBooks] = useState([]);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   const fetchAllBooks = async () => {
//     try {
//       setLoading(true);

//       const response = await fetch('https://auth.ssccglpinnacle.com/api/booksforactive');

//       if (!response.ok) {
//         throw new Error(`Failed to fetch book data. Status: ${response.status}`);
//       }

//       const booksData = await response.json();
//       setBooks(booksData);
//     } catch (error) {
//       console.error('Error fetching book data:', error);
//       setError('Error fetching book data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const toggleActiveStatus = async (bookId, currentActiveStatus) => {
//     try {
//       const response = await fetch(`https://auth.ssccglpinnacle.com/api/books/activestatus/${bookId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ active: !currentActiveStatus }),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to update active status. Status: ${response.status}`);
//       }

//       // Update the local state after a successful update
//       setBooks((prevBooks) =>
//         prevBooks.map((book) =>
//           book._id === bookId ? { ...book, active: !currentActiveStatus } : book
//         )
//       );
//     } catch (error) {
//       console.error('Error updating active status:', error);
//       setError('Error updating active status.');
//     }
//   };

//   useEffect(() => {
//     fetchAllBooks();
//   }, []);

//   return (
//     <>
//       {loading && <p className="book-edit-status loading">Loading...</p>}
//       {error && <p className="book-edit-status error">{error}</p>}
//       <table className="book-table">
//         <thead>
//           <tr>
//             <th>id</th>
//             <th>Sku</th>
//             <th>Title</th>
//             <th>Status</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {books.map((book) => (
//             <tr key={book._id}>
//               <td>{book.id}</td>
//               <td>{book.sku}</td>
//               <td>{book.title}</td>
//               <td className={`book-edit-status ${book.active ? 'active' : 'inactive'}`}>
//                 {book.active ? 'Active' : 'Inactive'}
//               </td>
//               <td>
//                 <button
//                   onClick={() => toggleActiveStatus(book._id, book.active)}
//                   className={`book-edit-status ${book.active ? 'active-button' : 'inactive-button'}`}
//                 >
//                   {book.active ? 'Deactivate' : 'Activate'}
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   );
// };

// export default BookList;



import React, { useState, useEffect } from 'react';
import './BookList.css'; // Replace with the correct path to your CSS file
import { Link, useNavigate } from 'react-router-dom';

const BookList = () => {
  const [loading, setLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchAllBooks = async () => {
    try {
      setLoading(true);

      const response = await fetch('https://auth.ssccglpinnacle.com/api/booksfordata');

      if (!response.ok) {
        throw new Error(`Failed to fetch book data. Status: ${response.status}`);
      }

      const booksData = await response.json();
      setBooks(booksData);
    } catch (error) {
      console.error('Error fetching book data:', error);
      setError('Error fetching book data.');
    } finally {
      setLoading(false);
    }
  };

  const toggleActiveStatus = async (bookId, currentActiveStatus) => {
    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/books/activestatus/${bookId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ active: !currentActiveStatus }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update active status. Status: ${response.status}`);
      }

      // Update the local state after a successful update
      setBooks((prevBooks) =>
        prevBooks.map((book) =>
          book._id === bookId ? { ...book, active: !currentActiveStatus } : book
        )
      );
    } catch (error) {
      console.error('Error updating active status:', error);
      setError('Error updating active status.');
    }
  };

  useEffect(() => {
    fetchAllBooks();
  }, []);

  return (
    <>
      {loading && <p className="book-edit-status loading">Loading...</p>}
      {error && <p className="book-edit-status error">{error}</p>}
      <table className="book-table">
        <thead>
          <tr>
            <th>id</th>
            <th>Sku</th>
            <th>Title</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {books.map((book) => (
            <tr key={book._id}>
              <td>{book.id}</td>
              <td>{book.sku}</td>
              <td>{book.title}</td>
              <td className={`book-edit-status ${book.active ? 'active' : 'inactive'}`}>
                {book.active ? 'Active' : 'Inactive'}
              </td>
              <td>
                <button
                  onClick={() => toggleActiveStatus(book._id, book.active)}
                  className={`book-edit-status ${book.active ? 'active-button' : 'inactive-button'}`}
                >
                  {book.active ? 'Deactivate' : 'Activate'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BookList;


import React, { useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import BlogHeader from '../Header';
import NextPageSSC from './NextPageSSC';
import NextPageDaily from './NextPageDaily';
import NextPageHSSC from './NextPageHSSC';
import NextPageUp from './NextPageUp';
import NextPageRRB from './NextPageRRB';
import NextPageTopic from "./NextPageTopic";
import NextPageMonthly from './NextPageMonthly';
import { useNavigate } from 'react-router-dom';
import NextPageYear from './NextPageYear';
import LoadingSpinner from '../../../Ebook/LoadingSpinner';
 // Import the LoadingSpinner component

const NextPage = () => {
  const { category } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    fetch('https://auth.ssccglpinnacle.com/api/active-blogs')
      .then(response => response.json())
      .then(data => {
        const filteredBlogs = data.filter(blog => blog.category === category);
        setBlogs(filteredBlogs);
        setIsLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error('There was an error fetching the blogs!', error);
        setIsLoading(false); // Set loading to false even if there's an error
      });
  }, [category]);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const renderComponent = () => {
    switch (category) {
      case 'SSC':
        return <NextPageSSC blogs={blogs} />;
      case 'RRB NTPC':
        return <NextPageRRB blogs={blogs} />;
      case 'Daily current affairs':
        return <NextPageDaily blogs={blogs} />;
      case 'Topic wise current affairs':
        return <NextPageTopic blogs={blogs} />;
      case 'Last one year current affairs':
        return <NextPageYear blogs={blogs} />;
      case 'Monthly current affairs':
        return <NextPageMonthly blogs={blogs} />;
      case 'Up police':
        return <NextPageUp blogs={blogs} />;
      case 'HSSC':
        return <NextPageHSSC blogs={blogs} />;
      default:
        return <div>Category not found</div>;
    }
  };

    
  const handleSeeAllClick = (category) => {
    navigate(`/blog/${category}`);
  };

  return (
    <>
      <BlogHeader />
      <div className="category-row">
        <div className={`search-container ${isSearchOpen ? 'open' : ''}`}>
          <input
            type="text"
            placeholder="Search for blog articles and topics"
            className={isSearchOpen ? 'open' : ''}
          />
          {isSearchOpen ? <FaTimes onClick={toggleSearch} /> : <FaSearch onClick={toggleSearch} />}
        </div>
        <div className="categories">
       <div onClick={() => handleSeeAllClick('SSC')}>SSC</div>
  <div onClick={() => handleSeeAllClick('RRB NTPC')}>RRB NTPC</div>
  <div onClick={() => handleSeeAllClick('Daily current affairs')}>Daily current affairs</div>
  <div onClick={() => handleSeeAllClick('Topic wise current affairs')}>Topic wise current affairs</div>
  <div onClick={() => handleSeeAllClick('Last one year current affairs')}>Last one year current affairs</div>
  <div onClick={() => handleSeeAllClick('Monthly current affairs')}>Monthly current affairs</div>
  <div onClick={() => handleSeeAllClick('Up police')}>Up police</div>
  <div onClick={() => handleSeeAllClick('HSSC')}>HSSC</div>
</div>
      </div>
      {isLoading ? <LoadingSpinner /> : renderComponent()}
    </>
  );
};

export default NextPage;

// import React from 'react';
// import ReactDOM from 'react-dom';
// import styles from './OverlayModal.module.css'; // CSS module for styling

// const OverlayModal = ({ show, onClose, children }) => {
//   if (!show) return null; // Don't render if not shown

//   return ReactDOM.createPortal(
//     <div className={styles.overlay}>
//       <div className={styles.popup}>
//         {children}
//         <button className={styles.closeButton} onClick={onClose}>
//           Close
//         </button>
//       </div>
//     </div>,
//     document.getElementById('modal-root') // Mount to modal-root
//   );
// };

// export default OverlayModal;

import React from "react";
import ReactDOM from "react-dom";
import styles from "./OverlayModal.module.css"; // Ensure this path is correct

const OverlayModal = ({ show, onClose, onSubmit, children }) => {
  if (!show) return null;

  const overlay = <div className={styles.overlay} onClick={onClose}></div>;

  const popup = (
    // <div className={styles.overlay}>
      <div className={styles.popup}>
        {children}
        <div className={styles.buttonContainer}>
          <button className={styles.submitButton} onClick={onSubmit}>
            Submit
          </button>
          <button className={styles.closeButton} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    // </div>
  );

  return (
    <>
      {ReactDOM.createPortal(overlay, document.getElementById("overlay-root"))}
      {ReactDOM.createPortal(popup, document.getElementById("popup-root"))}
    </>
  );
};

export default OverlayModal;

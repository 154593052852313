import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './BlogList1.css'; // Ensure you have this CSS file
import BlogHeader from '../Header';
import { FaSearch, FaTimes, FaFacebook, FaTelegram, FaEnvelope, FaWhatsapp, FaLinkedin, FaShare } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../Ebook/LoadingSpinner';


const BlogList1 = () => {
  const { permalink } = useParams();
  console.log("permalink BLOGS:",permalink);
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    // Replace the following URL with the actual endpoint to fetch the blog data by ID
    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/blogs/${permalink}`);
        // const response = await fetch(`http://localhost:5000/blogs/${permalink}`);
        const data = await response.json();
        console.log("DATA", data);
        setBlog(data);  
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchBlog();
  }, [permalink]);

  console.log('PERMALINK:', permalink);

  if (!blog) {
    return <LoadingSpinner />;
  }

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const openShareModal = () => {
    setIsShareModalOpen(true);
    setIsClosing(false);
  };

  const closeShareModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsShareModalOpen(false);
      setIsClosing(false);
    }, 300); // Duration of the close animation
  };

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('Link copied to clipboard!');
  };

    
  // const handleSeeAllClick = (category) => {
  //   navigate(`/blog/${category}`);
  // };

  const handleSeeAllClick = (permalink) => {
    if (permalink) {
      navigate(`/blog/${permalink}`);
    } else {
      console.error("Permalink is missing for this blog");
    }
  };

  return (
    <>
      <BlogHeader />
      <div className="category-row">
        <div className={`search-container ${isSearchOpen ? 'open' : ''}`}>
          <input
            type="text"
            placeholder="Search for blog articles and topics"
            className={isSearchOpen ? 'open' : ''}
          />
          {isSearchOpen ? <FaTimes onClick={toggleSearch} /> : <FaSearch onClick={toggleSearch} />}
        </div>
        <div className="categories">
  <div onClick={() => handleSeeAllClick('SSC')}>SSC</div>
  <div onClick={() => handleSeeAllClick('RRB NTPC')}>RRB NTPC</div>
  <div onClick={() => handleSeeAllClick('Daily current affairs')}>Daily current affairs</div>
  <div onClick={() => handleSeeAllClick('Topic wise current affairs')}>Topic wise current affairs</div>
  <div onClick={() => handleSeeAllClick('Last one year current affairs')}>Last one year current affairs</div>
  <div onClick={() => handleSeeAllClick('Monthly current affairs')}>Monthly current affairs</div>
  <div onClick={() => handleSeeAllClick('Up police')}>Up police</div>
  <div onClick={() => handleSeeAllClick('HSSC')}>HSSC</div>
</div>
      </div>
      <div className='for-content-categories'></div>
      <div className="container-fluid-blog1-bloglist">
        <div className="blog-row1-bloglist">
          <div className="content-left1-bloglist">
            <div className='content-left1-text-block-bloglist'>
              <p className='featured-article-category'>{blog.category}</p>
              <h1>{blog.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='for-sharing-blog'>
        <div className='instructor-detail-blog'>
          <p className='instructor-detail-blog-name'>{blog.author.name}</p>
          <p className='instructor-detail-blog-details'>{blog.author.details}</p>
        </div>
        <div className='blogsharing'>
          <button className='blogsharingbutton' onClick={openShareModal}>Share this article<FaShare className='share-button-blog' /></button>
        </div>
      </div>
      <div className="bloglist1-container">
        <div className="bloglist1-content" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
        <div className='page-last-updated'>
        <p>Page Last Updated: {new Date(blog.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
        </div>
      </div>
      
      {isShareModalOpen && (
        <div className={`share-modal-overlay ${isClosing ? 'closing' : ''}`} onClick={closeShareModal}>
          <div className="share-modal-content" onClick={e => e.stopPropagation()}>
            <div className='share-and-close-blog'>
              <h2 className='shareh2'>Share this article</h2>
              <IoMdClose className="close-icon-bloglist" onClick={closeShareModal} />
            </div>
            <div className='modal-body-bloglist'>
                <div className='for-both-input-icon'>
             <div className="share-link-container">
              <input type="text" value={window.location.href} readOnly />
              <span className="copy-link" onClick={copyLink}>Copy</span>
            </div>
            <ul className="share-icons">
              <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer"><FaFacebook className="share-icons-li-blog" /></a></li>
              <li><a href={`https://telegram.me/share/url?url=${window.location.href}`} target="_blank" rel="noopener noreferrer"><FaTelegram className="share-icons-li-blog"/></a></li>
              <li><a href={`mailto:?subject=Check this out&body=${window.location.href}`} target="_blank" rel="noopener noreferrer"><FaEnvelope className="share-icons-li-blog" /></a></li>
              <li><a href={`https://api.whatsapp.com/send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer"><FaWhatsapp className="share-icons-li-blog"/></a></li>
              <li><a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`} target="_blank" rel="noopener noreferrer"><FaLinkedin className="share-icons-li-blog"/></a></li>
            </ul>
          </div>
            </div>
            </div>
        </div>
      )}
    </>
  );
};

export default BlogList1;

import React, { useEffect, useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import './coinpage.css';

// Component to display loading or error messages
const StatusMessage = ({ message, type }) => (
  <p
    style={{
      textAlign: "center",
      color: type === "error" ? "#ff6347" : "#0d47a1",
      fontWeight: "bold",
      fontSize: "16px",
    }}
  >
    {message}
  </p>
);

// Component to display the table
// const CoinsTable = ({ data }) => (
//   <table
//     border="1"
//     style={{
//       width: "20%",
//       margin: "0 auto",
//       textAlign: "center",
//       borderCollapse: "collapse",
//     }}
//   >
//     <thead>
//       <tr>
//         <th style={{ padding: "8px" }}>#</th>
//         <th style={{ padding: "8px" }}>Coins</th>
//         <th style={{ padding: "8px" }}>Barcode</th>
//       </tr>
//     </thead>
//     <tbody>
//       {data.map((item, index) => (
//         <tr key={index}>
//           <td style={{ padding: "8px" }}>{index + 1}</td>
//           <td style={{ padding: "8px" }}>{item.coins || "N/A"}</td>
//           <td style={{ padding: "8px" }}>{item.searchBarcode || "N/A"}</td>
//         </tr>
//       ))}
//     </tbody>
//   </table>
// );




const CoinsTable = ({ data }) => (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
    <table
      border="1"
      style={{
        width: "30%",
        margin: "0 auto",
        borderCollapse: "collapse",
      }}
    >
      <thead>
        <tr>
          <th style={{ padding: "8px", textAlign: "center" }}>#</th>
          <th style={{ padding: "8px", textAlign: "center" }}>Coins</th>
          <th style={{ padding: "8px", textAlign: "center" }}>Barcode</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td style={{ padding: "8px", textAlign: "center" }}>{index + 1}</td>
            <td style={{ padding: "8px", textAlign: "center" }}>{item.coins || "N/A"}</td>
            <td style={{ padding: "8px", textAlign: "center" }}>{item.searchBarcode || "N/A"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);


// Component to display total coins
const TotalCoins = ({ totalCoins }) => (
  <div style={{ textAlign: "center", margin: "20px 0" }}>
    <p
      style={{
        fontSize: "20px",
        fontWeight: "bold",
        color: "#2f4f4f",
        padding: "10px",
        border: "1px solid #2f4f4f",
        borderRadius: "8px",
        display: "inline-block",
      }}
    >
      Total Coins : {totalCoins}
    </p>
  </div>
);

function CoinPages() {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCoinsAndBarcodes = async () => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/coins/${user?._id}`, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setData(result.data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user?._id) {
      fetchCoinsAndBarcodes();
    }
  }, [user?._id]);

  const totalCoins = data.reduce((sum, item) => sum + (item.coins || 0), 0);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",  
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",  
        }}
      >
        <StatusMessage message="You need to be logged in to access this feature." />
        <a
          href="https://testportal.ssccglpinnacle.com/login"
          style={{
            marginTop: "20px",
            textDecoration: "none",
          }}
        >
          <button
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: "#0d47a1",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Go to Login
          </button>
        </a>
      </div>
    );
  }
  

  if (error) {
    return <StatusMessage message={`Error: ${error}`} type="error" />;
  }

  return (
    <>
      <Header />
      <h2 className="coins">
        Your Books Coin {user?.full_name ? `, ${user.full_name}` : ""}
      </h2>
      <div
        style={{
          textAlign: "center",
          margin: "20px auto",
          padding: "15px",
          color: "#0d47a1",
          fontWeight: "bold",
          fontSize: "14px",
          maxWidth: "300px",
        }}
      >
        💰 Each Coin is Worth 1 Rs 💸
      </div>
      {data.length > 0 ? (
        <>
          <TotalCoins totalCoins={totalCoins} />
          <CoinsTable data={data} />
        </>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <StatusMessage
            message="No data found."
            type="error"
          />
          <p
            style={{
              color: "#2f4f4f",
              fontSize: "16px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Scan Pinnacle book barcode to earn coins.
          </p>
        </div>
      )}
    </>
  );
}

export default CoinPages;

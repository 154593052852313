import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styles from "./Wishlist.module.css";
import Header from "./Header";
import Swal from "sweetalert2";
import Footer from "../../Footer/Footer";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

function WishlistPage() {
  const [courseDetails, setCourseDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const emailId = user?.email_id || null;

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/userwishlist/${emailId}`
        );

        if (response.status === 200 && response.data.success) {
          setCourseDetails(response.data.data); // Populate wishlist with fetched data
        } else {
          setCourseDetails([]); // Set empty state if no wishlist data
        }
      } catch (error) {
        console.error("Failed to fetch wishlist data:", error);
        setError("Failed to load wishlist. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (emailId) {
      fetchWishlist();
    }
  }, [emailId]);

  const handleRemoveFromWishlist = async (courseId) => {
    try {
      const response = await axios.delete(
        `https://auth.ssccglpinnacle.com/api/userwishlist/${emailId}/${courseId}`
      );

      if (response.status === 200 && response.data.success) {
        setCourseDetails((prevCourses) =>
          prevCourses.filter((course) => course._id !== courseId)
        );
        Swal.fire({
          title: "Success!",
          text: "Course removed from wishlist",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Failed",
          text:
            response.data.message || "Failed to remove course from wishlist",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error removing course from wishlist:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while removing the course from the wishlist.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleNavigateToVideos = () => {
    navigate("/videos");
  };

  if (loading) {
    return (
      <div className={styles["loading-div"]}>
        <img
          src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
          alt="Loading..."
        />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Header />
      <div className={styles.cartContainer}>
        <h2 className={styles.cartHeader}>Shopping Wishlist</h2>
        <p className={styles.cartSubHeader}>
          {courseDetails.length} Courses in your wishlist
        </p>
        {courseDetails.length === 0 ? (
          <div className={styles.emptyWishlist}>
            <img
              src="/shoppingImage.webp"
              alt="Empty Wishlist"
              className={styles.emptyWishlistImage}
            />
            <p className={styles.emptyWishlistPara}>
              Your wishlist is empty. Keep exploring to find a course!
            </p>
            <button
              className={styles.keepShoppingButton}
              onClick={handleNavigateToVideos}
            >
              Keep exploring
            </button>
          </div>
        ) : (
          <div className={styles.courseContainer}>
            {courseDetails.map((course) => (
              <div key={course._id} className={styles.courseCard}>
                <div className={styles.courseImageContainer}>
                  <img
                    src={course.englishCoverImage}
                    alt="English Cover"
                    className={styles.coverImage}
                  />
                  <img
                    src={course.hindiCoverImage}
                    alt="Hindi Cover"
                    className={styles.coverImage}
                  />
                </div>
                <div className={styles.courseDetails}>
                  <h3 className={styles.courseTitle}>{course.courseTitle}</h3>
                  <p className={styles.courseInstructor}>
                    {course.instructorName}
                  </p>
                  <div className={styles.courseMeta}>
                    <span className={styles.rating}>
                      {parseFloat(course.averageRating).toFixed(1)} stars
                    </span>
                    <span>({course.totalRatings} reviews)</span>
                  </div>

                  <div className={styles.priceSection}>
                    <p className={styles.price}>
                      Price: ₹{course.price.toFixed(2)}
                    </p>
                  </div>
                  <button
                    className={styles.removeButton}
                    onClick={() => handleRemoveFromWishlist(course._id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default WishlistPage;

import React, { useState } from "react";
import axios from "axios";
import OverlayModal from "./OverlayModal";

import styles from "./RatingApp.module.css"; // Import the CSS Module
import { useAuth } from "../../Context/AuthContext";
import Swal from 'sweetalert2';


const RatingApp = ({ebook}) => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [review, setReview] = useState("");
  const { isLoggedIn, user, token } = useAuth();

  const handleStarClick = (rate) => {
    setRating(rate);
    setShowPopup(true); // Show the popup to add a review
  };

  const handleReviewSubmit = async () => {
    const payload = {
      email_id: user.email_id,
      student_id: user._id,
      ebook_id: ebook._id,
      rating,
      review,
    };

    try {
        const response = await axios.post(
            'https://auth.ssccglpinnacle.com/api/v1/rateEbook',
            // 'http://localhost:5000/api/v1/rateEbook',
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Attach the token here
              },
            }
          );
      if (response.data) {
        // Success alert with SweetAlert2
        setShowPopup(false);

        Swal.fire({
          icon: "success",
          title: "Thank you!",
          text: response.data.message, // Use response message from the backend
          confirmButtonText: "Close",
        });
        // console.log("isLoggedIn:", isLoggedIn);
        // console.log("user:", user);
        // console.log("token:", token);
        // console.log("Rating:", rating);
        // console.log("Review:", review);
        //   alert("Review Submitted!");
        // setReview("");
        // setRating(0);
      }
    } catch (error) {
            // Extracting error message
    const errorMessage = error.response?.data?.message || 'Something went wrong. Please try again later.';

    // Error alert with SweetAlert2
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMessage,
      confirmButtonText: 'Retry',
    });
    }
  };

  const handleCloseModal = () => {
    setRating(0);
    setShowPopup(false)
  };

  //   const handleOpenModal = () => setShowPopup(true);
  //   const handleSubmit = () => {
  //     console.log("Review Submitted:", review);
  //     alert("Thank you for your review!");
  //     setShowPopup(false);
  //     setReview("");
  //   };

  return (
    <div>
      {/* <h1>Rate and Review</h1> */}
      <div className={styles.ratingContainer}>
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`${styles.star} ${
              rating >= star || hoverRating >= star ? styles.starActive : ""
            }`}
            onClick={() => handleStarClick(star)}
            onMouseEnter={() => setHoverRating(star)}
            onMouseLeave={() => setHoverRating(0)}
          >
            ★
          </span>
        ))}
      </div>

      <OverlayModal
        show={showPopup}
        onClose={handleCloseModal}
        onSubmit={handleReviewSubmit}
      >
        <h2>Add a Review</h2>
        <textarea
          placeholder="Write your review here..."
          value={review}
          onChange={(e) => setReview(e.target.value)}
          style={{ width: "100%", height: "80px", margin: "10px 0" }}
        />
        {/* <div>
          <button onClick={handleReviewSubmit}>Submit</button>
        </div> */}
      </OverlayModal>
    </div>
  );
};

export default RatingApp;

import React, { useState, useEffect, useRef } from "react";

import Rate from "../../componentbook/StarBook/RateBook";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useProductContext } from "../../Context/ProductContext";
import Swal from "sweetalert2";
import BuyNow from "../../BuyNowBook/BuyNowBook";
import "./Row3Digital.css";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import amazon from "../../iBook/amazon.svg";
import flipkart from "../../iBook/flipkart.svg";
import snapdeal from "../../iBook/snapdeal.png";
import pinnacle from "../../iBook/pinnaclelogo.png";
import { FcApproval } from "react-icons/fc";
import BillingForm from "./BillingForm";
import "./Row4Digital.css";
import { AiOutlineLeft, AiOutlineRight, AiFillStar } from "react-icons/ai";
import pic from "../../iBook/book.jpg";
import gsap from "gsap";

function Row4Digital({ filteredEbookData }) {
  const [cardData, setCardData] = useState([]);
  const { isLoggedIn, user } = useAuth();
  const [hoveredId, setHoveredId] = useState(null);
  const navigate = useNavigate();
  const [showBillingForm, setShowBillingForm] = useState(false);
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector(
      ".card-digital-ebook-horizontal-row"
    ).offsetWidth; // Width of one card
    const shift = cardWidth * 5 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;

    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;

    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }

    if (targetScroll < 0) {
      targetScroll = 0;
    }

    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);

        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);

    // Check if the scroll has reached the end
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    const fetchEbooksData = async () => {
      try {
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }
        const ebookData = await ebookResponse.json();
        if (filteredEbookData && filteredEbookData.length > 0) {
          setCardData(filteredEbookData);
        } else {
          setCardData(ebookData);
        }
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };

    fetchEbooksData();
  }, [filteredEbookData]);

  const info = (card) => {
    const permalink = card.permalink;
    navigate(`/books${permalink}`);
  };

  const getRatingForCard = (cardId) => {
    const card = cardData.find((card) => card._id === cardId);

    if (!card || !card.rating || card.rating.length === 0) {
      return 0;
    }

    return calculateAverageRating(card.rating);
  };

  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce(
      (sum, rating) => sum + rating.starRate,
      0
    );
    return totalRating / ratings.length;
  };

  const handleBuyNow = async (bookId) => {
    if (!isLoggedIn) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to make a purchase.",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          // Billing information exists, navigate to VerifyAddress component
          navigate(`/verify-address/${bookId}`);
        } else {
          // Billing information does not exist, show the billing form modal
          setShowBillingForm(true);
          document.body.style.overflow = "hidden"; // Stop background scrolling
        }
      } else {
        // Handle case where fetching billing information fails
        console.error("Failed to fetch billing information");
        setShowBillingForm(true);
        document.body.style.overflow = "hidden"; // Stop background scrolling
      }
    } catch (error) {
      console.error("Error fetching billing information:", error);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden"; // Stop background scrolling
    }
  };

  const calculateDiscount = (BookSellingPrice, BookPrintingPrice) => {
    const savingAmount = BookPrintingPrice - BookSellingPrice;
    const discountPercentage = (savingAmount / BookPrintingPrice) * 100;
    return { savingAmount, discountPercentage };
  };
  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = ""; // Enable background scrolling
  };

  return (
    <>
      <div className="section-suggest-book-horizontal-row">
        <div className="container-fluid-book-recent">
          <div className="foruppersectionebook-horizontal-row">
            <div className="col-md-8 section-suggest-book-col-8-horizontal-row">
              <p className="col-md-8 section-suggest-book-p-horizontal-row">
                Most Popular Book
              </p>
            </div>
            <div className="col-md-4 section-suggest-book-col-4-horizontal-row">
              <div className="book-viewall-button-horizontal-row">
                <div className="View-all-digital-ebook-horizontal-row">
                  {" "}
                  <a href="">View All </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container-for-button-horizontal-row">
            {scrollX !== 5 && (
              <button className="prev-horizontal-row" onClick={() => slide(-1)}>
                <AiOutlineLeft className="next-book-scroll" color="white" />
              </button>
            )}
            <div className="container-digital-ebook-horizontal-row">
              <ul
                className="cards-digital-ebook-horizontal-row"
                ref={scrl}
                onScroll={scrollCheck}
              >
                {cardData && cardData.length > 0 ? (
                  cardData.map((card) => {
                    const { savingAmount, discountPercentage } =
                      calculateDiscount(
                        card.BookSellingPrice,
                        card.BookPrintingPrice
                      );
                    return (
                      <li
                        key={card._id}
                        className="card-digital-ebook-horizontal-row"
                      >
                        <div className="upper-card-digital-horizontal-row">
                          <div className="card-content-digital-ebook-horizontal-row">
                            <div
                              className="for-image-size-horizontal-row"
                              onClick={() => info(card)}
                            >
                              <img
                                className="card-title-digital-ebook-horizontal-row"
                                src={card.image1}
                                alt={card.title}
                              />
                            </div>
                          </div>

                          <div className="card-link-wrapper-digital-ebook-horizontal-row">
                            {/* <p>
              {card.title} {card.medium} medium {card.edition} edition
            </p> */}
                            <p>{card.title} </p>
                          </div>

                          <div className="buttons-for-buying-products-horizontal-row">
                            <div className="button-for-everything-horizontal-row">
                              <div className="try-fot-free-books-only-horizontal-row">
                                <div className="try-for-free-horizontal-row">
                                  <div
                                    className="Rating"
                                    onMouseOver={() => setHoveredId(card._id)}
                                    onMouseOut={() => setHoveredId(null)}
                                  >
                                    <div className="Star-Rating-row-5">
                                      {getRatingForCard(card._id).toFixed(1)}
                                      <div className="For-Star-div">
                                        <AiFillStar className="Star-Rating-1" />
                                      </div>
                                    </div>
                                    <div className="Total-Rating">
                                      ({card.rating.length})
                                    </div>
                                  </div>
                                </div>
                                <div className="StarRating">
                                  {/* {hoveredId === card._id && (
                  // <Rate cardData={card} />
                )} */}
                                </div>
                                <button
                                  className="buynow-for-user-cart-horizontal-row"
                                  // onClick={() => handleAddToCart(card._id)}
                                >
                                  {" "}
                                  Go to Cart
                                  {/* {isItemInCart(card._id) ? "Go to Cart" : "Add to Cart"} */}
                                </button>
                              </div>
                              <div className="price-for-billing-horizontal-row">
                                <span className="price-for-billing__selling-price-horizontal-row">
                                  ₹ {card.BookSellingPrice} incl. GST
                                </span>
                                <div className="price-for-billing__details-horizontal-row">
                                  <span className="price-for-billing__mrp-horizontal-row">
                                    M.R.P.:{" "}
                                    <del>₹ {card.BookPrintingPrice}</del>
                                  </span>
                                  <span className="price-for-billing__saving-amount-horizontal-row">
                                    Save: ₹ {savingAmount.toFixed(2)} (
                                    {discountPercentage.toFixed(0)}%)
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
 
  <div style={{ display: 'flex', gap: '40px', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={amazon} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={flipkart} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.flipkartLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>
  </div>

  
  <div style={{ display: 'flex', gap: '40px', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={snapdeal} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.snapdealLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={pinnacle} style={{ width: '60px', height: '40px' }} />
      <button
        onClick={() => {
          if (!isLoggedIn) {
            window.location.href = "https://testportal.ssccglpinnacle.com/login";
          } else {
            handleBuyNow(card.id);
          }
        }}
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
      >
        Buy Now
      </button>
    </div>
  </div>
</div> */}



<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  {/* First Row: Pinnacle and Flipkart */}
  <div style={{ display: 'flex', gap: '40px', justifyContent: 'center' }}>
    {/* Pinnacle */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={pinnacle} style={{ width: '60px', height: '40px' }} />
      <button
        onClick={() => {
          if (!isLoggedIn) {
            window.location.href = "https://testportal.ssccglpinnacle.com/login";
          } else {
            handleBuyNow(card.id);
          }
        }}
        style={{
          padding: '2px 4px',
          backgroundColor: '#2874F0',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Buy Now
      </button>
    </div>

    {/* Flipkart */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={flipkart} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.flipkartLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          padding: '2px 4px',
          backgroundColor: '#2874F0',
          color: '#fff',
          textDecoration: 'none',
          borderRadius: '4px',
        }}
      >
        Buy Now
      </a>
    </div>
  </div>

  {/* Second Row: Amazon and Snapdeal */}
  <div style={{ display: 'flex', gap: '40px', justifyContent: 'center' }}>
    {/* Amazon */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={amazon} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          padding: '2px 4px',
          backgroundColor: '#2874F0',
          color: '#fff',
          textDecoration: 'none',
          borderRadius: '4px',
        }}
      >
        Buy Now
      </a>
    </div>

    {/* Snapdeal */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={snapdeal} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.snapdealLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          padding: '2px 4px',
          backgroundColor: '#2874F0',
          color: '#fff',
          textDecoration: 'none',
          borderRadius: '4px',
        }}
      >
        Buy Now
      </a>
    </div>
  </div>
</div>


                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <p>No data available</p> // Display a message or a placeholder when there is no data
                )}
              </ul>
            </div>
            {!scrolEnd && (
              <button className="next-horizontal-row" onClick={() => slide(1)}>
                <AiOutlineRight className="prev-book-scroll" color="white" />
              </button>
            )}
          </div>
        </div>
      </div>
      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default Row4Digital;

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const BookMetaTags = ({ product }) => {
  if (!product) return null;

  // Helper function to sanitize strings by trimming and removing quotes
  const sanitize = (value) =>
    value ? value.replace(/^"+|"+$/g, "").trim() : "";
  const permalink = product.SEOPermalink;
  const jsonLD = {
    "@context": "https://schema.org",
    "@type": product.jsonLD?.["@type"] || "Product",
    name: sanitize(product.title),
    description: sanitize(
      product.SEODescription || "No description available."
    ),
    keywords: sanitize(product.SEOKeyword || "Default keywords"),
    image: sanitize(
      product.CoverImage || "https://ssccglpinnacle.com/favicon.png"
    ),
    url: `https://ssccglpinnacle.com/books${permalink})}`,
    offers: {
      "@type": "Offer",
      price: product.price || 0,
      priceCurrency: "INR",
      availability: "https://schema.org/InStock",
    },
  };

  const jsonLDScript = JSON.stringify(jsonLD);

  return (
    <HelmetProvider>
      <Helmet>
        {/* Basic SEO Meta Tags */}
        <title>{sanitize(product.title)}</title>
        <meta
          name="description"
          content={sanitize(
            product.SEODescription || "No description available."
          )}
        />
        <meta
          name="keywords"
          content={sanitize(product.SEOKeyword || "Default keywords")}
        />
        <link
          rel="canonical"
          href={`https://ssccglpinnacle.com/books${permalink})}`}
        />

        {/* Open Graph Tags */}
        <meta property="og:type" content="product" />
        <meta property="og:title" content={sanitize(product.title)} />
        <meta
          property="og:description"
          content={sanitize(
            product.SEODescription || "No description available."
          )}
        />
        <meta
          property="og:image"
          content={sanitize(
            product.CoverImage || "https://ssccglpinnacle.com/favicon.png"
          )}
        />
        <meta
          property="og:url"
          content={`https://ssccglpinnacle.com/books${permalink}
          )}`}
        />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">{jsonLDScript}</script>
      </Helmet>
    </HelmetProvider>
  );
};

export default BookMetaTags;

import React, { useEffect, useState } from "react";
import styles from "./CheckBlogStatus.module.css";
import axios from "axios";

const CheckBlogStatus = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/api/blogs"
        );
        setBlogs(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("Failed to fetch blogs");
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const toggleBlogStatus = async (blogId, blogStatus) => {
    setUpdating(blogId);
    setError(null);
    try {
      const newBlogStatus = !blogStatus;
      await axios.put(
        `https://auth.ssccglpinnacle.com/api/edit-blog/${blogId}/status`,
        // `https://auth.ssccglpinnacle.com/api/edit-blog/${blogId}/status`,
        {
          isActive: newBlogStatus,
        }
      );
      setBlogs((prevBlogs) =>
        prevBlogs.map((blog) =>
          blog._id === blogId ? { ...blog, isActive: newBlogStatus } : blog
        )
      );
      setUpdating(null);
    } catch (error) {
      console.error("Error updating blog status:", error);
      setError("Error updating blog status");
      setUpdating(null);
    }
  };

  const activeBlogsCount = blogs.filter((blog) => blog.isActive).length;
  const inactiveBlogsCount = blogs.length - activeBlogsCount;

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Blog Status</h1>
      <div className={styles.summary}>
        <p>Total Active Blogs: {activeBlogsCount}</p>
        <p>Total Inactive Blogs: {inactiveBlogsCount}</p>
      </div>

      {loading ? (
        <p>Loading blogs...</p>
      ) : error ? (
        <p className={styles.error}>{error}</p>
      ) : (
        <div className={styles.tableContainer}>
          <table className={styles.blogTable}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Author</th>
                <th>Heading</th>
                <th>Category</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog, index) => (
                <tr key={blog._id}>
                  <td>{index + 1}</td>
                  <td>{blog.author?.name || "Unknown Author"}</td>
                  <td>{blog.heading || "No Heading"}</td>
                  <td>{blog.category || "Uncategorized"}</td>
                  <td
                    className={
                      blog.isActive
                        ? styles.courseActive
                        : styles.courseInActive
                    }
                  >
                    {blog.isActive ? "Active" : "Inactive"}
                  </td>
                  <td>
                    <button
                      className={
                        blog.isActive
                          ? styles.deactivateButton
                          : styles.activateButton
                      }
                      onClick={() => toggleBlogStatus(blog._id, blog.isActive)}
                      disabled={updating === blog._id}
                    >
                      {updating === blog._id
                        ? "Updating..."
                        : blog.isActive
                        ? "Deactivate"
                        : "Activate"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CheckBlogStatus;


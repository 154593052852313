import React, { useState, useRef, useEffect } from "react";
import "./Row6.css";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

function Row6() {
  const [blogData, setblogData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/blogs"
        );
        const data = await response.json();

        // Ensure data is set correctly
        setblogData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to get the first 20 words from HTML content
  const getFirst20Words = (content) => {
    const div = document.createElement("div");
    div.innerHTML = content;
    const text = div.innerText || div.textContent || "";
    const words = text.split(/\s+/).slice(0, 20).join(" ");
    return words + (text.split(/\s+/).length > 20 ? "..." : "");
  };

  // Slide click
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector(".card-for-blog").offsetWidth; // Width of one card
    const shift = cardWidth * 4 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;

    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;

    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }

    if (targetScroll < 0) {
      targetScroll = 0;
    }

    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);

        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);

    // Check if the scroll has reached the end
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <div>
      <section className="section-suggest-blog">
        <div className="container-fluid-blog-home">
          <div className="foruppersection">
            <div className="col-md-8 section-suggest-blog-col-8">
              <p className="col-md-8 section-suggest-blog-p">
                Suggested for You: News | Blog | Notification | Announcement
              </p>
            </div>
          </div>

          <div className="container-for-button">
            {scrollX !== 5 && (
              <button className="prev" onClick={() => slide(-1)}>
                <AiOutlineLeft color="black" fontSize="1.5em" />
              </button>
            )}

            <div className="forcontainerblog">
              <ul className="cards-for-blog" ref={scrl} onScroll={scrollCheck}>
                {blogData.map((blog, index) => (
                  <li className="card card-for-blog" key={index}>
                    <div className="blog-div">
                      <div className="blog-category">
                        <span>{blog.category}</span>
                      </div>
                      <div className="blog-title">
                        <h3>{blog.heading}</h3>
                      </div>
                  
 <div className="blog-author">
                        <span className="author">Author: </span>
                        <span>{blog.author?.name || "Pinnacle"}</span>
                      </div>
                      <div className="blog-some-content">
                        <p className="blog-some-content-p">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getFirst20Words(blog.content),
                            }}
                          ></span>
                          &nbsp;
                          <Link to={`/blog/${blog.permalink}`}>Read More</Link>
                        </p>
                      </div>
                      <div className="blog-infor">
                        <span>
                          {new Date(blog.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {!scrolEnd && (
              <button className="next" onClick={() => slide(1)}>
                <AiOutlineRight color="black" fontSize="1.5em" />
              </button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Row6;

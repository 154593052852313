import React, { useState, useRef, useEffect } from "react";
import "./Row2.css";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import gsap from "gsap";
import bookImage from "../../Ebook/i/book.jpg";
import amazon from "../../iBook/amazon.svg";
import flipkart from "../../iBook/flipkart.svg";
import pinnacle from "../../iBook/pinnaclelogo.png";
import { IoOpenSharp } from "react-icons/io5";

function Row2() {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json();

        // Validate the data format (optional but recommended)
        if (Array.isArray(data)) {
          setCardData(data); // Update state with fetched data
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector(".card").offsetWidth; // Width of one card
    const shift = cardWidth * 5 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;

    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;

    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }

    if (targetScroll < 0) {
      targetScroll = 0;
    }

    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);

        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);

    // Check if the scroll has reached the end
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    console.log(cardData);
  }, [cardData]);

  const createLinkPath = (card) => {
    const permalink = card.permalink;
    return `/books${permalink}`;
  };

  return (
    <>
      <section className="section-suggest-book">
        <div className="container-fluid-new">
          <div className="foruppersection">
            <div className="col-md-8 section-suggest-book-col-8">
              <p className="col-md-8 section-suggest-book-p">
                Suggested for You: Book
              </p>
              <p className="col-md-8 section-suggest-book-second-p">
                Similar to items You Viewed
              </p>
            </div>
            <div className="col-md-4 section-suggest-book-col-4">
              <div className="book-viewall-button">
                <Link to={`/books`}>
                  {" "}
                  <button className="viewall-button" type="button">
                    VIEW ALL
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-for-button">
            {scrollX !== 5 && (
              <button className="prev" onClick={() => slide(-1)}>
                <AiOutlineLeft color="black" fontSize="1.5em" />
              </button>
            )}
            <div className="forcontainerbook">
              <ul className="cards" ref={scrl} onScroll={scrollCheck}>
                {cardData.map((card, index) => (
                  <li key={index} className="card">
                    <div>
                      <div className="card-title-image">
                        <Link to={createLinkPath(card)}>
                          <img
                            className="card-title"
                            src={card.image1}
                            alt="Card Title"
                          />{" "}
                        </Link>
                      </div>
                      <div className="card-content">
                        <p>{card.title}</p>
                        <div className="Link"></div>
                        <p className="choose-marketplace">
                          Choose your favorite marketplace
                        </p>
                      </div>
                    </div>
                    <div className="card-link-wrapper">
                      <div className="descriptive-row row">
                        <div className="description-col col-md-4">
                          <Link to={createLinkPath(card)}>
                            <img
                              className="website-logo website-logo2"
                              src={pinnacle}
                            />

                            <p className="description_p">
                              Best 7 offers
                              <IoOpenSharp
                                className="open-page-image"
                                color="#333"
                              />
                              {/* <img className="open-page-image" src="https://ssccglpinnacle.com/images/open-page-image.png" alt="Open page" /> */}
                            </p>
                          </Link>
                        </div>
                        <div className="description-col col-md-4">
                          <img
                            className="website-logo website-logo2"
                            src={amazon}
                          />
                          <a href={card.amazonLink}>
                            <p className="description_p description_p2">
                              {" "}
                              Check
                            </p>
                          </a>
                        </div>
                        <div className="description-col col-md-4">
                          <img
                            className="website-logo website-logo3"
                            src={flipkart}
                          />
                          <a href={card.flipkartLink}>
                            {" "}
                            <p className="description_p description_p3">
                              Check
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {scrollX !== (cardData.length - 5) * 250 && (
              <button className="next" onClick={() => slide(1)}>
                <AiOutlineRight color="black" fontSize="1.5em" />
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Row2;

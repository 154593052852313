import Row1Digital from "./Row1DigitalBook";
import HeaderBook from "../../componentbook/HeaderFilesBook/HeaderBook";
import Row2Digital from "./Row2DigitalBook";
import Row4Digital from "./Row4DigitalBook";
import Row5Digital from "./Row5DigitalBook";
import Row3Digital from "./Row3DigitalBook";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./Body.css";
import DataTransferContext from "../../DataTransferContext";
import React, { useContext, useEffect, useState } from "react";
import Footer from "../../FooterMain/Footer";
import MetaTags from "../../DynamicMetaData/DynamicMetadata";
import Modal from "react-bootstrap/Modal";
import { IoIosChatbubbles } from "react-icons/io";
import Chatbot from "../../chatBot/Chatbot";


function BodyBook() {
  const [filteredEbookData, setFilteredEbookData] = useState([]);

  // Callback to receive filtered data from Row1Digital
  const handleDataFiltered = (data) => {
    setFilteredEbookData(data);
  };
      const [showChatbot, setShowChatbot] = useState(false);
    const handleChatbotOpen = () => setShowChatbot(true);
    const handleChatbotClose = () => setShowChatbot(false);

  // console.log("row1" + filteredEbookData)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTags type="books" />
      <HeaderBook />
      <div className="digital-products-page">
        {/* First Row: Filters and Product Grid */}
        <div className="top-row">
          <div className="filter-section-book">
            <Row1Digital onDataFiltered={handleDataFiltered} />
          </div>
          {/* Chatbot Icon */}
          <div className="chatbot_icon">
                          <IoIosChatbubbles
                            onClick={handleChatbotOpen}
                            size={50}
                            style={{
                              cursor: "pointer",
                              position: "fixed",
                              bottom: "20px",
                              right: "20px",
                              color: "#007bff",
                            }}
                          />
                        </div>
                                  {/* Chatbot Modal */}
          <Modal show={showChatbot} onHide={handleChatbotClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Chatbot />
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleChatbotClose}>
            Close
          </Button>
        </Modal.Footer> */}
          </Modal>


          <div className="product-section">
            <Row3Digital filteredEbookData={filteredEbookData}/>
          </div>
        </div>

        {/* Second Row: Horizontal Scroller */}
        <div className="horizontal-row">
          <Row4Digital filteredEbookData={filteredEbookData} />
        </div>

        {/* Third Row: Bottom Product Grid */}
        <div className="bottom-row">
          <Row5Digital filteredEbookData={filteredEbookData} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BodyBook;

import React, { useState, useEffect } from "react";
import "./AddToCart.css";
import { BsCart2 } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Offer from "./Offer";
import { useAuth } from "../../Context/AuthContext";
import { useCart } from "../../Context/CartContext";
import Cartheader from "../../component/CartHeader/Cartheader";
import BookBuyButton from "./Payment";
import { useCookies } from "react-cookie";
import LoadingSpinner from "../LoadingSpinner";
import HeaderBar from "../../component/HeaderBar";
import swal from 'sweetalert';

function CartPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);

  const [discount, setDiscount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { isLoggedIn, user, token } = useAuth();
  const navigate = useNavigate();
  const { updateCartCount, cartCount } = useCart();
  const [cookies] = useCookies(["token", "email_id"]);
  const [totalCoins, setTotalCoins] = useState(0);
  const [redeemCoins, setRedeemCoins] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [savedAmount, setSavedAmount] = useState(0);

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/coins/${user?._id}`,
          {
            headers: {
              Authorization: `Bearer ${cookies.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch coin data");
        }

        const result = await response.json();
        const coins =
          result.data?.reduce((sum, item) => sum + (item.coins || 0), 0) || 0;
        setTotalCoins(coins);
      } catch (error) {
        console.error("Error fetching coins:", error.message);
      }
    };

    if (user?._id) {
      fetchCoins();
    }
  }, [user, cookies.token]);

  useEffect(() => {
    const checkAccess = async () => {
      if (cookies.token) {
        try {
          const response = await fetch(
            "https://auth.ssccglpinnacle.com/api/checkAccess",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${cookies.token}`,
              },
            }
          );

          if (response.ok) {
            const { access } = await response.json();
            if (access !== "access") {
              navigate("/ebooks");
              return false;
            }
            return true;
          } else {
            console.error("Access check error:", await response.json());
            navigate("/ebooks");
            return false;
          }
        } catch (error) {
          console.error("Access check error:", error);
          navigate("/ebooks");
          return false;
        }
      } else {
        console.error("Token not found in cookies");
        navigate("/ebooks");
        return false;
      }
    };

    const fetchPurchasedEbooks = async (useremail) => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/user/${useremail}/purchased-ebooks-cart`,
          {
            headers: {
              Authorization: `Bearer ${cookies.token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch purchased ebooks");
        }
        const purchasedEbooks = await response.json();
        return purchasedEbooks;
      } catch (error) {
        console.error("Error fetching purchased ebooks:", error.message);
        return [];
      }
    };

    const fetchData = async () => {
      const hasAccess = await checkAccess();
      if (hasAccess) {
        const userId = user?.id;
        const useremail = user?.email_id;
        console.log("User ID:", userId);
        if (userId) {
          try {
            const purchasedEbooks = await fetchPurchasedEbooks(useremail);
            const response = await fetch(
              `https://auth.ssccglpinnacle.com/api/cart-ebook/${useremail}`,
              {
                headers: {
                  Authorization: `Bearer ${cookies.token}`,
                },
              }
            );
            if (!response.ok) {
              throw new Error("User not found or unauthorized");
            }
            console.log("PURCHASED EBOOK", purchasedEbooks);
            const data = await response.json();
            const filteredData = data.filter(
              (item) =>
                !purchasedEbooks.some(
                  (purchased) => purchased.ebook_id === item.product.id
                )
            );
            const updatedData = filteredData.map((item) => {
              const defaultDuration = "12 Months";
              const defaultSubscription =
                item.product?.subscriptionPrices?.find(
                  (sub) => sub.duration === defaultDuration
                );
              return {
                ...item,
                selectedDuration: defaultDuration,
                product: {
                  ...item.product,
                  ebookSellingPrice:
                    defaultSubscription?.sellingPrice ||
                    item.product?.ebookSellingPrice,
                  ebookPrintingPrice:
                    defaultSubscription?.printingPrice ||
                    item.product?.ebookPrintingPrice,
                },
              };
            });
            setCartItems(updatedData);

            const newSubtotal = updatedData.reduce(
              (total, item) => total + (item.product?.ebookSellingPrice || 0),
              0
            );
            await deletePurchasedItemsFromCart(useremail);
            setSubtotal(newSubtotal);
          } catch (error) {
            console.error("Error fetching cart data:", error.message);
          }
        } else {
          console.error("User ID not found");
        }
      }
    };

    const deletePurchasedItemsFromCart = async (useremail) => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/delete-purchased-from-cart",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
            body: JSON.stringify({ useremail }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to delete purchased items from cart");
        }
        const result = await response.json();
        console.log(result.message);
      } catch (error) {
        console.error("Error deleting purchased items from cart:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [user, cookies.token, navigate]);

  const handleRemoveItem = async (itemIndex) => {
    const userId = user?.id;
    const username = user?.email_id;
    try {
      const productId = itemIndex;

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/cart/${username}/remove-ebook/${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );

      if (response.ok) {
        const updatedCartItems = [...cartItems];
        updatedCartItems.splice(itemIndex, 1);
        setCartItems(updatedCartItems);
      } else {
        const errorData = await response.json();
        console.error("Error removing item from cart:", errorData.error);
      }
    } catch (error) {
      console.error("Error removing item from cart:", error.message);
    }
    window.location.reload();
  };

  const handleDurationChange = (
    index,
    duration,
    sellingPrice,
    printingPrice
  ) => {
    const updatedCartItems = cartItems.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          selectedDuration: duration,
          product: {
            ...item.product,
            ebookSellingPrice: sellingPrice,
            ebookPrintingPrice: printingPrice,
          },
        };
      }
      return item;
    });

    setCartItems(updatedCartItems);

    const newSubtotal = updatedCartItems.reduce(
      (total, item) => total + (item.product?.ebookSellingPrice || 0),
      0
    );
    setSubtotal(newSubtotal);
  };

  const getCartItems = () => {
    return cartItems
      .map((item) => ({
        id: item.product?.id,
        duration: item.selectedDuration,
      }))
      .filter((item) => item.id != null);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // const handleRedeemCoins = () => {
  //   if (
  //     redeemCoins <= totalCoins &&
  //     redeemCoins >= 0 &&
  //     subtotal >= redeemCoins
  //   ) {
  //     setTotalCoins(totalCoins - redeemCoins); // Deduct redeemed coins from total coins
  //     setSubtotal(subtotal - redeemCoins); // Deduct redeemed coins from subtotal
  //     setSavedAmount(redeemCoins); // Set the saved amount
  //     setRedeemCoins(savedAmount); // Reset input field
  //     setShowMessage(true); // Show the celebration message

  //     // Hide the message after 3 seconds
  //     setTimeout(() => {
  //       setShowMessage(false);
  //     }, 3000);
  //   } else {
  //     alert("Invalid redeem amount. Please enter a valid number of coins.");
  //   }
  // };


  const handleRedeemCoins = () => {
    if (
      redeemCoins <= totalCoins &&         
      redeemCoins >= 0 &&                   
      subtotal - redeemCoins >= 1          
    ) {
      setTotalCoins(totalCoins - redeemCoins); 
      setSubtotal(subtotal - redeemCoins);     
      setSavedAmount(redeemCoins);           
      setRedeemCoins(savedAmount);          
      setShowMessage(true);                  
  
      
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } else {
      swal({
        title: "Payment Error!",
        text: "At least 1 rupee is required to complete the payment.",
        icon: "warning",
        button: "OK",
      });
    }
  };



  return (
    <>
      <div className="main-body-container">
        <Offer />
        {/* <Cartheader /> */}
        <HeaderBar />
        <div className="container-for-cart">
          <div className="margin-for-cart"> </div>

          {cartItems.length === 0 ? (
            <>
              <div className="body-for-cart">
                <div className="body-row1-for-cart">
                  <h1>Your cart</h1>

                  <div className="checkout-cart-empty">
                    <div
                      className="checkout-cart-button-empty"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={() => navigate("/ebooks")}
                    >
                      Continue Shopping
                      <span className="icon">
                        {isHovered ? (
                          <FiArrowRight size={20} />
                        ) : (
                          <AiOutlineRight className="after-hover-arrow" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cartitems-empty">
                  <div className="empty-cart-message">Your cart is empty.</div>

                  <div className="checkout-cart-empty-button">
                    <button
                      className="checkout-cart-button-empty-button"
                      onClick={() => navigate("/ebooks")}
                    >
                      <BsCart2 className="cart-svg" />
                      Continue Shopping
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="body-for-cart">
              <div className="body-row1-for-cart">
                <h1>Your cart</h1>

                <div className="subtotal-checkout-cart">
                  <div className="subtotal-cart">
                    <div className="subtotal-cart-1">Subtotal</div>
                    <div className="subtotal-cart-2">
                      <span className="money">₹ {subtotal.toFixed(2)} INR</span>
                    </div>
                  </div>
                  <div className="checkout-cart">
                    <BookBuyButton
                      userDetails={user}
                      orderAmount={subtotal}
                      redeemCoins={savedAmount}
                      cartItems={getCartItems()}
                    />
                  </div>
                </div>
              </div>
              <ul className="cart-u-list">
                {cartItems.map((item, index) => (
                  <li className="list-of-the-cart" key={index}>
                    <div className="img-of-current-product">
                      {item.product && (
                        <img
                          className="card-title-buy-img"
                          src={item.product.image}
                          alt={item.product.title}
                        />
                      )}
                    </div>

                    <div className="product-desc-cart">
                      <div className="title-current-price">
                        <div className="title-of-the-cart-product">
                          <p className="p-for-title-cart">
                            {item.product?.title}
                          </p>
                        </div>

                        <div className="price-of-the-cart-product">
                          <p className="price-for-title-cart">Printing Price</p>
                          <p className="price2-for-title-cart">
                            ₹{item.product?.ebookPrintingPrice}
                          </p>
                        </div>
                      </div>

                      <div className="duration-total-price-close">
                        <div className="duration-of-the-product">
                          <select
                            id="selectField-cart"
                            value={item.selectedDuration || "12 Months"}
                            onChange={(e) => {
                              const selectedOption = e.target.value;
                              let sellingPrice = 0;
                              let printingPrice = 0;
                              switch (selectedOption) {
                                case "12 Months":
                                  sellingPrice =
                                    item.product?.subscriptionPrices?.find(
                                      (sub) => sub.duration === "12 Months"
                                    )?.sellingPrice;
                                  printingPrice =
                                    item.product?.subscriptionPrices?.find(
                                      (sub) => sub.duration === "12 Months"
                                    )?.printingPrice;
                                  break;
                                default:
                                  sellingPrice =
                                    item.product?.subscriptionPrices?.find(
                                      (sub) => sub.duration === "12 Months"
                                    )?.sellingPrice;
                                  printingPrice =
                                    item.product?.subscriptionPrices?.find(
                                      (sub) => sub.duration === "12 Months"
                                    )?.printingPrice;
                              }
                              handleDurationChange(
                                index,
                                selectedOption,
                                sellingPrice,
                                printingPrice
                              );
                            }}
                          >
                            <option value="12 Months">12 Months</option>
                          </select>
                        </div>

                        <div className="total-price-of-the-product">
                          <p className="total-price-of-the-product1">
                            Selling Price
                          </p>
                          <p className="total-price-of-the-product2">
                            ₹ {item.product?.ebookSellingPrice}
                          </p>
                        </div>
                        <div className="for-delete-cart">
                          <div
                            className="circle-for-close"
                            onClick={() => handleRemoveItem(item.product._id)}
                          >
                            <RxCross2 className="icon-cross-cart" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <div className="for-line"></div>

              <div className="sub-total-container">
                <div className="coin-summary">
                  <h5
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      color: "#DAA520", // Gold color for the "Total Coins Earned" text
                      textShadow: "1px 1px 2px #555", // Subtle shadow for depth
                      fontFamily: "'Poppins', sans-serif", // Stylish font
                    }}
                  >
                    Total Coins Earned: {totalCoins}
                    <span style={{ marginLeft: "5px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="gold"
                        width="20px"
                        height="20px"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <text
                          x="50%"
                          y="55%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fontSize="10"
                          fill="black"
                          fontWeight="bold"
                        >
                          $
                        </text>
                      </svg>
                    </span>
                  </h5>

                  <div className="redeem-section">
                    <input
                      type="number"
                      className="redeem-input"
                      value={redeemCoins || ""} // Display empty string when redeemCoins is 0 or null
                      onChange={(e) =>
                        setRedeemCoins(Number(e.target.value) || 0)
                      } // Set to 0 if input is cleared
                      placeholder="Coins to redeem"
                      onWheel={(e) => e.target.blur()}
                    />
                    <button
                      className="redeem-button"
                      onClick={handleRedeemCoins}
                      disabled={redeemCoins <= 0 || redeemCoins > totalCoins}
                    >
                      Redeem Coins
                    </button>
                    <a
      href="https://ssccglpinnacle.com/coins" // Replace this with your actual URL
      target="_blank"
      rel="noopener noreferrer"
      className="redeem-link"
      style={{ textDecoration: "none", color: "#007bff", fontSize: "14px" }}
    >
      Click To Coins Details
    </a>
                  </div>
                </div>
                {/* Celebration message */}
                {showMessage && (
                  <div className="celebration-message">
                    🎉 Congrats! You have saved ₹{savedAmount}! 🎉
                  </div>
                )}
                <div className="for-subtotal">
                  <div className="sub-total-container-totalpara">Subtotal</div>

                  <div className="sub-total-container-total">
                    ₹ {subtotal.toFixed(2)} INR
                  </div>
                </div>
                <div className="sub-total-container-tax">
                  <p>18% GST has been included</p>
                </div>

                <div className="sub-total-container-message">
                  <p>Your cart does not require shipping.</p>
                </div>

                {/* <div className="checkout-cart-bottom"> */}
                <div className="checkout-cart-button-bottom">
                  <BookBuyButton
                    userDetails={user}
                    orderAmount={subtotal}
                    cartItems={getCartItems()}
                     redeemCoins={savedAmount}
                  />
                  {/* <button className="checkout-cart-button-bottom">
                  </button> */}
                </div>
              </div>
              <div className="checkout-cart-empty-on-bottom">
                <div
                  className="checkout-cart-button-empty"
                  onClick={() => {
                    navigate("/ebook");
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  Continue Shopping
                  <span className="icon">
                    {isHovered ? (
                      <FiArrowRight size={20} />
                    ) : (
                      <AiOutlineRight className="after-hover-arrow" />
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer-for-cart"></div>
      </div>

      {showModal && (
        <div className="Cart-coupon-overlay show">
          <div className="Cart-coupon-modal show">
            <div className="Cart-coupon-close" onClick={closeModal}>
              &times;
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CartPage;



// import React, { useState, useEffect } from "react";
// import "./AddToCart.css";
// import { BsCart2 } from "react-icons/bs";
// import { RxCross2 } from "react-icons/rx";
// import { FiArrowRight } from "react-icons/fi";
// import { AiOutlineRight } from "react-icons/ai";
// import { useNavigate } from "react-router-dom";
// import Offer from "./Offer";
// import { useAuth } from "../../Context/AuthContext";
// import { useCart } from "../../Context/CartContext";
// import Cartheader from "../../component/CartHeader/Cartheader";
// import BookBuyButton from "./Payment";
// import { useCookies } from "react-cookie";
// import LoadingSpinner from "../LoadingSpinner";

// function CartPage() {
//   const [isLoading, setIsLoading] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);
//   const [cartItems, setCartItems] = useState([]);
//   const [subtotal, setSubtotal] = useState(0);
//   const [coinsToUse, setCoinsToUse] = useState(0);
//   const [discount, setDiscount] = useState(0);
//   const [showModal, setShowModal] = useState(false);
//   const { isLoggedIn, user, token } = useAuth();
//   const navigate = useNavigate();
//   const { updateCartCount, cartCount } = useCart();
//   const [cookies] = useCookies(["token", "email_id"]);

//   useEffect(() => {
//     const checkAccess = async () => {
//       if (cookies.token) {
//         try {
//           const response = await fetch(
//             "https://auth.ssccglpinnacle.com/api/checkAccess",
//             {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//                 Authorization: `Bearer ${cookies.token}`,
//               },
//             }
//           );

//           if (response.ok) {
//             const { access } = await response.json();
//             if (access !== "access") {
//               navigate("/ebooks");
//               return false;
//             }
//             return true;
//           } else {
//             console.error("Access check error:", await response.json());
//             navigate("/ebooks");
//             return false;
//           }
//         } catch (error) {
//           console.error("Access check error:", error);
//           navigate("/ebooks");
//           return false;
//         }
//       } else {
//         console.error("Token not found in cookies");
//         navigate("/ebooks");
//         return false;
//       }
//     };

//     const fetchPurchasedEbooks = async (useremail) => {
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/user/${useremail}/purchased-ebooks-cart`,
//           {
//             headers: {
//               Authorization: `Bearer ${cookies.token}`,
//             },
//           }
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch purchased ebooks");
//         }
//         const purchasedEbooks = await response.json();
//         return purchasedEbooks;
//       } catch (error) {
//         console.error("Error fetching purchased ebooks:", error.message);
//         return [];
//       }
//     };

//     const fetchData = async () => {
//       const hasAccess = await checkAccess();
//       if (hasAccess) {
//         const userId = user?.id;
//         const useremail = user?.email_id; // Use either _id or id
//         console.log("User ID:", userId);
//         if (userId) {
//           try {
//             const purchasedEbooks = await fetchPurchasedEbooks(useremail);
//             const response = await fetch(
//               `https://auth.ssccglpinnacle.com/api/cart-ebook/${useremail}`,
//               {
//                 headers: {
//                   Authorization: `Bearer ${cookies.token}`,
//                 },
//               }
//             );
//             if (!response.ok) {
//               throw new Error("User not found or unauthorized");
//             }
//             const data = await response.json();
//             const filteredData = data.filter(
//               (item) =>
//                 !purchasedEbooks.some(
//                   (purchased) => purchased.ebook_id === item.product.id
//                 )
//             );
//             const updatedData = filteredData.map((item) => {
//               const defaultDuration = "12 Months";
//               const defaultSubscription =
//                 item.product?.subscriptionPrices?.find(
//                   (sub) => sub.duration === defaultDuration
//                 );
//               return {
//                 ...item,
//                 selectedDuration: defaultDuration,
//                 product: {
//                   ...item.product,
//                   ebookSellingPrice:
//                     defaultSubscription?.sellingPrice ||
//                     item.product?.ebookSellingPrice,
//                   ebookPrintingPrice:
//                     defaultSubscription?.printingPrice ||
//                     item.product?.ebookPrintingPrice,
//                 },
//               };
//             });
//             setCartItems(updatedData);

//             const newSubtotal = updatedData.reduce(
//               (total, item) => total + (item.product?.ebookSellingPrice || 0),
//               0
//             );
//             await deletePurchasedItemsFromCart(useremail);
//             setSubtotal(newSubtotal);
//           } catch (error) {
//             console.error("Error fetching cart data:", error.message);
//           }
//         } else {
//           console.error("User ID not found");
//         }
//       }
//     };

//     const deletePurchasedItemsFromCart = async (useremail) => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/delete-purchased-from-cart",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${cookies.token}`,
//             },
//             body: JSON.stringify({ useremail }),
//           }
//         );
//         if (!response.ok) {
//           throw new Error("Failed to delete purchased items from cart");
//         }
//         const result = await response.json();
//         console.log(result.message);
//       } catch (error) {
//         console.error("Error deleting purchased items from cart:", error);
//       }
//       setIsLoading(false);
//     };

//     fetchData();
//   }, [user, cookies.token, navigate]);

//   const handleRemoveItem = async (itemIndex) => {
//     const userId = user?.id; // Use either _id or id
//     const username = user?.email_id;
//     try {
//       const productId = itemIndex;

//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/cart/${username}/remove-ebook/${productId}`,
//         {
//           method: "DELETE",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${cookies.token}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const updatedCartItems = [...cartItems];
//         updatedCartItems.splice(itemIndex, 1);
//         setCartItems(updatedCartItems);
//       } else {
//         const errorData = await response.json();
//         console.error("Error removing item from cart:", errorData.error);
//       }
//     } catch (error) {
//       console.error("Error removing item from cart:", error.message);
//     }
//     window.location.reload();
//   };

//   const handleDurationChange = (
//     index,
//     duration,
//     sellingPrice,
//     printingPrice
//   ) => {
//     const updatedCartItems = cartItems.map((item, i) => {
//       if (i === index) {
//         return {
//           ...item,
//           selectedDuration: duration,
//           product: {
//             ...item.product,
//             ebookSellingPrice: sellingPrice,
//             ebookPrintingPrice: printingPrice,
//           },
//         };
//       }
//       return item;
//     });

//     setCartItems(updatedCartItems);

//     // Recalculate subtotal
//     const newSubtotal = updatedCartItems.reduce(
//       (total, item) => total + (item.product?.ebookSellingPrice || 0),
//       0
//     );
//     setSubtotal(newSubtotal);
//   };

//   const getCartItems = () => {
//     return cartItems
//       .map((item) => ({
//         id: item.product?.id,
//         duration: item.selectedDuration,
//       }))
//       .filter((item) => item.id != null);
//   };

//   const handleApplyCoins = () => {
//     if (user.coins >= coinsToUse) {
//       const newDiscount = coinsToUse / 100; // Assuming 1 coin = 1 unit of currency
//       setDiscount(newDiscount);
//       setSubtotal(subtotal - newDiscount);
//       setShowModal(false);
//     } else {
//       alert("Insufficient coins");
//     }
//   };

//   const openModal = () => {
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   const handleCoinsChange = (e) => {
//     let value = e.target.value;
//     value = value.replace(/^0+/, ""); // Remove leading zeros
//     setCoinsToUse(value ? Number(value) : 0);
//   };

//   if (isLoading) {
//     return <LoadingSpinner />;
//   }

//   return (
//     <>
//       <div className="main-body-container">
//         <Offer />
//         <Cartheader />
//         <div className="container-for-cart">
//           <div className="margin-for-cart"> </div>

//           {cartItems.length === 0 ? (
//             <>
//               <div className="body-for-cart">
//                 <div className="body-row1-for-cart">
//                   <h1>Your cart</h1>

//                   <div className="checkout-cart-empty">
//                     <div
//                       className="checkout-cart-button-empty"
//                       onMouseEnter={() => setIsHovered(true)}
//                       onMouseLeave={() => setIsHovered(false)}
//                       onClick={() => navigate("/ebooks")}
//                     >
//                       Continue Shopping
//                       <span className="icon">
//                         {isHovered ? (
//                           <FiArrowRight size={20} />
//                         ) : (
//                           <AiOutlineRight className="after-hover-arrow" />
//                         )}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="cartitems-empty">
//                   <div className="empty-cart-message">Your cart is empty.</div>

//                   <div className="checkout-cart-empty-button">
//                     <button
//                       className="checkout-cart-button-empty-button"
//                       onClick={() => navigate("/ebooks")}
//                     >
//                       <BsCart2 className="cart-svg" />
//                       Continue Shopping
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </>
//           ) : (
//             <div className="body-for-cart">
//               <div className="body-row1-for-cart">
//                 <h1>Your cart</h1>

//                 <div className="subtotal-checkout-cart">
//                   <div className="subtotal-cart">
//                     <div className="subtotal-cart-1">Subtotal</div>
//                     <div className="subtotal-cart-2">
//                       <span className="money">₹ {subtotal.toFixed(2)} INR</span>
//                     </div>
//                   </div>
//                   <div className="checkout-cart">
//                     <BookBuyButton
//                       userDetails={user}
//                       orderAmount={subtotal}
//                       cartItems={getCartItems()}
//                     />
//                   </div>
//                 </div>
//               </div>
//               <ul className="cart-u-list">
//                 {cartItems.map((item, index) => (
//                   <li className="list-of-the-cart" key={index}>
//                     <div className="img-of-current-product">
//                       {item.product && (
//                         <img
//                           className="card-title-buy-img"
//                           src={item.product.image}
//                           alt={item.product.title}
//                         />
//                       )}
//                     </div>

//                     <div className="product-desc-cart">
//                       <div className="title-current-price">
//                         <div className="title-of-the-cart-product">
//                           <p className="p-for-title-cart">
//                             {item.product?.title}
//                           </p>
//                         </div>

//                         <div className="price-of-the-cart-product">
//                           <p className="price-for-title-cart">Printing Price</p>
//                           <p className="price2-for-title-cart">
//                             ₹{item.product?.ebookPrintingPrice}
//                           </p>
//                         </div>
//                       </div>

//                       <div className="duration-total-price-close">
//                         <div className="duration-of-the-product">
//                           <select
//                             id="selectField-cart"
//                             value={item.selectedDuration || "12 Months"} // Set default value to 12 Months
//                             onChange={(e) => {
//                               const selectedOption = e.target.value;
//                               let sellingPrice = 0;
//                               let printingPrice = 0;
//                               switch (selectedOption) {
//                                 case "12 Months":
//                                   sellingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.sellingPrice;
//                                   printingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.printingPrice;
//                                   break;
//                                 default:
//                                   sellingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.sellingPrice;
//                                   printingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.printingPrice;
//                               }
//                               handleDurationChange(
//                                 index,
//                                 selectedOption,
//                                 sellingPrice,
//                                 printingPrice
//                               );
//                             }}
//                           >
//                             <option value="12 Months">12 Months</option>
//                           </select>
//                         </div>

//                         <div className="total-price-of-the-product">
//                           <p className="total-price-of-the-product1">
//                             Selling Price
//                           </p>
//                           <p className="total-price-of-the-product2">
//                             ₹ {item.product?.ebookSellingPrice}
//                           </p>
//                         </div>
//                         <div className="for-delete-cart">
//                           <div
//                             className="circle-for-close"
//                             onClick={() => handleRemoveItem(item.product._id)}
//                           >
//                             <RxCross2 className="icon-cross-cart" />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                 ))}
//               </ul>

//               <div className="for-line"></div>

//               <div className="sub-total-container">
//                 <div className="for-subtotal">
//                   <div className="sub-total-container-totalpara">Subtotal</div>

//                   <div className="sub-total-container-total">
//                     ₹ {subtotal.toFixed(2)} INR
//                   </div>
//                 </div>
//                 <div className="sub-total-container-tax">
//                   <p>18% GST has been included</p>
//                 </div>

//                 <div className="sub-total-container-message">
//                   <p>Your cart does not require shipping.</p>
//                 </div>
//                 <div className="apply-coins-cart">
//                   <button onClick={openModal}>Apply Coins</button>
//                 </div>

//                 <div className="checkout-cart-bottom">
//                   <button className="checkout-cart-button-bottom">
//                     <BookBuyButton
//                       userDetails={user}
//                       orderAmount={subtotal}
//                       cartItems={getCartItems()}
//                     />
//                   </button>
//                 </div>
//               </div>
//               <div className="checkout-cart-empty-on-bottom">
//                 <div
//                   className="checkout-cart-button-empty"
//                   onClick={() => {
//                     navigate("/ebook");
//                   }}
//                   onMouseEnter={() => setIsHovered(true)}
//                   onMouseLeave={() => setIsHovered(false)}
//                 >
//                   Continue Shopping
//                   <span className="icon">
//                     {isHovered ? (
//                       <FiArrowRight size={20} />
//                     ) : (
//                       <AiOutlineRight className="after-hover-arrow" />
//                     )}
//                   </span>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//         <div className="footer-for-cart"></div>
//       </div>

//       {showModal && (
//         <div className="Cart-coupon-overlay show">
//           <div className="Cart-coupon-modal show">
//             <div className="Cart-coupon-close" onClick={closeModal}>
//               &times;
//             </div>
//             <div className="Cart-coupon-content">
//               <h2>Apply Coins</h2>
//               <p>Total Amount: ₹ {subtotal.toFixed(2)} INR</p>
//               <input
//                 type="number"
//                 value={coinsToUse}
//                 onChange={handleCoinsChange}
//                 placeholder="Enter coins to use"
//               />
//               <p>Coins to use: {coinsToUse}</p>
//               <p>New Total: ₹ {(subtotal - coinsToUse / 100).toFixed(2)} INR</p>
//               <button className="Cart-coupon-button" onClick={handleApplyCoins}>
//                 Apply Coins
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default CartPage;

// import React, { useState, useEffect } from "react";
// import "./AddToCart.css";
// import { BsCart2 } from "react-icons/bs";
// import { RxCross2 } from "react-icons/rx";
// import { FiArrowRight } from "react-icons/fi";
// import { AiOutlineRight } from "react-icons/ai";
// import { useNavigate } from "react-router-dom";
// import Offer from "./Offer";
// import { useAuth } from "../../Context/AuthContext";
// import { useCart } from "../../Context/CartContext";
// import Cartheader from "../../component/CartHeader/Cartheader";
// import BookBuyButton from "./Payment";
// import { useCookies } from "react-cookie";
// import LoadingSpinner from "../LoadingSpinner";

// function CartPage() {
//   const [isLoading, setIsLoading] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);
//   const [cartItems, setCartItems] = useState([]);
//   const [subtotal, setSubtotal] = useState(0);
//   const [coinsToUse, setCoinsToUse] = useState(0);
//   const [discount, setDiscount] = useState(0);
//   const [showModal, setShowModal] = useState(false);
//   const { isLoggedIn, user, token } = useAuth();
//   const navigate = useNavigate();
//   const { updateCartCount, cartCount } = useCart();
//   const [cookies] = useCookies(["token", "email_id"]);

//   useEffect(() => {
//     const checkAccess = async () => {
//       if (cookies.token) {
//         try {
//           const response = await fetch(
//             "https://auth.ssccglpinnacle.com/api/checkAccess",
//             {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//                 Authorization: `Bearer ${cookies.token}`,
//               },
//             }
//           );

//           if (response.ok) {
//             const { access } = await response.json();
//             if (access !== "access") {
//               navigate("/ebooks");
//               return false;
//             }
//             return true;
//           } else {
//             console.error("Access check error:", await response.json());
//             navigate("/ebooks");
//             return false;
//           }
//         } catch (error) {
//           console.error("Access check error:", error);
//           navigate("/ebooks");
//           return false;
//         }
//       } else {
//         console.error("Token not found in cookies");
//         navigate("/ebooks");
//         return false;
//       }
//     };

//     const fetchPurchasedEbooks = async (useremail) => {
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/user/${useremail}/purchased-ebooks-cart`,
//           {
//             headers: {
//               Authorization: `Bearer ${cookies.token}`,
//             },
//           }
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch purchased ebooks");
//         }
//         const purchasedEbooks = await response.json();
//         return purchasedEbooks;
//       } catch (error) {
//         console.error("Error fetching purchased ebooks:", error.message);
//         return [];
//       }
//     };

//     const fetchData = async () => {
//       const hasAccess = await checkAccess();
//       if (hasAccess) {
//         const userId = user?.id;
//         const useremail = user?.email_id; // Use either _id or id
//         console.log("User ID:", userId);
//         if (userId) {
//           try {
//             const purchasedEbooks = await fetchPurchasedEbooks(useremail);
//             const response = await fetch(
//               `https://auth.ssccglpinnacle.com/api/cart-ebook/${useremail}`,
//               {
//                 headers: {
//                   Authorization: `Bearer ${cookies.token}`,
//                 },
//               }
//             );
//             if (!response.ok) {
//               throw new Error("User not found or unauthorized");
//             }
//             const data = await response.json();
//             const filteredData = data.filter(
//               (item) =>
//                 !purchasedEbooks.some(
//                   (purchased) => purchased.ebook_id === item.product.id
//                 )
//             );
//             const updatedData = filteredData.map((item) => {
//               const defaultDuration = "12 Months";
//               const defaultSubscription =
//                 item.product?.subscriptionPrices?.find(
//                   (sub) => sub.duration === defaultDuration
//                 );
//               return {
//                 ...item,
//                 selectedDuration: defaultDuration,
//                 product: {
//                   ...item.product,
//                   ebookSellingPrice:
//                     defaultSubscription?.sellingPrice ||
//                     item.product?.ebookSellingPrice,
//                   ebookPrintingPrice:
//                     defaultSubscription?.printingPrice ||
//                     item.product?.ebookPrintingPrice,
//                 },
//               };
//             });
//             setCartItems(updatedData);

//             const newSubtotal = updatedData.reduce(
//               (total, item) => total + (item.product?.ebookSellingPrice || 0),
//               0
//             );
//             await deletePurchasedItemsFromCart(useremail);
//             setSubtotal(newSubtotal);
//           } catch (error) {
//             console.error("Error fetching cart data:", error.message);
//           }
//         } else {
//           console.error("User ID not found");
//         }
//       }
//     };

//     const deletePurchasedItemsFromCart = async (useremail) => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/delete-purchased-from-cart",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${cookies.token}`,
//             },
//             body: JSON.stringify({ useremail }),
//           }
//         );
//         if (!response.ok) {
//           throw new Error("Failed to delete purchased items from cart");
//         }
//         const result = await response.json();
//         console.log(result.message);
//       } catch (error) {
//         console.error("Error deleting purchased items from cart:", error);
//       }
//       setIsLoading(false);
//     };

//     fetchData();
//   }, [user, cookies.token, navigate]);

//   const handleRemoveItem = async (itemIndex) => {
//     const userId = user?.id; // Use either _id or id
//     const username = user?.email_id;
//     try {
//       const productId = itemIndex;

//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/cart/${username}/remove-ebook/${productId}`,
//         {
//           method: "DELETE",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${cookies.token}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const updatedCartItems = [...cartItems];
//         updatedCartItems.splice(itemIndex, 1);
//         setCartItems(updatedCartItems);
//       } else {
//         const errorData = await response.json();
//         console.error("Error removing item from cart:", errorData.error);
//       }
//     } catch (error) {
//       console.error("Error removing item from cart:", error.message);
//     }
//     window.location.reload();
//   };

//   const handleDurationChange = (
//     index,
//     duration,
//     sellingPrice,
//     printingPrice
//   ) => {
//     const updatedCartItems = cartItems.map((item, i) => {
//       if (i === index) {
//         return {
//           ...item,
//           selectedDuration: duration,
//           product: {
//             ...item.product,
//             ebookSellingPrice: sellingPrice,
//             ebookPrintingPrice: printingPrice,
//           },
//         };
//       }
//       return item;
//     });

//     setCartItems(updatedCartItems);

//     // Recalculate subtotal
//     const newSubtotal = updatedCartItems.reduce(
//       (total, item) => total + (item.product?.ebookSellingPrice || 0),
//       0
//     );
//     setSubtotal(newSubtotal);
//   };

//   const getCartItems = () => {
//     return cartItems
//       .map((item) => ({
//         id: item.product?.id,
//         duration: item.selectedDuration,
//       }))
//       .filter((item) => item.id != null);
//   };

//   const handleApplyCoins = () => {
//     if (user.coins >= coinsToUse) {
//       const newDiscount = coinsToUse / 100; // Assuming 1 coin = 1 unit of currency
//       setDiscount(newDiscount);
//       setSubtotal(subtotal - newDiscount);
//       setShowModal(false);
//     } else {
//       alert("Insufficient coins");
//     }
//   };

//   const openModal = () => {
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   const handleCoinsChange = (e) => {
//     let value = e.target.value;
//     value = value.replace(/^0+/, ""); // Remove leading zeros
//     setCoinsToUse(value ? Number(value) : 0);
//   };

//   if (isLoading) {
//     return <LoadingSpinner />;
//   }

//   return (
//     <>
//       <div className="main-body-container">
//         <Offer />
//         <Cartheader />
//         <div className="container-for-cart">
//           <div className="margin-for-cart"> </div>

//           {cartItems.length === 0 ? (
//             <>
//               <div className="body-for-cart">
//                 <div className="body-row1-for-cart">
//                   <h1>Your cart</h1>

//                   <div className="checkout-cart-empty">
//                     <div
//                       className="checkout-cart-button-empty"
//                       onMouseEnter={() => setIsHovered(true)}
//                       onMouseLeave={() => setIsHovered(false)}
//                       onClick={() => navigate("/ebooks")}
//                     >
//                       Continue Shopping
//                       <span className="icon">
//                         {isHovered ? (
//                           <FiArrowRight size={20} />
//                         ) : (
//                           <AiOutlineRight className="after-hover-arrow" />
//                         )}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="cartitems-empty">
//                   <div className="empty-cart-message">Your cart is empty.</div>

//                   <div className="checkout-cart-empty-button">
//                     <button
//                       className="checkout-cart-button-empty-button"
//                       onClick={() => navigate("/ebooks")}
//                     >
//                       <BsCart2 className="cart-svg" />
//                       Continue Shopping
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </>
//           ) : (
//             <div className="body-for-cart">
//               <div className="body-row1-for-cart">
//                 <h1>Your cart</h1>

//                 <div className="subtotal-checkout-cart">
//                   <div className="subtotal-cart">
//                     <div className="subtotal-cart-1">Subtotal</div>
//                     <div className="subtotal-cart-2">
//                       <span className="money">₹ {subtotal.toFixed(2)} INR</span>
//                     </div>
//                   </div>
//                   <div className="checkout-cart">
//                     <BookBuyButton
//                       userDetails={user}
//                       orderAmount={subtotal}
//                       cartItems={getCartItems()}
//                     />
//                   </div>
//                 </div>
//               </div>
//               <ul className="cart-u-list">
//                 {cartItems.map((item, index) => (
//                   <li className="list-of-the-cart" key={index}>
//                     <div className="img-of-current-product">
//                       {item.product && (
//                         <img
//                           className="card-title-buy-img"
//                           src={item.product.image}
//                           alt={item.product.title}
//                         />
//                       )}
//                     </div>

//                     <div className="product-desc-cart">
//                       <div className="title-current-price">
//                         <div className="title-of-the-cart-product">
//                           <p className="p-for-title-cart">
//                             {item.product?.title}
//                           </p>
//                         </div>

//                         <div className="price-of-the-cart-product">
//                           <p className="price-for-title-cart">Printing Price</p>
//                           <p className="price2-for-title-cart">
//                             ₹{item.product?.ebookPrintingPrice}
//                           </p>
//                         </div>
//                       </div>

//                       <div className="duration-total-price-close">
//                         <div className="duration-of-the-product">
//                           <select
//                             id="selectField-cart"
//                             value={item.selectedDuration || "12 Months"} // Set default value to 12 Months
//                             onChange={(e) => {
//                               const selectedOption = e.target.value;
//                               let sellingPrice = 0;
//                               let printingPrice = 0;
//                               switch (selectedOption) {
//                                 case "12 Months":
//                                   sellingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.sellingPrice;
//                                   printingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.printingPrice;
//                                   break;
//                                 default:
//                                   sellingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.sellingPrice;
//                                   printingPrice =
//                                     item.product?.subscriptionPrices?.find(
//                                       (sub) => sub.duration === "12 Months"
//                                     )?.printingPrice;
//                               }
//                               handleDurationChange(
//                                 index,
//                                 selectedOption,
//                                 sellingPrice,
//                                 printingPrice
//                               );
//                             }}
//                           >
//                             <option value="12 Months">12 Months</option>
//                           </select>
//                         </div>

//                         <div className="total-price-of-the-product">
//                           <p className="total-price-of-the-product1">
//                             Selling Price
//                           </p>
//                           <p className="total-price-of-the-product2">
//                             ₹ {item.product?.ebookSellingPrice}
//                           </p>
//                         </div>
//                         <div className="for-delete-cart">
//                           <div
//                             className="circle-for-close"
//                             onClick={() => handleRemoveItem(item.product._id)}
//                           >
//                             <RxCross2 className="icon-cross-cart" />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                 ))}
//               </ul>

//               <div className="for-line"></div>

//               <div className="sub-total-container">
//                 <div className="for-subtotal">
//                   <div className="sub-total-container-totalpara">Subtotal</div>

//                   <div className="sub-total-container-total">
//                     ₹ {subtotal.toFixed(2)} INR
//                   </div>
//                 </div>
//                 <div className="sub-total-container-tax">
//                   <p>18% GST has been included</p>
//                 </div>

//                 <div className="sub-total-container-message">
//                   <p>Your cart does not require shipping.</p>
//                 </div>
//                 <div className="apply-coins-cart">
//                   <button onClick={openModal}>Apply Coins</button>
//                 </div>

//                 <div className="checkout-cart-bottom">
//                   <button className="checkout-cart-button-bottom">
//                     <BookBuyButton
//                       userDetails={user}
//                       orderAmount={subtotal}
//                       cartItems={getCartItems()}
//                     />
//                   </button>
//                 </div>
//               </div>
//               <div className="checkout-cart-empty-on-bottom">
//                 <div
//                   className="checkout-cart-button-empty"
//                   onClick={() => {
//                     navigate("/ebook");
//                   }}
//                   onMouseEnter={() => setIsHovered(true)}
//                   onMouseLeave={() => setIsHovered(false)}
//                 >
//                   Continue Shopping
//                   <span className="icon">
//                     {isHovered ? (
//                       <FiArrowRight size={20} />
//                     ) : (
//                       <AiOutlineRight className="after-hover-arrow" />
//                     )}
//                   </span>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//         <div className="footer-for-cart"></div>
//       </div>

//       {showModal && (
//         <div className="Cart-coupon-overlay show">
//           <div className="Cart-coupon-modal show">
//             <div className="Cart-coupon-close" onClick={closeModal}>
//               &times;
//             </div>
//             <div className="Cart-coupon-content">
//               <h2>Apply Coins</h2>
//               <p>Total Amount: ₹ {subtotal.toFixed(2)} INR</p>
//               <input
//                 type="number"
//                 value={coinsToUse}
//                 onChange={handleCoinsChange}
//                 placeholder="Enter coins to use"
//               />
//               <p>Coins to use: {coinsToUse}</p>
//               <p>New Total: ₹ {(subtotal - coinsToUse / 100).toFixed(2)} INR</p>
//               <button className="Cart-coupon-button" onClick={handleApplyCoins}>
//                 Apply Coins
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default CartPage;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import { TfiEmail } from "react-icons/tfi";
import { BsCart2, BsFillMenuButtonWideFill } from "react-icons/bs";
import {
  FaHome,
  FaKeyboard,
  FaBars,
  FaTimes,
  FaRegHeart,
  FaCoins,
  FaSearch,
} from "react-icons/fa";
import { HiOutlineBookOpen } from "react-icons/hi";
import { AiOutlineLineChart, AiOutlineUser } from "react-icons/ai";
import { MdOndemandVideo } from "react-icons/md";
import { ImBlogger } from "react-icons/im";
import { IoLogoGooglePlaystore, IoSearchOutline } from "react-icons/io5";

// import { FaCoins } from "react-icons/fa";
// import { FaRegHeart } from "react-icons/fa";
// import { BsFillMenuButtonWideFill } from "react-icons/bs";

// import SearchBar from "./HeaderFilesEbook/SearchBar";
import SearchBar from "../component/HeaderFiles/SearchBar1";
import { useAuth } from "../Context/AuthContext";

// import pinnacleWhiteLogo from "./HeaderFilesEbook/images/pinnacleWhiteLogo.png";
import google from "./HeaderFilesEbook/images/google.svg";
import logoMobile from "./HeaderFilesEbook/images/logo_mobile.svg";
import styles from "./HeaderBar.module.css";

const Header = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { isLoggedIn, user, logout } = useAuth();

  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showAccountOption, setShowAccountOption] = useState(false);
  const [mobileSearchActive, setMobileSearchActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [username, setUsername] = useState("Student");

  const navigate = useNavigate();
  const searchRef = useRef(null); // Ref for detecting outside clicks

  const loginButtonRefDesktop = useRef(null); // Ref for desktop login button
  const loginButtonRefMobile = useRef(null); // Ref for mobile login button
  const authDropdownRefDesktop = useRef(null); // Ref for the dropdown
  const authDropdownRefMobile = useRef(null); // Ref for the dropdown
  
  // // Function to toggle search bar on mobile
  // const MobileSearchHandler = () => {
  //   setMobileSearchActive(true);
  // };

  // Detect clicks outside the search bar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setMobileSearchActive(false); // Close the search bar
      }
    };

    // Add event listener on the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside all relevant elements
      const isOutsideDesktop =
        authDropdownRefDesktop.current &&
        !authDropdownRefDesktop.current.contains(event.target) &&
        loginButtonRefDesktop.current &&
        !loginButtonRefDesktop.current.contains(event.target);
  
      const isOutsideMobile =
        authDropdownRefMobile.current &&
        !authDropdownRefMobile.current.contains(event.target) &&
        loginButtonRefMobile.current &&
        !loginButtonRefMobile.current.contains(event.target);
  
      // If the click is outside both desktop and mobile elements
      if (isOutsideDesktop && isOutsideMobile) {
        setShowAccountOption(false); // Close the dropdown
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  // CART
  useEffect(() => {
    const userId = user?.id;
    const useremail = user?.email_id;
    const token = Cookies.get("token");
    console.log("USER", user);
    console.log("USER_ID", userId);
    console.log("USER_EMAIL", useremail);
    setUsername(user?.full_name || "Student"); 

    // if(user.full_name){
    // }

    if (userId) {
      fetch(`https://auth.ssccglpinnacle.com/api/cart-ebook/${useremail}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCartItems(data);
          console.log("CART DATA", data);
        })
        .catch((error) => console.error("Error fetching cart data:", error));
    }
  }, [user]);



  // WISHLIST
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        if (!user || !user._id) {
          console.error("User is null or _id is undefined.");
          return;
        }

        const token = Cookies.get("token");
        const wishlistResponse = await fetch(
          `https://auth.ssccglpinnacle.com/api/get-wishlist-ebook/${user.email_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const wishlistData = await wishlistResponse.json();
        const wishlistIds = wishlistData.wishlist;

        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/ebooksforactive"
        );
        const ebookData = await ebookResponse.json();
        const wishlistItemsData = ebookData.filter((ebook) =>
          wishlistIds.includes(ebook._id)
        );

        setWishlistItems(wishlistItemsData);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    fetchWishlist();
  }, [user]);

  const handleWishlistHover = () => {
    setShowDropdown(true);
    setIsHovered(true);
  };

  const handleWishlistLeave = () => {
    if (!isClicked) {
      setShowDropdown(false);
      setIsHovered(false);
    }
  };

  const handleWishlistClick = () => {
    setShowDropdown(true);
    setIsClicked((prevIsClicked) => !prevIsClicked);
    setIsHovered(false);
  };

  const handleAddToCart = async (ebookId) => {
    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      setIsAddingToCart(true);
      const token = Cookies.get("token");
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ebookId }),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Failed to add item to cart. Error: ${errorMessage.error}`,
        });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  function truncateText(text, maxWords) {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  }

  const cartHandler = async () => {
    if (!isLoggedIn) {
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
    } else {
      navigate("/ebooks/cart");
    }
  };

  const goToWishListHandler = async () => {
    if (!isLoggedIn) {
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
    } else {
      navigate("/ebooks/wish-list");
      // navigate("/ebooks");
    }
  };

  const loginHandler = async () => {
    if (!isLoggedIn) {
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
    } else {
      setShowAccountOption((prev) => !prev);
    }
  };

  const mobileSidebarloginHandler = async () => {
    if (!isLoggedIn) {
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
    } else {
      // setShowAccountOption((prev) => !prev);
      logout();
    }
  };

  const logoutHandler = async() => {
    logout();
  };

  const MobileSearchHandler = async () => {
    setMobileSearchActive(true);
    setIsMobileMenuOpen(false);
  };

  // const authHandler = async () => {
console.log("SHOW ACCOUNT OPTION ", showAccountOption)
console.log("isLoggedIn ", isLoggedIn)
  // };

  return (
    <>
      <header className={styles.header1}>
        {/* <div className={styles.hamburgerMenu} onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div> */}
        <div className={styles.firstColumn1}>
          <div className={styles.logo}>
            {/* <img src={pinnacleWhiteLogo} alt="Pinnacle Logo" /> */}
            <img src="/PinnacleLogoWhite.svg" alt="Pinnacle Logo" />
          </div>
          <div className={styles.queryEmail}>
            <TfiEmail />
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@ssccglpinnacle.com
            </a>
          </div>
        </div>

        <div className={styles.secondColumn1}>
          <div className={styles.firstrow1}>
            <div className={styles.search}>
              <SearchBar />
            </div>
            <div className={styles.icons}>
              {/* {isLoggedIn && ( */}
              <div
                className={styles.cartIcon}
                onClick={cartHandler}
                // onClick={() => navigate("/ebooks/cart")}
                title="Cart"
              >
                <BsCart2 className="" />
                <div className={styles.circleMain}>
                  {cartItems.length === 0 && cartItems !== null
                    ? "0"
                    : cartItems.length}
                </div>
              </div>
              {/* )} */}

              {/* {isLoggedIn && ( */}
              <div
                // className="cart-icon-header-main-heart"
                className={styles.wishList}
                onMouseEnter={() => !isClicked && handleWishlistHover()}
                onMouseLeave={handleWishlistLeave}
                onClick={handleWishlistClick}
                title="Wishlist"
              >
                <FaRegHeart className={styles.wishListIcon} />
                {showDropdown && (
                  // <div
                  //   className={`wishlist-dropdown ${
                  //     showDropdown ? "show" : ""
                  //   }`}
                  // >

                  <div
                    className={`${styles.wishlistDropdown} ${
                      showDropdown ? styles.show : ""
                    }`}
                  >
                    <div className={styles.wishListShowDropdown}>
                      {wishlistItems && wishlistItems.length > 0 ? (
                        wishlistItems.map((item) => (
                          <div className={styles.dropWishlist} key={item._id}>
                            <div className={styles.forDropWishlist}>
                              <div className={styles.forImageDisplay}>
                                <div className={styles.imageForWishDrop}>
                                  <img src={item.image} alt="Selected" />
                                </div>
                              </div>
                              <div className={styles.forPriceDropWish}>
                                <div className={styles.forShowTitleDrop}>
                                  {truncateText(
                                    `${item.title} ${item.edition} edition ${item.medium}`,
                                    10
                                  )}
                                </div>
                                <div className={styles.forShowPriceDrop}>
                                  ₹ {item.subscriptionPrices[0].sellingPrice}
                                </div>
                              </div>
                            </div>
                            <div
                              // className="add-to-cart-buy-modal-intro-wish"
                              className={styles.addToCartBuyModalIntroWish}
                              onClick={() => handleAddToCart(item._id)}
                            >
                              Add to cart
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className={styles.emptyErrorMessage}>
                          <div className={styles.wishlistEmptyMessage}>
                            Your wishlist is empty.
                          </div>
                          <div
                            // className="explore-empty-message"
                            className={styles.exploreEmptyMessage}
                            onClick={() => navigate("/ebooks")}
                          >
                            Explore courses
                          </div>
                        </div>
                      )}
                      <div className={styles.gotToModal}>
                        <div
                          // className="go-to-wishlist-modal-intro-wish"
                          className={styles.goToWishlistModalIntroWish}
                          onClick={goToWishListHandler}
                        >
                          Go to wishlist
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.googlePlay}>
              <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal">
                <img src={google} className={styles.googlePlayButton} />
              </a>
            </div>
          </div>

          <nav>
            <ul className={styles.nav}>
              {/* <ul className="navlistsbook"> */}
              <li onClick={() => navigate("/")} className={styles.navLink}>
                <FaHome className={styles.navLinkIcon} />
                &nbsp;Home
              </li>
              <li
                onClick={() =>
                  (window.location.href =
                    "https://testportal.ssccglpinnacle.com/")
                }
                className={styles.navLink}
              >
                <AiOutlineLineChart className={styles.navLinkIcon} />
                &nbsp;Test Portal
              </li>
              <li
                onClick={() => navigate("/typing")}
                className={styles.navLink}
              >
                <FaKeyboard className={styles.navLinkIcon} />
                &nbsp;Typing
                {/* <Link to={hasTypingAccess ? "/typingexamselection" : "/typing"}>
                       </Link> */}
              </li>
              <li onClick={() => navigate("/books")} className={styles.navLink}>
                <HiOutlineBookOpen className={styles.navLinkIcon} />
                &nbsp;Books
              </li>
              <li
                onClick={() => navigate("/videos")}
                className={styles.navLink}
              >
                <MdOndemandVideo className={styles.navLinkIcon} />
                &nbsp;Videos
                {/* <Link to="/videos">
                       </Link> */}
              </li>
              <li
                onClick={() => navigate("/ebooks")}
                className={styles.navLink}
              >
                <BsFillMenuButtonWideFill className={styles.navLinkIcon} />
                &nbsp;E-Books
              </li>
              <li onClick={() => navigate("/blog")} className={styles.navLink}>
                <ImBlogger className={styles.navLinkIcon} />
                &nbsp;Blogs
              </li>
              <li onClick={() => navigate("/coins")} className={styles.navLink}>
                <FaCoins className={styles.navLinkIcon} />
                &nbsp;Coins
              </li>
              {/* <li onClick={handleUserIconClick}> */}
              <li
                className={`${styles.navLink} ${styles.loginOption}`}
                onClick={loginHandler}
                // ref={loginButtonRef} // Attach ref to the login button
                ref={loginButtonRefDesktop} // Attach ref to desktop login button
              >
                &nbsp;
                {isLoggedIn ? (
                  <div>
                    {user.photo ? (
                      <img src={user.photo} alt="student" />
                    ) : (
                      <AiOutlineUser className={styles.navLinkIcon} />
                    )}
                    {username}
                  </div>
                ) : (
                  "Login"
                )}
              {isLoggedIn && showAccountOption && (
                <div
                  className={`${styles.AuthDropdown} ${
                    showAccountOption ? styles.show1 : ""
                  }`}
                  ref={authDropdownRefDesktop} // Attach ref to the dropdown
                >
                  <ul>
                    <li
                      onClick={() => navigate("/ebooks/mylearning")}
                      // onClick={() => navigate("/")}
                      // className={styles.navLink1}
                      className={`${styles.navLink1} ${styles.AuthDropdownlist}`}
                    >
                      <FaHome className={styles.navLinkIcon} />
                      &nbsp;My Account
                    </li>

                    <li
                      onClick={logoutHandler}
                      className={`${styles.navLink1} ${styles.AuthDropdownlist}`}
                    >
                      <FaKeyboard className={styles.navLinkIcon} />
                      &nbsp;Logout
                    </li>
                  </ul>
                </div>
              )}
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <header className={styles.header}>
        <div className={styles.hamburgerMenu} onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <div className={styles.logoMobile}>
          <img src={logoMobile} alt="Pinnacle Logo" />
        </div>
        <div className={styles.firstColumn1}>
          <img src="/PinnacleLogoWhite.svg" alt="Pinnacle Logo" />
          <div className={styles.queryEmail}>
            <TfiEmail />
            {/* <TfiEmail className={styles.emailIcon}/> */}
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@ssccglpinnacle.com
            </a>
          </div>
        </div>

        <div className={styles.search}>
          <SearchBar />
        </div>
        <div className={styles.searchMobile} onClick={MobileSearchHandler}>
          {/* <FaSearch className={styles.searchIcon} /> */}
          <IoSearchOutline className={styles.searchIconMobile} />
        </div>
        <div
          className={styles.cartIcon}
          // onClick={() => navigate("/ebooks/cart")}
          onClick={cartHandler}
          title="Cart"
        >
          <BsCart2 className="" />
          <div className={styles.circleMain}>
            {cartItems.length === 0 && cartItems !== null
              ? "0"
              : cartItems.length}
          </div>
        </div>

        <div
          // className="cart-icon-header-main-heart"
          className={styles.wishList}
          onMouseEnter={() => !isClicked && handleWishlistHover()}
          onMouseLeave={handleWishlistLeave}
          onClick={handleWishlistClick}
          title="Wishlist"
        >
          <FaRegHeart className={styles.wishListIcon} />
          {showDropdown && (
            // <div
            //   className={`wishlist-dropdown show ${showDropdown ? "show" : ""}`}
            // >
            <div
              className={`${styles.wishlistDropdownMobile} ${
                showDropdown ? styles.show : ""
              }`}
            >
              <div className={styles.wishListShowDropdown}>
                {wishlistItems && wishlistItems.length > 0 ? (
                  wishlistItems.map((item) => (
                    <div className={styles.dropWishlist} key={item._id}>
                      <div className={styles.forDropWishlist}>
                        <div className={styles.forImageDisplay}>
                          <div className={styles.imageForWishDrop}>
                            <img src={item.image} alt="Selected" />
                          </div>
                        </div>
                        <div className={styles.forPriceDropWish}>
                          <div className={styles.forShowTitleDrop}>
                            {truncateText(
                              `${item.title} ${item.edition} edition ${item.medium}`,
                              10
                            )}
                          </div>
                          <div className={styles.forShowPriceDrop}>
                            ₹ {item.subscriptionPrices[0].sellingPrice}
                          </div>
                        </div>
                      </div>
                      <div
                        // className="add-to-cart-buy-modal-intro-wish"
                        className={styles.addToCartBuyModalIntroWish}
                        onClick={() => handleAddToCart(item._id)}
                      >
                        Add to cart
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.emptyErrorMessage}>
                    <div className={styles.wishlistEmptyMessage}>
                      Your wishlist is empty.
                    </div>
                    <div
                      // className="explore-empty-message"
                      className={styles.exploreEmptyMessage}
                      onClick={() => navigate("/ebooks")}
                    >
                      Explore courses
                    </div>
                  </div>
                )}
                <div className={styles.gotToModal}>
                  <div
                    // className="go-to-wishlist-modal-intro-wish"
                    className={styles.goToWishlistModalIntroWish}
                    // onClick={() => navigate("/ebooks/wish-list")}
                    onClick={goToWishListHandler}
                  >
                    Go to wishlist
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.googlePlay}>
          <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal">
            <IoLogoGooglePlaystore className={styles.playStoreIcon} />
          </a>
        </div>

        <div
          className={styles.googlePlay}
          title={
            isLoggedIn
              ? user?.full_name || user?.useremail || "Student"
              : "Student"
          }
          onClick={loginHandler}
          // ref={loginButtonRef} // Attach ref to the login button
          ref={loginButtonRefMobile} // Attach ref to mobile login button

        >
          <AiOutlineUser className={styles.navLinkIcon} />
          {isLoggedIn && showAccountOption && (
                <div
                  className={`${styles.AuthDropdown} ${
                    showAccountOption ? styles.show1 : ""
                  }`}
                  ref={authDropdownRefMobile} // Attach ref to the dropdown
                >
                  <ul>
                    <li
                      onClick={() => navigate("/ebooks/mylearning")}
                      // onClick={() => navigate("/")}
                      // className={styles.navLink1}
                      className={`${styles.navLink1} ${styles.AuthDropdownlist}`}
                    >
                      <FaHome className={styles.navLinkIcon} />
                      &nbsp;My Account
                    </li>

                    <li
                      onClick={logoutHandler}
                      className={`${styles.navLink1} ${styles.AuthDropdownlist}`}
                    >
                      <FaKeyboard className={styles.navLinkIcon} />
                      &nbsp;Logout
                    </li>
                  </ul>
                </div>
              )}
        </div>
        {mobileSearchActive ? (
          <div ref={searchRef} className={styles.SearchActive}>
            {/* Wrapper with ref */}
            <SearchBar />
          </div>
        ) : (
          ""
        )}
        {/* <div className={styles.firstrow2}>

        </div> */}
      </header>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <nav className={styles.mobileMenu}>
          <ul>
            <li onClick={() => navigate("/")} className={styles.navLink1}>
              <FaHome className={styles.navLinkIcon} />
              &nbsp;Home
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://testportal.ssccglpinnacle.com/")
              }
              className={styles.navLink1}
            >
              <AiOutlineLineChart className={styles.navLinkIcon} />
              &nbsp;Test Portal
            </li>
            <li onClick={() => navigate("/typing")} className={styles.navLink1}>
              <FaKeyboard className={styles.navLinkIcon} />
              &nbsp;Typing
              {/* <Link to={hasTypingAccess ? "/typingexamselection" : "/typing"}>
                       </Link> */}
            </li>
            <li onClick={() => navigate("/books")} className={styles.navLink1}>
              <HiOutlineBookOpen className={styles.navLinkIcon} />
              &nbsp;Books
            </li>
            <li onClick={() => navigate("/videos")} className={styles.navLink1}>
              <MdOndemandVideo className={styles.navLinkIcon} />
              &nbsp;Videos
              {/* <Link to="/videos">
                       </Link> */}
            </li>
            <li onClick={() => navigate("/ebooks")} className={styles.navLink1}>
              <BsFillMenuButtonWideFill className={styles.navLinkIcon} />
              &nbsp;E-Books
            </li>
            <li onClick={() => navigate("/blog")} className={styles.navLink1}>
              <ImBlogger className={styles.navLinkIcon} />
              &nbsp;Blogs
            </li>
            {/* <li onClick={handleUserIconClick}> */}
            <li className={styles.navLink1} onClick={mobileSidebarloginHandler}>
              <AiOutlineUser className={styles.navLinkIcon} />
              &nbsp;{isLoggedIn ? "Logout" : "Login"}
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Header;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RatingApp_ratingContainer__glBX4 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 20px 0; */
  }
  
  .RatingApp_star__g-LNC {
    font-size: 20px;
    color: #ccc;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .RatingApp_star__g-LNC:hover,
  .RatingApp_starActive__XC5fQ {
    color: #f39c12;
  }
  
  .RatingApp_overlay__6UJFW {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .RatingApp_popup__I2f-w {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 90%;
    max-width: 400px;
  }
  
  .RatingApp_popup__I2f-w textarea {
    width: 100%;
    height: 80px;
    margin: 10px 0;
  }
  
  .RatingApp_popup__I2f-w button {
    padding: 10px 20px;
    margin: 10px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
  }
  
  .RatingApp_popup__I2f-w button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/Ebook/User/RatingApp.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;EACtB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,eAAe;IACf,sBAAsB;EACxB;;EAEA;;IAEE,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".ratingContainer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    /* margin: 20px 0; */\n  }\n  \n  .star {\n    font-size: 20px;\n    color: #ccc;\n    cursor: pointer;\n    transition: color 0.2s;\n  }\n  \n  .star:hover,\n  .starActive {\n    color: #f39c12;\n  }\n  \n  .overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.7);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .popup {\n    background: white;\n    padding: 20px;\n    border-radius: 10px;\n    text-align: center;\n    width: 90%;\n    max-width: 400px;\n  }\n  \n  .popup textarea {\n    width: 100%;\n    height: 80px;\n    margin: 10px 0;\n  }\n  \n  .popup button {\n    padding: 10px 20px;\n    margin: 10px 5px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    background-color: #007bff;\n    color: white;\n  }\n  \n  .popup button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ratingContainer": `RatingApp_ratingContainer__glBX4`,
	"star": `RatingApp_star__g-LNC`,
	"starActive": `RatingApp_starActive__XC5fQ`,
	"overlay": `RatingApp_overlay__6UJFW`,
	"popup": `RatingApp_popup__I2f-w`
};
export default ___CSS_LOADER_EXPORT___;

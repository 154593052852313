import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Blog2.css'; // Ensure you have this CSS file

const Blog2 = ({ category }) => {
  const [blogs, setBlogs] = useState([]);
  const [scrollCount, setScrollCount] = useState(0);
  const navigate = useNavigate();
console.log("category:",category);
  useEffect(() => {
    fetch('https://auth.ssccglpinnacle.com/api/active-blogs')
      .then(response => response.json())
      .then(data => {
        console.log('API Response:', data);
        const filteredBlogs = data.filter(blog => blog.category === category);
        console.log("filteredBlogs:",filteredBlogs);
        setBlogs(filteredBlogs);
      })
      .catch(error => console.error('There was an error fetching the blogs!', error));
  }, [category]);

  const handleScrollRight = () => {
    if (scrollCount < Math.ceil(blogs.length / 4) - 1) {
      setScrollCount(scrollCount + 1);
    } else {
      setScrollCount(0);
    }
  };

  const truncateText = (text, maxWords) => {
    const wordsArray = text.split(' ');
    if (wordsArray.length > maxWords) {
      return wordsArray.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };
  const handleCardClick = (blog) => {
    navigate(`/blog/${blog.permalink}`);
  };

  const handleSeeAllClick = () => {
    navigate(`/blogNext/${category}`);
  };

  return (
    <>
      <div className='row3-blog'>
        <div className='row3-blog-first'>
          <h2 className='row2h2'>{category} Articles</h2>
          <p className='p-row2p' onClick={handleSeeAllClick}>See all {category} articles</p>
        </div>
        <div className="blog2-cards-wrapper">
          <div className='blog2-cards-blog'>
            <div className="blog2-cards" style={{ transform: `translateX(-${scrollCount * 30}%)` }}>
              {blogs.map(blog => (
                <div className="blog2-card" key={blog._id} onClick={() => handleCardClick(blog)}>
                  <h3>{truncateText(blog.heading, 11)}</h3>
                  <p>By {blog.author.name}</p>
                </div>
              ))}
            </div>
            <div className="blog2-scroll-button" onClick={handleScrollRight}>
              <FaArrowRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog2;



// import React, { useEffect, useState } from 'react';
// import { FaArrowRight } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';
// import './Blog2.css'; // Ensure you have this CSS file

// const Blog2 = ({ category }) => {
//   const [blogs, setBlogs] = useState([]);
//   const [scrollCount, setScrollCount] = useState(0);
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Log the category passed to the component
//     console.log('Category passed to Blog2:', category);

//     fetch('https://auth.ssccglpinnacle.com/api/active-blogs')
//       .then(response => {
//         console.log('API Response Status:', response.status);
//         return response.json();
//       })
//       .then(data => {
//         console.log('API Response Data:', data);

//         // Ensure category comparison is case insensitive
//         const filteredBlogs = data.filter(blog => 
//           blog.category.toLowerCase() === category.toLowerCase()
//         );

//         console.log(`Filtered Blogs for category "${category}":`, filteredBlogs);

//         setBlogs(filteredBlogs);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the blogs!', error);
//       });
//   }, [category]);

//   const handleScrollRight = () => {
//     if (scrollCount < Math.ceil(blogs.length / 4) - 1) {
//       setScrollCount(scrollCount + 1);
//     } else {
//       setScrollCount(0);
//     }
//   };

//   const truncateText = (text, maxWords) => {
//     const wordsArray = text.split(' ');
//     if (wordsArray.length > maxWords) {
//       return wordsArray.slice(0, maxWords).join(' ') + '...';
//     }
//     return text;
//   };

//   const handleCardClick = (blog) => {
//     console.log('Navigating to blog permalink:', blog.permalink);
//     navigate(`/blog/${blog.permalink}`);
//   };

//   const handleSeeAllClick = () => {
//     console.log(`Navigating to all blogs in category: ${category}`);
//     navigate(`/blogNext/${category}`);
//   };

//   return (
//     <>
//       <div className='row3-blog'>
//         <div className='row3-blog-first'>
//           <h2 className='row2h2'>{category} Articles</h2>
//           <p className='p-row2p' onClick={handleSeeAllClick}>See all {category} articles</p>
//         </div>
//         {blogs.length === 0 ? (
//           <p>No articles available for this category.</p>
//         ) : (
//           <div className="blog2-cards-wrapper">
//             <div className='blog2-cards-blog'>
//               <div className="blog2-cards" style={{ transform: `translateX(-${scrollCount * 30}%)` }}>
//                 {blogs.map(blog => (
//                   <div className="blog2-card" key={blog._id} onClick={() => handleCardClick(blog)}>
//                     <h3>{truncateText(blog.heading, 11)}</h3>
//                     <p>By {blog.author.name}</p>
//                   </div>
//                 ))}
//               </div>
//               <div className="blog2-scroll-button" onClick={handleScrollRight}>
//                 <FaArrowRight />
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Blog2;

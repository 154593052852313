import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  MenuItem,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import styles from "./EditCourse.module.css";

const EditCourse = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [course, setCourse] = useState(location.state.course);
  const [hindiCoverPreview, setHindiCoverPreview] = useState(
    course.hindiCoverImage || null
  );
  const [englishCoverPreview, setEnglishCoverPreview] = useState(
    course.englishCoverImage || null
  );
  const [hindiCoverFile, setHindiCoverFile] = useState(null);
  const [englishCoverFile, setEnglishCoverFile] = useState(null);
  const [generating, setGenerating] = useState({
    seoTitle: false,
    seoKeyword: false,
    seoDescription: false,
    shortDescription: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (type === "hindi") {
      setHindiCoverFile(file);
      setHindiCoverPreview(URL.createObjectURL(file));
    } else {
      setEnglishCoverFile(file);
      setEnglishCoverPreview(URL.createObjectURL(file));
    }
  };

  const generateSEOContent = async (type) => {
    if (
      ![
        "shortDescription",
        "seoKeyword",
        "seoDescription",
        "seoTitle",
      ].includes(type)
    ) {
      Swal.fire("Error", `Invalid type: ${type}`, "error");
      return;
    }

    const { courseTitle, subject, category } = course;

    if (!courseTitle || !subject || !category) {
      Swal.fire(
        "Error",
        "Please provide Course Title, Subject, and Category",
        "error"
      );
      return;
    }

    setGenerating((prev) => ({ ...prev, [type]: true }));
    try {
      const response = await axios.post(
        "https://auth.ssccglpinnacle.com/api/seo/video/generate",
        {
          courseTitle,
          subject,
          category,
          type,
        }
      );

      const content = response.data?.content || "No content generated.";

      setCourse((prev) => ({
        ...prev,
        [type === "seoKeyword"
          ? "SEOKeyword"
          : type === "seoDescription"
          ? "SEODescription"
          : type === "seoTitle"
          ? "SEOTitle"
          : "shortDescription"]: content,
      }));

      Swal.fire("Success", `${type} generated successfully`, "success");
    } catch (error) {
      console.error("Error generating SEO content:", error);
      Swal.fire(
        "Error",
        `Failed to generate ${type}: ${
          error.response?.data?.error || error.message
        }`,
        "error"
      );
    } finally {
      setGenerating((prev) => ({ ...prev, [type]: false }));
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("courseTitle", course.courseTitle);
      formData.append("shortDescription", course.shortDescription);
      formData.append("longDescription", course.longDescription);
      formData.append("category", course.category);
      formData.append("subject", course.subject);
      formData.append("price", course.price);
      formData.append("edition", course.edition);
      formData.append("mrp", course.mrp);
      formData.append("instructorName", course.instructorName);
      formData.append("SEOTitle", course.SEOTitle);
      formData.append("SEOKeyword", course.SEOKeyword);
      formData.append("SEODescription", course.SEODescription);
      formData.append("SEOPermalink", course.SEOPermalink);

      if (hindiCoverFile) formData.append("hindiCoverImage", hindiCoverFile);
      if (englishCoverFile)
        formData.append("englishCoverImage", englishCoverFile);

      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/api/edit-courses/${course._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        Swal.fire("Success", "Course updated successfully!", "success");
        navigate(-1);
      } else {
        throw new Error("Failed to update course");
      }
    } catch (error) {
      console.error("Error saving course:", error);
      Swal.fire("Error", "There was an error saving the course.", "error");
    }
  };

  return (
    <div className={styles.editCourseContainer}>
      <h1 className={styles.editCourseHeading}>Edit Course</h1>

      <div className={styles.formGroup}>
        <label className={styles.label}>Course Title</label>
        <input
          type="text"
          name="courseTitle"
          value={course.courseTitle}
          onChange={handleChange}
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>Short Description</label>
        <textarea
          name="ShortDescription"
          value={course.shortDescription}
          onChange={handleChange}
          rows="5"
          className={styles.textarea}
        ></textarea>
        <button
          onClick={() => generateSEOContent("shortDescription")}
          disabled={generating.shortDescription}
          className={`${styles.generateButton} ${
            generating.shortDescription ? styles.disabledButton : ""
          }`}
        >
          {generating.shortDescription
            ? "Generating..."
            : "Generate Short Description"}
        </button>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>Long Description</label>
        <textarea
          name="longDescription"
          value={course.longDescription}
          onChange={handleChange}
          rows="6"
          className={styles.textarea}
        ></textarea>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>Category</label>
        <input
          type="text"
          name="category"
          value={course.category}
          onChange={handleChange}
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>Subject</label>
        <input
          type="text"
          name="subject"
          value={course.subject}
          onChange={handleChange}
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>Price</label>
        <input
          type="number"
          name="price"
          value={course.price}
          onChange={handleChange}
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>MRP</label>
        <input
          type="number"
          name="mrp"
          value={course.mrp}
          onChange={handleChange}
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>Edition</label>
        <input
          type="text"
          name="edition"
          value={course.edition}
          onChange={handleChange}
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>Intructor Name</label>
        <input
          type="text"
          name="instructor name"
          value={course.instructorName}
          onChange={handleChange}
          className={styles.input}
        />
      </div>
      <div className={styles.fileInputGroup}>
        <label className={styles.label}>Hindi Cover Image</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, "hindi")}
          className={styles.fileInput}
        />
        {hindiCoverPreview && (
          <img
            src={hindiCoverPreview}
            alt="Hindi Cover Preview"
            className={styles.imagePreview}
          />
        )}
      </div>

      <div className={styles.fileInputGroup}>
        <label className={styles.label}>English Cover Image</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, "english")}
          className={styles.fileInput}
        />
        {englishCoverPreview && (
          <img
            src={englishCoverPreview}
            alt="English Cover Preview"
            className={styles.imagePreview}
          />
        )}
      </div>
      <div className={styles.editCourseContainer}>
        <div className={styles.seoSection}>
          <div className={styles.formGroup}>
            <label className={styles.label}>SEO Title</label>
            <input
              type="text"
              name="SEOTitle"
              value={course.SEOTitle}
              onChange={handleChange}
              className={styles.input}
            />
            <button
              onClick={() => generateSEOContent("seoTitle")}
              disabled={generating.seoTitle}
              className={`${styles.generateButton} ${
                generating.seoTitle ? styles.disabledButton : ""
              }`}
            >
              {generating.seoTitle ? "Generating..." : "Generate SEO Title"}
            </button>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.label}>SEO Keywords</label>
            <textarea
              name="SEOKeyword"
              value={course.SEOKeyword}
              onChange={handleChange}
              rows="4"
              className={styles.textarea}
            ></textarea>
            <button
              onClick={() => generateSEOContent("seoKeyword")}
              disabled={generating.seoKeyword}
              className={`${styles.generateButton} ${
                generating.seoKeyword ? styles.disabledButton : ""
              }`}
            >
              {generating.seoKeyword
                ? "Generating..."
                : "Generate SEO Keywords"}
            </button>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.label}>SEO Description</label>
            <textarea
              name="SEODescription"
              value={course.SEODescription}
              onChange={handleChange}
              rows="5"
              className={styles.textarea}
            ></textarea>
            <button
              onClick={() => generateSEOContent("seoDescription")}
              disabled={generating.seoDescription}
              className={`${styles.generateButton} ${
                generating.seoDescription ? styles.disabledButton : ""
              }`}
            >
              {generating.seoDescription
                ? "Generating..."
                : "Generate SEO Description"}
            </button>
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>SEO Permalink</label>
          <input
            type="text"
            name="SEOPermalink"
            value={course.SEOPermalink}
            onChange={handleChange}
            className={styles.input}
          />
        </div>

        <div className={styles.buttonGroup}>
          <button onClick={handleSave} className={styles.saveButton}>
            Save
          </button>
          <button onClick={() => navigate(-1)} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCourse;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchBar.css";
import { FaSearch, FaMicrophone } from "react-icons/fa";
import { SearchContext } from "../../Context/SearchContext";

function SearchBar() {
  const { query, setQuery } = useContext(SearchContext);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const navigate = useNavigate();

  const placeholderTexts = [
    "Search For Books",
    "Search For Ebooks",
    "Search For Video Courses",
    "Search For Test Passes",
  ];

  useEffect(() => {
    let currentIndex = 0;
    let charIndex = 0;
    let typingDirection = 1; // 1 for typing, -1 for deleting
    const typewriterInterval = setInterval(() => {
      const currentText = placeholderTexts[currentIndex];
      if (typingDirection === 1 && charIndex <= currentText.length) {
        setPlaceholder(currentText.slice(0, charIndex));
        charIndex++;
      } else if (typingDirection === -1 && charIndex >= 0) {
        setPlaceholder(currentText.slice(0, charIndex));
        charIndex--;
      }

      if (charIndex === currentText.length && typingDirection === 1) {
        typingDirection = -1; // Start deleting
      } else if (charIndex === 0 && typingDirection === -1) {
        typingDirection = 1; // Start typing next
        currentIndex = (currentIndex + 1) % placeholderTexts.length; // Move to next text
      }
    }, 150); // Typing speed

    return () => clearInterval(typewriterInterval);
  }, []); // Empty dependency array ensures it runs once

  const fetchSuggestions = async (query) => {
    if (!query.trim()) {
      setSuggestions([]);
      return;
    }
    try {
      const previousSearches = ["ssc", "rail"];
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(
          query
        )}&previousSearches=${encodeURIComponent(previousSearches.join(","))}`
      );
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const saveSearchQuery = async (searchTerm) => {
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/save-search",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query: searchTerm }),
        }
      );
      const result = await response.json();
      console.log("Search query saved successfully:", result.message);
    } catch (error) {
      console.error("Error saving search query:", error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    fetchSuggestions(value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.title);
    setShowSuggestions(false);
    saveSearchQuery(suggestion.title);
    navigate("/globalSearch");
  };

  const handleSearchIconClick = async () => {
    if (query.trim()) {
      await saveSearchQuery(query);
      navigate("/globalSearch");
    }
    setShowSuggestions(false);
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && query.trim()) {
      await saveSearchQuery(query);
      navigate("/globalSearch");
      setShowSuggestions(false);
    }
  };

  const startListening = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Speech recognition is not supported in this browser. Please try Chrome."
      );
      return;
    }

    const SpeechRecognition =
      window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;

    recognition.start();
    setIsListening(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setQuery(transcript);
      fetchSuggestions(transcript);
      setIsListening(false);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  return (
    <div className="container11">
      <div className="input-wrap-5">
        <input
          type="text"
          name="product-search"
          id="product-search-5"
          placeholder={placeholder} // Dynamic typing placeholder
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
        />
        <div className="searchicon-home" onClick={handleSearchIconClick}>
          <FaSearch
            className="serch-icon"
            color="white"
            fontSize="1.2em"
            opacity="1em"
            background="#008B8B"
          />
        </div>
        <div className="searchicon-home" onClick={startListening}>
          <FaMicrophone
            className={`mic-icon ${isListening ? "listening" : ""}`}
            color={isListening ? "red" : "White"}
            fontSize="1.5em"
          />
        </div>
        {showSuggestions && suggestions.length > 0 && (
          <ul className="suggestions-dropdown">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestion-item"
              >
                <img
                  src={
                    suggestion.image ||
                    (suggestion.type === "ebook"
                      ? "https://d3m4h509gttb94.cloudfront.net/Image/ebookImage.svg"
                      : suggestion.type === "book"
                      ? "https://d3m4h509gttb94.cloudfront.net/Image/booksImage.svg"
                      : suggestion.type === "video"
                      ? "https://d3m4h509gttb94.cloudfront.net/Image/video-course-Image.svg"
                      : "https://d3m4h509gttb94.cloudfront.net/Image/sampleSvgimage.svg")
                  }
                  alt={suggestion.title}
                  className="suggestion-image"
                />

                <div className="suggestion-content">
                  <span className="suggestion-title">{suggestion.title}</span>
                  <span className="suggestion-type">({suggestion.type})</span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default SearchBar;

// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import "./SearchBar.css";
// import { FaSearch, FaMicrophone } from "react-icons/fa";
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [isListening, setIsListening] = useState(false);
//   const navigate = useNavigate();

//   // Fetch suggestions from the updated API
//   const fetchSuggestions = async (query) => {
//     if (!query.trim()) {
//       setSuggestions([]);
//       return;
//     }
//     try {
//       const previousSearches = ["ssc", "rail"]; // Example of previously searched terms
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(
//           query
//         )}&previousSearches=${encodeURIComponent(previousSearches.join(","))}`
//       );
//       const data = await response.json();
//       setSuggestions(data);
//     } catch (error) {
//       console.error("Error fetching suggestions:", error);
//     }
//   };

//   // Save search query to the backend
//   const saveSearchQuery = async (searchTerm) => {
//     try {
//       const response = await fetch(
//         "https://auth.ssccglpinnacle.com/api/save-search",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ query: searchTerm }),
//         }
//       );
//       const result = await response.json();
//       console.log("Search query saved successfully:", result.message);
//     } catch (error) {
//       console.error("Error saving search query:", error);
//     }
//   };

//   // Handle input change
//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setQuery(value);
//     fetchSuggestions(value);
//     setShowSuggestions(true);
//   };

//   // Handle suggestion click
//   const handleSuggestionClick = (suggestion) => {
//     setQuery(suggestion.title); // Set the query to the selected suggestion's title
//     setShowSuggestions(false); // Hide suggestions dropdown
//     saveSearchQuery(suggestion.title); // Save search query to the backend
//     navigate("/globalSearch"); // Navigate to global search
//   };

//   // Handle search icon click
//   const handleSearchIconClick = async () => {
//     if (query.trim()) {
//       await saveSearchQuery(query); // Save search query to the backend
//       navigate("/globalSearch"); // Navigate to global search
//     }
//     setShowSuggestions(false); // Hide suggestions dropdown
//   };

//   // Handle key press (e.g., Enter key)
//   const handleKeyPress = async (e) => {
//     if (e.key === "Enter" && query.trim()) {
//       await saveSearchQuery(query); // Save search query to the backend
//       navigate("/globalSearch"); // Navigate to global search
//       setShowSuggestions(false); // Hide suggestions dropdown
//     }
//   };

//   // Speech-to-Text (Microphone functionality)
//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert(
//         "Speech recognition is not supported in this browser. Please try Chrome."
//       );
//       return;
//     }

//     const SpeechRecognition =
//       window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       fetchSuggestions(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = (event) => {
//       console.error("Speech recognition error:", event.error);
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder="Search for products..."
//           value={query}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyPress}
//           onFocus={() => setShowSuggestions(true)}
//           onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
//         />
//         <div className="searchicon-home" onClick={handleSearchIconClick}>
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//         <div className="searchicon-home" onClick={startListening}>
//           <FaMicrophone
//             className={`mic-icon ${isListening ? "listening" : ""}`}
//             color={isListening ? "red" : "White"}
//             fontSize="1.5em"
//           />
//         </div>
//         {showSuggestions && suggestions.length > 0 && (
//           <ul className="suggestions-dropdown">
//             {suggestions.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//                 className="suggestion-item"
//               >
//                 <img
//                   src={
//                     suggestion.image ||
//                     (suggestion.type === "ebook"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/ebookImage.svg"
//                       : suggestion.type === "book"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/booksImage.svg"
//                       : suggestion.type === "video"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/video-course-Image.svg"
//                       : "https://d3m4h509gttb94.cloudfront.net/Image/sampleSvgimage.svg")
//                   }
//                   alt={suggestion.title}
//                   className="suggestion-image"
//                 />

//                 <div className="suggestion-content">
//                   <span className="suggestion-title">{suggestion.title}</span>
//                   <span className="suggestion-type">({suggestion.type})</span>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchBar;




// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import "./SearchBar.css";
// import { FaSearch, FaMicrophone } from "react-icons/fa";
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [isListening, setIsListening] = useState(false);
//   const navigate = useNavigate();

//   // Fetch suggestions
//   const fetchSuggestions = async (query) => {
//     if (!query.trim()) {
//       setSuggestions([]);
//       return;
//     }
//     try {
//       const response = await fetch(
//         `http://localhost:5000/api/search-suggestions?query=${encodeURIComponent(
//           query
//         )}`
//       );
//       const data = await response.json();
//       setSuggestions(data);
//     } catch (error) {
//       console.error("Error fetching suggestions:", error);
//     }
//   };

//   // Save search query to the backend
//   const saveSearchQuery = async (searchTerm) => {
//     try {
//       const response = await fetch(
//         "https://auth.ssccglpinnacle.com/api/save-search",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ query: searchTerm }),
//         }
//       );
//       const result = await response.json();
//       console.log("Search query saved successfully:", result.message);
//     } catch (error) {
//       console.error("Error saving search query:", error);
//     }
//   };

//   // Handle input change
//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setQuery(value);
//     fetchSuggestions(value);
//     setShowSuggestions(true);
//   };

//   // Handle suggestion click
//   const handleSuggestionClick = (suggestion) => {
//     setQuery(suggestion.title); // Set the query to the selected suggestion's title
//     setShowSuggestions(false); // Hide suggestions dropdown
//     saveSearchQuery(suggestion.title); // Save search query to the backend
//     navigate("/globalSearch"); // Navigate to global search
//   };

//   // Handle search icon click
//   const handleSearchIconClick = async () => {
//     if (query.trim()) {
//       await saveSearchQuery(query); // Save search query to the backend
//       navigate("/globalSearch"); // Navigate to global search
//     }
//     setShowSuggestions(false); // Hide suggestions dropdown
//   };

//   // Handle key press (e.g., Enter key)
//   const handleKeyPress = async (e) => {
//     if (e.key === "Enter" && query.trim()) {
//       await saveSearchQuery(query); // Save search query to the backend
//       navigate("/globalSearch"); // Navigate to global search
//       setShowSuggestions(false); // Hide suggestions dropdown
//     }
//   };

//   // Speech-to-Text (Microphone functionality)
//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert(
//         "Speech recognition is not supported in this browser. Please try Chrome."
//       );
//       return;
//     }

//     const SpeechRecognition =
//       window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       fetchSuggestions(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = (event) => {
//       console.error("Speech recognition error:", event.error);
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder="Search for products..."
//           value={query}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyPress}
//           onFocus={() => setShowSuggestions(true)}
//           onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
//         />
//         <div className="searchicon-home" onClick={handleSearchIconClick}>
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//         <div className="searchicon-home" onClick={startListening}>
//           <FaMicrophone
//             className={`mic-icon ${isListening ? "listening" : ""}`}
//             color={isListening ? "red" : "White"}
//             fontSize="1.5em"
//           />
//         </div>
//         {showSuggestions && suggestions.length > 0 && (
//           <ul className="suggestions-dropdown">
//             {suggestions.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//                 className="suggestion-item"
//               >
//                 <img
//                   src={
//                     suggestion.image ||
//                     (suggestion.type === "ebook"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/ebookImage.svg"
//                       : suggestion.type === "book"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/booksImage.svg"
//                       : suggestion.type === "video"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/video-course-Image.svg"
//                       : "https://d3m4h509gttb94.cloudfront.net/Image/sampleSvgimage.svg")
//                   }
//                   alt={suggestion.title}
//                   className="suggestion-image"
//                 />

//                 <div className="suggestion-content">
//                   <span className="suggestion-title">{suggestion.title}</span>
//                   <span className="suggestion-type">({suggestion.type})</span>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchBar;

// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import "./SearchBar.css";
// import { FaSearch, FaMicrophone } from "react-icons/fa";
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [isListening, setIsListening] = useState(false);
//   const navigate = useNavigate();

//   // Fetch suggestions
//   const fetchSuggestions = async (query) => {
//     if (!query.trim()) {
//       setSuggestions([]);
//       return;
//     }
//     try {
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(query)}`
//       );
//       const data = await response.json();
//       setSuggestions(data);
//     } catch (error) {
//       console.error("Error fetching suggestions:", error);
//     }
//   };

//   // Save search query to the backend
//   const saveSearchQuery = async (searchTerm) => {
//     try {
//       const response = await fetch("https://auth.ssccglpinnacle.com/api/save-search", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ query: searchTerm }),
//       });
//       const result = await response.json();
//       console.log("Search query saved successfully:", result.message);
//     } catch (error) {
//       console.error("Error saving search query:", error);
//     }
//   };

//   // Handle input change
//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setQuery(value);
//     fetchSuggestions(value);
//     setShowSuggestions(true);
//   };

//   // Handle suggestion click
//   const handleSuggestionClick = (suggestion) => {
//     setQuery(suggestion.title); // Set the query to the selected suggestion's title
//     setShowSuggestions(false); // Hide suggestions dropdown
//     saveSearchQuery(suggestion.title); // Save search query to the backend
//     navigate("/globalSearch"); // Navigate to global search
//   };

//   // Handle search icon click
//   const handleSearchIconClick = async () => {
//     if (query.trim()) {
//       await saveSearchQuery(query); // Save search query to the backend
//       navigate("/globalSearch"); // Navigate to global search
//     }
//     setShowSuggestions(false); // Hide suggestions dropdown
//   };

//   // Handle key press (e.g., Enter key)
//   const handleKeyPress = async (e) => {
//     if (e.key === "Enter" && query.trim()) {
//       await saveSearchQuery(query); // Save search query to the backend
//       navigate("/globalSearch"); // Navigate to global search
//       setShowSuggestions(false); // Hide suggestions dropdown
//     }
//   };

//   // Speech-to-Text (Microphone functionality)
//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript; // Get transcribed text
//       setQuery(transcript);
//       fetchSuggestions(transcript); // Fetch suggestions for spoken query
//       setIsListening(false);
//     };

//     recognition.onerror = (event) => {
//       console.error("Speech recognition error:", event.error);
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder="Search for products..."
//           value={query}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyPress}
//           onFocus={() => setShowSuggestions(true)}
//           onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
//         />
//         <div className="searchicon-home" onClick={handleSearchIconClick}>
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//         <div className="searchicon-home" onClick={startListening}>
//           <FaMicrophone
//             className={`mic-icon ${isListening ? "listening" : ""}`}
//             color={isListening ? "red" : "White"}
//             fontSize="1.5em"
//           />
//         </div>
//         {showSuggestions && suggestions.length > 0 && (
//           <ul className="suggestions-dropdown">
//             {suggestions.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//                 className="suggestion-item"
//               >
//                 {suggestion.title} <span className="suggestion-type">({suggestion.type})</span>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchBar;

// import React, { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "./SearchBar.css";
// import { FaSearch, FaMicrophone } from "react-icons/fa"; // Add microphone icon
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [isListening, setIsListening] = useState(false); // State for speech recognition
//   const navigate = useNavigate();

//   const fetchSuggestions = async (query) => {
//     if (!query.trim()) {
//       setSuggestions([]);
//       return;
//     }
//     try {
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(query)}`
//       );
//       const data = await response.json();
//       setSuggestions(data);
//     } catch (error) {
//       console.error("Error fetching suggestions:", error);
//     }
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setQuery(value);
//     fetchSuggestions(value);
//     setShowSuggestions(true);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       navigate("/globalSearch");
//       setShowSuggestions(false);
//     }
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setQuery(suggestion.title);
//     setShowSuggestions(false);
//     navigate("/globalSearch");
//   };

//   const handleSearchIconClick = () => {
//     navigate("/globalSearch");
//     setShowSuggestions(false);
//   };

//   // Speech-to-Text
//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert("Speech recognition is not supported in this browser. Please try Chrome.");
//       return;
//     }

//     const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US"; // Set language
//     recognition.interimResults = false; // Get only final results

//     recognition.start(); // Start recognition
//     setIsListening(true); // Update state

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript; // Get transcribed text
//       setQuery(transcript); // Update query
//       fetchSuggestions(transcript); // Fetch suggestions for spoken query
//       setIsListening(false); // Stop listening
//     };

//     recognition.onerror = (event) => {
//       console.error("Speech recognition error:", event.error);
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder="Search for products..."
//           value={query}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyPress}
//           onFocus={() => setShowSuggestions(true)}
//           onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
//         />
//         <div className="searchicon-home" onClick={handleSearchIconClick}>
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//         <div className="searchicon-home" onClick={startListening}>
//           <FaMicrophone
//             className={`mic-icon ${isListening ? "listening" : ""}`}
//             color={isListening ? "red" : "White"}
//             fontSize="1.5em"
//           />
//         </div>
//         {showSuggestions && suggestions.length > 0 && (
//           <ul className="suggestions-dropdown">
//             {suggestions.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//                 className="suggestion-item"
//               >
//                 {suggestion.title} <span className="suggestion-type">({suggestion.type})</span>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchBar;

// import React, { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "./SearchBar.css";
// import { FaSearch } from "react-icons/fa";
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);
//   const [suggestions, setSuggestions] = useState([]); // State for suggestions
//   const [showSuggestions, setShowSuggestions] = useState(false); // Toggle suggestions dropdown
//   const navigate = useNavigate();

//   // Fetch suggestions
//   const fetchSuggestions = async (query) => {
//     if (!query.trim()) {
//       setSuggestions([]);
//       return;
//     }
//     try {
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(query)}`
//       );
//       const data = await response.json();
//       setSuggestions(data);
//     } catch (error) {
//       console.error("Error fetching suggestions:", error);
//     }
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setQuery(value); // Update global query
//     fetchSuggestions(value); // Fetch suggestions
//     setShowSuggestions(true); // Show dropdown
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       navigate("/globalSearch");
//       setShowSuggestions(false); // Hide dropdown on search
//     }
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setQuery(suggestion.title); // Set clicked suggestion as the query
//     setShowSuggestions(false); // Hide dropdown
//     navigate("/globalSearch"); // Trigger search
//   };

//   const handleSearchIconClick = () => {
//     navigate("/globalSearch");
//     setShowSuggestions(false); // Hide dropdown
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder="Search for products..."
//           value={query}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyPress}
//           onFocus={() => setShowSuggestions(true)} // Show dropdown when focused
//           onBlur={() => setTimeout(() => setShowSuggestions(false), 200)} // Delay hiding for click handling
//         />
//         <div className="searchicon-home" onClick={handleSearchIconClick}>
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//         {showSuggestions && suggestions.length > 0 && (
//           <ul className="suggestions-dropdown">
//             {suggestions.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//                 className="suggestion-item"
//               >
//                 {suggestion.title} <span className="suggestion-type">({suggestion.type})</span>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchBar;

// import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
// import "./SearchBar.css";
// import { FaSearch } from "react-icons/fa";
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);
//   const navigate = useNavigate(); // Initialize useNavigate

//   const handleInputChange = (e) => {
//     setQuery(e.target.value); // Update global query
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       navigate("/globalSearch");
//     }
//   };

//   const handleSearchIconClick = () => {
//     navigate("/globalSearch"); // Navigate when search icon is clicked
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder="Search for products..."
//           value={query}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyPress} // Trigger navigation on Enter key
//         />
//         <div className="searchicon-home" onClick={handleSearchIconClick}>
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SearchBar;

// import React, { useContext } from "react";
// import "./SearchBar.css";
// import { FaSearch } from "react-icons/fa";
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);

//   const handleInputChange = (e) => {
//     setQuery(e.target.value); // Update global query
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder="Search for products..."
//           value={query}
//           onChange={handleInputChange}
//         />
//         <div className="searchicon-home">
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SearchBar;

// import React from 'react'
// import "./SearchBar.css";
// import { FaSearch } from "react-icons/fa";

// function SearchBar() {

//     return (
//         <div className='container11'>
//             <div className='input-wrap-5'>

//                <input
//                     type="text"
//                     name="product-search"
//                     id="product-search-5"
//                     placeholder="Search for products..."
//                 />
//                 <div className='searchicon-home'>
//                  < FaSearch className = "serch-icon" color="white" fontSize="1.2em" opacity="1em" background="#008B8B" /> </div> {/* <i
//                     className="fas fa-times"
//                 ></i> */}
//             </div>
//         </div>
//     );
// }

// export default SearchBar;

import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import Footer from "../component/HomePage01/Footer/Footer";
import LoadingSpinner from "../LoadingSpinner";
import classes from "./TypingMyOrders.module.css";
import axios from "axios";

function TypingMyOrders() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [cookies] = useCookies(["token"]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [productData, setProductData] = useState([]); // Initialize as an empty array

  const checkAccessTypingProduct = async () => {
    try {
      const response = await axios.post(
        "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
        { product_id: "999" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );

      if (response.data.access === "access") {
        // Assuming response.data contains an array of products under 'purchasedProducts'
        setProductData(response.data);
      } else {
        setMessage("You do not have access to this product.");
      }
    } catch (error) {
      console.error("Error checking product access:", error);
      setMessage("An error occurred while checking access.");
    } finally {
      setLoading(false);
    }
  };

  checkAccessTypingProduct();

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleOrderClick = (access) => {
    if (access === "access") {
      navigate("/typingexamselection");
    }
  };

  return (
    <>
      <Header />
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.heading_title}>
            <h1 className={classes.title}>Purchased Typing Software</h1>
          </div>
          <div className={classes.button_div}>
            <button className={classes.button_link}>
              <Link
                to="https://testportal.ssccglpinnacle.com/myorders"
                target="_blank"
                rel="noopener noreferrer"
              >
                Purchased Test Series
              </Link>
            </button>
            <button className={classes.button_link}>
              <Link
                to="https://ssccglpinnacle.com/mylearning"
                target="_blank"
                rel="noopener noreferrer"
              >
                Purchased E-Books
              </Link>
            </button>
            <button className={classes.button_link}>
              <Link
                to="https://ssccglpinnacle.com/videos/myorders"
                target="_blank"
                rel="noopener noreferrer"
              >
                Purchased Video Course
              </Link>
            </button>
            <button className={classes.button_books}>
              <Link
                to="https://ssccglpinnacle.com/purchase-book"
                target="_blank"
                rel="noopener noreferrer"
              >
                Purchased Books
              </Link>
            </button>
          </div>
        </div>
        {message && <p className={classes.message}>{message}</p>}
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Product Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{"999"}</td>
              <td
                onClick={() => handleOrderClick(productData.access)}
                style={{ cursor: "pointer", color: "blue" }}
              >
                {"Typing Software"}
              </td>
              <td>{productData.access}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
}

export default TypingMyOrders;

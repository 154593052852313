import React, { useState, useEffect } from "react";
import "./BuyNow.css";
import { GrClose } from "react-icons/gr";
import { useNavigate, useLocation } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { useAuth } from "../../Context/AuthContext";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";

function BuyNow({ onClose, onAddToCart }) {
  const location = useLocation();
  const [cookies] = useCookies(["email_id", "token"]);
  const queryParams = new URLSearchParams(location.search);
  const cardId = queryParams.get("cardId");
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedDuration, setSelectedDuration] = useState("12 Months");
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const [selectedCardData, setselectedCardData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch the ebook data
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }
        const ebookData = await ebookResponse.json();

        // Find the ebook that matches the cardId
        const selectedEbook = ebookData.find((item) => item._id === cardId);
        setselectedCardData(selectedEbook ? [selectedEbook] : []);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [cardId]);

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  const calculateDiscount = (sellingPrice, printingPrice) => {
    const savingAmount = printingPrice - sellingPrice;
    const discountPercentage = (savingAmount / printingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  const twelveMonthSubscription =
    selectedCardData.length > 0
      ? selectedCardData[0].subscriptionPrices.find(
          (sub) => sub.duration === "12 Months"
        )
      : { printingPrice: 0, sellingPrice: 0 };

  const { savingAmount, discountPercentage } = calculateDiscount(
    twelveMonthSubscription.sellingPrice || 0,
    twelveMonthSubscription.printingPrice || 0
  );

  const currentOriginalPrice = twelveMonthSubscription.printingPrice;
  const currentDiscountedPrice = twelveMonthSubscription.sellingPrice;

  const handleAddToCart = async (ebookId) => {
    if (!isLoggedIn || !user || !user._id) {
      Swal.fire({
        icon: "info", // You can change this to 'info', 'success', 'warning', etc.
        title: "Note",
        text: "Please log in to add items to your cart.",
      });
      return;
    }

    setIsAddingToCart(true);

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
          body: JSON.stringify({ ebookId, fullName: user.email_id }),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        navigate("/ebooks/cart");
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          navigate("/ebooks/cart");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to add item to cart. Error: ${errorMessage.error}`,
          });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleCloseButtonClick = async () => {
    onClose();
  };

  if (!isLoggedIn || !user) {
    return <p>User not logged in</p>;
  }

  return (
    <div className="modal-overlay-buy">
      <div className="modal-buy">
        <div className="modal-header-buy">
          <div className="free-trail-over">
            "Your free trial has ended. Purchase a subscription to continue
            access."
          </div>
          <button className="close-button-buy" onClick={handleCloseButtonClick}>
            <GrClose />
          </button>
        </div>
        <div className="modal-content-buy-container">
          <div className="buy-modal-1">
            <img
              className="card-title-buy"
              src={selectedCardData.length > 0 ? selectedCardData[0].image : ""}
              alt="Product"
            />
          </div>
          <div className="buy-modal-2">
            <div className="product-title">
              <p>{selectedCardData.length > 0 && selectedCardData[0].title}</p>
            </div>
            <div className="by-pinnacle">
              <p>by pinnacle</p>
            </div>
            <div className="for-buy-price">
              <span className="sale" data-badge-sales="">
                <span data-badge-sales-range="">Sale</span>
              </span>
              <div className="price-details">
                <p className="original-price">₹{currentOriginalPrice}</p>
                <p className="discounted-price">₹{currentDiscountedPrice} </p>
              </div>
            </div>
            <div className="for-duration-buy-modal">
              <select
                id="selectField"
                value={selectedDuration}
                onChange={handleDurationChange}
              >
                <option value="12 Months">12 Months</option>
              </select>
              <div
                className="add-to-cart-buy-modal"
                onClick={() => handleAddToCart(cardId)}
              >
                Add to cart
              </div>
            </div>
            <div className="buy-it-now-from-buy-model">
              <button id="buyButton" onClick={() => handleAddToCart(cardId)}>
                Buy It Now
              </button>
            </div>
            <div className="courses-available-from-buy-model">
              <p>Course also available in: </p>
            </div>
            <div className="course-available-from-buy-model">
              <p className="buy-model-SSC">CHSL</p>
              <p className="buy-model-SSC">CPO</p>
              <p className="buy-model-SSC">MTS</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyNow;

// // ******************* JODIT TEXT EDITOR *****************
// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import "./BlogForm.css";
// // import ReviewForm from "../../BlogProject/BlogContainer/ReviewForm";
// // import RichTextEditor from "../../BlogProject/BlogContainer/RichTextEditor";
// import JoditRichTextEditor from "../../BlogProject/BlogContainer/JoditRichTextEditor";
// import axios from "axios";
// import { useCookies } from "react-cookie";
// const BlogForm = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const blogData = location.state?.blogData || {};
//   const [cookies, setCookie, removeCookie] = useCookies(["myadmin"]);
//   console.log("cookies",cookies)

//   const email_id = cookies.email_id_admin;  // Replace with dynamic email if needed
// console.log("email",email_id)

//   const [author, setAuthor] = useState({
//     name: blogData.author?.name || "Baljit Dhaka",
//     details: blogData.author?.details || "Pinnacle Civil Services",
//   });

//   const [category, setCategory] = useState(blogData.category || "");
//   const [heading, setHeading] = useState(blogData.heading || "");
//   const [content, setContent] = useState(blogData.content || "");
//   const [userId, setUserId ] = useState(blogData.userId || "");

//   console.log("CHAT GPT Conten", content);
  
//   const [seo, setSeo] = useState({
//     keywords: blogData.seo?.keywords || "",
//     description: blogData.seo?.description || "",
//     title: blogData.seo?.title || "",
//   });
//   const [permalink, setPermalink] = useState(blogData.permalink || "");
//   const [image, setImage] = useState(null);
//   const [imagePreviews, setImagePreviews] = useState([]);
  

//     // Auto-fetch draft data on component mount
//     // useEffect(() => {
//     //   const fetchDraft = async () => {
//     //     try {
//     //       const response = await axios.get(`http://localhost:5000/api/draft/${email_id}`);
//     //       console.log("response:",response);
//     //       if (response.data && Object.keys(response.data).length > 0) {
//     //         const draft = response.data;
//     //         setAuthor(draft.author || { name: "", details: "" });
//     //         setCategory(draft.category || "");
//     //         setHeading(draft.heading || "");
//     //         setContent(draft.content || "");
//     //         setSeo(draft.seo || { keywords: "", description: "", title: "" });
//     //         setPermalink(draft.permalink || "");
//     //       }
//     //     } catch (error) {
//     //       console.error("Draft fetch karne me error:", error);
//     //     }
//     //   };
//     //   if (email_id) {
//     //     fetchDraft();
//     //   }
//     // }, [email_id]);

//     // useEffect(() => {
//     //   const fetchDraft = async () => {
//     //     try {
//     //       const response = await axios.get(`http://localhost:5000/api/draft/${email_id}`);
//     //       const data = response.data
//     //                 console.log("Draft Response:", data);
    
//     //       if (response.data && Object.keys(response.data).length > 0) {
//     //         const draft = response.data;
    
           
//     //         setAuthor((prevAuthor) =>
//     //           prevAuthor.name === "" && prevAuthor.details === ""
//     //             ? draft.author || { name: "", details: "" }
//     //             : prevAuthor
//     //         );
    
//     //         setCategory((prevCategory) =>
//     //           prevCategory === "" ? draft.category || "" : prevCategory
//     //         );
    
//     //         setContent((prevContent) =>
//     //           prevContent === "" ? draft.content || "" : prevContent
//     //         );
    
//     //         setSeo((prevSeo) =>
//     //           prevSeo.keywords === "" && prevSeo.description === "" && prevSeo.title === ""
//     //             ? draft.seo || { keywords: "", description: "", title: "" }
//     //             : prevSeo
//     //         );
    
//     //         setPermalink((prevPermalink) =>
//     //           prevPermalink === "" ? draft.permalink || "" : prevPermalink
//     //         );
//     //       }
//     //     } catch (error) {
//     //       console.error("Draft fetch karne mein error:", error);
//     //     }
//     //   };
    
//     //   if (email_id) {
//     //     fetchDraft();
//     //   }
//     // }, [email_id]);
    
//     useEffect(() => {
//       const fetchDraft = async () => {
//         try {
//           const response = await axios.get(`http://localhost:5000/api/draft/${email_id}`);
//           const draft = response.data;
    
//           if (draft) {
//             console.log("📂 Restoring Draft Data:", draft);
    
//             setAuthor((prevAuthor) =>
//                         prevAuthor.name === "" && prevAuthor.details === ""
//                           ? draft.author || { name: "", details: "" }
//                           : prevAuthor
//                       );
//             setCategory(draft.category || "");
//             setHeading(draft.heading || "");
//             setContent(draft.content || "");
//                    // ✅ Properly updating SEO fields
//                   //  setSeo((prevSeo) =>
//                   //   prevSeo.keywords === "" && prevSeo.title === "" && prevSeo.description === ""
//                   //     ? draft.seo || { keywords: "", title: "" , description: ""}
//                   //     : prevSeo
//                   // );
//                   setSeo({
//                     keywords: draft.seo?.keywords || "",
//                     description: draft.seo?.description || "",
//                     title: draft.seo?.title || "",
//                   });
//         // setSeo((prevSeo) => ({
//         //   ...prevSeo,
//         //   keywords: draft.seo?.keywords || "",
//         //   description: draft.seo?.description || "",
//         //   title: draft.seo?.title || "",
//         // }));

//             // setSeo(draft.seo || { keywords: "", description: "", title: "" });
//             setPermalink(draft.permalink || "");
//           }
//         } catch (error) {
//           console.error("❌ Error fetching draft data:", error);
//         }
//       };
    
//       if (email_id) {
//         fetchDraft();
//       }
//     }, [email_id]);
    
    

//         // Auto-save draft on form change
//         // const saveDraft = async () => {
//         //   try {
//         //     const draftData = {
//         //       email_id,
//         //       author,
//         //       category,
//         //       content,
//         //       heading,
//         //       seo: {
//         //         keywords: seo.keywords,
//         //         description: seo.description,
//         //         title: seo.title,       
//         //       },
//         //       // permalink,
//         //       permalink: generateSlug(heading), 
//         //     };
      
//         //     await axios.post("http://localhost:5000/api/draft", draftData);
//         //   } catch (error) {
//         //     console.error("Draft save karne me error:", error);
//         //   }
//         // };


//         // const saveDraft = async (updatedData = {}) => {
//         //   try {
//         //     // Draft data ko merge karke bhejna
//         //     const draftData = {
//         //       email_id,
//         //       author,
//         //       category,
//         //       content,
//         //       heading,
//         //       seo,
//         //       permalink,
//         //       ...updatedData,  // ✅ Updated data merge ho raha hai
//         //     };
        
//         //     console.log("📦 Auto-Saving Draft Data:", draftData);
        
//         //     await axios.post("http://localhost:5000/api/draft", draftData);
        
//         //   } catch (error) {
//         //     console.error("❌ Error saving draft:", error);
//         //   }
//         // };

//         // const saveDraft = async (updatedData = {}) => {
//         //   try {
//         //     const draftData = {
//         //       email_id,
//         //       author,
//         //       category,
//         //       content,
//         //       heading,
//         //       seo: {
//         //         keywords: seo.keywords || "",
//         //         description: seo.description || "",
//         //         title: seo.title || "",
//         //       },
//         //       permalink,
//         //       ...updatedData,  // Merge any updated data
//         //     };
        
//         //     console.log("📦 Auto-Saving Draft Data:", draftData);
        
//         //     await axios.post("http://localhost:5000/api/draft", draftData);
        
//         //   } catch (error) {
//         //     console.error("❌ Error saving draft:", error);
//         //   }
//         // };
        

//         const saveDraft = async (updatedData = {}) => {
//           try {
//             const draftData = {
//               email_id,
//               author,
//               category,
//               heading,
//               content,
//               seo: {
//                 keywords: seo.keywords || "",
//                 description: seo.description || "",
//                 title: seo.title || "",
//               },
//               permalink,
//               ...updatedData,  // Merge any updated data
//             };
        
//             console.log("📦 Auto-Saving Draft Data:", draftData);
        
//             await axios.post("http://localhost:5000/api/draft", draftData);
        
//           } catch (error) {
//             console.error("❌ Error saving draft:", error);
//           }
//         };
        
        
//   const generateSlug = (text) => {
//     return text
//       .toLowerCase()
//       .trim()
//       .replace(/[^a-z0-9\s-]/g, "")
//       .replace(/\s+/g, "-")
//       .replace(/-+$/, ""); 
      
//   };

//   // const handleHeadingChange = async (value) => {
//   //   setHeading(value);
//   //   setSeo((prevSeo) => ({
//   //     ...prevSeo,
//   //     keywords: value,
//   //     description: value,
//   //     title: value,
//   //   }));
//   //   setPermalink(generateSlug(value));
//   //   saveDraft(); 

//   //   if (!value.trim()) {
//   //     setContent("");
//   //     return;
//   //   }

//   //   try {
//   //     const response = await fetch("http://localhost:5000/api/chat", {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //       body: JSON.stringify({ userMessage: value }),
//   //     });

//   //     if (response.ok) {
//   //       const data = await response.json();
//   //       setContent(data.reply);
//   //     } else {
//   //       const errorData = await response.json();
//   //       console.error("Error generating content:", errorData);
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching content:", error);
//   //   }
//   // };

//   // const handleImageUpload = (e) => {
//   //   const file = e.target.files[0];
//   //   setImage(file);
//   //   const reader = new FileReader();
//   //   reader.onloadend = () => {
//   //     setImagePreviews([...imagePreviews, reader.result]);
//   //   };
//   //   reader.readAsDataURL(file);
//   // };
//   const handleHeadingChange = async (value) => {
//     setHeading(value);  // Only update the heading
  
//     // Update the permalink based on the heading
//     setPermalink(generateSlug(value));
  
//     // Save draft with updated heading and permalink
//     saveDraft({
//       heading: value,
//       permalink: generateSlug(value),
//     });
  
//     // If the heading is empty, clear the content
//     if (!value.trim()) {
//       setContent("");
//       return;
//     }
  
//     // Optional: Fetch content using API if needed
//     try {
//       const response = await fetch("http://localhost:5000/api/chat", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ userMessage: value }),
//       });
  
//       if (response.ok) {
//         const data = await response.json();
//         setContent(data.reply);
//         console.log("POST DATA:",data);
//       } else {
//         const errorData = await response.json();
//         console.error("Error generating content:", errorData);
//       }
//     } catch (error) {
//       console.error("Error fetching content:", error);
//     }
//   };
  
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const method = blogData._id ? "PUT" : "POST";
//       const url = blogData._id
//         ? `https://auth.ssccglpinnacle.com/api/blogs/${blogData._id}`
//         : "https://auth.ssccglpinnacle.com/api/blogs";

//       const formData = new FormData();
//       formData.append("authorName", author.name);
//       formData.append("authorDetails", author.details);
//       formData.append("category", category);
//       formData.append("heading", heading);
//       formData.append("content", content);
//       formData.append("seoKeywords", seo.keywords);
//       formData.append("seoDescription", seo.description);
//       formData.append("seoTitle", seo.title);
//       formData.append("permalink", generateSlug(heading));

//       if (image) {
//         formData.append("image", image);
//       }

//       const response = await fetch(url, {
//         method,
//         body: formData,
//       });

//       if (response.ok) {
//         Swal.fire(
//           "Success",
//           blogData._id
//             ? "Blog updated successfully!"
//             : "Blog created successfully!",
//           "success"
//         );
//         // navigate("/blogs");
//       } else {
//         const errorData = await response.json();
//         console.error("Error details:", errorData);
//         Swal.fire(
//           "Error",
//           `There was an error: ${errorData.message || "Unknown error occurred"}`,
//           "error"
//         );
//       }
//     } catch (error) {
//       console.error("Fetch error:", error);
//       Swal.fire(
//         "Error",
//         `There was an error: ${error.message || "Unknown error occurred"}`,
//         "error"
//       );
//     }
//   };

//   return (
//     <>
//       <h1 className="add-ebook-form">
//         {blogData._id ? "Edit Blog" : "Create Blog"}
//       </h1>
//       <form className="unique-blog-form" onSubmit={handleSubmit}>
//         <div className="unique-form-group">
//           <label className="unique-form-label">Author Name:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={author.name}
//             onChange={(e) => setAuthor({ ...author, name: e.target.value })}
//           />
//         </div>
//         <div className="unique-form-group">
//           <label className="unique-form-label">Author Details:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={author.details}
//             onChange={(e) => setAuthor({ ...author, details: e.target.value })}
//           />
//         </div>
//         <div className="unique-form-group">
//           <label className="unique-form-label">Category:</label>
//           <select
//             className="unique-form-input"
//             value={category}
//             onChange={(e) => setCategory(e.target.value)}
//             required
//           >
//             <option value="">Select a category</option>
//             <option value="SSC">SSC</option>
//             <option value="RRB NTPC">RRB NTPC</option>
//             <option value="Daily current affairs">Daily current affairs</option>
//             <option value="Topic wise current affairs">Topic wise current affairs</option>
//             <option value="Last one year current affairs">Last one year current affairs</option>
//             <option value="Monthly current affairs">Monthly current affairs</option>
//             <option value="Up police">Up police</option>
//             <option value="HSSC">HSSC</option>
//           </select>
//         </div>
//         <div className="unique-form-group">
//           <label className="unique-form-label">Heading:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={heading}
//             onChange={(e) => handleHeadingChange(e.target.value)}
//             required
//           />
//         </div>
//         <div className="unique-form-group">
//           <label className="unique-form-label">SEO Keywords:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={seo.keywords}
//             onChange={(e) => setSeo({ ...seo, keywords: e.target.value })}
//             required
//           />
//         </div>
//         <div className="unique-form-group">
//           <label className="unique-form-label">SEO Description:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={seo.description}
//             onChange={(e) => setSeo({ ...seo, description: e.target.value })}
//             required
//           />
//         </div>
//         <div className="unique-form-group">
//           <label className="unique-form-label">SEO Title:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={seo.title}
//             onChange={(e) => setSeo({ ...seo, title: e.target.value })}
//             required
//           />
//         </div>
//         <div className="unique-form-group">
//           <label className="unique-form-label">Permalink:</label>
//           <input
//             className="unique-form-input"
//             type="text"
//             value={permalink}
//             onChange={(e) => setPermalink(e.target.value)}
//             required
//           />
//         </div>
//         {/* <div className="unique-form-group">
//           <label className="unique-form-label">Upload a Post Image:</label>
//           <input
//             className="unique-form-input"
//             type="file"
//             accept="image/*"
//             onChange={handleImageUpload}
//           />
//         </div> */}
//        <div className="unique-form-group">
//           <label className="unique-form-label">Content:</label>
//           <JoditRichTextEditor
//             content={content}
//             onContentChange={setContent}
//             // onImageUpload={handleImageUpload}
//             imagePreviews={imagePreviews}
//             // userId={userId}
//             // email_id={email_id}
//           />
//         </div>
//         <div className="create-blog">
//           <button className="unique-form-button" type="submit">
//             {blogData._id ? "Update Blog" : "Create Blog"}
//           </button>
//         </div>        
//       </form>
//     </>
//   );
// };

// export default BlogForm;

// ******************* JODIT TEXT EDITOR *****************
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./BlogForm.css";
import JoditRichTextEditor from "../../BlogProject/BlogContainer/JoditRichTextEditor";
import axios from "axios";
import { useCookies } from "react-cookie";

const BlogForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blogData = location.state?.blogData || {};
  const [cookies, setCookie, removeCookie] = useCookies(["myadmin"]);
  console.log("cookies", cookies);

  const email_id = cookies.email_id_admin;
  console.log("email", email_id);

  const [author, setAuthor] = useState({
    name: blogData.author?.name || "Baljit Dhaka",
    details: blogData.author?.details || "Pinnacle Civil Services",
  });

  const [category, setCategory] = useState(blogData.category || "");
  const [heading, setHeading] = useState(blogData.heading || "");
  const [content, setContent] = useState(blogData.content || "");
  const [userId, setUserId] = useState(blogData.userId || "");

  console.log("CHAT GPT Content", content);

  const [seo, setSeo] = useState({
    keywords: blogData.seo?.keywords || "",
    description: blogData.seo?.description || "",
    title: blogData.seo?.title || "",
  });
  const [permalink, setPermalink] = useState(blogData.permalink || "");
  const [image, setImage] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    const fetchDraft = async () => {
      try {
        const response = await axios.get(`https://auth.ssccglpinnacle.com/api/draft/${email_id}`);
        const draft = response.data;

        if (draft) {
          console.log("📂 Restoring Draft Data:", draft);

          setAuthor((prevAuthor) =>
            prevAuthor.name === "" && prevAuthor.details === ""
              ? draft.author || { name: "", details: "" }
              : prevAuthor
          );
          setCategory(draft.category || "");
          setHeading(draft.heading || "");
          setContent(draft.content || "");
          setSeo({
            keywords: draft.seo?.keywords || "",
            description: draft.seo?.description || "",
            title: draft.seo?.title || "",
          });
          setPermalink(draft.permalink || "");
        }
      } catch (error) {
        console.error("❌ Error fetching draft data:", error);
      }
    };

    if (email_id) {
      fetchDraft();
    }
  }, [email_id]);

  const saveDraft = async (updatedData = {}) => {
    try {
      const draftData = {
        email_id,
        author,
        category,
        heading,
        content,
        seo: {
          keywords: seo.keywords || "",
          description: seo.description || "",
          title: seo.title || "",
        },
        permalink,
        ...updatedData,
      };

      console.log("📦 Auto-Saving Draft Data:", draftData);
      await axios.post("https://auth.ssccglpinnacle.com/api/draft", draftData);
    } catch (error) {
      console.error("❌ Error saving draft:", error);
    }
  };

  const generateSlug = (text) => {
    return text.toLowerCase().trim().replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, "-").replace(/-+$/, "");
  };

  const handleHeadingChange = async (value) => {
    setHeading(value);
    setPermalink(generateSlug(value));
    saveDraft({ heading: value, permalink: generateSlug(value) });

    if (!value.trim()) {
      setContent("");
      return;
    }

    try {
      const response = await fetch("https://auth.ssccglpinnacle.com/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userMessage: value }),
      });

      if (response.ok) {
        const data = await response.json();
        setContent(data.reply);
        console.log("POST DATA:", data);
      } else {
        const errorData = await response.json();
        console.error("Error generating content:", errorData);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  //   const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const method = blogData._id ? "PUT" : "POST";
  //     const url = blogData._id
  //       ? `https://auth.ssccglpinnacle.com/api/blogs/${blogData._id}`
  //       : "https://auth.ssccglpinnacle.com/api/blogs";

  //     const formData = new FormData();
  //     formData.append("authorName", author.name);
  //     formData.append("authorDetails", author.details);
  //     formData.append("category", category);
  //     formData.append("heading", heading);
  //     formData.append("content", content);
  //     formData.append("seoKeywords", seo.keywords);
  //     formData.append("seoDescription", seo.description);
  //     formData.append("seoTitle", seo.title);
  //     formData.append("permalink", generateSlug(heading));

  //     if (image) {
  //       formData.append("image", image);
  //     }

  //     const response = await fetch(url, {
  //       method,
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       Swal.fire(
  //         "Success",
  //         blogData._id
  //           ? "Blog updated successfully!"
  //           : "Blog created successfully!",
  //         "success"
  //       );
  //       // navigate("/blogs");
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Error details:", errorData);
  //       Swal.fire(
  //         "Error",
  //         `There was an error: ${errorData.message || "Unknown error occurred"}`,
  //         "error"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //     Swal.fire(
  //       "Error",
  //       `There was an error: ${error.message || "Unknown error occurred"}`,
  //       "error"
  //     );
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
      formData.append("authorName", author.name);
      formData.append("authorDetails", author.details);
      formData.append("category", category);
      formData.append("heading", heading);
      formData.append("content", content);
      formData.append("seoKeywords", seo.keywords);
      formData.append("seoDescription", seo.description);
      formData.append("seoTitle", seo.title);
      formData.append("permalink", permalink);
  
      if (image) {
        formData.append("image", image);
      }
  
      const response = await axios.post("https://auth.ssccglpinnacle.com/api/blogs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 200 || response.status === 201) {
        Swal.fire("Success", "Blog created successfully!", "success");
      } else {
        Swal.fire("Error", "Failed to create the blog.", "error");
      }
    } catch (error) {
      console.error("Error submitting blog:", error.response?.data || error.message);
      Swal.fire("Error", `Failed to create the blog: ${error.response?.data?.message || error.message}`, "error");
    }
  };
  
  return (
  
   <>
      <h1 className="add-ebook-form">
         {blogData._id ? "Edit Blog" : "Create Blog"}
       </h1>
       <form className="unique-blog-form" onSubmit={handleSubmit}>
         <div className="unique-form-group">
           <label className="unique-form-label">Author Name:</label>          <input
            className="unique-form-input"
            type="text"
            value={author.name}
            onChange={(e) => setAuthor({ ...author, name: e.target.value })}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Author Details:</label>
          <input
            className="unique-form-input"
            type="text"
            value={author.details}
            onChange={(e) => setAuthor({ ...author, details: e.target.value })}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Category:</label>
          <select
            className="unique-form-input"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Select a category</option>
            <option value="SSC">SSC</option>
            <option value="RRB NTPC">RRB NTPC</option>
            <option value="Daily current affairs">Daily current affairs</option>
            <option value="Topic wise current affairs">Topic wise current affairs</option>
            <option value="Last one year current affairs">Last one year current affairs</option>
            <option value="Monthly current affairs">Monthly current affairs</option>
            <option value="Up police">Up police</option>
            <option value="HSSC">HSSC</option>
          </select>
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Heading:</label>
          <input
            className="unique-form-input"
            type="text"
            value={heading}
            onChange={(e) => handleHeadingChange(e.target.value)}
            required
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Keywords:</label>
          <input
            className="unique-form-input"
            type="text"
            value={seo.keywords}
            onChange={(e) => setSeo({ ...seo, keywords: e.target.value })}
            required
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Description:</label>
          <input
            className="unique-form-input"
            type="text"
            value={seo.description}
            onChange={(e) => setSeo({ ...seo, description: e.target.value })}
            required
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Title:</label>
          <input
            className="unique-form-input"
            type="text"
            value={seo.title}
            onChange={(e) => setSeo({ ...seo, title: e.target.value })}
            required
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Permalink:</label>
          <input
            className="unique-form-input"
            type="text"
            value={permalink}
            onChange={(e) => setPermalink(e.target.value)}
            required
          />
        </div>
        {/* <div className="unique-form-group">
          <label className="unique-form-label">Upload a Post Image:</label>
          <input
            className="unique-form-input"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div> */}
       <div className="unique-form-group">
          <label className="unique-form-label">Content:</label>
          <JoditRichTextEditor
            content={content}
            onContentChange={setContent}
            // onImageUpload={handleImageUpload}
            imagePreviews={imagePreviews}
            // userId={userId}
            // email_id={email_id}
          />
        </div>
        <div className="create-blog">
          <button className="unique-form-button" type="submit">
            {blogData._id ? "Update Blog" : "Create Blog"}
          </button>
        </div>        
      </form>
    </>
  );
};

export default BlogForm;

import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./CourseStatusChecker.module.css";

const CourseStatusChecker = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    setLoading(true);
    setError(null);
    try {
      const coursesResponse = await axios.get(
        "https://auth.ssccglpinnacle.com/api/courses"
      );
      setCourses(coursesResponse.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setError("Error fetching courses");
      setLoading(false);
    }
  };

  const toggleCourseStatus = async (courseId, currentStatus) => {
    setUpdating(courseId);
    setError(null);
    try {
      const newStatus = !currentStatus;
      await axios.put(
        `https://auth.ssccglpinnacle.com/api/courses/${courseId}/status`,
        {
          isActive: newStatus,
        }
      );
      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course._id === courseId ? { ...course, isActive: newStatus } : course
        )
      );
      setUpdating(null);
    } catch (error) {
      console.error("Error updating course status:", error);
      setError("Error updating course status");
      setUpdating(null);
    }
  };

  // Calculate active and inactive course counts
  const activeCoursesCount = courses.filter((course) => course.isActive).length;
  const inactiveCoursesCount = courses.length - activeCoursesCount;

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Course Status</h1>
      <div className={styles.summary}>
        <p>Total Active Courses: {activeCoursesCount}</p>
        <p>Total Inactive Courses: {inactiveCoursesCount}</p>
      </div>
      <div className={styles.tableContainer}>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className={styles.error}>{error}</p>
        ) : (
          <table className={styles.courseTable}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>ID</th>
                <th>Title</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <tr key={course._id}>
                  <td>{index + 1}</td>
                  <td>{course._id}</td>
                  <td>{course.courseTitle}</td>
                  <td
                    className={
                      course.isActive
                        ? styles.courseActive
                        : styles.courseInActive
                    }
                  >
                    {course.isActive ? "Active" : "Inactive"}
                  </td>
                  <td>
                    <button
                      className={
                        course.isActive
                          ? styles.deactivateButton
                          : styles.activateButton
                      }
                      onClick={() =>
                        toggleCourseStatus(course._id, course.isActive)
                      }
                      disabled={updating === course._id}
                    >
                      {updating === course._id
                        ? "Updating..."
                        : course.isActive
                        ? "Deactivate"
                        : "Activate"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CourseStatusChecker;

import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import Rate from "../../componentbook/StarBook/RateBook";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useProductContext } from "../../Context/ProductContext";
import Swal from "sweetalert2";
import BuyNow from "../../BuyNowBook/BuyNowBook";
import "./Row3Digital.css";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import amazon from "../../iBook/amazon.svg";
import flipkart from "../../iBook/flipkart.svg";
import snapdeal from "../../iBook/snapdeal.png";
import pinnacle from "../../iBook/pinnaclelogo.png";
import { FcApproval } from "react-icons/fc";
import BillingForm from "./BillingForm";

function Row3Digital({ filteredEbookData }) {
  const [cardData, setCardData] = useState();
  const { isLoggedIn, user } = useAuth();
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const { setProductContext } = useProductContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [StarData, setStarData] = useState([]);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [successMessage1, setSuccessMessage1] = useState(null);
  const [showBillingForm, setShowBillingForm] = useState(false);
  const [billingInfo, setBillingInfo] = useState(null);
  const [currentBookId, setCurrentBookId] = useState(null);

  const handleMouseOver = (_id) => {
    setHoveredId(_id);
  };

  const handleMouseOut = () => {
    setHoveredId(null);
  };
  

  useEffect(() => {
    const fetchBillingInfo = async () => {
      if (isLoggedIn && user && user.id) {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/bookBilling/${user.id}`
          );
          if (response.ok) {
            const data = await response.json();
            setBillingInfo(data);
          } else {
            setBillingInfo(null);
          }
        } catch (error) {
          console.error("Error fetching billing info:", error);
          setBillingInfo(null);
        }
      }
    };

    fetchBillingInfo();
  }, [user, isLoggedIn]);


  useEffect(() => {
    const fetchEbooksData = async () => {
      try {
        // Fetch data from the API
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );
  
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }
  
        const ebookData = await ebookResponse.json();
  
        // Strictly filter out items where active is not true
        const activeEbooks = ebookData.filter((ebook) => ebook.active === true);
  
        // Sort the filtered data
        activeEbooks.sort((a, b) => a.order - b.order);
  
        // Update state with strictly active data
        setCardData(activeEbooks);
  
        // Collect and flatten ratings data
        const ratings = activeEbooks.map((ebook) => ebook.rating || []);
        setStarData(ratings.flat());
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };
  
    fetchEbooksData();
  }, []);
  
  useEffect(() => {
    
    const timeoutId = setTimeout(() => {
      setSuccessMessage1(null);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [successMessage1]);

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        if (isLoggedIn && user && user._id) {
          const purchaseHistoryResponse = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchase-history-book/${user._id}`
          );
          if (!purchaseHistoryResponse.ok) {
            throw new Error(
              `Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`
            );
          }
          const purchaseHistoryData = await purchaseHistoryResponse.json();
          setPurchaseHistory(purchaseHistoryData);
        }
      } catch (error) {
        console.error("Error fetching purchase history:", error);
      }
    };

    // Call fetchPurchaseHistory function
    fetchPurchaseHistory();
  }, [isLoggedIn, user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryCardId = queryParams.get("cardId");

    const checkUserPurchased = async () => {
      try {
        if (isLoggedIn && user && user._id && queryCardId) {
          const purchaseHistoryResponse = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchase-history-book/${user._id}`
          );
          if (purchaseHistoryResponse.ok) {
            const purchaseHistoryData = await purchaseHistoryResponse.json();
            const hasPurchased = purchaseHistoryData.some(
              (purchase) => purchase.ebooksPurchased.ebook._id === queryCardId
            );

            if (hasPurchased) {
              setShowBuyNowModal(false);
              document.body.style.overflow = "";
            } else {
              setShowBuyNowModal(true);
              document.body.style.overflow = "hidden";
            }
          }
        }
      } catch (error) {
        console.error("Error checking user purchase history:", error);
      }
    };

    checkUserPurchased();
  }, [isLoggedIn, user, location]);

  const closeModal = () => {
    setShowBuyNowModal(false);
    document.body.style.overflow = "";
  };
  useEffect(() => {
    console.log(cardData); // Inspect the API response
  }, [cardData]);

  const info = (card) => {
    const permalink = card.permalink;
    navigate(`/books${permalink}`);
  };

  const viewAll = () => {
    navigate("/view-all");
  };

  const isEbookPurchased = (ebookId) => {
    return (
      isLoggedIn &&
      purchaseHistory.some(
        (purchase) =>
          purchase.ebooksPurchased &&
          purchase.ebooksPurchased.some(
            (ebook) =>
              ebook.ebook &&
              ebook.ebook._id === ebookId &&
              purchase.user === user?._id
          )
      )
    );
  };

  const handleBuyNow = async (bookId) => {
    if (!isLoggedIn) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to make a purchase.",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          // Billing information exists, navigate to VerifyAddress component
          navigate(`/verify-address/${bookId}`);
        } else {
          // Billing information does not exist, show the billing form modal
          setShowBillingForm(true);
          document.body.style.overflow = "hidden"; // Stop background scrolling
        }
      } else {
        // Handle case where fetching billing information fails
        console.error("Failed to fetch billing information");
        setShowBillingForm(true);
        document.body.style.overflow = "hidden"; // Stop background scrolling
      }
    } catch (error) {
      console.error("Error fetching billing information:", error);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden"; // Stop background scrolling
    }
  };

  const closeModalbiling = () => {
    setShowBuyNowModal(false);
    document.body.style.overflow = ""; // Enable background scrolling
  };

  <BillingForm
    onClose={() => {
      setShowBillingForm(false);
      document.body.style.overflow = ""; // Enable background scrolling
    }}
  />;

  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = ""; // Enable background scrolling
  };

  const handleAddToCart = async (bookId) => {
    try {
      // Check if the user is logged in
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      // Update UI to indicate loading
      setIsAddingToCart(true);

      // Send request to server to add to cart
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-cart-book/${user.email_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ bookId }),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        // Optionally, update local state to reflect the change in the UI
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          // If the error is "Item already in the cart", navigate to the cart page
          navigate("/cart-book");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to add item to cart. Error: ${errorMessage.error}`,
          });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      // Reset loading state
      setIsAddingToCart(false);
    }
  };

  const isItemInCart = (productId) => {
    if (!user || !user.cart) {
      return false;
    }

    // Add a console log here to check the user's cart items
    // console.log("User's Cart:", user.cart);

    return user.cart.some((item) => item.product === productId);
  };

  const getRatingForCard = (cardId) => {
    const card = cardData.find((card) => card._id === cardId);

    if (!card || !card.rating || card.rating.length === 0) {
      return 0;
    }

    return calculateAverageRating(card.rating);
  };

  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce(
      (sum, rating) => sum + rating.starRate,
      0
    );
    return totalRating / ratings.length;
  };

  // const handleWishlistClick = (event, cardId) => {
  //   event.stopPropagation();

  //   if (wishlist.includes(cardId)) {
  //     setWishlist(wishlist.filter((id) => id !== cardId));
  //   } else {
  //     setWishlist([...wishlist, cardId]);
  //   }
  // };

  const handleWishlistClick = async (event, cardId) => {
    event.stopPropagation();

    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      // Update the local wishlist state immediately
      const updatedWishlist = isWishlistItem(cardId)
        ? wishlist.filter((id) => id !== cardId)
        : [...wishlist, cardId];
      setWishlist(updatedWishlist);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/add-to-wishlist-book/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cardId }),
        }
      );

      if (!response.ok) {
        console.error(
          "Failed to update wishlist. Server response:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        // Add a check for user existence
        if (!user || !user._id) {
          console.error("User is null or _id is undefined.");
          return;
        }

        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/get-wishlist-book/${user._id}`
        );

        if (response.ok) {
          const data = await response.json();
          const wishlist = data.wishlist || [];
          // Process the wishlist data as needed
          // console.log('Wishlist:', wishlist);
          setWishlist(wishlist);
          // Update your component state with the fetched wishlist if needed
        } else {
          console.error("Failed to fetch wishlist:", await response.text());
        }
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    // Call the fetchWishlist function
    fetchWishlist();
  }, [user, cardData]);

  const handleRemoveFromWishlist = async (event, cardId) => {
    event.stopPropagation();

    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "info", // You can change this to 'info', 'success', 'warning', etc.
          title: "Note",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      // Update the local wishlist state immediately
      const updatedWishlist = wishlist.filter((id) => id !== cardId);
      setWishlist(updatedWishlist);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/remove-from-wishlist-book/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cardId }),
        }
      );

      if (!response.ok) {
        console.error(
          "Failed to remove from wishlist. Server response:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error removing from wishlist:", error);
    }
  };

  const isWishlistItem = (cardId) => wishlist.includes(cardId);

  const calculateDiscount = (BookSellingPrice, BookPrintingPrice) => {
    const savingAmount = BookPrintingPrice - BookSellingPrice;
    const discountPercentage = (savingAmount / BookPrintingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  return (
    <>
      <div className="Making-your-Study">
        <h4>Printed Books</h4>
      </div>
      <div className="container-digital">
  <ul className="cards-digital">
    {Array.isArray(cardData) &&
      cardData
        .filter((card) => card.active === true) // Filter strictly for active: true
        .slice(0, 6)
        .map((card) => {
          // Calculate the discount and saving amount
          const { savingAmount, discountPercentage } = calculateDiscount(
            card.BookSellingPrice,
            card.BookPrintingPrice
          );

          return (
            <li className="card-digital-book" key={card._id}>
              <div className="upper-card-digital">
                <div className="card-content-digital">
                  <div className="wishlist-book-for-image">
                    <div
                      className={`wishlist-book ${
                        isWishlistItem(card._id) ? "wishlist-active" : ""
                      }`}
                      onClick={(e) => {
                        if (!isWishlistItem(card._id)) {
                          setSuccessMessage1("Added to your Wishlist");
                          handleWishlistClick(e, card._id);
                        } else {
                          setSuccessMessage1("Removed from your Wishlist");
                          handleRemoveFromWishlist(e, card._id);
                        }
                      }}
                      style={{ transition: "color 0.2s ease-in-out" }}
                    >
                      {isWishlistItem(card._id) ? (
                        <AiFillHeart
                          className="wishlist-heart-first"
                          style={{
                            color: "#ff0000",
                            transition: "color 0.2s ease-in-out",
                          }}
                        />
                      ) : (
                        <AiFillHeart
                          className="wishlist-heart"
                          style={{
                            color: "inherit",
                            transition: "color 0.2s ease-in-out",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {successMessage1 && (
                    <div className="success-message-wishlist">
                      <div className="for-sm-wish">
                        <div className="tick-icon-wish">
                          <FcApproval size={26} />
                        </div>
                        <p className="sm-wish">{successMessage1}</p>
                      </div>
                    </div>
                  )}

                  <div
                    className="for-image-size-newbook"
                    onClick={() => info(card)}
                  >
                    <div className="for-image-size-book">
                      <img
                        className="card-title-digital"
                        src={card.image1}
                        alt={card.title}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-link-wrapper-digital">
                  <p>{card.title}</p>
                </div>
                <div className="buttons-for-buying-products">
                  <div className="button-for-everything">
                    <div className="buttons-for-buying-products-books">
                      <div className="try-for-free">
                        <div
                          className="Rating-newBook"
                          onMouseOver={() => handleMouseOver(card._id)}
                          onMouseOut={handleMouseOut}
                        >
                          <div className="Star-Rating">
                            {getRatingForCard(card._id).toFixed(1)}
                            {Array.from({ length: 1 }).map((_, index) => (
                              <AiFillStar className="Star-Rating-1" />
                            ))}
                          </div>
                          <div className="Total-Rating">
                            ({card.rating.length})
                          </div>
                        </div>
                      </div>
                      <button
                        className="buynow-for-user-cart"
                        onClick={() => handleAddToCart(card._id)}
                      >
                        {isItemInCart(card._id) ? "Go to Cart" : "Add to Cart"}
                      </button>
                    </div>
                    <div className="price-for-billing">
                      <span className="price-for-billing__selling-price">
                        ₹ {card.BookSellingPrice} incl. GST
                      </span>
                      <div className="price-for-billing__details">
                        <span className="price-for-billing__mrp">
                          M.R.P.: <del>₹ {card.BookPrintingPrice}</del>
                        </span>
                        <span className="price-for-billing__saving-amount">
                          Save: ₹ {savingAmount.toFixed(2)} (
                          {discountPercentage.toFixed(0)}%)
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  
  <div style={{ display: 'flex', gap: '70px', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={amazon} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={flipkart} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.flipkartLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>
  </div>

 
  <div style={{ display: 'flex', gap: '70px', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={snapdeal} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.snapdealLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <img src={pinnacle} style={{ width: '60px', height: '40px' }} />
      <button
        onClick={() => {
          if (!isLoggedIn) {
            window.location.href = "https://testportal.ssccglpinnacle.com/login";
          } else {
            handleBuyNow(card.id);
          }
        }}
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
      >
        Buy Now
      </button>
    </div>
  </div>
</div> */}




<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  
  <div style={{ display: 'flex', gap: '70px', justifyContent: 'center' }}>
    {/* Pinnacle */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={pinnacle} style={{ width: '60px', height: '40px' }} />
      <button
        onClick={() => {
          if (!isLoggedIn) {
            window.location.href = "https://testportal.ssccglpinnacle.com/login";
          } else {
            handleBuyNow(card.id);
          }
        }}
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
      >
        Buy Now
      </button>
    </div>

    {/* Flipkart */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={flipkart} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.flipkartLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>
  </div>

  <div style={{ display: 'flex', gap: '70px', justifyContent: 'center' }}>
    {/* Amazon */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={amazon} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>

    {/* Snapdeal */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={snapdeal} style={{ width: '60px', height: '40px' }} />
      <a
        href={card.snapdealLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '2px 4px', backgroundColor: '#2874F0', color: '#fff', textDecoration: 'none', borderRadius: '4px' }}
      >
        Buy Now
      </a>
    </div>
  </div>
</div>




                </div>
              </div>
            </li>
          );
        })}
  </ul>
  <div className="View-all-digital">
    <Link to="/books/view-all">View All</Link>
  </div>
</div>


      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default Row3Digital;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import YouTubeModal from "./YoutubeModal"; // Modal component for playing YouTube videos
import { AiOutlineDown, AiOutlineUp, AiFillLock } from "react-icons/ai"; // Import arrow and lock icons
import styles from "./ChapterAccordion.module.css"; // CSS module

const ChapterAccordion = () => {
  const [chapters, setChapters] = useState([]);
  const [courseId, setCourseId] = useState(""); // State for courseId
  const [visibleChapters, setVisibleChapters] = useState(10); // Show first 10 chapters
  const [activeIndex, setActiveIndex] = useState(0); // Open first chapter by default
  const [selectedVideo, setSelectedVideo] = useState(null); // Track selected video for modal
  const [isLoading, setIsLoading] = useState(true); // Loading state for chapters
  const { category, subject, seoPermalink } = useParams(); // Extracts the full path after "/videos/"

  // Fetch courseId based on seoPermalink
  useEffect(() => {
    const fetchCourseId = async () => {
      try {
        setIsLoading(true); // Start loading
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/get-seo/${category}/${subject}/${seoPermalink}`
        );
        setCourseId(response.data._id); // Set courseId from response
      } catch (error) {
        console.error("Error fetching courseId:", error);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchCourseId();
  }, [seoPermalink]);

  // Fetch chapters data using courseId
  useEffect(() => {
    if (!courseId) return; // Wait until courseId is available

    const fetchChapters = async () => {
      try {
        setIsLoading(true); // Start loading
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${courseId}`
        );
        setChapters(response.data); // Set chapters data
      } catch (error) {
        console.error("Error fetching chapters:", error);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchChapters();
  }, [courseId]);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const handleShowMore = () => {
    setVisibleChapters(chapters.length);
  };

  const handleHide = () => {
    setVisibleChapters(10);
    setActiveIndex(0); // Optionally close all chapters except the first one
  };

  const handlePreview = (videoYoutubeLink) => {
    setSelectedVideo(videoYoutubeLink);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  if (chapters.length === 0) {
    return (
      <div className={styles.noContent}>
        No chapters available for this course.
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {chapters.slice(0, visibleChapters).map((chapter, index) => (
        <div key={chapter._id} className={styles.chapterItem}>
          <button
            className={styles.accordionTitle}
            onClick={() => handleClick(index)}
          >
            <span>{chapter.chapterTitle}</span>
            {activeIndex === index ? (
              <AiOutlineUp className={styles.arrowIcon} />
            ) : (
              <AiOutlineDown className={styles.arrowIcon} />
            )}
          </button>
          {activeIndex === index && (
            <div className={styles.accordionContent}>
              {chapter.topics.map((topic, topicIndex) => (
                <div key={topic._id} className={styles.videoTitle}>
                  <span>{topic.videoTitle}</span>
                  {index === 0 && topicIndex < 2 ? (
                    <button
                      className={styles.previewButton}
                      onClick={() => handlePreview(topic.videoYoutubeLink)}
                    >
                      Preview
                    </button>
                  ) : (
                    <AiFillLock className={styles.lockIcon} />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      {visibleChapters < chapters.length && (
        <button onClick={handleShowMore} className={styles.seeMoreButton}>
          See More
        </button>
      )}
      {visibleChapters > 10 && (
        <button onClick={handleHide} className={styles.seeMoreButton}>
          Hide
        </button>
      )}
      {selectedVideo && (
        <YouTubeModal
          videoYoutubeLink={selectedVideo}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default ChapterAccordion;

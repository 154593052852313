import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import "./Introo.css";
import { useParams, useNavigate } from "react-router-dom";
import Row2intro from "./Row2introBook";
import Row3intro from "./Row3introBook";
import Row4intro from "./Row4introBook";
import ShareModal from "./ShareModalBook";
import { GiCheckMark } from "react-icons/gi";
import RatingReview from "./RatingReviewBook";
import Row7intro from "./Row7introBook";
import Swal from "sweetalert2";
import Footer from "../component/Footer/Footer";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import LoadingSpinner from "../LoadingSpinner";
import ReactImageMagnify from "react-image-magnify";
import BillingForm from "../PagesBook/DigitalContentBody/BillingForm";
import BookMetaTags from "../DynamicMetaData/BookMetaTags";
import amazon from "../iBook/amazon.svg";
import flipkart from "../iBook/flipkart.svg";
import snapdeal from "../iBook/snapdeal.png";
import pinnaclelogo from "../iBook/pinnaclelogo.png";

function IntroBook({ onClose }) {
  const { isLoggedIn, user } = useAuth();
  const [cardData, setCardData] = useState(null); // Single object
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showBillingForm, setShowBillingForm] = useState(false);
  const { category, subject, permalink } = useParams();
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const endpoint = `https://auth.ssccglpinnacle.com/get-book/${category}/${subject}/${permalink}`;
        console.log("Fetching from endpoint:", endpoint);

        const response = await fetch(endpoint);

        if (!response.ok)
          throw new Error(`Failed to fetch data. Status: ${response.status}`);

        const bookData = await response.json();
        const productData = {
          title: bookData.title || "Course Title Not Available",
          SEOTitle:
            bookData.seoTitle || bookData.title || "SEO Title Not Available",
          SEODescription: bookData.seoDescription || "No description available",
          SEOKeyword: bookData.seoKeywords || "",
          CoverImage: bookData.image1 || "",
          SEOPermalink: bookData.permalink,
        };
        setProduct(productData);
        setCardData(bookData);
        setSelectedImage(bookData?.image1 || null); // Set default image
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [category, subject, permalink]);

  const calculateDiscount = (sellingPrice, printingPrice) => {
    const savingAmount = printingPrice - sellingPrice;
    const discountPercentage = (savingAmount / printingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  const handleBuyNow = async () => {
    if (!isLoggedIn) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to make a purchase.",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`
      );
      const data = response.ok ? await response.json() : null;

      if (data) {
        navigate(`/verify-address/${cardData?.id}`);
      } else {
        setShowBillingForm(true);
        document.body.style.overflow = "hidden";
      }
    } catch (error) {
      console.error("Error handling Buy Now:", error.message);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden";
    }
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = "";
  };

  if (loading) return <LoadingSpinner />;

  if (!cardData) {
    return <div>No data available for this book.</div>;
  }

  const { savingAmount, discountPercentage } = calculateDiscount(
    cardData.BookSellingPrice,
    cardData.BookPrintingPrice
  );

  return (
    <>
      {product && <BookMetaTags product={product} />}
      <div className="container-body-intro">
        <Header />
        <div className="container-body-for-intro">
          <div className="for-intro-row1-image-introproduct">
            <div className="row1-for-intro">
              <div className="for-image-intro">
                <div className="image-container">
                  <div className="image-container-book-show">
                    {selectedImage ? (
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "Selected",
                            isFluidWidth: true,
                            src: selectedImage,
                          },
                          largeImage: {
                            src: selectedImage,
                            width: 1200,
                            height: 1800,
                          },
                          enlargedImageContainerDimensions: {
                            width: "250%",
                            height: "200%",
                          },
                        }}
                      />
                    ) : (
                      <div className="placeholder">Select an image</div>
                    )}
                  </div>
                </div>
                <div className="thumbnails">
                  {[
                    cardData.image1,
                    cardData.image2,
                    cardData.image3,
                    cardData.image4,
                    cardData.image5,
                  ]
                    .filter(Boolean)
                    .map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className="thumbnail"
                        onClick={() => handleThumbnailClick(image)}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="row2-for-intro">
              <div className="name-of-the-product">
                <h2>{cardData.title}</h2>
              </div>
              <div className="price-details-intro">
                <div className="price-for-billing">
                  <span className="price-for-billing__selling-price">
                    ₹{cardData.BookSellingPrice} incl. GST
                  </span>
                  <div className="price-for-billing__details">
                    <span className="price-for-billing__mrp">
                      M.R.P.: <del>₹{cardData.BookPrintingPrice}</del>
                    </span>
                    <span className="price-for-billing__saving-amount">
                      Save: ₹{savingAmount.toFixed(2)} (
                      {discountPercentage.toFixed(0)}%)
                    </span>
                  </div>
                </div>
                <div className="Value-deal">
                  <p>
                    VALUE DEAL! Save &nbsp;
                    <span className="money">₹{savingAmount.toFixed(2)}</span>
                  </p>
                </div>
                <div className="share-product">
                  <ShareModal />
                </div>
              </div>
              <div className="Add-ISBN">
                <p className="intro-1-isbn">ISBN: {cardData.ISBN}</p>
                <p className="intro-1-isbn">Pages: {cardData.pages}</p>
              </div>
              <div className="intro-of-the-product">
                <p className="intro-1">{cardData.para1}</p>
                {[cardData.para2, cardData.para3, cardData.para4].map(
                  (para, index) =>
                    para && (
                      <div className="intro-2" key={index}>
                        <GiCheckMark className="tick" />
                        {para}
                      </div>
                    )
                )}
              </div>
              <div className="buy-it-now-from-buy-model-intro">
                
              {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'flex-start' }}>
  
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={amazon} alt="Amazon" style={{ width: '80px' }} />
    </div>
    <button
      onClick={() => window.open(cardData.amazonLink, "_blank", "noopener,noreferrer")}
      style={{
        padding: '5px 10px',
        backgroundColor: '#FF9900',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',  
      }}
    >
      Buy Now
    </button>
  </div>

  
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={flipkart} alt="Flipkart" style={{ width: '80px' }} />
    </div>
    <button
      onClick={() => window.open(cardData.flipkartLink, "_blank", "noopener,noreferrer")}
      style={{
        padding: '5px 10px',
        backgroundColor: '#2874F0',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',  
      }}
    >
      Buy Now
    </button>
  </div>

  
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={snapdeal} alt="Snapdeal" style={{ width: '80px' }} />
    </div>
    <button
      onClick={() => window.open(cardData.snapdealLink, "_blank", "noopener,noreferrer")}
      style={{
        padding: '5px 10px',
        backgroundColor: '#E62E2D',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',  
      }}
    >
      Buy Now
    </button>
  </div>

  
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={pinnaclelogo} alt="Buy Now" style={{ width: '95px' }} />
    </div>
    <button
      id="buyButton-intro"
      onClick={handleBuyNow}
      style={{
        padding: '5px 10px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',  
      }}
    >
      Buy Now
    </button>
  </div>
</div> */}





<div style={{ display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'flex-start' }}>
  {/* Pinnacle */}
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={pinnaclelogo} alt="Buy Now" style={{ width: '95px' }} />
    </div>
    <button
      id="buyButton-intro"
      onClick={handleBuyNow}
      style={{
        padding: '5px 10px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',
      }}
    >
      Buy Now
    </button>
  </div>

  {/* Flipkart */}
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={flipkart} alt="Flipkart" style={{ width: '80px' }} />
    </div>
    <button
      onClick={() => window.open(cardData.flipkartLink, "_blank", "noopener,noreferrer")}
      style={{
        padding: '5px 10px',
        backgroundColor: '#2874F0',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',
      }}
    >
      Buy Now
    </button>
  </div>

  {/* Amazon */}
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={amazon} alt="Amazon" style={{ width: '80px' }} />
    </div>
    <button
      onClick={() => window.open(cardData.amazonLink, "_blank", "noopener,noreferrer")}
      style={{
        padding: '5px 10px',
        backgroundColor: '#FF9900',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',
      }}
    >
      Buy Now
    </button>
  </div>

  {/* Snapdeal */}
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <img src={snapdeal} alt="Snapdeal" style={{ width: '80px' }} />
    </div>
    <button
      onClick={() => window.open(cardData.snapdealLink, "_blank", "noopener,noreferrer")}
      style={{
        padding: '5px 10px',
        backgroundColor: '#E62E2D',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '4px',
      }}
    >
      Buy Now
    </button>
  </div>
</div>





                {/* <button id="buyButton-intro" onClick={handleBuyNow}>
                  Buy Now
                </button> */}
              </div>
              <div className="show-preview-pdf-book">
                <div className="preview-book-pdf-title">Preview</div>
                <div className="pdf-showpreview">
                  {cardData.pdf1 && (
                    <a
                      href={cardData.pdf1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preview 1
                    </a>
                  )}
                  {cardData.pdf2 && (
                    <a
                      href={cardData.pdf2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preview 2
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Row2intro />
        </div>
        <Row3intro
          currentOriginalPrice={cardData.BookPrintingPrice}
          currentDiscountedPrice={cardData.BookSellingPrice}
          selectedCardData={cardData}
        />
        <Row4intro />
        <RatingReview />
        <Row7intro />
        <Footer />
      </div>
      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default IntroBook;

import React, { useState, useEffect, useContext } from "react";
import classes from "./Header.module.css";
import Logo from "../../../assests/pinnacleWhiteLogo.svg";
import googleplay from "./google.svg";
import { useNavigate, Link } from "react-router-dom";
import { FaHome, FaMicrophone, FaSearch } from "react-icons/fa";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { SearchContext } from "../../../Context/SearchContext";
import HoverCart from "./HoverCart";
import Hoverwishlist from "./Hoverwishlist";
import Account from "./Account";
import { useAuth } from "../../../Context/AuthContext";
import pic1 from "../../HeaderFiles/images/gmail.svg"

export default function Header() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isListening, setIsListening] = useState(false);

  const { isLoggedIn, user } = useAuth();
  const { query, setQuery } = useContext(SearchContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await fetch("https://auth.ssccglpinnacle.com/categories");
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch subcategories
  const fetchSubcategories = async (categoryId) => {
    if (subcategories[categoryId]) return;
    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/exams/category/${categoryId}`
      );
      const data = await response.json();
      setSubcategories((prev) => ({
        ...prev,
        [categoryId]: data,
      }));
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  // Fetch search suggestions
  // const fetchSuggestions = async (searchQuery) => {
  //   if (!searchQuery.trim()) {
  //     setSuggestions([]);
  //     return;
  //   }
  //   try {
  //     const response = await fetch(
  //       `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(
  //         searchQuery
  //       )}`
  //     );
  //     const data = await response.json();
  //     setSuggestions(data);
  //   } catch (error) {
  //     console.error("Error fetching suggestions:", error);
  //   }
  // };

  // Handle search input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    // fetchSuggestions(value);
    setShowSuggestions(true);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.title);
    setShowSuggestions(false);
    navigate("/globalSearch");
  };

  // Handle search submit
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/videoSearch?query=${encodeURIComponent(query)}`);
    }
  };
  // Speech-to-Text functionality
  const startListening = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Speech recognition is not supported in this browser. Please try Chrome.");
      return;
    }
    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;

    recognition.start();
    setIsListening(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setQuery(transcript);
      // fetchSuggestions(transcript);
      setIsListening(false);
    };

    recognition.onerror = () => {
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  return (
    <header className={classes.Header}>
<div className={classes.logo_support_div}>
        <div className={classes.logo_div} onClick={() => navigate("/")}>
          <img src={Logo} alt="Logo" className={classes.logo_img} />
        </div>
        <div className={classes.email_support_div}>
          <img className={classes.email_img} src={pic1} />
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@ssccglpinnacle.com
          </a>
        </div>
      </div>

      <div className={classes.account_section}>
        <div onClick={() => navigate("/videos")} className={classes.home_button}>
          <FaHome className={classes.home_icon} />
          Home
        </div>

        <nav className={classes.subnav}>
          <div
            className={classes.subnav_button}
            onClick={() => navigate("/videos/category/66a8a5518c0f4f53f38de5e1")}
          >
            <BiSolidCategoryAlt />
            <span>Categories</span>
            <span className={classes.arrow} />
          </div>

          <div className={classes.subnav_content}>
            {categories.map((category) => (
              <div className={classes.subnav_content_subnav} key={category._id}>
                <div
                  className={classes.subnavbtn2x}
                  onClick={() => fetchSubcategories(category._id)}
                >
                  <Link
                    className={classes.item}
                    to={`/videos/category/${category._id}`}
                  >
                    {category.categoryTitle}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </nav>
        
        {/* Search Section */}
        <div className={classes.search_container}>
          <form onSubmit={handleSearchSubmit} className={classes.search_form}>
            <input
              type="text"
              name="product-search"
              id="product-search"
              placeholder="Search for video courses..."
              value={query}
              onChange={handleInputChange}
              className={classes.search_input}
            />
            <button type="submit" className={classes.search_icon}>
              <FaSearch />
            </button>
            <button type="button" onClick={startListening} className={classes.search_icon}>
              <FaMicrophone color={isListening ? "red" : "black"} />
            </button>
          </form>
        </div>

        <div className={classes.playstore_link}>
          <a
            href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googleplay} alt="Google Playstore" />
          </a>
        </div>

        {isLoggedIn && user ? (
          <>
            <div className={classes.mylearning_div}>
              <Link to={`/videos/mylearning`} className={classes.myLearning_link}>
                My Learning
              </Link>
            </div>


            <div className={classes.mylearning_div}>
              <Link to={`/coins`} className={classes.myLearning_link}>
                Coins
              </Link>
            </div>




            <div className={classes.wishlist_icon} onClick={() => navigate("/videos/wishlist")}>
              <Hoverwishlist size={35} />
            </div>
            <div className={classes.cart_icon} onClick={() => navigate("/videos/cart")}>
              <HoverCart size={35} />
            </div>
            <Account userDetails={user} />
          </>
        ) : (
          <div className={classes.auth_buttons}>
            <div
              onClick={() =>
                (window.location.href = "https://testportal.ssccglpinnacle.com/login")
              }
              className={classes.login_button}
            >
              Register
            </div>
            <div
              onClick={() =>
                (window.location.href = "https://testportal.ssccglpinnacle.com/login")
              }
              className={classes.login_button}
            >
              Log in
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

// import React, { useState, useEffect } from "react";
// import classes from "./Header.module.css";
// import Logo from "../../../assests/pinnacleWhiteLogo.svg";
// import Playstore from "../../../assests/white playstore.svg";
// import { useNavigate, Link } from "react-router-dom";
// import HoverCart from "./HoverCart";
// import Hoverwishlist from "./Hoverwishlist";
// import Account from "./Account";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { useAuth } from "../../../Context/AuthContext";
// import { FaSearch, FaHome } from "react-icons/fa";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import { BiSolidCategoryAlt } from "react-icons/bi";
// import { PiVideoFill } from "react-icons/pi";
// import SearchBar from "./SearchBar";
// import googleplay from "./google.svg";
// import { RiArrowDropDownLine } from "react-icons/ri";
// import pic1 from "../../HeaderFiles/images/gmail.svg"

// export default function Header() {
//   const [categories, setCategories] = useState([]);
//   const [subcategories, setSubcategories] = useState({});
//   const { isLoggedIn, user } = useAuth();
//   const [searchTerm, setSearchTerm] = useState("");

//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchCategories();
//   }, []);
//   const handleSearch = (event) => {
//     event.preventDefault();
//     if (searchTerm.trim()) {
//       navigate(`/videoSearch?query=${encodeURIComponent(searchTerm)}`);
//     }
//   };

//   useEffect(() => {
//     fetchCategories();
//   }, []);
//   const fetchCategories = async () => {
//     try {
//       const response = await fetch(
//         "https://auth.ssccglpinnacle.com/categories"
//       );
//       const data = await response.json();
//       setCategories(data);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };

//   const fetchSubcategories = async (categoryId) => {
//     if (subcategories[categoryId]) return;

//     try {
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/exams/category/${categoryId}`
//       );
//       const data = await response.json();
//       setSubcategories((prevSubcategories) => ({
//         ...prevSubcategories,
//         [categoryId]: data,
//       }));
//     } catch (error) {
//       console.error("Error fetching subcategories:", error);
//     }
//   };

//   return (
//     <>
//     <header className={classes.Header}>
//       <div className={classes.logo_support_div}>
//       <div className={classes.logo_div} onClick={() => navigate("/")}>
//         <img src={Logo} alt="Logo" className={classes.logo_img} />
//       </div>
//       <div className={classes.email_support_div}>
//         <img className={classes.email_img} src={pic1}/>
//       <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com" target="_blank" rel="noopener noreferrer">support@ssccglpinnacle.com</a>
//     </div>
//     </div>

//       <div className={classes.account_section}>
//         <div
//           onClick={() => navigate("/videos")}
//           className={classes.home_button}
//         >
//           <FaHome className={classes.home_icon} />
//           Home
//         </div>

//         <nav className={classes.subnav}>
//           {/* <button className={classes.subnav_button}>
//             <BiSolidCategoryAlt />
//             Categories
//           </button> */}
//           <div
//             className={classes.subnav_button}
//             onClick={() =>
//               navigate("/videos/category/66a8a5518c0f4f53f38de5e1")
//             }
//           >
//             <BiSolidCategoryAlt />
//             {/* Categories
//             <RiArrowDropDownLine className={classes.dropdown_icon} size={25} /> */}
//             <span>Categories</span>
//             <span className={classes.arrow} />
//           </div>

//           <div className={classes.subnav_content}>
//             {categories.map((category) => (
//               <div className={classes.subnav_content_subnav} key={category._id}>
//                 <div
//                   className={classes.subnavbtn2x}
//                   onClick={() => fetchSubcategories(category._id)}
//                 >
//                   <Link
//                     className={classes.item}
//                     to={`/videos/category/${category._id}`}
//                   >
//                     {category.categoryTitle}
//                   </Link>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </nav>

//         <div className={classes.search_container}>
//           {/* Search Bar Section */}
//           <form onSubmit={handleSearch} className={classes["search_form"]}>
//             <input
//               type="text"
//               name="product-search"
//               id="product-search"
//               placeholder=" search for video courses..."
//               value={searchTerm}
//               className={classes["search_container"]}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//           </form>
//         </div>

//         {/* <Link
//           to="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
//           target="_blank"
//           rel="noopener noreferrer"
//           className={classes.playstore_link}
//         >
//           <IoLogoGooglePlaystore className={classes.playstore_icon} size={20} />
//           Google Play
//         </Link> */}
//         <div class="playstore_link">
//           {" "}
//           <a
//             href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img src={googleplay} />
//           </a>
//         </div>

//         {isLoggedIn && user ? (
//           <>
//             <div className={classes.mylearning_div}>
//               <Link
//                 to={`/videos/mylearning`}
//                 className={classes.myLearning_link}
//               >
//                 <PiVideoFill size={20} /> My learning
//               </Link>
//             </div>
//             <div
//               className={classes.wishlist_icon}
//               onClick={() => navigate("/videos/wishlist")}
//             >
//               <Hoverwishlist size={35} />
//             </div>
//             <div
//               className={classes.cart_icone}
//               onClick={() => navigate("/videos/cart")}
//             >
//               <HoverCart size={35} />
//             </div>
//             <Account userDetails={user} />
//           </>
//         ) : (
//           <div className={classes.auth_buttons}>
//             <div
//               onClick={() =>
//                 (window.location.href =
//                   "https://testportal.ssccglpinnacle.com/login")
//               }
//               className={classes.login_button}
//             >
//               Register
//             </div>
//             <div
//               onClick={() =>
//                 (window.location.href =
//                   "https://testportal.ssccglpinnacle.com/login")
//               }
//               className={classes.login_button}
//             >
//               Log in
//             </div>
//           </div>
//         )}
//       </div>
//     </header>
//     </>
//   );
// }

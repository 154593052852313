import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./VideoBanner.module.css";
import Swal from "sweetalert2";

const VideoBanner = () => {
  const [courseTitle, setCourseTitle] = useState("");
  const [order, setOrder] = useState("");
  const [hindiCoverImagePreview, setHindiCoverImagePreview] = useState(null);
  const [englishCoverImagePreview, setEnglishCoverImagePreview] =
    useState(null);
  const [recentCourses, setRecentCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [error, setError] = useState(null);

  // Fetch recent courses
  useEffect(() => {
    const fetchRecentCourses = async () => {
      try {
        console.log("Fetching recent courses...");
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/rc/courses"
        );

        const data = response.data;

        console.log("Courses API Response:", data);

        if (Array.isArray(data) && data.length > 0) {
          setRecentCourses(data.filter((course) => course.courseTitle)); // Filter valid courses
        } else {
          setError("No courses found.");
        }
      } catch (error) {
        console.error("Error fetching recent courses:", error);
        setError("Failed to fetch courses.");
      }
    };

    fetchRecentCourses();
  }, []);

  const handleCourseChange = (e) => {
    const selectedCourseTitle = e.target.value;
    setCourseTitle(selectedCourseTitle);

    const selectedCourse = recentCourses.find(
      (course) => course.courseTitle === selectedCourseTitle
    );

    if (selectedCourse) {
      setSelectedCourseId(selectedCourse._id);
      setHindiCoverImagePreview(selectedCourse.hindiCoverImage);
      setEnglishCoverImagePreview(selectedCourse.englishCoverImage);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCourseId || !courseTitle || !order) {
      Swal.fire({
        title: "Error!",
        text: "Please fill all required fields.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const newBannerData = {
      course: selectedCourseId,
      courseTitle,
      order: Number(order),
      hindiCoverImage: hindiCoverImagePreview,
      englishCoverImage: englishCoverImagePreview,
    };

    try {
      const response = await axios.post(
        "https://auth.ssccglpinnacle.com/api/banner",
        newBannerData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Banner created successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Reset form fields
        setCourseTitle("");
        setOrder("");
        setHindiCoverImagePreview(null);
        setEnglishCoverImagePreview(null);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message || "Failed to create banner",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error creating banner:", error);
      Swal.fire({
        title: "Error!",
        text:
          error.response?.data?.message ||
          "An error occurred while creating the banner",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <h1 className={styles.heading}>Upload Data to Video Promotion Slider</h1>
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="courseTitle" className={styles.labelTitle}>
              Course Title
            </label>
            <select
              id="courseTitle"
              name="courseTitle"
              value={courseTitle}
              onChange={handleCourseChange}
              className={styles.inputTitle}
              required
              disabled={recentCourses.length === 0 || error}
            >
              <option value="" disabled>
                {error || "Select a course"}
              </option>
              {recentCourses.map((course) => (
                <option key={course._id} value={course.courseTitle}>
                  {course.courseTitle}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.previewSection}>
            {hindiCoverImagePreview && (
              <div>
                <h4>Hindi Cover Image Preview:</h4>
                <img
                  src={hindiCoverImagePreview}
                  alt="Hindi Cover Preview"
                  style={{ width: "150px", height: "auto", margin: "10px 0" }}
                />
              </div>
            )}
            {englishCoverImagePreview && (
              <div>
                <h4>English Cover Image Preview:</h4>
                <img
                  src={englishCoverImagePreview}
                  alt="English Cover Preview"
                  style={{ width: "150px", height: "auto", margin: "10px 0" }}
                />
              </div>
            )}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="order" className={styles.labelTitle}>
              Order
            </label>
            <input
              type="number"
              id="order"
              name="order"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              className={styles.inputTitle}
              required
            />
          </div>

          <button
            type="submit"
            className={styles.buttonUpload}
            disabled={!recentCourses.length || error}
          >
            Upload Banner
          </button>
        </form>
      </div>
    </>
  );
};

export default VideoBanner;

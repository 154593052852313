import React, { useState, useEffect } from "react";
import Header1 from "./Header1";
import "./Header.css";
import lightImage from "./images/pinnaclelogo.png";
import darkImage from "./images/pinnacleWhiteLogo.png";
import Header2 from "./Header2";
import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaUser } from "react-icons/fa";

import SearchBar1 from "./SearchBar1";
import { BsCart2 } from "react-icons/bs";
import { useCart } from "../../Context/CartContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

import { ThemeContext } from "../../Context/ThemeContext"; // Import the ThemeContext

function Header({ cardId }) {
  const [cartItems, setCartItems] = useState([]);
  const { isLoggedIn, user, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCartItems = async () => {
      const userId = user?.id; // Replace with the actual user ID
      const useremail = user?.email_id;
      if (userId) {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/cart-ebook/${useremail}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Ensure you send the token for authentication
              },
            }
          );
          const data = await response.json();
          setCartItems(data); // Update cartItems in the state
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      }
    };

    fetchCartItems();
  }, [user]);

  const { theme } = useContext(ThemeContext);

  const getImage = () => {
    if (theme === "dark") {
      return darkImage;
    }
    return lightImage;
  };

  const handleClick2 = () => {
    if (cardId) {
      // If cardId is present, navigate to /{cardId}/mylearning
      navigate(`/${cardId}/mylearning`);
    } else {
      // If cardId is not present, navigate to /mylearning
      navigate("/ebooks/mylearning");
    }
  };

  const handleClickhome = () => {
    navigate("/");
  };
  const handleClick3 = () => {
    navigate("/ebooks/cart");
  };

  return (
    <>
      <div
        className={`my-component ${
          theme === "dark" ? "dark-theme" : "light-theme"
        }`}
      >
        <div className="header6">
          <div className="Headercontainer">
            <header className="main-header1">
              <div className="row1header">
                <div className="headerimage" onClick={handleClickhome}>
                  <img src={getImage()} alt="Component Image" />
                </div>
                <Header1 />
                <SearchBar1 />
                <div className="for-content-header">
                  <div className="cart-icon-header2" onClick={handleClick3}>
                    <div className="cart-header">
                      <BsCart2 className="cart-on-header" size={25} />
                    </div>
                    <div className="circle-for-intro3">{cartItems.length}</div>
                  </div>
                </div>

                <div className="My-dashboard">
                  <p onClick={handleClick2}>My Dashboard</p>
                </div>
                <div className="user-account-icon">
                  {/* <div className="for-circle-user" onClick={handleClick2}>
                    <FaUser className="the-account" color="#39701d" />
                  </div> */}
                </div>
              </div>

              <div className="row2header">
                <Header2 />
              </div>
            </header>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

// import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import { load } from "@cashfreepayments/cashfree-js";
// import "./VideosBuyButton.css";
// import { useAuth } from "../../../Context/AuthContext";
// import { useNavigate } from "react-router-dom";

// const VideoBuyButton = ({
//   productId,
//   userDetails,
//   buttonText = "Checkout",
//   duration = "12 Months",
// }) => {
//   const [cashfree, setCashfree] = useState(null);
//   const navigate = useNavigate();
//   const { token } = useAuth();

//   useEffect(() => {
//     const initializeSDK = async () => {
//       const cashfreeInstance = await load({
//         // mode: "sandbox",
//         mode: "production",
//       });
//       setCashfree(cashfreeInstance);
//     };
//     initializeSDK();
//   }, []);

//   const doPayment = async (id, orderId) => {
//     if (cashfree) {
//       cashfree.checkout({
//         paymentSessionId: id,
//         returnUrl: `https://auth.ssccglpinnacle.com/api/videos_payment_status/${orderId}`,
//         redirectTarget: "_blank",
//         onSuccess: (paymentResponse) => {
//           //   console.log("Payment successful", paymentResponse);
//         },
//         onFailure: (paymentResponse) => {
//           //   console.error("PAYMENT FAILED", paymentResponse);
//         },
//       });
//     } else {
//       //   console.error("Cashfree SDK not initialized");
//     }
//   };

//   const checkAndRemovePurchasedCourses = async () => {
//     try {
//       // Fetch purchased courses for the user
//       const purchasedCoursesResponse = await fetch(
//         `https://auth.ssccglpinnacle.com/api/user_purchased_videos/${userDetails._id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (purchasedCoursesResponse.ok) {
//         const purchasedCourses = await purchasedCoursesResponse.json();
//         // console.log("Purchased Courses Response:", purchasedCourses);

//         // Access the purchasedVideos array from the response object
//         const purchasedVideos = purchasedCourses.purchasedVideos;

//         if (Array.isArray(purchasedVideos)) {
//           // Filter out courses that are still valid
//           const validPurchasedCourses = purchasedVideos.filter((course) => {
//             const expirationDate = new Date(course.expirationDate);
//             return expirationDate > new Date();
//           });

//           // Map valid purchased course IDs
//           const validPurchasedCourseIds = validPurchasedCourses.map(
//             (course) => course.productId
//           );

//           // Filter out the product IDs that have already been purchased
//           const remainingProductIds = productId.filter(
//             (id) => !validPurchasedCourseIds.includes(id)
//           );

//           if (remainingProductIds.length < productId.length) {
//             // Remove the purchased courses from the user's cart before showing Swal
//             const removePurchasedCourses = await fetch(
//               `https://auth.ssccglpinnacle.com/api/cart/remove-purchased`,
//               {
//                 method: "POST",
//                 headers: {
//                   "Content-Type": "application/json",
//                   Authorization: `Bearer ${token}`,
//                 },
//                 body: JSON.stringify({
//                   userId: userDetails._id,
//                   productIds: validPurchasedCourseIds, // The purchased product IDs to be removed
//                 }),
//               }
//             );

//             if (!removePurchasedCourses.ok) {
//               // console.error('Failed to remove purchased courses from cart.');
//             } else {
//               // After successfully removing purchased items, show the Swal alert
//               Swal.fire({
//                 icon: "info",
//                 title: "Course Already Purchased",
//                 text: "Some of the courses in your cart have already been purchased and are still valid. These have been removed from your cart.",
//                 confirmButtonText: "OK",
//               });
//             }

//             // Update the productId array to only include non-purchased courses
//             return remainingProductIds;
//           }
//         } else {
//           // console.error("Expected purchasedVideos to be an array, but got:", purchasedVideos);
//         }
//       } else {
//         // console.error("Failed to fetch purchased courses for validation.");
//       }
//     } catch (error) {
//       // console.error("Error checking purchased courses:", error);
//     }

//     return productId;
//   };

//   const payMe = async () => {
//     try {
//       if (!userDetails || !userDetails._id) {
//         Swal.fire({
//           icon: "error",
//           title: "Error",
//           text: "Please log in to make a purchase.",
//         });
//         return;
//       }

//       // Check for already purchased courses before proceeding to payment
//       const filteredProductIds = await checkAndRemovePurchasedCourses();

//       if (filteredProductIds.length === 0) {
//         Swal.fire({
//           icon: "info",
//           title: "Purchased courses removed; proceed with remaining.",
//           text: "Your cart includes previously purchased courses. These will be removed at checkout, and the remaining items will be available for purchase.",
//           confirmButtonText: "OK",
//         });
//         return;
//       }

//       // If courses were removed, wait for the user to click OK before proceeding
//       if (filteredProductIds.length < productId.length) {
//         await Swal.fire({
//           icon: "info",
//           title: "Course Already Purchased",
//           text: "Some of the courses in your cart have already been purchased and are still valid. These have been removed from your cart.",
//           confirmButtonText: "OK",
//         });
//       }

//       const data = {
//         userId: userDetails._id, // Use _id instead of userId
//         productId: filteredProductIds, // Use the filtered array of product IDs
//         userName: userDetails.full_name,
//         userEmail: userDetails.email_id,
//         userMobileNo: userDetails.mobile_number,
//         duration,
//       };

//       const url = "https://auth.ssccglpinnacle.com/api/videos_payment";
//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         if (responseData.error) {
//           Swal.fire({
//             icon: "error",
//             title: "Error",
//             text: responseData.error,
//           });
//         } else {
//           doPayment(responseData.cftoken, responseData.orderId);
//         }
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Error",
//           text: "Failed to initiate payment. Please try again.",
//         });
//       }
//     } catch (err) {
//       console.error("Error fetching data:", err);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: "An error occurred. Please try again.",
//       });
//     }
//   };

//   return (
//     <button
//       onClick={payMe}
//       style={{
//         backgroundColor: "purple",
//         color: "white",
//         padding: "10px 20px",
//         border: "none",
//         borderRadius: "5px",
//         cursor: "pointer",
//       }}
//     >
//       {buttonText}
//     </button>
//   );
// };

// export default VideoBuyButton;


import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { load } from "@cashfreepayments/cashfree-js";
import "./VideosBuyButton.css";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

const VideoBuyButton = ({
  productId,
  userDetails,
  buttonText = "Checkout",
  duration = "12 Months",
  redeemCoins
}) => {
  const [cashfree, setCashfree] = useState(null);
  const navigate = useNavigate();
  const { token } = useAuth();

  useEffect(() => {
    const initializeSDK = async () => {
      const cashfreeInstance = await load({
        // mode: "sandbox",
        mode: "production",
      });
      setCashfree(cashfreeInstance);
    };
    initializeSDK();
  }, []);

  const doPayment = async (id, orderId) => {
    if (cashfree) {
      cashfree.checkout({
        paymentSessionId: id,
        returnUrl: `https://auth.ssccglpinnacle.com/api/videos_payment_status/${orderId}`,
        redirectTarget: "_blank",
        onSuccess: (paymentResponse) => {
          //   console.log("Payment successful", paymentResponse);
        },
        onFailure: (paymentResponse) => {
          //   console.error("PAYMENT FAILED", paymentResponse);
        },
      });

      setTimeout(() => {
        redeemCoinsAPI(orderId, userDetails._id, redeemCoins);
      }, 70000);
    } else {
      //   console.error("Cashfree SDK not initialized");
    }
  };

  const redeemCoinsAPI = async (orderId, userId, coinsToRedeem) => {
      console.log("RedeemCoinsAPI called with:", {
        orderId,
        userId,
        coinsToRedeem,
      });
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/redeem-coinsvideos/${orderId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId,
              coinsToRedeem,
            }),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to redeem coins");
        }
  
        const data = await response.json();
        console.log("Coins redeemed successfully:", data);
        Swal.fire({
          icon: "success",
          title: "Coins Redeemed",
          text: `Coins redeemed successfully. Remaining Coins: ${data.remainingCoins}`,
        });
      } catch (error) {
        console.error("Error redeeming coins:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to redeem coins. Please try again.",
        });
      }
    };

  const checkAndRemovePurchasedCourses = async () => {
    try {
      // Fetch purchased courses for the user
      const purchasedCoursesResponse = await fetch(
        `https://auth.ssccglpinnacle.com/api/user_purchased_videos/${userDetails._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (purchasedCoursesResponse.ok) {
        const purchasedCourses = await purchasedCoursesResponse.json();
        // console.log("Purchased Courses Response:", purchasedCourses);

        // Access the purchasedVideos array from the response object
        const purchasedVideos = purchasedCourses.purchasedVideos;

        if (Array.isArray(purchasedVideos)) {
          // Filter out courses that are still valid
          const validPurchasedCourses = purchasedVideos.filter((course) => {
            const expirationDate = new Date(course.expirationDate);
            return expirationDate > new Date();
          });

          // Map valid purchased course IDs
          const validPurchasedCourseIds = validPurchasedCourses.map(
            (course) => course.productId
          );

          // Filter out the product IDs that have already been purchased
          const remainingProductIds = productId.filter(
            (id) => !validPurchasedCourseIds.includes(id)
          );

          if (remainingProductIds.length < productId.length) {
            // Remove the purchased courses from the user's cart before showing Swal
            const removePurchasedCourses = await fetch(
              `https://auth.ssccglpinnacle.com/api/cart/remove-purchased`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  userId: userDetails._id,
                  productIds: validPurchasedCourseIds, // The purchased product IDs to be removed
                }),
              }
            );

            if (!removePurchasedCourses.ok) {
              // console.error('Failed to remove purchased courses from cart.');
            } else {
              // After successfully removing purchased items, show the Swal alert
              Swal.fire({
                icon: "info",
                title: "Course Already Purchased",
                text: "Some of the courses in your cart have already been purchased and are still valid. These have been removed from your cart.",
                confirmButtonText: "OK",
              });
            }

            // Update the productId array to only include non-purchased courses
            return remainingProductIds;
          }
        } else {
          // console.error("Expected purchasedVideos to be an array, but got:", purchasedVideos);
        }
      } else {
        // console.error("Failed to fetch purchased courses for validation.");
      }
    } catch (error) {
      // console.error("Error checking purchased courses:", error);
    }

    return productId;
  };

  const payMe = async () => {
    try {
      if (!userDetails || !userDetails._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to make a purchase.",
        });
        return;
      }

      // Check for already purchased courses before proceeding to payment
      const filteredProductIds = await checkAndRemovePurchasedCourses();

      if (filteredProductIds.length === 0) {
        Swal.fire({
          icon: "info",
          title: "Purchased courses removed; proceed with remaining.",
          text: "Your cart includes previously purchased courses. These will be removed at checkout, and the remaining items will be available for purchase.",
          confirmButtonText: "OK",
        });
        return;
      }

      // If courses were removed, wait for the user to click OK before proceeding
      if (filteredProductIds.length < productId.length) {
        await Swal.fire({
          icon: "info",
          title: "Course Already Purchased",
          text: "Some of the courses in your cart have already been purchased and are still valid. These have been removed from your cart.",
          confirmButtonText: "OK",
        });
      }

      const data = {
        userId: userDetails._id, // Use _id instead of userId
        productId: filteredProductIds, // Use the filtered array of product IDs
        userName: userDetails.full_name,
        userEmail: userDetails.email_id,
        userMobileNo: userDetails.mobile_number,
        duration,
        redeemCoins
      };

      const url = "https://auth.ssccglpinnacle.com/api/videos_payment";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: responseData.error,
          });
        } else {
          doPayment(responseData.cftoken, responseData.orderId);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to initiate payment. Please try again.",
        });
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <button
      onClick={payMe}
      style={{
        backgroundColor: "purple",
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      {buttonText}
    </button>
  );
};

export default VideoBuyButton;

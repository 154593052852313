// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  width: 100%;
  height: 30px;
  background-color: #ccc;
  position: relative;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
  text-align: center;
  line-height: 30px;
  color: white;
}
.for-show-percent{
    display: flex;
    justify-content: end;
    font-size: 13px;
    font-weight: bolder;
}
.S{
  padding: 5px;
  font-weight: 600;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/User/ProgressBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;AACd;AACA;IACI,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,mBAAmB;AACvB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".progress-bar {\n  width: 100%;\n  height: 30px;\n  background-color: #ccc;\n  position: relative;\n}\n\n.progress-bar-fill {\n  height: 100%;\n  background-color: #4caf50;\n  transition: width 0.3s ease;\n  text-align: center;\n  line-height: 30px;\n  color: white;\n}\n.for-show-percent{\n    display: flex;\n    justify-content: end;\n    font-size: 13px;\n    font-weight: bolder;\n}\n.S{\n  padding: 5px;\n  font-weight: 600;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

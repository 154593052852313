import classe from "./Header12.module.css";
import { BsCircleHalf } from "react-icons/bs";
import Nav2 from "./Nav2";
import React, { useContext } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Context/AuthContext";
import "./a.css";
import { useCookies } from 'react-cookie';

function Header2() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { logout } = useAuth(); // Use logout function from AuthContext
  const navigate = useNavigate();
  const [cookies] = useCookies(['token', 'email_id']);

  const handleThemeToggle = () => {
    toggleTheme(theme === "light" ? "dark" : "light");
  };

  const handleClick2 = () => {
    navigate('/ebooks');
  };


  return (
    <>
      <nav className={classe.navDigital2}>
        <Nav2 />
        <div className={classe.navlistsdigital_2}>
          {/* <div className="browse-other-content" onClick={handleClick2}>
            Other Ebooks
          </div> 
          <div
            className={`menu-wrap ${theme === "dark" ? "dark-theme" : "light-theme"}`}
            onClick={handleThemeToggle}
          >
            <BsCircleHalf className="Home" fontSize="19px" />
          </div>
         
          <div className="log-out-user" onClick={logout}>
            Logout
          </div>
           */}
        </div>
      </nav>
    </>
  );
}

export default Header2;

import React, { useState } from "react";
import VideoFeatures from "./VideoFeatures";
import Categories from "./Categories";
import Comments from "./Comments";
import classes from "./Body.module.css";
import RecLunchedCourse from "./RecLunchedCourse";
import MostPopularCourses from "./MostPopularCourses";
import Modal from "react-bootstrap/Modal";
import { IoIosChatbubbles } from "react-icons/io";
import Chatbot from "../../../chatBot/Chatbot";

export default function Body() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [showChatbot, setShowChatbot] = useState(false);
  const handleChatbotOpen = () => setShowChatbot(true);
  const handleChatbotClose = () => setShowChatbot(false);

  // console.log("Selected Category:", selectedCategory);
  // console.log("Selected Subjects:", selectedSubjects);

  return (
    <>
      <div className={classes.Body_FullPage}>
        <div className={classes["Categories-VideoFeatures-Comments-div"]}>
          <div className={classes.categories_component}>
            {/* Passing the category and subject state handlers to Categories */}
            <Categories
              onCategorySelect={setSelectedCategory}
              onSubjectSelect={setSelectedSubjects}
            />
          </div>
          <div className={classes.videoAndComments_div}>
            <div>
              <VideoFeatures />
                        {/* Chatbot Icon */}
                        <div className={classes.chatbot_icon}>
                          <IoIosChatbubbles
                            onClick={handleChatbotOpen}
                            size={50}
                            style={{
                              cursor: "pointer",
                              position: "fixed",
                              bottom: "20px",
                              right: "20px",
                              color: "#007bff",
                            }}
                          />
                        </div>
                                  {/* Chatbot Modal */}
          <Modal show={showChatbot} onHide={handleChatbotClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Chatbot />
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleChatbotClose}>
            Close
          </Button>
        </Modal.Footer> */}
          </Modal>

            </div>
            <div className={classes.Comments_div}>
              <Comments />
            </div>
          </div>
        </div>

        <div className={classes.RecLunchedCourse_div}>
          {/* Passing selectedCategory and selectedSubjects to RecLunchedCourse */}
          <RecLunchedCourse
            category={selectedCategory}
            subject={selectedSubjects}
          />
        </div>
        {/* <div className={classes.UpcomingCourse_div}>
          <UpcomingCourse
            category={selectedCategory}
            subject={selectedSubjects}
          />
        </div> */}
        <div className={classes.MostPopularCourses_div}>
          <MostPopularCourses
            category={selectedCategory}
            subject={selectedSubjects}
          />
        </div>
      </div>
    </>
  );
}

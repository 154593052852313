import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./Row3.css"; // Make sure you have this CSS file
import pic from "../../Ebook/i/book.jpg";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import gsap from "gsap";

function Row3() {
  const [cardData, setCardData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
        );
        const data = await response.json();

        // Sort the data by the `order` field
        data.sort((a, b) => a.order - b.order);
        setCardData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Slide click
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector(".ebookhome-card").offsetWidth; // Width of one card
    const shift = cardWidth * 5 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;

    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;

    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }

    if (targetScroll < 0) {
      targetScroll = 0;
    }

    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);

        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);

    // Check if the scroll has reached the end
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  const formatTitleForUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, "-");
  };

  const createLinkPath = (card) => {
    const formattedExamCategory = formatTitleForUrl(card.category);
    const formattedSubjects = formatTitleForUrl(card.subject);
    const formattedTitle = formatTitleForUrl(card.title);
    // return `/ebook/${formattedExamCategory}/${formattedSubjects}/${formattedTitle}`;
    return `ebook${card.permalink}`;
  };

  const navigateToEbookIntro = (card) => {
    // const formattedExamCategory = formatTitleForUrl(card.category);
    // const formattedSubjects = formatTitleForUrl(card.subject);
    // const formattedTitle = formatTitleForUrl(card.title);
    const url = card.permalink;
    navigate(`/ebook${url}`);
  };

  return (
    <>
      <section className="ebookhome-section-suggest-book">
        <div className="ebookhome-container-fluid">
          <div className="ebookhome-foruppersection">
            <div className="col-md-8 ebookhome-section-suggest-book-col-8">
              <p className="ebookhome-section-suggest-book-p">
                Suggested for You: ebook
              </p>
              <p className="ebookhome-section-suggest-book-second-p">
                Similar to items You Viewed
              </p>
            </div>
            <div className="col-md-4 ebookhome-section-suggest-book-col-4">
              <div className="ebookhome-book-viewall-button">
                <Link to={`/ebooks`}>
                  {" "}
                  <button className="ebookhome-viewall-button" type="button">
                    VIEW ALL
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="ebookhome-container-for-button">
            {scrollX !== 5 && (
              <button className="prev" onClick={() => slide(-1)}>
                <AiOutlineLeft color="black" fontSize="1.5em" />
              </button>
            )}
            <div className="ebookhome-forcontainerbook">
              <ul className="ebookhome-cards" ref={scrl} onScroll={scrollCheck}>
                {cardData.map((card) => (
                  <li key={card._id} className="ebookhome-card">
                    <div>
                      <div className="ebookhome-card-title-image">
                        <img
                          className="ebookhome-card-title"
                          src={card.image || pic}
                          alt={card.title}
                          onClick={() => navigateToEbookIntro(card)}
                        />
                      </div>
                      <div className="ebookhome-card-content">
                        <p>{card.title}</p>
                        <div className="ebookhome-Link">
                          <Link
                            to={createLinkPath(card)}
                            className="description-link"
                          >
                            Description
                          </Link>

                          <Link to={`/ebooks`} className="description-link">
                            BuyNow
                          </Link>
                        </div>
                        {/* <p className="ebookhome-choose-marketplace">Choose your favourite marketplace</p> */}
                      </div>
                    </div>
                    {/* <div className="ebookhome-card-link-wrapper">
                      <div className="ebookhome-descriptive-row row">
                        <div className="ebookhome-description-col col-md-4">
                          <img className="ebookhome-website-logo ebookhome-website-logo2" src="https://ssccglpinnacle.com/images/pinnacle_logo_new.png" alt="Pinnacle logo" />
                          <a href={card.pinnacleLink}><p className="ebookhome-description_p">Best 7 offers <img className="ebookhome-open-page-image" src="https://ssccglpinnacle.com/images/open-page-image.png" alt="Open page" /></p></a>
                        </div>
                        <div className="ebookhome-description-col col-md-4">
                          <img className="ebookhome-website-logo ebookhome-website-logo2" src="https://ssccglpinnacle.com/images/amazon-logo-transparent.png" alt="Amazon logo" />
                          <a href={card.amazonLink}><p className="ebookhome-description_p ebookhome-description_p2">Check</p></a>
                        </div>
                        <div className="ebookhome-description-col col-md-4">
                          <img className="ebookhome-website-logo ebookhome-website-logo3" src="https://ssccglpinnacle.com/images/flipkart3.png" alt="Flipkart logo" />
                          <a href={card.flipkartLink}><p className="ebookhome-description_p ebookhome-description_p3">Check</p></a>
                        </div>
                      </div>
                    </div> */}
                  </li>
                ))}
              </ul>
            </div>
            {!scrolEnd && (
              <button className="next" onClick={() => slide(1)}>
                <AiOutlineRight color="black" fontSize="1.5em" />
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Row3;

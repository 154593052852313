import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./EbookNewForm.css";

// Helper function to generate a slug
const generateSlug = (text) => {
  return text
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading and trailing spaces
    .replace(/[^a-z0-9\s/-]/g, "") // Remove special characters except spaces, hyphens, and forward slashes
    .replace(/(\s*-\s*)/g, "-") // Replace spaces around hyphens with a single hyphen
    .replace(/\s+/g, "-") // Replace remaining spaces with hyphens
    .replace(/\/+/g, "/") // Replace multiple slashes with a single slash
    .replace(/^\/|\/$/g, ""); // Remove leading and trailing slashes
};

const EbookNewForm = () => {
  const [ebooks, setEbooks] = useState([]);
  const [selectedSku, setSelectedSku] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    medium: "",
    sku: "",
    subject: "",
    examCategory: "",
    examName: "",
    mrp: 0,
    pinnacleSP: 0,
    ebookPrintingPrice: 0,
    ebookSellingPrice: 0,
    discount: 0,
    search: "",
    desc: "",
    edition: "",
    author: "",
    publishingDate: "",
    img: "",
    selfImg: "", // Added field for self-image
    subscriptionPrices: [], // Added field for subscription prices
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    permalink: "",
  });
  const [selectedImageFile, setSelectedImageFile] = useState(null); // To hold the selected image file
  const [generating, setGenerating] = useState({
    seoTitle: false,
    seoKeywords: false,
    seoDescription: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://nodei.ssccglpinnacle.com/getlist"
        );
        if (!response.ok) {
          throw new Error("Server response not OK");
        }
        const data = await response.json();
        setEbooks(data);
        // console.log("Fetched ebooks:", data);
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const { examCategory, subject, title, edition, medium } = formData;

  //   const combinedValue = `${examCategory || ""}/${subject || ""}/${
  //     title || ""
  //   }-${edition || ""}-edition-${medium || ""}-medium`;

  //   const sanitizedValue = combinedValue
  //     .toLowerCase() // Convert to lowercase
  //     .trim() // Remove leading and trailing spaces
  //     .replace(/[^a-z0-9\s/-]/g, "") // Remove special characters except spaces, hyphens, and forward slashes
  //     .replace(/(\s*-\s*)/g, "-") // Replace spaces around hyphens with a single hyphen
  //     .replace(/\s+/g, "-") // Replace remaining spaces with hyphens
  //     .replace(/\/+/g, "/") // Replace multiple slashes with a single slash
  //     .replace(/^\/|\/$/g, "");

  //   setFormData((prev) => ({
  //     ...prev,
  //     permalink: sanitizedValue,
  //   }));
  //   // console.log("Generated permalink:", sanitizedValue);
  // }, [
  //   formData.examCategory,
  //   formData.subject,
  //   formData.title,
  //   formData.edition,
  //   formData.medium,
  // ]);
  useEffect(() => {
    const { title, medium, edition, examCategory, subject } = formData;

    if (title) {
      const hasMedium = title.toLowerCase().includes("medium");
      const hasEdition = title.toLowerCase().includes("edition");

      let slug;

      if (hasMedium && hasEdition) {
        // If both "medium" and "edition" are present in the title
        slug = `/${generateSlug(examCategory || "")}/${generateSlug(
          subject || ""
        )}/${generateSlug(title)}`;
      } else if (hasEdition && !hasMedium) {
        // If "edition" is present but not "medium"
        slug = `/${generateSlug(examCategory || "")}/${generateSlug(
          subject || ""
        )}/${generateSlug(title)}-${generateSlug(medium || "")}-medium`;
      } else if (!hasEdition && hasMedium) {
        // If "medium" is present but not "edition"
        slug = `/${generateSlug(examCategory || "")}/${generateSlug(
          subject || ""
        )}/${generateSlug(title)}-${generateSlug(edition || "")}-edition`;
      } else {
        // Default slug when neither "medium" nor "edition" is present in the title
        slug = `/${generateSlug(examCategory || "")}/${generateSlug(
          subject || ""
        )}/${generateSlug(title)}-${generateSlug(
          edition || ""
        )}-edition-${generateSlug(medium || "")}-medium`;
      }

      // Set the generated slug
      setFormData((prev) => ({
        ...prev,
        permalink: slug,
      }));
    }
  }, [
    formData.title,
    formData.medium,
    formData.edition,
    formData.examCategory,
    formData.subject,
  ]);

  const handleTitleChange = (selectedSku) => {
    const selectedEbookData = ebooks.find(
      (ebook) =>
        ebook.sku &&
        ebook.sku.trim().toLowerCase() === selectedSku.trim().toLowerCase()
    );

    if (selectedEbookData) {
      setSelectedSku(selectedSku);

      // Populate form fields with selected ebook data
      setFormData({
        title: selectedEbookData.title || "",
        medium: selectedEbookData.Medium || "",
        sku: selectedEbookData.sku || "",
        subject: selectedEbookData.subjects || "",
        examCategory: selectedEbookData.examCategory || "",
        examName: selectedEbookData.examName || "",
        mrp: selectedEbookData.mrp || 0,
        pinnacleSP: selectedEbookData.pinnacleSP || 0,
        ebookPrintingPrice: selectedEbookData.ebookPrice || 0,
        ebookSellingPrice: selectedEbookData.ebookSellingPrice || 0,
        discount: selectedEbookData.discount || 0,
        search: selectedEbookData.Search || "",
        desc: selectedEbookData.Desc || "",
        edition: selectedEbookData.edition || "",
        author: selectedEbookData.author || "",
        publishingDate: selectedEbookData.publishingDate || "",
        img: selectedEbookData.img || "",
        selfImg: "",
        subscriptionPrices: selectedEbookData.subscriptionPrices || [],
      });
      // console.log("Selected ebook data:", selectedEbookData);
    } else {
      console.error(`Ebook data not found for SKU: ${selectedSku}`);
    }
  };

  const generateSEOField = async (field) => {
    const { title, edition, medium, subject, examCategory, examName } =
      formData;

    // // Log formData to debug missing fields
    // console.log("Generating SEO for:", {
    //   title,
    //   edition,
    //   medium,
    //   subject,
    //   examCategory,
    //   examName,
    //   field,
    // });

    // Check if all required fields are filled
    if (
      !title ||
      !edition ||
      !medium ||
      !subject ||
      !examCategory ||
      !examName
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all required fields before generating SEO metadata.",
      });
      return;
    }

    setGenerating((prev) => ({ ...prev, [field]: true }));

    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/seo/ebook/generate",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title,
            edition,
            medium,
            subject,
            examCategory,
            examName,
            field,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to generate metadata");
      }

      setFormData((prev) => ({
        ...prev,
        [field]: data[field],
      }));

      Swal.fire({
        icon: "success",
        title: "Generated Successfully",
        text: `${field} generated successfully.`,
      });
      // console.log(`Generated ${field}:`, data[field]);
    } catch (error) {
      console.error(`Error generating ${field}:`, error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Failed to generate ${field}. Please try again.`,
      });
    } finally {
      setGenerating((prev) => ({ ...prev, [field]: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = [
      "title",
      "medium",
      "sku",
      "subject",
      "examCategory",
      "examName",
      "mrp",
      "pinnacleSP",
      "edition",
      "author",
      "publishingDate",
      "seoTitle",
      "seoKeywords",
      "seoDescription",
      "permalink",
    ];

    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please fill all required fields: ${missingFields.join(", ")}`,
      });
      return;
    }

    if (!formData.img && !selectedImageFile) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please provide an image before submission.",
      });
      return;
    }

    const formDataToSend = new FormData();

    // Handle image logic
    if (selectedImageFile) {
      formDataToSend.append("image", selectedImageFile);
    } else if (formData.img) {
      formDataToSend.append("existingImage", formData.img);
    }

    // Append all other fields
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "subscriptionPrices") {
        formDataToSend.append(key, JSON.stringify(value));
      } else if (key !== "selfImg") {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/v1/ebooks",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to save ebook data.");
      }

      Swal.fire({
        icon: "success",
        title: "Ebook Saved Successfully",
      });

      // Reset form
      setFormData({
        title: "",
        medium: "",
        sku: "",
        subject: "",
        examCategory: "",
        examName: "",
        mrp: 0,
        pinnacleSP: 0,
        ebookPrintingPrice: 0,
        ebookSellingPrice: 0,
        discount: 0,
        search: "",
        desc: "",
        edition: "",
        author: "",
        publishingDate: "",
        img: "",
        selfImg: "",
        subscriptionPrices: [],
        seoTitle: "",
        seoKeywords: "",
        seoDescription: "",
        permalink: "",
      });
      setSelectedSku("");
      setSelectedImageFile(null);
    } catch (error) {
      console.error("Error saving ebook data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save ebook data. Please try again.",
      });
    }
  };

  const handleImgChange = (e) => {
    const imgUrl = e.target.src;
    const imgName = imgUrl.substring(imgUrl.lastIndexOf("/") + 1);

    setFormData({
      ...formData,
      img: imgName,
    });
  };

  const handleSelfImgChange = (e) => {
    const selfImgUrl = e.target.src;
    const selfImgName = selfImgUrl.substring(selfImgUrl.lastIndexOf("/") + 1);

    setFormData({
      ...formData,
      selfImg: selfImgName,
    });
  };

  const addSubscriptionPrice = () => {
    if (formData.subscriptionPrices.length < 1) {
      setFormData({
        ...formData,
        subscriptionPrices: [
          ...formData.subscriptionPrices,
          { duration: "12 Months", sellingPrice: 0, printingPrice: 0 },
        ],
      });
    }
  };

  const handleSubscriptionPriceChange = (index, field, value) => {
    const updatedSubscriptionPrices = formData.subscriptionPrices.map(
      (price, i) => (i === index ? { ...price, [field]: value } : price)
    );
    setFormData({
      ...formData,
      subscriptionPrices: updatedSubscriptionPrices,
    });
  };

  return (
    <>
      <div className="ebook-new-form-container">
        <h2 className="add-ebook-form">Add digital content</h2>
        <form onSubmit={handleSubmit} className="ebook-form-new">
          <div className="form-group">
            <label htmlFor="selectSku" className="label">
              Select SKU:
            </label>
            <Select
              id="selectSku"
              value={{ label: selectedSku, value: selectedSku }} // Use the correct format for value
              onChange={(option) => handleTitleChange(option.value)}
              options={ebooks.map((ebook) => ({
                label: ebook.sku,
                value: ebook.sku,
              }))}
              className="title-select"
            />
          </div>

          <div className="form-group">
            <label htmlFor="skuInput" className="label">
              Title:
            </label>
            <input
              type="text"
              id="skuInput"
              value={formData.title}
              readOnly
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="mediumInput" className="label">
              Medium:
            </label>
            <input
              type="text"
              id="mediumInput"
              value={formData.medium}
              readOnly
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="editionInput" className="label">
              Edition:
            </label>
            <input
              type="text"
              id="editionInput"
              value={formData.edition}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, edition: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="subjectsInput" className="label">
              Subjects:
            </label>
            <input
              type="text"
              id="subjectsInput"
              value={formData.subject}
              onChange={(e) =>
                setFormData({ ...formData, subject: e.target.value })
              }
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="examCategoryInput" className="label">
              Exam Category:
            </label>
            <input
              type="text"
              id="examCategoryInput"
              value={formData.examCategory}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, examCategory: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="examNameInput" className="label">
              Exam Name:
            </label>
            <input
              type="text"
              id="examNameInput"
              value={formData.examName}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, examName: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="mrpInput" className="label">
              Book MRP:
            </label>
            <input
              type="number"
              id="mrpInput"
              value={formData.mrp}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, mrp: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="pinnacleSPInput" className="label">
              Book selling price:
            </label>
            <input
              type="number"
              id="pinnacleSPInput"
              value={formData.pinnacleSP}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, pinnacleSP: e.target.value })
              }
            />
          </div>

          {/* <div className="form-group">
            <label htmlFor="ebookPriceInput" className="label">
              Ebook printing price:
            </label>
            <input
              type="number"
              id="ebookPriceInput"
              value={formData.ebookPrintingPrice}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, ebookPrintingPrice: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="ebookSellingPriceInput" className="label">
              Ebook selling price:
            </label>
            <input
              type="number"
              id="ebookSellingPriceInput"
              value={formData.ebookSellingPrice}
              className="form-input"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  ebookSellingPrice: e.target.value,
                })
              }
            />
          </div> */}

          <div className="form-group">
            <label className="label subscription-group-price">
              Subscription Prices:
            </label>
            {formData.subscriptionPrices.map((price, index) => (
              <div key={index} className="subscription-price-group">
                <label className="label">Duration:</label>
                <select
                  value={price.duration}
                  onChange={(e) =>
                    handleSubscriptionPriceChange(
                      index,
                      "duration",
                      e.target.value
                    )
                  }
                  className="form-input subscription-duration"
                >
                  <option value="">Select Duration</option>
                  <option value="12 Months">12 Months</option>
                </select>
                <label htmlFor="ebookPriceInput" className="label">
                  Ebook MRP:
                </label>
                <input
                  type="number"
                  value={price.printingPrice}
                  onChange={(e) =>
                    handleSubscriptionPriceChange(
                      index,
                      "printingPrice",
                      e.target.value
                    )
                  }
                  className="form-input subscription-price"
                  placeholder="Enter Printing Price"
                />
                <label htmlFor="ebookSellingPriceInput" className="label">
                  Ebook selling price:
                </label>
                <input
                  type="number"
                  value={price.sellingPrice}
                  onChange={(e) =>
                    handleSubscriptionPriceChange(
                      index,
                      "sellingPrice",
                      e.target.value
                    )
                  }
                  className="form-input subscription-price"
                  placeholder="Enter Selling Price"
                />
              </div>
            ))}
            <button
              type="button"
              onClick={addSubscriptionPrice}
              className="add-subscription-price-button"
              disabled={formData.subscriptionPrices.length >= 1}
            >
              Add Subscription Price
            </button>
          </div>

          <div className="form-group">
            <label htmlFor="discountInput" className="label">
              Discount: 10%
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="searchInput" className="label">
              Search:
            </label>
            <input
              type="text"
              id="searchInput"
              value={formData.search}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, search: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="descInput" className="label">
              Desc:
            </label>
            <input
              type="text"
              id="descInput"
              value={formData.desc}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, desc: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="authorInput" className="label">
              Author:
            </label>
            <input
              type="text"
              id="authorInput"
              value={formData.author}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, author: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="publishingDate" className="label">
              Date for publishing:
            </label>
            <input
              type="date"
              id="publishingDate"
              value={formData.publishingDate}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, publishingDate: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <div className="form-group-img">
              <label htmlFor="imgInput" className="label">
                Image:
              </label>
              <div className="photo-of-ebook">
                <img
                  // src={`http://13.200.156.92:5000/${formData.img}`}
                  src={`https://nodei.ssccglpinnacle.com/${formData.img}`}
                  id="pic"
                  alt="foto"
                  onLoad={handleImgChange} // Call handleImgChange when the image is loaded
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="form-group-img">
              <label htmlFor="selfImgInput" className="label">
                Self Image:
              </label>
              <input
                type="file"
                id="selfImgInput"
                className="form-input-new"
                onChange={(e) => setSelectedImageFile(e.target.files[0])}
              />
              {selectedImageFile && (
                <div className="photo-of-self">
                  <img
                    src={URL.createObjectURL(selectedImageFile)}
                    alt="Self"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="seoTitle" className="label">
              SEO Title:
            </label>
            <textarea
              id="seoTitle"
              value={formData.seoTitle}
              onChange={(e) =>
                setFormData({ ...formData, seoTitle: e.target.value })
              }
              className="form-input"
            />
            <button
              type="button"
              onClick={() => generateSEOField("seoTitle")}
              className="generate-button"
              disabled={generating.seoTitle}
            >
              {generating.seoTitle ? "Generating..." : "Generate Title"}
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="seoKeywords" className="label">
              SEO Keywords:
            </label>
            <textarea
              id="seoKeywords"
              value={formData.seoKeywords}
              onChange={(e) =>
                setFormData({ ...formData, seoKeywords: e.target.value })
              }
              className="form-input"
            />
            <button
              type="button"
              onClick={() => generateSEOField("seoKeywords")}
              className="generate-button"
              disabled={generating.seoKeywords}
            >
              {generating.seoKeywords ? "Generating..." : "Generate Keywords"}
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="seoDescription" className="label">
              SEO Description:
            </label>
            <textarea
              id="seoDescription"
              value={formData.seoDescription}
              onChange={(e) =>
                setFormData({ ...formData, seoDescription: e.target.value })
              }
              className="form-input"
            />
            <button
              type="button"
              onClick={() => generateSEOField("seoDescription")}
              className="generate-button"
              disabled={generating.seoDescription}
            >
              {generating.seoDescription
                ? "Generating..."
                : "Generate Description"}
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="permalinkInput" className="label">
              Permalink:
            </label>
            <input
              type="text"
              id="permalinkInput"
              value={formData.permalink}
              className="form-input"
              readOnly
            />
          </div>
          <div className="form-group">
            <div className="bbb">
              <button type="submit" className="submit-ebook-button">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EbookNewForm;

import React, { useEffect, useState } from "react";
import { FaHome, FaKeyboard, FaBars, FaTimes } from 'react-icons/fa';
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { HiOutlineBookOpen } from "react-icons/hi";
import { AiOutlineLineChart, AiOutlineUser } from "react-icons/ai";
import { MdOndemandVideo } from "react-icons/md";
import { ImBlogger } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { useCookies } from "react-cookie";
import { FaCoins } from "react-icons/fa";
import "./Navbar2.css"

function Navbar1() {
  const navigate = useNavigate();
  const { user, isLoggedIn, logout } = useAuth();
  const [cookies] = useCookies(["token"]);
  const [hasTypingAccess, setHasTypingAccess] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const checkAccessTypingProduct = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
            body: JSON.stringify({ product_id: "999" }),
        });

        if (response.ok) {
          const { access: productAccess } = await response.json();
          setHasTypingAccess(productAccess === "access");
        } else {
          console.log("Product access check failed");
        }
      } catch (error) {
        console.error("Error checking product access:", error);
      }
    };

    checkAccessTypingProduct();
  }, [cookies.token]);

  const handleUserIconClick = () => {
    if (isLoggedIn) {
      navigate("/ebooks/mylearning");
    } else {
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
    }
  };

  const info3 = () => {
    navigate("/");
  };

  const book = () => {
    navigate("/books");
  };

  const blog = () => {
    navigate("/blogs");
  };

  return (
    <>
      <button className="overlay-button" onClick={toggleSidebar}>
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>
      {/* <div className={`overlay ${isSidebarOpen ? 'active' : ''}`} onClick={toggleSidebar}></div> */}
      <nav className={`nav2book ${isSidebarOpen ? 'active' : ''}`}>
        <ul className="navlistsbook">
          <li onClick={info3}>
            <FaHome color="white" fontSize="1.5em" />
            &nbsp;Home
          </li>
          <li onClick={() => (window.location.href = "https://testportal.ssccglpinnacle.com/")}>
            <AiOutlineLineChart color="white" fontSize="1.5em" />
            &nbsp;Test Portal
          </li>
          <li>
            <Link to={hasTypingAccess ? "/typingexamselection" : "/typing"}>
              <FaKeyboard color="white" fontSize="1.5em" />
              &nbsp;Typing
            </Link>
          </li>
          <li onClick={book}>
            <HiOutlineBookOpen color="white" fontSize="1.5em" />
            &nbsp;Books
          </li>
          <li>
            <Link to="/videos">
              <MdOndemandVideo color="white" fontSize="1.5em" />
              &nbsp;Videos
            </Link>
          </li>
          <li onClick={() => navigate("/ebooks")}>
            <BsFillMenuButtonWideFill color="white" fontSize="1.5em" />
            &nbsp;E-Books
          </li>
   <li onClick={() => navigate("/coins")}>
      <FaCoins color="white" fontSize="1em" />
      &nbsp;Coins
    </li>
          <li onClick={blog}>
            <ImBlogger color="white" fontSize="1.5em" />
            &nbsp;Blogs
          </li>
          <li onClick={handleUserIconClick}>
            <AiOutlineUser color="white" fontSize="1.5em" />
            &nbsp;{isLoggedIn ? "My Learning" : "Login"}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar1;

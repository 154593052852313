import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ReviewForm from "../BlogProject/BlogContainer/ReviewForm";
import "./BookNewForm.css"; // Import your CSS file

const BookNewForm = () => {
  const [Books, setBooks] = useState([]);
  const [selectedSku, setSelectedSku] = useState("");
  const [selectedBook, setSelectedBook] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [seoLimits] = useState({
    title: 60,
    keywords: 120,
    description: 160,
  });

  const [generating, setGenerating] = useState({
    seoTitle: false,
    seoKeywords: false,
    seoDescription: false,
  });

  const [formData, setFormData] = useState({
    title: "",
    medium: "",
    sku: "",
    subjects: "",
    examCategory: "",
    examName: "",
    BookPrintingPrice: 0,
    BookSellingPrice: 0,
    discount: 0,
    search: "",
    desc: "",
    edition: "",
    amazonLink: "",
    flipkartLink: "",
    snapdealLink: "",
    author: "",
    publishingDate: "",
    para1: "",
    para2: "",
    para3: "",
    para4: "",
    whytakecourse: "",
    seoKeywords: "",
    seoDescription: "",
    seoTitle: "",
    permalink: "",
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    ISBN: "", // Added ISBN field
    pages: 0, // Added pages field
  });

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://nodei.ssccglpinnacle.com/getlist/"
        );
        if (!response.ok) {
          throw new Error("Server response not OK");
        }
        const data = await response.json();
        setBooks(data);
      } catch (error) {
        console.error("Error fetching Book data:", error);
      }
    };

    fetchData();
  }, []);
  const handleSeoChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  // const handleTitleChange = (selectedSku) => {
  //   const selectedBookData = Books.find(
  //     (Book) => Book.sku && Book.sku.trim().toLowerCase() === selectedSku.trim().toLowerCase()
  //   );

  //   if (selectedBookData) {
  //     setSelectedSku(selectedSku);
  //     setSelectedBook(selectedBookData);

  //     setFormData({
  //       ...formData,
  //       title: selectedBookData.title || '',
  //       medium: selectedBookData.Medium || '',
  //       sku: selectedBookData.sku || '',
  //       subjects: selectedBookData.subjects || '',
  //       examCategory: selectedBookData.examCategory || '',
  //       examName: selectedBookData.examName || '',
  //       BookPrintingPrice: selectedBookData.mrp || 0,
  //       BookSellingPrice: selectedBookData.pinnacleSP || 0,
  //       discount: 0, // Assuming discount is not available in API data
  //       search: selectedBookData.Search || '',
  //       desc: selectedBookData.Desc || '',
  //       amazonLink: selectedBookData.Amazon || '',
  //       flipkartLink: selectedBookData.Flipkart || '',
  //       edition: selectedBookData.edition || '',
  //       author: selectedBookData.author || '',
  //       publishingDate: selectedBookData.date || '',
  //       seoKeywords: '', // Assuming these fields are not available in API data
  //       seoDescription: '',
  //       seoTitle: '',
  //       permalink: selectedBookData.permalink || '',
  //       image1: selectedBookData.img || null,
  //       image2: null,
  //       image3: null,
  //       image4: null,
  //       image5: null,
  //       pdf1: null,
  //       pdf2: null,
  //       ISBN: selectedBookData.isbn || '',        // Reset ISBN field
  //       pages: 0,

  //     });
  //     setImagePreviews([
  //       selectedBookData.img ? `path/to/images/${selectedBookData.img}` : null,
  //     ]);
  //   } else {
  //     console.error(`Book data not found for SKU: ${selectedSku}`);
  //   }
  // };
  const handleTitleChange = (selectedSku) => {
    const selectedBook = Books.find(
      (book) =>
        book.sku.trim().toLowerCase() === selectedSku.trim().toLowerCase()
    );

    if (!selectedBook) {
      Swal.fire({
        icon: "error",
        title: "Not Found",
        text: `No book found with SKU: ${selectedSku}`,
      });
      return;
    }

    // Generate permalink
    const generatedPermalink = (selectedBook.title || "")
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-|-$/g, "");

    setSelectedSku(selectedSku);
    setFormData({
      title: selectedBook.title || "",
      medium: selectedBook.Medium || "",
      sku: selectedBook.sku || "",
      subjects: selectedBook.subjects || "",
      examCategory: selectedBook.examCategory || "",
      examName: selectedBook.examName || "",
      BookPrintingPrice: selectedBook.mrp || 0,
      BookSellingPrice: selectedBook.pinnacleSP || 0,
      desc: selectedBook.Desc || "",
      edition: selectedBook.edition || "",
      author: selectedBook.author || "",
      amazonLink: selectedBook.Amazon || "",
      flipkartLink: selectedBook.Flipkart || "",
      snapdealLink: selectedBook.Snapdeal || "",
      publishingDate: selectedBook.date || "",
      ISBN: selectedBook.isbn || "",
      search: selectedBook.Search || "",
      permalink: generatedPermalink,
      image1: selectedBook.img || null,
      pages: selectedBook.pages || 0,
      para1: "",
      para2: "",
      para3: "",
      para4: "",
      whytakecourse: "",
      seoKeywords: "",
      seoDescription: "",
      seoTitle: "",
      discount: 0,
    });

    setImagePreviews([
      selectedBook.img
        ? `https://nodei.ssccglpinnacle.com/${selectedBook.img}`
        : null,
    ]);
  };

  // useEffect(() => {
  //   const { examCategory, subjects, title, edition, medium } = formData;

  //   // Combine fields to form the permalink structure
  //   const combinedValue = `/${examCategory || ""}/${subjects || ""}/${
  //     title || ""
  //   }-${edition || ""}-edition-${medium || ""}-medium`;

  //   // Sanitize the combined value to create a valid URL
  //   const sanitizedValue = combinedValue
  //     .toLowerCase()
  //     .replace(/\s+/g, "-") // Replace spaces with hyphens
  //     .replace(/[^a-z0-9/-]/g, "") // Allow only lowercase letters, numbers, hyphens, and slashes
  //     .replace(/--+/g, "-") // Replace multiple hyphens with a single hyphen
  //     .replace(/\/+/g, "/") // Ensure no duplicate slashes
  //     .replace(/^\/|\/$/g, ""); // Remove leading or trailing slashes

  //   setFormData((prev) => ({
  //     ...prev,
  //     permalink: sanitizedValue,
  //   }));
  // }, [
  //   formData.examCategory,
  //   formData.subjects,
  //   formData.title,
  //   formData.edition,
  //   formData.medium,
  // ]);
  const generateSlug = (text) => {
    return text
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading and trailing spaces
      .replace(/[^a-z0-9\s/-]/g, "") // Remove special characters except spaces, hyphens, and forward slashes
      .replace(/(\s*-\s*)/g, "-") // Replace spaces around hyphens with a single hyphen
      .replace(/\s+/g, "-") // Replace remaining spaces with hyphens
      .replace(/\/+/g, "/") // Replace multiple slashes with a single slash
      .replace(/^\/|\/$/g, ""); // Remove leading and trailing slashes
  };
  useEffect(() => {
    const { examCategory, subjects, title, edition, medium } = formData;

    // Check if "medium" or "edition" is present in the title
    const hasMedium = title.toLowerCase().includes("medium");
    const hasEdition = title.toLowerCase().includes("edition");

    // Generate slug based on the conditions
    let slug;
    if (hasMedium && hasEdition) {
      // If both "medium" and "edition" are present in the title
      slug = `/${generateSlug(examCategory || "")}/${generateSlug(
        subjects || ""
      )}/${generateSlug(title || "")}`;
    } else if (hasEdition && !hasMedium) {
      // If "edition" is present but not "medium"
      slug = `/${generateSlug(examCategory || "")}/${generateSlug(
        subjects || ""
      )}/${generateSlug(title || "")}-${generateSlug(medium || "")}-medium`;
    } else if (!hasEdition && hasMedium) {
      // If "medium" is present but not "edition"
      slug = `/${generateSlug(examCategory || "")}/${generateSlug(
        subjects || ""
      )}/${generateSlug(title || "")}-${generateSlug(edition || "")}-edition`;
    } else {
      // Default slug when neither "medium" nor "edition" is present in the title
      slug = `/${generateSlug(examCategory || "")}/${generateSlug(
        subjects || ""
      )}/${generateSlug(title || "")}-${generateSlug(
        edition || ""
      )}-edition-${generateSlug(medium || "")}-medium`;
    }

    // Update formData with the generated slug
    setFormData((prev) => ({
      ...prev,
      permalink: slug,
    }));
  }, [
    formData.examCategory,
    formData.subjects,
    formData.title,
    formData.edition,
    formData.medium,
  ]);

  const handleImageChangeimg = (index, e) => {
    const file = e.target.files[0];
    const updatedPreviews = [...imagePreviews];
    updatedPreviews[index] = URL.createObjectURL(file);
    setFormData({
      ...formData,
      [`image${index + 1}`]: file,
    });
    setImagePreviews(updatedPreviews);
  };
  // Generate SEO or JSON-LD Fields

  const generateSEOField = async (field) => {
    const { title, edition, medium, subjects, examCategory, examName } =
      formData;

    if (
      !title ||
      !edition ||
      !medium ||
      !subjects ||
      !examCategory ||
      !examName
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all required book details before generating metadata.",
      });
      return;
    }
    // Set generating state to true for the specific field
    setGenerating((prev) => ({ ...prev, [field]: true }));
    try {
      console.log("Sending payload:", {
        title,
        edition,
        medium,
        subjects,
        examCategory,
        examName,
        field,
      });

      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/seo/generate",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title,
            edition,
            medium,
            subjects,
            examCategory,
            examName,
            field,
          }),
        }
      );

      const data = await response.json();
      console.log("API Response:", data);

      if (!response.ok) {
        throw new Error(data.error || "Failed to generate metadata");
      }

      // Dynamically update the formData for the respective field
      setFormData((prev) => ({
        ...prev,
        [field]: data[field],
      }));

      // Display success message
      Swal.fire({
        icon: "success",
        title: "Generated Successfully",
        text: `${field} generated successfully.`,
      });
    } catch (error) {
      console.error(`Error generating ${field}:`, error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Failed to generate ${field}. Please try again.`,
      });
    } finally {
      setGenerating((prev) => ({ ...prev, [field]: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = new FormData();

    // Convert `image1` to a File object if it is a string (filename)
    if (typeof formData.image1 === "string") {
      try {
        const response = await fetch(
          `https://nodei.ssccglpinnacle.com/${formData.image1}`
        );
        const blob = await response.blob();
        const file = new File([blob], formData.image1, { type: blob.type });
        postData.append("image1", file);
      } catch (error) {
        console.error("Error converting image1 string to file:", error);
      }
    } else if (formData.image1 instanceof File) {
      // If `image1` is already a File object, append it as is
      postData.append("image1", formData.image1);
    }

    // Append other images and pdfs directly if they exist
    ["image2", "image3", "image4", "image5", "pdf1", "pdf2"].forEach((key) => {
      if (formData[key] instanceof File) {
        postData.append(key, formData[key]);
      }
    });

    // Ensure single values are sent to the server, not arrays
    const singleValueFields = [
      "title",
      "medium",
      "sku",
      "subjects",
      "examCategory",
      "examName",
      "BookPrintingPrice",
      "BookSellingPrice",
      "discount",
      "search",
      "desc",
      "edition",
      "amazonLink",
      "flipkartLink",
      "snapdealLink",
      "author",
      "publishingDate",
      "para1",
      "para2",
      "para3",
      "para4",
      "whytakecourse",
      "seoKeywords",
      "seoDescription",
      "seoTitle",
      "permalink",
      "ISBN", // Added ISBN field
      "pages", // Added pages field
    ];

    singleValueFields.forEach((key) => {
      if (Array.isArray(formData[key])) {
        postData.append(key, formData[key][0]);
      } else {
        postData.append(key, formData[key]);
      }
    });

    console.log("Form data being sent:", postData);

    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/books",
        {
          method: "POST",
          body: postData,
        }
      );

      const responseBody = await response.json();
      console.log("Response from backend:", responseBody);
      if (!response.ok) {
        throw new Error(responseBody.error || "Server response not OK");
      }

      setFormData({
        title: "",
        medium: "",
        sku: "",
        subjects: "",
        examCategory: "",
        examName: "",
        BookPrintingPrice: 0,
        BookSellingPrice: 0,
        discount: 0,
        search: "",
        desc: "",
        edition: "",
        amazonLink: "",
        flipkartLink: "",
        snapdealLink: "",
        author: "",
        publishingDate: "",
        para1: "",
        para2: "",
        para3: "",
        para4: "",
        whytakecourse: "",
        seoKeywords: "",
        seoDescription: "",
        seoTitle: "",
        permalink: "",
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        pdf1: null,
        pdf2: null,
        ISBN: "", // Reset ISBN field
        pages: 0,
      });
      setImagePreviews([]);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Book data inserted successfully!",
      });
    } catch (error) {
      console.error("Error submitting Book data:", error);

      if (error.message.includes("Book with the same SKU already exists")) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Book with the same SKU already exists",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while submitting Book data. Please try again later.",
        });
      }
    }
  };

  const handleImgChange = (e) => {
    const imgUrl = e.target.src;

    // Check if the current image is already a URL (string), if yes, extract the image name
    if (typeof formData.image1 === "string") {
      const imgName = imgUrl.substring(imgUrl.lastIndexOf("/") + 1);

      setFormData({
        ...formData,
        image1: imgName,
      });
    }
  };

  const handlePdfChange = (index, e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      [`pdf${index}`]: file,
    });
  };

  const handleContentChange = (value) => {
    setFormData({ ...formData, whytakecourse: value });
  };

  return (
    <>
      <div className="Book-new-form-container">
        <h2 className="add-ebook-form">Add book</h2>
        <form onSubmit={handleSubmit} className="Book-form-new">
          <div className="form-group">
            <label htmlFor="selectSku" className="label">
              Select SKU:
            </label>
            <Select
              id="selectSku"
              value={{ label: selectedSku, value: selectedSku }}
              onChange={(option) => handleTitleChange(option.value)}
              options={Books.map((Book) => ({
                label: Book.sku,
                value: Book.sku,
              }))}
              className="title-select"
            />
          </div>

          <div className="form-group">
            <label htmlFor="skuInput" className="label">
              Title:
            </label>
            <input
              type="text"
              id="skuInput"
              value={formData.title}
              readOnly
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="mediumInput" className="label">
              Medium:
            </label>
            <input
              type="text"
              id="mediumInput"
              value={formData.medium}
              readOnly
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="editionInput" className="label">
              Edition:
            </label>
            <input
              type="text"
              id="editionInput"
              value={formData.edition}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, edition: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="subjectsInput" className="label">
              Subjects:
            </label>
            <input
              type="text"
              id="subjectsInput"
              value={formData.subjects}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, subjects: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="examCategoryInput" className="label">
              Exam Category:
            </label>
            <input
              type="text"
              id="examCategoryInput"
              value={formData.examCategory}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, examCategory: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="examNameInput" className="label">
              Exam Name:
            </label>
            <input
              type="text"
              id="examNameInput"
              value={formData.examName}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, examName: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="mrpInput" className="label">
              Book MRP:
            </label>
            <input
              type="number"
              id="mrpInput"
              value={formData.BookPrintingPrice}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, BookPrintingPrice: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="pinnacleSPInput" className="label">
              Book selling price:
            </label>
            <input
              type="number"
              id="pinnacleSPInput"
              value={formData.BookSellingPrice}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, BookSellingPrice: e.target.value })
              }
            />
          </div>

          {/* <div className="form-group">
            <label htmlFor="discountInput" className="label">
              Discount: 10%
            </label>
          </div> */}

          <div className="form-group">
            <label htmlFor="isbnInput" className="label">
              ISBN:
            </label>
            <input
              type="text"
              id="isbnInput"
              value={formData.ISBN}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, ISBN: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="pagesInput" className="label">
              Pages:
            </label>
            <input
              type="number"
              id="pagesInput"
              value={formData.pages}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, pages: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="searchInput" className="label">
              Search:
            </label>
            <input
              type="text"
              id="searchInput"
              value={formData.search}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, search: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="descInput" className="label">
              Desc:
            </label>
            <input
              type="text"
              id="descInput"
              value={formData.desc}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, desc: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="amazonLinkInput" className="label">
              Amazon Link:
            </label>
            <input
              type="text"
              id="amazonLinkInput"
              value={formData.amazonLink}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, amazonLink: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="flipkartLinkInput" className="label">
              Flipkart Link:
            </label>
            <input
              type="text"
              id="flipkartLinkInput"
              value={formData.flipkartLink}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, flipkartLink: e.target.value })
              }
            />
          </div>


          <div className="form-group">
            <label htmlFor="snapdealLinkInput" className="label">
              Snapdeal Link:
            </label>
            <input
              type="text"
              id="snapdealLinkInput"
              value={formData.snapdealLink}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, snapdealLink: e.target.value })
              }
            />
          </div>



          <div className="form-group">
            <label htmlFor="authorInput" className="label">
              Author:
            </label>
            <input
              type="text"
              id="authorInput"
              value={formData.author}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, author: e.target.value })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="publishingDate" className="label">
              Date for publishing:
            </label>
            <input
              type="date"
              id="publishingDate"
              value={formData.publishingDate}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, publishingDate: e.target.value })
              }
            />
          </div>

          {/* <div className="form-group">
            <label htmlFor="seoKeywordsInput" className="label">
              SEO Keywords:
            </label>
            <input
              type="text"
              id="seoKeywordsInput"
              value={formData.seoKeywords}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, seoKeywords: e.target.value })
              }
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label htmlFor="seoDescriptionInput" className="label">
              SEO Description:
            </label>
            <input
              type="text"
              id="seoDescriptionInput"
              value={formData.seoDescription}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, seoDescription: e.target.value })
              }
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label htmlFor="seoTitleInput" className="label">
              SEO Title:
            </label>
            <input
              type="text"
              id="seoTitleInput"
              value={formData.seoTitle}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, seoTitle: e.target.value })
              }
              autoComplete="off"
            />
          </div> */}

          {/* SEO Keywords Field */}

          <div className="book-form-group">
            <label htmlFor="seoTitle" className="book-form-label">
              SEO Title:
            </label>
            <textarea
              id="seoTitle"
              value={formData.seoTitle}
              onChange={(e) => handleSeoChange("seoTitle", e.target.value)}
              className="book-form-textarea"
              style={{
                color:
                  formData.seoTitle.length > seoLimits.title ? "red" : "black",
              }}
              rows="2"
              placeholder="Enter or Generate SEO Title"
            />
            <button
              type="button"
              onClick={() => generateSEOField("seoTitle")}
              className="book-form-generate-button"
              disabled={generating.seoTitle}
            >
              {generating.seoTitle ? "Generating..." : "Generate Title"}
            </button>

            <small className="book-form-character-count">
              {formData.seoTitle.length}/{seoLimits.title} characters
            </small>
          </div>

          {/* SEO Keywords Field */}
          <div className="book-form-group">
            <label htmlFor="seoKeywords" className="book-form-label">
              SEO Keywords (comma-separated, max {seoLimits.keywords}):
            </label>
            <textarea
              id="seoKeywords"
              value={formData.seoKeywords}
              onChange={(e) => handleSeoChange("seoKeywords", e.target.value)}
              className="book-form-textarea"
              style={{
                color:
                  formData.seoKeywords.split(",").filter((kw) => kw.trim())
                    .length > seoLimits.keywords
                    ? "red"
                    : "black",
              }}
              rows="3"
              placeholder="Enter or Generate SEO Keywords"
            />
            <button
              type="button"
              onClick={() => generateSEOField("seoKeywords")}
              className="book-form-generate-button"
              disabled={generating.seoKeywords} // Disable the button while generating
            >
              {generating.seoKeywords ? "Generating..." : "Generate Keywords"}
            </button>

            <small className="book-form-character-count">
              {formData.seoKeywords.split(",").filter((kw) => kw.trim()).length}
              /{seoLimits.keywords} keywords
            </small>
          </div>

          {/* SEO Description Field */}
          <div className="book-form-group">
            <label htmlFor="seoDescription" className="book-form-label">
              SEO Description:
            </label>
            <textarea
              id="seoDescription"
              value={formData.seoDescription}
              onChange={(e) =>
                handleSeoChange("seoDescription", e.target.value)
              }
              className="book-form-textarea"
              style={{
                color:
                  formData.seoDescription.length > seoLimits.description
                    ? "red"
                    : "black",
              }}
              rows="4"
              placeholder="Enter or Generate SEO Description"
            />
            <button
              type="button"
              onClick={() => generateSEOField("seoDescription")}
              className="book-form-generate-button"
              disabled={generating.seoDescription}
            >
              {generating.seoDescription
                ? "Generating..."
                : "Generate Description"}
            </button>

            <small className="book-form-character-count">
              {formData.seoDescription.length}/{seoLimits.description}{" "}
              characters
            </small>
          </div>

          {/* Permalink Input */}
          {/* <div className="form-group">
            <label htmlFor="permalinkInput" className="label">
              Permalink:
            </label>
            <input
              type="text"
              id="permalinkInput"
              value={formData.permalink}
              className="form-input"
              onChange={handlePermalinkChange}
            />
          </div> */}
          <div className="form-group">
            <label htmlFor="permalinkInput" className="label">
              Permalink:
            </label>
            <input
              type="text"
              id="permalinkInput"
              value={formData.permalink}
              className="form-input"
              // readOnly // Prevent manual changes
            />
          </div>

          <div className="form-group">
            <label htmlFor="para1Input" className="label">
              Para 1:
            </label>
            <input
              type="text"
              id="para1Input"
              value={formData.para1}
              className="custom-input-para1"
              onChange={(e) =>
                setFormData({ ...formData, para1: e.target.value })
              }
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label htmlFor="para2Input" className="label">
              Para 2:
            </label>
            <input
              type="text"
              id="para2Input"
              value={formData.para2}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, para2: e.target.value })
              }
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label htmlFor="para3Input" className="label">
              Para 3:
            </label>
            <input
              type="text"
              id="para3Input"
              value={formData.para3}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, para3: e.target.value })
              }
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label htmlFor="para4Input" className="label">
              Para 4:
            </label>
            <input
              type="text"
              id="para4Input"
              value={formData.para4}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, para4: e.target.value })
              }
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <div className="form-group-img">
              <label htmlFor="imgInput" className="label">
                Image1:
              </label>
              <div className="photo-of-Book">
                <img
                  src={`https://nodei.ssccglpinnacle.com/${formData.image1}`}
                  id="pic"
                  alt="foto"
                  onLoad={handleImgChange} // Call handleImgChange when the image is loaded
                />
              </div>
            </div>
          </div>

          {[2, 3, 4, 5].map((index) => (
            <div key={index} className="form-group">
              <label htmlFor={`image${index}Input`} className="label">
                Image {index}: &nbsp;
              </label>
              <input
                type="file"
                accept="image/*"
                className="input-for-info-image"
                onChange={(e) => handleImageChangeimg(index - 1, e)}
              />
              {imagePreviews[index - 1] && (
                <img
                  src={imagePreviews[index - 1]}
                  alt={`Image ${index} Preview`}
                  className="image-preview-book"
                />
              )}
            </div>
          ))}

          {[1, 2].map((index) => (
            <div key={index} className="form-group">
              <label htmlFor={`pdf${index}Input`} className="label">
                PDF {index}: &nbsp;
              </label>
              <input
                type="file"
                accept="application/pdf"
                className="input-for-info-pdf"
                onChange={(e) => handlePdfChange(index, e)}
              />
              {formData[`pdf${index}`] && (
                <a
                  href={URL.createObjectURL(formData[`pdf${index}`])}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-preview-link"
                >
                  View PDF {index} Preview
                </a>
              )}
            </div>
          ))}

          <div className="form-group">
            <label htmlFor="whytakecourse" className="label">
              Why should you take this book?
            </label>
            <ReviewForm
              content={formData.whytakecourse}
              onContentChange={handleContentChange}
              onImageUpload={(file) =>
                handleImageChangeimg(0, { target: { files: [file] } })
              }
              imagePreviews={imagePreviews}
            />
          </div>
          <div className="form-group">
            <div className="bbb">
              <button type="submit" className="submit-Book-button">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BookNewForm;

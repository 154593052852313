// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import "./SearchBar.css";
// import { FaSearch, FaMicrophone } from "react-icons/fa";
// import { SearchContext } from "../../Context/SearchContext";

// function SearchBar() {
//   const { query, setQuery } = useContext(SearchContext);
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [isListening, setIsListening] = useState(false);
//   const [placeholder, setPlaceholder] = useState("");
//   const navigate = useNavigate();

//   const placeholderTexts = [
//     "Search For Books",
//     "Search For Ebooks",
//     "Search For Video Courses",
//     "Search For Test Passes",
//   ];

//   useEffect(() => {
//     let currentIndex = 0;
//     let charIndex = 0;
//     let typingDirection = 1; // 1 for typing, -1 for deleting
//     const typewriterInterval = setInterval(() => {
//       const currentText = placeholderTexts[currentIndex];
//       if (typingDirection === 1 && charIndex <= currentText.length) {
//         setPlaceholder(currentText.slice(0, charIndex));
//         charIndex++;
//       } else if (typingDirection === -1 && charIndex >= 0) {
//         setPlaceholder(currentText.slice(0, charIndex));
//         charIndex--;
//       }

//       if (charIndex === currentText.length && typingDirection === 1) {
//         typingDirection = -1; // Start deleting
//       } else if (charIndex === 0 && typingDirection === -1) {
//         typingDirection = 1; // Start typing next
//         currentIndex = (currentIndex + 1) % placeholderTexts.length; // Move to next text
//       }
//     }, 150); // Typing speed

//     return () => clearInterval(typewriterInterval);
//   }, []); // Empty dependency array ensures it runs once

//   const fetchSuggestions = async (query) => {
//     if (!query.trim()) {
//       setSuggestions([]);
//       return;
//     }
//     try {
//       const previousSearches = ["ssc", "rail"];
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(
//           query
//         )}&previousSearches=${encodeURIComponent(previousSearches.join(","))}`
//       );
//       const data = await response.json();
//       setSuggestions(data);
//     } catch (error) {
//       console.error("Error fetching suggestions:", error);
//     }
//   };

//   const saveSearchQuery = async (searchTerm) => {
//     try {
//       const response = await fetch(
//         "https://auth.ssccglpinnacle.com/api/save-search",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ query: searchTerm }),
//         }
//       );
//       const result = await response.json();
//       console.log("Search query saved successfully:", result.message);
//     } catch (error) {
//       console.error("Error saving search query:", error);
//     }
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setQuery(value);
//     fetchSuggestions(value);
//     setShowSuggestions(true);
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setQuery(suggestion.title);
//     setShowSuggestions(false);
//     saveSearchQuery(suggestion.title);
//     navigate("/globalSearch");
//   };

//   const handleSearchIconClick = async () => {
//     if (query.trim()) {
//       await saveSearchQuery(query);
//       navigate("/globalSearch");
//     }
//     setShowSuggestions(false);
//   };

//   const handleKeyPress = async (e) => {
//     if (e.key === "Enter" && query.trim()) {
//       await saveSearchQuery(query);
//       navigate("/globalSearch");
//       setShowSuggestions(false);
//     }
//   };

//   const startListening = () => {
//     if (!("webkitSpeechRecognition" in window)) {
//       alert(
//         "Speech recognition is not supported in this browser. Please try Chrome."
//       );
//       return;
//     }

//     const SpeechRecognition =
//       window.webkitSpeechRecognition || window.SpeechRecognition;
//     const recognition = new SpeechRecognition();
//     recognition.lang = "en-US";
//     recognition.interimResults = false;

//     recognition.start();
//     setIsListening(true);

//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setQuery(transcript);
//       fetchSuggestions(transcript);
//       setIsListening(false);
//     };

//     recognition.onerror = (event) => {
//       console.error("Speech recognition error:", event.error);
//       setIsListening(false);
//     };

//     recognition.onend = () => {
//       setIsListening(false);
//     };
//   };

//   return (
//     <div className="container11">
//       <div className="input-wrap-5">
//         <input
//           type="text"
//           name="product-search"
//           id="product-search-5"
//           placeholder={placeholder} // Dynamic typing placeholder
//           value={query}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyPress}
//           onFocus={() => setShowSuggestions(true)}
//           onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
//         />
//         <div className="searchicon-home" onClick={handleSearchIconClick}>
//           <FaSearch
//             className="serch-icon"
//             color="white"
//             fontSize="1.2em"
//             opacity="1em"
//             background="#008B8B"
//           />
//         </div>
//         <div className="searchicon-home" onClick={startListening}>
//           <FaMicrophone
//             className={`mic-icon ${isListening ? "listening" : ""}`}
//             color={isListening ? "red" : "White"}
//             fontSize="1.5em"
//           />
//         </div>
//         {showSuggestions && suggestions.length > 0 && (
//           <ul className="suggestions-dropdown">
//             {suggestions.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//                 className="suggestion-item"
//               >
//                 <img
//                   src={
//                     suggestion.image ||
//                     (suggestion.type === "ebook"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/ebookImage.svg"
//                       : suggestion.type === "book"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/booksImage.svg"
//                       : suggestion.type === "video"
//                       ? "https://d3m4h509gttb94.cloudfront.net/Image/video-course-Image.svg"
//                       : "https://d3m4h509gttb94.cloudfront.net/Image/sampleSvgimage.svg")
//                   }
//                   alt={suggestion.title}
//                   className="suggestion-image"
//                 />

//                 <div className="suggestion-content">
//                   <span className="suggestion-title">{suggestion.title}</span>
//                   <span className="suggestion-type">({suggestion.type})</span>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchBar;


import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaMicrophone } from "react-icons/fa";
import { SearchContext } from "../../Context/SearchContext";
import styles from "./SearchBar.module.css";

function SearchBar() {
  const { query, setQuery } = useContext(SearchContext);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const navigate = useNavigate();

  const placeholderTexts = [
    "Search For Books",
    "Search For Ebooks",
    "Search For Video Courses",
    "Search For Test Passes",
  ];

  useEffect(() => {
    let currentIndex = 0;
    let charIndex = 0;
    let typingDirection = 1; // 1 for typing, -1 for deleting
    const typewriterInterval = setInterval(() => {
      const currentText = placeholderTexts[currentIndex];
      if (typingDirection === 1 && charIndex <= currentText.length) {
        setPlaceholder(currentText.slice(0, charIndex));
        charIndex++;
      } else if (typingDirection === -1 && charIndex >= 0) {
        setPlaceholder(currentText.slice(0, charIndex));
        charIndex--;
      }

      if (charIndex === currentText.length && typingDirection === 1) {
        typingDirection = -1; // Start deleting
      } else if (charIndex === 0 && typingDirection === -1) {
        typingDirection = 1; // Start typing next
        currentIndex = (currentIndex + 1) % placeholderTexts.length; // Move to next text
      }
    }, 150); // Typing speed

    return () => clearInterval(typewriterInterval);
  }, []); // Empty dependency array ensures it runs once

  const fetchSuggestions = async (query) => {
    if (!query.trim()) {
      setSuggestions([]);
      return;
    }
    try {
      const previousSearches = ["ssc", "rail"];
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/search-suggestions?query=${encodeURIComponent(
          query
        )}&previousSearches=${encodeURIComponent(previousSearches.join(","))}`
      );
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const saveSearchQuery = async (searchTerm) => {
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/save-search",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query: searchTerm }),
        }
      );
      const result = await response.json();
      console.log("Search query saved successfully:", result.message);
    } catch (error) {
      console.error("Error saving search query:", error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    fetchSuggestions(value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.title);
    setShowSuggestions(false);
    saveSearchQuery(suggestion.title);
    navigate("/globalSearch");
  };

  const handleSearchIconClick = async () => {
    if (query.trim()) {
      await saveSearchQuery(query);
      navigate("/globalSearch");
    }
    setShowSuggestions(false);
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && query.trim()) {
      await saveSearchQuery(query);
      navigate("/globalSearch");
      setShowSuggestions(false);
    }
  };

  const startListening = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Speech recognition is not supported in this browser. Please try Chrome."
      );
      return;
    }

    const SpeechRecognition =
      window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;

    recognition.start();
    setIsListening(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setQuery(transcript);
      fetchSuggestions(transcript);
      setIsListening(false);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputWrap}>
        <input
          type="text"
          name="product-search"
        //   id="product-search-5"
          className={styles.productSearch5}
          placeholder={placeholder} // Dynamic typing placeholder
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
        />
        <div className={styles.searchIconHome} onClick={handleSearchIconClick}>
          <FaSearch
            className={styles.searchIcon}
            // color="white"
            // fontSize="1.2em"
            // opacity="1em"
            // background="#008B8B"
          />
        </div>
        <div className={styles.searchIconHome} onClick={startListening}>
          <FaMicrophone
            className={`${styles.searchIcon} ${
              isListening ? styles.listening : ""
            }`}
            color={isListening ? "red" : "black"}
            fontSize="1.5em"
          />
        </div>
        {showSuggestions && suggestions.length > 0 && (
          <ul className={styles.suggestionsDropdown}>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className={styles.suggestionItem}
              >
                <img
                  src={
                    suggestion.image ||
                    (suggestion.type === "ebook"
                      ? "https://d3m4h509gttb94.cloudfront.net/Image/ebookImage.svg"
                      : suggestion.type === "book"
                      ? "https://d3m4h509gttb94.cloudfront.net/Image/booksImage.svg"
                      : suggestion.type === "video"
                      ? "https://d3m4h509gttb94.cloudfront.net/Image/video-course-Image.svg"
                      : "https://d3m4h509gttb94.cloudfront.net/Image/sampleSvgimage.svg")
                  }
                  alt={suggestion.title}
                  className={styles.suggestionImage}
                />

                <div className={styles.suggestionContent}>
                  <span className={styles.suggestionTitle}>
                    {suggestion.title}
                  </span>
                  <span className={styles.suggestionType}>
                    ({suggestion.type})
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default SearchBar;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./GlobalSearch.module.css";
import Header from "../component/HeaderFiles/Header";
import Footer from "../FooterMain/Footer";
import { SearchContext } from "../Context/SearchContext";
import { MdTimeline } from "react-icons/md";
import NoResults from "./NoResults";
import { BiSolidArrowToBottom, BiSolidArrowFromBottom } from "react-icons/bi";

const fuzzyMatch = (query, text) => {
  if (!query || !text) return false;

  const words = query.trim().split(/\s+/);
  const regex = new RegExp(words.map((word) => `(${word})`).join("|"), "i");

  return regex.test(text);
};

const highlightText = (text, query) => {
  if (!text) return "";
  if (!query) return text;

  const regex = new RegExp(`(${query})`, "gi");
  return text.split(regex).map((part, index) =>
    regex.test(part) ? (
      <span key={index} className={styles.highlight}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const GlobalSearch = () => {
  const { query } = useContext(SearchContext);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [subjectFilter, setSubjectFilter] = useState("");
  const [mediumFilter, setMediumFilter] = useState("");
  const [ratingFilter, setRatingFilter] = useState(null);
  const [editionFilter, setEditionFilter] = useState(null);
  const [trendingSearches, setTrendingSearches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrendingSearches = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/trending-searches`
        );
        const data = await response.json();
        setTrendingSearches(data.map((item) => item.query));
      } catch (error) {
        console.error("Error fetching trending searches:", error);
      }
    };

    fetchTrendingSearches();
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      if (!query) {
        setResults([]);
        return;
      }

      setLoading(true);
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
            query
          )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
            ratingFilter || ""
          }&edition=${editionFilter || ""}`
        );
        const data = await response.json();

        // Match specific search type based on query
        const searchType = query.match(/(video|book|ebook|testpass|typing)/i)?.[0]?.toLowerCase();
        const filteredResults = data.filter((item) => {
          const matchesQuery = fuzzyMatch(query, item.title || item.courseTitle);
          const matchesType = !searchType || item.type === searchType;
          return matchesQuery && matchesType;
        });

        setResults(filteredResults);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [
    query,
    categoryFilter,
    subjectFilter,
    mediumFilter,
    ratingFilter,
    editionFilter,
  ]);

  const handleFilterChange = (type, value) => {
    if (type === "category") setCategoryFilter(value);
    if (type === "subject") setSubjectFilter(value);
    if (type === "medium") setMediumFilter(value);
    if (type === "rating") setRatingFilter(value);
    if (type === "edition") setEditionFilter(value);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const navigateToDetails = (type, id, permalink, SEOPermalink, slug) => {
    if (type === "book") {
      window.open(`/books${permalink}`, "_blank");
    } else if (type === "ebook") {
      window.open(`/ebooks${permalink}`, "_blank"); 
    } else if (type === "video") {
      window.open(`/videos/${SEOPermalink}`, "_blank");
    } else if (type === "testpass") {
      window.open(`https://testportal.ssccglpinnacle.com/buy/${slug}`, "_blank");
    } else if (type === "typing") {
      window.open(`/typing`, "_blank"); 
    } else {
      console.warn(`Unhandled type: ${type}`);
    }
  };
  const renderCard = (item) => {
    const tagColorClass = {
      testpass: styles.testPassTag,
      book: styles.bookTag,
      video: styles.videoTag,
      ebook: styles.ebookTag,
      typing: styles.typingTag,
    };
    const tag =
      item.type === "testpass"
        ? "Test Pass"
        : item.type === "book"
        ? "Book"
        : item.type === "video"
        ? "Video"
        : item.type === "ebook"
        ? "E-book"
        : "Typing";

    return (
      <div
        key={item._id}
        className={styles.globalSearchCard}
        onClick={() =>
          navigateToDetails(
            item.type,
            item._id,
            item.permalink,
            item.SEOPermalink,
            item.slug
          )
        }
      >
        <div
          className={`${styles.globalSearchTag} ${tagColorClass[item.type]}`}
        >
          {tag}
        </div>
        <img
          src={item.image1 || item.image || item.englishCoverImage}
          alt={item.title || item.courseTitle}
          className={styles.globalSearchCardImage}
        />
        <h4 className={styles.globalSearchCardTitle}>
          {highlightText(item.title || item.courseTitle, query)}
        </h4>
        <button
          className={styles.showMoreButton}
          onClick={(e) => {
            e.stopPropagation();
            navigateToDetails(
              item.type,
              item._id,
              item.permalink,
              item.SEOPermalink,
              item.slug
            );
          }}
        >
          Show More
        </button>
      </div>
    );
  };

  const orderedResults = [
    ...results.filter((item) => item.type === "testpass"),
    ...results.filter((item) => item.type === "book"),
    ...results.filter((item) => item.type === "video"),
    ...results.filter((item) => item.type === "ebook"),
    ...results.filter((item) => item.type === "typing"),
  ];

  return (
    <>
      <Header />
      <div className={styles.globalSearchContainer}>
        <div className={styles.globalSearchSidebar}>
          <h3>Trending Searches</h3>
          <ul>
            {trendingSearches.map((search, index) => (
              <div className={styles.trandingSearchDiv} key={index}>
                <MdTimeline />
                <div
                  className={styles.trandingSearchitem}
                  onClick={() => setCategoryFilter(search)}
                >
                  {search}
                </div>
              </div>
            ))}
          </ul>

          <h3>Category</h3>
          <ul>
            <li>
              <input
                type="radio"
                name="category"
                checked={categoryFilter === "all"}
                onChange={() => handleFilterChange("category", "all")}
              />
              <label> All</label>
            </li>
            <li>
              <input
                type="radio"
                name="category"
                checked={categoryFilter === "testpass"}
                onChange={() => handleFilterChange("category", "testpass")}
              />
              <label> Test Pass</label>
            </li>
            <li>
              <input
                type="radio"
                name="category"
                checked={categoryFilter === "book"}
                onChange={() => handleFilterChange("category", "book")}
              />
              <label> Books</label>
            </li>
            <li>
              <input
                type="radio"
                name="category"
                checked={categoryFilter === "video"}
                onChange={() => handleFilterChange("category", "video")}
              />
              <label> Videos</label>
            </li>
            <li>
              <input
                type="radio"
                name="category"
                checked={categoryFilter === "ebook"}
                onChange={() => handleFilterChange("category", "ebook")}
              />
              <label> E-books</label>
            </li>
            <li>
              <input
                type="radio"
                name="category"
                checked={categoryFilter === "typing"}
                onChange={() => handleFilterChange("category", "typing")}
              />
              <label> Typing</label>
            </li>
          </ul>
        </div>

        <div className={styles.globalSearchMain}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <h4>
                {orderedResults.length > 0
                  ? `We found ${orderedResults.length} result${
                      orderedResults.length > 1 ? "s" : ""
                    } for "${query}".`
                  : `No results found for "${query}".`}
              </h4>
              {orderedResults.length > 0 ? (
                <div className={styles.globalSearchCardContainer}>
                  {orderedResults.map((item) => renderCard(item))}
                </div>
              ) : (
                <NoResults query={query} />
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.scrollToTopContainer}>
        <button onClick={scrollToTop} className={styles.scrollToTop}>
          <BiSolidArrowFromBottom size={25} />
        </button>
      </div>
      <Footer />
    </>
  );
};

export default GlobalSearch;



// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import { BiSolidArrowToBottom, BiSolidArrowFromBottom } from "react-icons/bi";

// const fuzzyMatch = (query, text) => {
//   if (!query || !text) return false;

//   const words = query.trim().split(/\s+/);
//   const regex = new RegExp(words.map((word) => `(${word})`).join("|"), "i");

//   return regex.test(text);
// };

// const highlightText = (text, query) => {
//   if (!text) return "";
//   if (!query) return text;

//   const regex = new RegExp(`(${query})`, "gi");
//   return text.split(regex).map((part, index) =>
//     regex.test(part) ? (
//       <span key={index} className={styles.highlight}>
//         {part}
//       </span>
//     ) : (
//       part
//     )
//   );
// };

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const [defaultResults, setDefaultResults] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/trending-searches`
//       );
//       const data = await response.json();
//       setTrendingSearches(data.map((item) => item.query));
//     } catch (error) {
//       console.error("Error fetching trending searches:", error);
//     }
//   };

//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchDefaultResults = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/search");
//         const data = await response.json();
//         setDefaultResults(data);
//       } catch (error) {
//         console.error("Error fetching default results:", error);
//       }
//     };

//     fetchDefaultResults();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       if (!query) {
//         setResults(defaultResults);
//         return;
//       }

//       setLoading(true);
//       try {
//         const response = await fetch(
//           `http://localhost:5000/api/search?query=${encodeURIComponent(
//             query
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();

//         // Match specific search type based on query
//         const searchType = query.match(/(video|book|ebook|testpass|typing)/i)?.[0]?.toLowerCase();
//         const filteredResults = data.filter((item) => {
//           const matchesQuery = fuzzyMatch(query, item.title || item.courseTitle);
//           const matchesType = !searchType || item.type === searchType;
//           return matchesQuery && matchesType;
//         });

//         setResults(filteredResults);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchResults();
//   }, [
//     query,
//     categoryFilter,
//     subjectFilter,
//     mediumFilter,
//     ratingFilter,
//     editionFilter,
//     defaultResults,
//   ]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
//   };

//   const navigateToDetails = (type, id, permalink, SEOPermalink, slug) => {
//     if (type === "book") {
//       navigate(`/books${permalink}`);
//     } else if (type === "ebook") {
//       navigate(`/ebooks${permalink}`);
//     } else if (type === "video") {
//       navigate(`/videos/${SEOPermalink}`);
//     } else if (type === "testpass") {
//       window.open(`https://testportal.ssccglpinnacle.com/buy/${slug}`, "_blank");
//     } else if (type === "typing") {
//       navigate(`/typing/${id}`);
//     } else {
//       console.warn(`Unhandled type: ${type}`);
//     }
//   };

//   const renderCard = (item) => {
//     const tagColorClass = {
//       testpass: styles.testPassTag,
//       book: styles.bookTag,
//       video: styles.videoTag,
//       ebook: styles.ebookTag,
//       typing: styles.typingTag,
//     };
//     const tag =
//       item.type === "testpass"
//         ? "Test Pass"
//         : item.type === "book"
//         ? "Book"
//         : item.type === "video"
//         ? "Video"
//         : item.type === "ebook"
//         ? "E-book"
//         : "Typing";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() =>
//           navigateToDetails(
//             item.type,
//             item._id,
//             item.permalink,
//             item.SEOPermalink,
//             item.slug
//           )
//         }
//       >
//         <div
//           className={`${styles.globalSearchTag} ${tagColorClass[item.type]}`}
//         >
//           {tag}
//         </div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {highlightText(item.title || item.courseTitle, query)}
//         </h4>
//         <button
//           className={styles.showMoreButton}
//           onClick={(e) => {
//             e.stopPropagation();
//             navigateToDetails(
//               item.type,
//               item._id,
//               item.permalink,
//               item.SEOPermalink,
//               item.slug
//             );
//           }}
//         >
//           Show More
//         </button>
//       </div>
//     );
//   };

//   const orderedResults = [
//     ...results.filter((item) => item.type === "testpass"),
//     ...results.filter((item) => item.type === "book"),
//     ...results.filter((item) => item.type === "video"),
//     ...results.filter((item) => item.type === "ebook"),
//     ...results.filter((item) => item.type === "typing"),
//   ];

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div
//                   className={styles.trandingSearchitem}
//                   onClick={() => setCategoryFilter(search)}
//                 >
//                   {search}
//                 </div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "testpass"}
//                 onChange={() => handleFilterChange("category", "testpass")}
//               />
//               <label> Test Pass</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "typing"}
//                 onChange={() => handleFilterChange("category", "typing")}
//               />
//               <label> Typing</label>
//             </li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <>
//               <h4>
//                 {orderedResults.length > 0
//                   ? `We found ${orderedResults.length} result${
//                       orderedResults.length > 1 ? "s" : ""
//                     } for "${query}".`
//                   : `No results found for "${query}".`}
//               </h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {orderedResults.map((item) => renderCard(item))}
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       <div className={styles.scrollToTopContainer}>
//         <button onClick={scrollToTop} className={styles.scrollToTop}>
//           <BiSolidArrowFromBottom size={25} />
//         </button>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;


// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import { BiSolidArrowToBottom, BiSolidArrowFromBottom } from "react-icons/bi";

// const fuzzyMatch = (query, text) => {
//   if (!query || !text) return false;
//   const words = query.trim().split(/\s+/);
//   const regex = new RegExp(words.map((word) => `(${word})`).join("|"), "i");
//   return regex.test(text);
// };
// const highlightText = (text, query) => {
//   if (!text) return "";
//   if (!query) return text;

//   const regex = new RegExp(`(${query})`, "gi");
//   return text.split(regex).map((part, index) =>
//     part.toLowerCase() === query.toLowerCase() ? (
//       <span key={index} className={styles.highlight}>
//         {part}
//       </span>
//     ) : (
//       part
//     )
//   );
// };


// const parseSearchQuery = (query) => {
//   if (!query) return { term: query, type: "all" };
//   const queryLower = query.toLowerCase();
//   if (queryLower.includes("video")) return { term: query.replace(/video/i, "").trim(), type: "video" };
//   if (queryLower.includes("ebook")) return { term: query.replace(/ebook/i, "").trim(), type: "ebook" };
//   if (queryLower.includes("book")) return { term: query.replace(/book/i, "").trim(), type: "book" };
//   if (queryLower.includes("test pass")) return { term: query.replace(/test pass/i, "").trim(), type: "testpass" };
//   return { term: query, type: "all" };
// };

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [filters, setFilters] = useState({
//     category: "all",
//     subject: "",
//     medium: "",
//     rating: null,
//     edition: "",
//   });
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/trending-searches");
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };
//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       const { term, type } = parseSearchQuery(query);
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `http://localhost:5000/api/search?query=${encodeURIComponent(term)}&category=${filters.category}&subject=${filters.subject}&medium=${filters.medium}&rating=${filters.rating || ""}&edition=${filters.edition || ""}`
//         );
//         const data = await response.json();
//         const filteredResults = data.filter((item) => {
//           const textFields = [item.title, item.courseTitle, item.description];
//           const matchesQuery = textFields.some((field) => fuzzyMatch(term, field));
//           const matchesType = type === "all" || item.type === type;
//           return matchesQuery && matchesType;
//         });
//         setResults(filteredResults);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchResults();
//   }, [query, filters]);

//   const handleFilterChange = (filter, value) => {
//     setFilters((prev) => ({ ...prev, [filter]: value }));
//   };

//   const navigateToDetails = (type, id, permalink, SEOPermalink, slug) => {
//     if (type === "book") {
//       navigate(`/books${permalink}`);
//     } else if (type === "ebook") {
//       navigate(`/ebooks${permalink}`);
//     } else if (type === "video") {
//       navigate(`/videos/${SEOPermalink}`);
//     } else if (type === "testpass") {
//       window.open(`https://testportal.ssccglpinnacle.com/buy/${slug}`, "_blank");
//     } else if (type === "typing") {
//       navigate(`/typing/${id}`);
//     } else {
//       console.warn(`Unhandled type: ${type}`);
//     }
//   };
//   const renderCard = (item) => {
//     const tagColors = {
//       testpass: styles.testPassTag,
//       book: styles.bookTag,
//       video: styles.videoTag,
//       ebook: styles.ebookTag,
//       typing: styles.typingTag,
//     };
//     const tag =
//       item.type === "testpass"
//         ? "Test Pass"
//         : item.type === "book"
//         ? "Book"
//         : item.type === "video"
//         ? "Video"
//         : item.type === "ebook"
//         ? "E-book"
//         : "Typing";

//     return (
//       <div key={item._id} className={styles.globalSearchCard}>
//         <div className={`${styles.globalSearchTag} ${tagColors[item.type]}`}>{tag}</div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>{highlightText(item.title || item.courseTitle, query)}</h4>
//         <button
//           className={styles.showMoreButton}
//           onClick={() => navigateToDetails(item.type, item._id, item.category, item.subject, item.SEOPermalink, item.title)}
//         >
//           Show More
//         </button>
//       </div>
//     );
//   };

//   const orderedResults = [
//     ...results.filter((item) => item.type === "testpass"),
//     ...results.filter((item) => item.type === "book"),
//     ...results.filter((item) => item.type === "video"),
//     ...results.filter((item) => item.type === "ebook"),
//     ...results.filter((item) => item.type === "typing"),
//   ];

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <li key={index} className={styles.trendingItem} onClick={() => setFilters({ ...filters, query: search })}>
//                 {search}
//               </li>
//             ))}
//           </ul>
//           <h3>Filters</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={filters.category === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label>All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={filters.category === "testpass"}
//                 onChange={() => handleFilterChange("category", "testpass")}
//               />
//               <label>Test Pass</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={filters.category === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label>Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={filters.category === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label>Videos</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={filters.category === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label>E-books</label>
//             </li>
//           </ul>
//         </div>
//         <div className={styles.globalSearchMain}>
//           {loading ? (
//             <p>Loading...</p>
//           ) : results.length > 0 ? (
//             <div className={styles.globalSearchCardContainer}>{orderedResults.map(renderCard)}</div>
//           ) : (
//             <NoResults query={query} />
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;


// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import { BiSolidArrowToBottom, BiSolidArrowFromBottom } from "react-icons/bi";

// const fuzzyMatch = (query, text) => {
//   if (!query || !text) return false;

//   const words = query.trim().split(/\s+/);
//   const regex = new RegExp(words.map((word) => `(${word})`).join("|"), "i");

//   return regex.test(text);
// };

// const highlightText = (text, query) => {
//   if (!text) return "";
//   if (!query) return text;

//   const regex = new RegExp(`(${query})`, "gi");
//   return text.split(regex).map((part, index) =>
//     part.toLowerCase() === query.toLowerCase() ? (
//       <span key={index} className={styles.highlight}>
//         {part}
//       </span>
//     ) : (
//       part
//     )
//   );
// };

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const [defaultResults, setDefaultResults] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/trending-searches"
//         );
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchDefaultResults = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/search"
//         );
//         const data = await response.json();
//         setDefaultResults(data);
//       } catch (error) {
//         console.error("Error fetching default results:", error);
//       }
//     };

//     fetchDefaultResults();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       if (!query) {
//         setResults(defaultResults);
//         return;
//       }

//       setLoading(true);

//       // Determine type based on keywords in query
//       const typeKeywordMap = {
//         video: ["video", "video course"],
//         book: ["book", "books"],
//         ebook: ["ebook", "e-books", "e books"],
//         testpass: ["testpass", "test pass"],
//         typing: ["typing"],
//       };

//       const detectedType = Object.keys(typeKeywordMap).find((type) =>
//         typeKeywordMap[type].some((keyword) => query.toLowerCase().includes(keyword))
//       );

//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query
//           )}&category=${detectedType || categoryFilter}&subject=${subjectFilter}&medium=${
//             mediumFilter || ""
//           }&rating=${ratingFilter || ""}&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();

//         const filteredResults = data.filter((item) =>
//           fuzzyMatch(query, item.title || item.courseTitle)
//         );
//         setResults(filteredResults);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchResults();
//   }, [
//     query,
//     categoryFilter,
//     subjectFilter,
//     mediumFilter,
//     ratingFilter,
//     editionFilter,
//     defaultResults,
//   ]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
//   };

//   const navigateToDetails = (type, id) => {
//     const routes = {
//       book: `/books/${id}`,
//       ebook: `/ebooks/${id}`,
//       video: `/videos/${id}`,
//       testpass: `/testpass/${id}`,
//       typing: `/typing/${id}`,
//     };
//     if (routes[type]) navigate(routes[type]);
//   };

//   const renderCard = (item) => {
//     const tagColors = {
//       testpass: "#FFD700",
//       book: "#32CD32",
//       video: "#1E90FF",
//       ebook: "#FF69B4",
//       typing: "#FFA500",
//     };
//     const tag = {
//       book: "Book",
//       ebook: "E-book",
//       video: "Video",
//       testpass: "Test Pass",
//       typing: "Typing",
//     }[item.type];

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(item.type, item._id)}
//       >
//         <div
//           style={{ backgroundColor: tagColors[item.type] }}
//           className={styles.globalSearchTag}
//         >
//           {tag}
//         </div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || "Course"}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {highlightText(item.title || item.courseTitle, query)}
//         </h4>
//         <p className={styles.globalSearchCardPrice}>
//           ₹{item.BookSellingPrice || item.price || "Free"}
//         </p>
//       </div>
//     );
//   };

//   // Order results: TestPass > Books > Videos > Ebooks > Typing
//   const orderedResults = [
//     ...results.filter((item) => item.type === "testpass"),
//     ...results.filter((item) => item.type === "book"),
//     ...results.filter((item) => item.type === "video"),
//     ...results.filter((item) => item.type === "ebook"),
//     ...results.filter((item) => item.type === "typing"),
//   ];

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div className={styles.trandingSearchitem}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "testpass"}
//                 onChange={() => handleFilterChange("category", "testpass")}
//               />
//               <label> Test Pass</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "typing"}
//                 onChange={() => handleFilterChange("category", "typing")}
//               />
//               <label> Typing</label>
//             </li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <>
//               <h4>
//                 {orderedResults.length > 0
//                   ? `We found ${orderedResults.length} result${
//                       orderedResults.length > 1 ? "s" : ""
//                     } for "${query}".`
//                   : `No results found for "${query}".`}
//               </h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {orderedResults.map((item) => renderCard(item))}
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       <div className={styles.scrollToTopContainer}>
//         <button onClick={scrollToTop} className={styles.scrollToTop}>
//           <BiSolidArrowFromBottom size={25} />
//         </button>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;







// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import { BiSolidArrowToBottom, BiSolidArrowFromBottom } from "react-icons/bi";

// const fuzzyMatch = (query, text) => {
//   if (!query || !text) return false;

//   const words = query.trim().split(/\s+/);
//   const regex = new RegExp(words.map((word) => `(${word})`).join("|"), "i");

//   return regex.test(text);
// };

// const prioritizeExactMatch = (query, items) => {
//   if (!query) return items;

//   const exactMatches = items.filter(
//     (item) =>
//       item.title?.toLowerCase() === query.toLowerCase() ||
//       item.courseTitle?.toLowerCase() === query.toLowerCase()
//   );
//   const otherMatches = items.filter(
//     (item) =>
//       item.title?.toLowerCase() !== query.toLowerCase() &&
//       item.courseTitle?.toLowerCase() !== query.toLowerCase()
//   );

//   return [...exactMatches, ...otherMatches];
// };

// const highlightText = (text, query) => {
//   if (!text) return "";
//   if (!query) return text;

//   const regex = new RegExp(`(${query})`, "gi");
//   return text.split(regex).map((part, index) =>
//     part.toLowerCase() === query.toLowerCase() ? (
//       <span key={index} className={styles.highlight}>
//         {part}
//       </span>
//     ) : (
//       part
//     )
//   );
// };

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const [defaultResults, setDefaultResults] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/trending-searches"
//         );
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchDefaultResults = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/search"
//         );
//         const data = await response.json();
//         setDefaultResults(data);
//       } catch (error) {
//         console.error("Error fetching default results:", error);
//       }
//     };

//     fetchDefaultResults();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       if (!query) {
//         setResults(defaultResults);
//         return;
//       }

//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();

//         const filteredResults = data.filter((item) => {
//           const textFields = [
//             item.title,
//             item.courseTitle,
//             item.description,
//             item.paper_code,
//             item.exam,
//             item.subject,
//             item.category,
//           ];
//           return textFields.some((field) => fuzzyMatch(query, field));
//         });

//         const sortedResults = prioritizeExactMatch(query, filteredResults);
//         setResults(sortedResults);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchResults();
//   }, [
//     query,
//     categoryFilter,
//     subjectFilter,
//     mediumFilter,
//     ratingFilter,
//     editionFilter,
//     defaultResults,
//   ]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
//   };
  // const navigateToDetails = (
  //   type,
  //   id,
  //   category,
  //   subject,
  //   SEOPermalink,
  //   title
  // ) => {
  //   if (type === "book") {
  //     // Navigate to book page
  //     if (title && category && subject) {
  //       navigate(
  //         `/books/${category.trim().toLowerCase().replace(/ +/g, "-")}/${subject
  //           .trim()
  //           .toLowerCase()
  //           .replace(/ +/g, "-")}/${title
  //           .trim()
  //           .toLowerCase()
  //           .replace(/ +/g, "-")}`
  //       );
  //     } else {
  //       console.warn(
  //         `Missing data for book navigation:`,
  //         `Category: ${category || "undefined"}`,
  //         `Subject: ${subject || "undefined"}`,
  //         `Title: ${title || "undefined"}`
  //       );
  //     }
  //   } else if (type === "ebook") {
  //     // Navigate to eBook page
  //     if (title && category && subject) {
  //       navigate(
  //         `/ebooks/${category
  //           .trim()
  //           .toLowerCase()
  //           .replace(/ +/g, "-")}/${subject
  //           .trim()
  //           .toLowerCase()
  //           .replace(/ +/g, "-")}/${title
  //           .trim()
  //           .toLowerCase()
  //           .replace(/ +/g, "-")}`
  //       );
  //     } else {
  //       console.warn(
  //         `Missing data for eBook navigation:`,
  //         `Category: ${category || "undefined"}`,
  //         `Subject: ${subject || "undefined"}`,
  //         `Title: ${title || "undefined"}`
  //       );
  //     }
  //   } else if (type === "video") {
  //     // Navigate to video page
  //     if (SEOPermalink) {
  //       navigate(`/videos/${SEOPermalink}`);
  //     } else {
  //       console.warn(
  //         `Missing data for video navigation:`,
  //         `Category: ${category || "undefined"}`,
  //         `Subject: ${subject || "undefined"}`,
  //         `SEOPermalink: ${SEOPermalink || "undefined"}`
  //       );
  //     }
  //   } else if (type === "testpass") {
  //     // Navigate to third-party website for test passes
  //     // const testPassUrl = `https://testportal.ssccglpinnacle.com/ssc/ssc-tcs-test-pass`;
  //     const testPassUrl = `https://testportal.ssccglpinnacle.com`;
  //     window.open(testPassUrl, "_blank");
  //   } else if (type === "typing") {
  //     navigate(`/typing/${id}`);
  //   } else {
  //     console.warn(`Unhandled type: ${type}`);
  //   }
  // };
//   const renderCard = (item) => {
//     const tagColorClass = {
//       testpass: styles.testPassTag,
//       book: styles.bookTag,
//       video: styles.videoTag,
//       ebook: styles.ebookTag,
//       typing: styles.typingTag,
//     };
//     const tag =
//       item.type === "book"
//         ? "Book"
//         : item.type === "ebook"
//         ? "E-book"
//         : item.type === "video"
//         ? "Video"
//         : item.type === "testpass"
//         ? "Test Pass"
//         : "Typing";
//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() =>
//           navigateToDetails(
//             item.type,
//             item._id,
//             item.category,
//             item.subject,
//             item.SEOPermalink
//           )
//         }
//       >
//         <div
//           className={`${styles.globalSearchTag} ${tagColorClass[item.type]}`}
//         >
//           {tag}
//         </div>
//         {item.type === "typing" ? (
//           <div className={styles.typingCardContent}>
//             <p>
//               <strong>Exam:</strong> {item.exam || "N/A"}
//             </p>
//             <p>
//               <strong>Paper Code:</strong> {item.paper_code || "N/A"}
//             </p>
//           </div>
//         ) : (
//           <img
//             src={item.image1 || item.image || item.englishCoverImage}
//             alt={item.title || item.courseTitle}
//             className={styles.globalSearchCardImage}
//           />
//         )}
//         <h4 className={styles.globalSearchCardTitle}>
//           {highlightText(item.title || item.courseTitle, query)}
//         </h4>
//         {item.author && (
//           <p className={styles.globalSearchCardAuthor}>
//             {highlightText(item.author, query)}
//           </p>
//         )}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {highlightText(item.instructorName, query)}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//         {item.totalTests && (
//           <p className={styles.globalSearchCardTests}>
//             Total Tests: {item.totalTests}
//           </p>
//         )}
//       </div>
//     );
//   };

//   const orderedResults = [
//     ...results
//       .filter((item) => fuzzyMatch(query, item.title || item.courseTitle))
//       .sort((a, b) =>
//         a.title?.toLowerCase() === query.toLowerCase() ? -1 : 1
//       ),
//     ...results.filter(
//       (item) => !fuzzyMatch(query, item.title || item.courseTitle)
//     ),
//   ];
//   // const orderedResults = [
//   //   ...results.filter((item) => item.type === "testpass"),
//   //   ...results.filter((item) => item.type === "book"),
//   //   ...results.filter((item) => item.type === "video"),
//   //   ...results.filter((item) => item.type === "ebook"),
//   //   // ...results.filter((item) => item.type === "typing"),
//   // ];

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div className={styles.trandingSearchitem}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "testpass"}
//                 onChange={() => handleFilterChange("category", "testpass")}
//               />
//               <label> Test Pass</label>
//             </li>

//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "typing"}
//                 onChange={() => handleFilterChange("category", "typing")}
//               />
//               <label> Typing</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && (
//             <p>
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                 alt="Loading..."
//               />
//             </p>
//           )}
//           {!loading && (
//             <div>
//               <div className={styles.scrollToBottomContainer}>
//               <h4 className={styles.orangeText}>
//   {orderedResults.length > 0
//     ? `We found ${orderedResults.length} result${
//         orderedResults.length > 1 ? "s" : ""
//       } for "${query}".`
//     : `Sorry, no results were found for "${query}".`}
// </h4>
//                 <button
//                   onClick={scrollToBottom}
//                   className={styles.scrollToBottom}
//                 >
//                   <BiSolidArrowToBottom size={25} />
//                 </button>
//               </div>
//               <div className={styles.globalSearchCardContainer}>
//                 {orderedResults.length > 0
//                   ? orderedResults.map((item) => renderCard(item))
//                   : query && <NoResults query={query} />}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className={styles.scrollToTopContainer}>
//         <button onClick={scrollToTop} className={styles.scrollToTop}>
//           <BiSolidArrowFromBottom size={25} />
//         </button>
//       </div>
//       <Footer />
    
//     </>
//   );
// };

// export default GlobalSearch;
// this is for testng git account of ritesh sir

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import { BiSolidArrowToBottom, BiSolidArrowFromBottom } from "react-icons/bi";

// const fuzzyMatch = (query, text) => {
//   if (!query || !text) return false;

//   const words = query.trim().split(/\s+/);
//   const regex = new RegExp(words.map((word) => `(${word})`).join("|"), "i");

//   return regex.test(text);
// };

// const prioritizeExactMatch = (query, items) => {
//   if (!query) return items;

//   const exactMatches = items.filter(
//     (item) =>
//       item.title?.toLowerCase() === query.toLowerCase() ||
//       item.courseTitle?.toLowerCase() === query.toLowerCase()
//   );
//   const otherMatches = items.filter(
//     (item) =>
//       item.title?.toLowerCase() !== query.toLowerCase() &&
//       item.courseTitle?.toLowerCase() !== query.toLowerCase()
//   );

//   return [...exactMatches, ...otherMatches];
// };

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const [defaultResults, setDefaultResults] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/trending-searches"
//         );
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchDefaultResults = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/default-results");
//         const data = await response.json();
//         setDefaultResults(data);
//       } catch (error) {
//         console.error("Error fetching default results:", error);
//       }
//     };

//     fetchDefaultResults();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       if (!query) {
//         setResults(defaultResults);
//         return;
//       }

//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();

//         const filteredResults = data.filter((item) => {
//           const textFields = [
//             item.title,
//             item.courseTitle,
//             item.description,
//             item.paper_code,
//             item.exam,
//             item.subject,
//             item.category,
//           ];
//           return textFields.some((field) => fuzzyMatch(query, field));
//         });

//         const sortedResults = prioritizeExactMatch(query, filteredResults);
//         setResults(sortedResults);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchResults();
//   }, [
//     query,
//     categoryFilter,
//     subjectFilter,
//     mediumFilter,
//     ratingFilter,
//     editionFilter,
//     defaultResults,
//   ]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
//   };

//   const navigateToDetails = (type, id, category, subject, SEOPermalink) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       if (SEOPermalink && category && subject) {
//         navigate(
//           `/videos/${category.trim().toLowerCase().replace(/ +/g, "-")}/${subject
//             .trim()
//             .toLowerCase()
//             .replace(/ +/g, "-")}/${SEOPermalink}`
//         );
//       } else {
//         console.warn(
//           `Missing data for SEO navigation:`,
//           `Category: ${category || "undefined"}`,
//           `Subject: ${subject || "undefined"}`,
//           `SEOPermalink: ${SEOPermalink || "undefined"}`
//         );
//       }
//     } else if (type === "testpass") {
//       navigate(`/test-pass/${id}`);
//     } else if (type === "typing") {
//       navigate(`/typing/${id}`);
//     }
//   };

//   const highlightText = (text, query) => {
//     if (!text) return "";
//     if (!query) return text;

//     const parts = text.split(new RegExp(`(${query})`, "gi"));
//     return parts.map((part, index) =>
//       part.toLowerCase() === query.toLowerCase() ? (
//         <span key={index} className={styles.highlight}>
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };

// const renderCard = (item) => {
//   const tagColorClass = {
//     testpass: styles.testPassTag, // Add this class in your CSS module
//     book: styles.bookTag, // Add this class in your CSS module
//     video: styles.videoTag, // Add this class in your CSS module
//     ebook: styles.ebookTag, // Add this class in your CSS module
//     typing: styles.typingTag, // Add this class in your CSS module
//   };

//   const tag =
//     item.type === "book"
//       ? "Book"
//       : item.type === "ebook"
//       ? "E-book"
//       : item.type === "video"
//       ? "Video"
//       : item.type === "testpass"
//       ? "Test Pass"
//       : "Typing";

//   return (
//     <div
//       key={item._id}
//       className={styles.globalSearchCard}
//       onClick={() =>
//         navigateToDetails(
//           item.type,
//           item._id,
//           item.category,
//           item.subject,
//           item.SEOPermalink
//         )
//       }
//     >
//       <div className={`${styles.globalSearchTag} ${tagColorClass[item.type]}`}>
//         {tag}
//       </div>
//       {item.type === "typing" ? (
//         <div className={styles.typingCardContent}>
//           <p>
//             <strong>Exam:</strong> {item.exam || "N/A"}
//           </p>
//           <p>
//             <strong>Paper Code:</strong> {item.paper_code || "N/A"}
//           </p>
//         </div>
//       ) : (
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//       )}
//       <h4 className={styles.globalSearchCardTitle}>
//         {highlightText(item.title || item.courseTitle, query)}
//       </h4>
//       {item.author && (
//         <p className={styles.globalSearchCardAuthor}>
//           {highlightText(item.author, query)}
//         </p>
//       )}
//       {item.instructorName && (
//         <p className={styles.globalSearchCardInstructor}>
//           Instructor: {highlightText(item.instructorName, query)}
//         </p>
//       )}
//       <p className={styles.globalSearchCardPrice}>
//         {item.BookSellingPrice || item.price
//           ? `₹${item.BookSellingPrice || item.price}`
//           : "Free"}
//       </p>
//       {item.totalTests && (
//         <p className={styles.globalSearchCardTests}>
//           Total Tests: {item.totalTests}
//         </p>
//       )}
//     </div>
//   );
// };

//   const orderedResults = [
//     ...results.filter((item) => item.type === "testpass"),
//     ...results.filter((item) => item.type === "book"),
//     ...results.filter((item) => item.type === "video"),
//     ...results.filter((item) => item.type === "ebook"),
//     ...results.filter((item) => item.type === "typing"),
//   ];

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div className={styles.trandingSearchitem}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "testpass"}
//                 onChange={() => handleFilterChange("category", "testpass")}
//               />
//               <label> Test Pass</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "typing"}
//                 onChange={() => handleFilterChange("category", "typing")}
//               />
//               <label> Typing</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && (
//             <p>
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                 alt="Loading..."
//               />
//             </p>
//           )}
//           {!loading && (
//             <div>
//               <div className={styles.scrollToBottomContainer}>
//                 <h4>Search Results</h4>
//                 <button
//                   onClick={scrollToBottom}
//                   className={styles.scrollToBottom}
//                 >
//                   <BiSolidArrowToBottom size={25} />
//                 </button>
//               </div>
//               <div className={styles.globalSearchCardContainer}>
//                 {orderedResults.length > 0 ? (
//                   orderedResults.map((item) => renderCard(item))
//                 ) : (
//                   query && <NoResults query={query} />
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className={styles.scrollToTopContainer}>
//         <button onClick={scrollToTop} className={styles.scrollToTop}>
//           <BiSolidArrowFromBottom size={25} />
//         </button>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import { BiSolidArrowToBottom, BiSolidArrowFromBottom } from "react-icons/bi";

// const fuzzyMatch = (query, text) => {
//   if (!query || !text) return false;

//   const words = query.trim().split(/\s+/);
//   const regex = new RegExp(words.map((word) => `(${word})`).join("|"), "i");

//   return regex.test(text);
// };

// const prioritizeExactMatch = (query, items) => {
//   if (!query) return items;

//   const exactMatches = items.filter(
//     (item) =>
//       item.title?.toLowerCase() === query.toLowerCase() ||
//       item.courseTitle?.toLowerCase() === query.toLowerCase()
//   );
//   const otherMatches = items.filter(
//     (item) =>
//       item.title?.toLowerCase() !== query.toLowerCase() &&
//       item.courseTitle?.toLowerCase() !== query.toLowerCase()
//   );

//   return [...exactMatches, ...otherMatches];
// };

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const [defaultResults, setDefaultResults] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/trending-searches"
//         );
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchDefaultResults = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/default-results");
//         const data = await response.json();
//         setDefaultResults(data);
//       } catch (error) {
//         console.error("Error fetching default results:", error);
//       }
//     };

//     fetchDefaultResults();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       if (!query) {
//         setResults(defaultResults);
//         return;
//       }

//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();

//         const filteredResults = data.filter((item) => {
//           const textFields = [
//             item.title,
//             item.courseTitle,
//             item.description,
//             item.paper_code,
//             item.exam,
//             item.subject,
//             item.category,
//           ];
//           return textFields.some((field) => fuzzyMatch(query, field));
//         });

//         const sortedResults = prioritizeExactMatch(query, filteredResults);
//         setResults(sortedResults);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchResults();
//   }, [
//     query,
//     categoryFilter,
//     subjectFilter,
//     mediumFilter,
//     ratingFilter,
//     editionFilter,
//     defaultResults,
//   ]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
//   };

//   const navigateToDetails = (type, id, category, subject, SEOPermalink) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       if (SEOPermalink && category && subject) {
//         navigate(
//           `/videos/${category.trim().toLowerCase().replace(/ +/g, "-")}/${subject
//             .trim()
//             .toLowerCase()
//             .replace(/ +/g, "-")}/${SEOPermalink}`
//         );
//       } else {
//         console.warn(
//           `Missing data for SEO navigation:`,
//           `Category: ${category || "undefined"}`,
//           `Subject: ${subject || "undefined"}`,
//           `SEOPermalink: ${SEOPermalink || "undefined"}`
//         );
//       }
//     } else if (type === "testpass") {
//       navigate(`/test-pass/${id}`);
//     } else if (type === "typing") {
//       navigate(`/typing/${id}`);
//     }
//   };

//   const highlightText = (text, query) => {
//     if (!text) return "";
//     if (!query) return text;

//     const parts = text.split(new RegExp(`(${query})`, "gi"));
//     return parts.map((part, index) =>
//       part.toLowerCase() === query.toLowerCase() ? (
//         <span key={index} className={styles.highlight}>
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };

//   const renderCard = (item) => {
//     const tag =
//       item.type === "book"
//         ? "Book"
//         : item.type === "ebook"
//         ? "E-book"
//         : item.type === "video"
//         ? "Video"
//         : item.type === "testpass"
//         ? "Test Pass"
//         : "Typing";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() =>
//           navigateToDetails(
//             item.type,
//             item._id,
//             item.category,
//             item.subject,
//             item.SEOPermalink
//           )
//         }
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         {item.type === "typing" ? (
//           <div className={styles.typingCardContent}>
//             <p>
//               <strong>Exam:</strong> {item.exam || "N/A"}
//             </p>
//             <p>
//               <strong>Paper Code:</strong> {item.paper_code || "N/A"}
//             </p>
//           </div>
//         ) : (
//           <img
//             src={item.image1 || item.image || item.englishCoverImage}
//             alt={item.title || item.courseTitle}
//             className={styles.globalSearchCardImage}
//           />
//         )}
//         <h4 className={styles.globalSearchCardTitle}>
//           {highlightText(item.title || item.courseTitle, query)}
//         </h4>
//         {item.author && (
//           <p className={styles.globalSearchCardAuthor}>
//             {highlightText(item.author, query)}
//           </p>
//         )}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {highlightText(item.instructorName, query)}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//         {item.totalTests && (
//           <p className={styles.globalSearchCardTests}>
//             Total Tests: {item.totalTests}
//           </p>
//         )}
//       </div>
//     );
//   };

//   const orderedResults = [
//     ...results.filter((item) => item.type === "testpass"),
//     ...results.filter((item) => item.type === "book"),
//     ...results.filter((item) => item.type === "video"),
//     ...results.filter((item) => item.type === "ebook"),
//     ...results.filter((item) => item.type === "typing"),
//   ];

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div className={styles.trandingSearchitem}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "testpass"}
//                 onChange={() => handleFilterChange("category", "testpass")}
//               />
//               <label> Test Pass</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "typing"}
//                 onChange={() => handleFilterChange("category", "typing")}
//               />
//               <label> Typing</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && (
//             <p>
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                 alt="Loading..."
//               />
//             </p>
//           )}
//           {!loading && (
//             <div>
//               <div className={styles.scrollToBottomContainer}>
//                 <h4>Search Results</h4>
//                 <button
//                   onClick={scrollToBottom}
//                   className={styles.scrollToBottom}
//                 >
//                   <BiSolidArrowToBottom size={25} />
//                 </button>
//               </div>
//               <div className={styles.globalSearchCardContainer}>
//                 {orderedResults.length > 0 ? (
//                   orderedResults.map((item) => renderCard(item))
//                 ) : (
//                   query && <NoResults query={query} />
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className={styles.scrollToTopContainer}>
//         <button onClick={scrollToTop} className={styles.scrollToTop}>
//           <BiSolidArrowFromBottom size={25} />
//         </button>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import {
//   BiSolidArrowToBottom,
//   BiSolidArrowFromBottom,
// } from "react-icons/bi";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/trending-searches");
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query || ""
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();
//         setResults(data || []);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (query?.trim()) {
//       fetchResults();
//     }
//   }, [query, categoryFilter, subjectFilter, mediumFilter, ratingFilter, editionFilter]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
//   };

//   const navigateToDetails = (type, id) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       navigate(`/course/description/${id}`);
//     } else if (type === "testpass") {
//       navigate(`/test-pass/${id}`);
//     } else if (type === "typing") {
//       navigate(`/typing/${id}`);
//     }
//   };
//   const highlightText = (text, query) => {
//     if (!text) return "";
//     if (!query) return text;

//     const parts = text.split(new RegExp(`(${query})`, "gi"));
//     return parts.map((part, index) =>
//       part.toLowerCase() === query.toLowerCase() ? (
//         <span key={index} className={styles.highlight}>
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };
//   const renderCard = (item) => {
//     const tag =
//       item.type === "book"
//         ? "Book"
//         : item.type === "ebook"
//         ? "E-book"
//         : item.type === "video"
//         ? "Video"
//         : item.type === "testpass"
//         ? "Test Pass"
//         : "Typing";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(item.type, item._id)}
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         {item.type === "typing" ? (
//           <div className={styles.typingCardContent}>
//             <p>
//               <strong>Exam:</strong> {item.exam || "N/A"}
//             </p>
//             <p>
//               <strong></strong> {item.paper_code || "N/A"}
//             </p>
//           </div>
//         ) : (
//           <img
//             src={item.image1 || item.image || item.englishCoverImage}
//             alt={item.title || item.courseTitle}
//             className={styles.globalSearchCardImage}
//           />
//         )}
//         <h4 className={styles.globalSearchCardTitle}>
//           {highlightText(item.title || item.courseTitle, query)}
//         </h4>
//         {item.author && (
//           <p className={styles.globalSearchCardAuthor}>
//             {highlightText(item.author, query)}
//           </p>
//         )}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {highlightText(item.instructorName, query)}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//         {item.totalTests && (
//           <p className={styles.globalSearchCardTests}>
//             Total Tests: {item.totalTests}
//           </p>
//         )}
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div className={styles.trandingSearchitem}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "testpass"}
//                 onChange={() => handleFilterChange("category", "testpass")}
//               />
//               <label> Test Pass</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "typing"}
//                 onChange={() => handleFilterChange("category", "typing")}
//               />
//               <label> Typing</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && (
//             <p>
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                 alt="Loading..."
//               />
//             </p>
//           )}
//           {!loading && (
//             <div>
//               <div className={styles.scrollToBottomContainer}>
//                 <h4>Search Results</h4>
//                 <button onClick={scrollToBottom} className={styles.scrollToBottom}>
//                   <BiSolidArrowToBottom size={25} />
//                 </button>
//               </div>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <NoResults query={query} />}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className={styles.scrollToTopContainer}>
//         <button onClick={scrollToTop} className={styles.scrollToTop}>
//           <BiSolidArrowFromBottom size={25} />
//         </button>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import NoResults from "./NoResults";
// import { BiSolidArrowToBottom ,BiSolidArrowFromBottom,BiSolidArrowToRight} from "react-icons/bi";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);
//   const [trendingSearches, setTrendingSearches] = useState([]);
//   const navigate = useNavigate();
//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/trending-searches");
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);
//   useEffect(() => {
//     const fetchResults = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query || ""
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();
//         setResults(data || []);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (query?.trim()) {
//       fetchResults();
//     }
//   }, [query, categoryFilter, subjectFilter, mediumFilter, ratingFilter, editionFilter]);
//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//  // Scroll to Top functionality
//  const scrollToTop = () => {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// };

// // Scroll to Bottom functionality
// const scrollToBottom = () => {
//   window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
// };
//   const navigateToDetails = (type, id) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       navigate(`/course/description/${id}`);
//     }
//   };
//   const highlightText = (text, query) => {
//     if (!query) return text;

//     const parts = text.split(new RegExp(`(${query})`, "gi"));
//     return parts.map((part, index) =>
//       part.toLowerCase() === query.toLowerCase() ? (
//         <span key={index} className={styles.highlight}>
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };
//   const renderCard = (item) => {
//     const tag = item.type === "book" ? "Book" : item.type === "ebook" ? "E-book" : "Video";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(item.type, item._id)}
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {highlightText(item.title || item.courseTitle, query)}
//         </h4>
//         {item.author && (
//           <p className={styles.globalSearchCardAuthor}>
//             {highlightText(item.author, query)}
//           </p>
//         )}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {highlightText(item.instructorName, query)}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div className={styles.trandingSearchitem}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && (
//             <p>
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                 alt="Loading..."
//               />
//             </p>
//           )}
//           {!loading && (
//             <div>
//                 <div className={styles.scrollToBottomContainer}>
//                 <h4>Search Results</h4>
//                 <button
//                   onClick={scrollToBottom}
//                   className={styles.scrollToBottom}
//                 >
//                  <BiSolidArrowToBottom size={25}/>
//                 </button>
//               </div>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <NoResults query={query} />}

//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className={styles.scrollToTopContainer}>
//         <button onClick={scrollToTop} className={styles.scrollToTop}>
//          <BiSolidArrowFromBottom size={25}/>
//         </button>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import { LuTimerReset } from "react-icons/lu";
// import NoResults from "./NoResults"

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);

//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trendingSearches, setTrendingSearches] = useState([]);

//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedSearches = JSON.parse(localStorage.getItem("recentSearches")) || [];
//     setRecentSearches(storedSearches);
//   }, []);

//   const updateRecentSearches = (query) => {
//     const searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
//     const updatedSearches = [query, ...searches.filter((item) => item !== query)].slice(0, 5);
//     localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
//     setRecentSearches(updatedSearches);
//   };

//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/trending-searches");
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);

//   useEffect(() => {
//     const fetchResults = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query || ""
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();
//         setResults(data || []); // Ensure fallback to an empty array if no results
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (query?.trim()) {
//       updateRecentSearches(query);
//     }
//     fetchResults();
//   }, [query, categoryFilter, subjectFilter, mediumFilter, ratingFilter, editionFilter]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const navigateToDetails = (type, id) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       navigate(`/course/description/${id}`);
//     }
//   };

//   const highlightText = (text, query) => {
//     if (!query) return text;

//     const parts = text.split(new RegExp(`(${query})`, "gi")); // Split by the query (case-insensitive)
//     return parts.map((part, index) =>
//       part.toLowerCase() === query.toLowerCase() ? (
//         <span key={index} className={styles.highlight}>
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };

//   const renderCard = (item) => {
//     const tag = item.type === "book" ? "Book" : item.type === "ebook" ? "E-book" : "Video";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(item.type, item._id)}
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {highlightText(item.title || item.courseTitle, query)}
//         </h4>
//         {item.author && (
//           <p className={styles.globalSearchCardAuthor}>
//             {highlightText(item.author, query)}
//           </p>
//         )}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {highlightText(item.instructorName, query)}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <ul>
//             {recentSearches.slice(0, 2).map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <LuTimerReset />
//                 <div onClick={() => updateRecentSearches(search)} className={styles.trandingSearchitem}>
//                   {search}
//                 </div>
//               </div>
//             ))}

//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv} key={index}>
//                 <MdTimeline />
//                 <div className={styles.trandingSearchitem}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>
//         <div className={styles.globalSearchMain}>
//           {loading && (
//             <p>
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                 alt="Loading..."
//               />
//             </p>
//           )}
//           {!loading && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <NoResults query={query} /> }

//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";
// import { MdTimeline } from "react-icons/md";
// import { LuTimerReset } from "react-icons/lu";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);

//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trendingSearches, setTrendingSearches] = useState([]);

//   const navigate = useNavigate();
//   useEffect(() => {
//     const storedSearches = JSON.parse(localStorage.getItem("recentSearches")) || [];
//     setRecentSearches(storedSearches);
//   }, []);
//   const updateRecentSearches = (query) => {
//     const searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
//     const updatedSearches = [query, ...searches.filter((item) => item !== query)].slice(0, 5);
//     localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
//     setRecentSearches(updatedSearches);
//   };
//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch("https://auth.ssccglpinnacle.com/api/trending-searches");
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);
//   useEffect(() => {
//     const fetchResults = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           // `http://localhost:5000/api/search?query=${encodeURIComponent(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query || ""
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();
//         setResults(data || []); // Ensure fallback to an empty array if no results
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (query?.trim()) {
//       updateRecentSearches(query);
//     }
//     fetchResults();
//   }, [query, categoryFilter, subjectFilter, mediumFilter, ratingFilter, editionFilter]);
//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };
//   const navigateToDetails = (type, id) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       navigate(`/course/description/${id}`);
//     }
//   };

//   const renderCard = (item) => {
//     const tag = item.type === "book" ? "Book" : item.type === "ebook" ? "E-book" : "Video";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(item.type, item._id)}
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {item.title || item.courseTitle}
//         </h4>
//         {item.author && <p className={styles.globalSearchCardAuthor}>{item.author}</p>}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {item.instructorName}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <ul>
//           {recentSearches.slice(0, 2).map((search, index) => (
//   <div className={styles.trandingSearchDiv} key={index}>
//     <LuTimerReset />
//     <div onClick={() => updateRecentSearches(search)} className={styles.trandingSearchitem}>
//       {search}
//     </div>
//   </div>
// ))}

//             {trendingSearches.map((search, index) => (
//               <div className={styles.trandingSearchDiv}>
//               <MdTimeline/>
//               <div className={styles.trandingSearchitem} key={index}>{search}</div>
//               </div>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>
//         <div className={styles.globalSearchMain}>
//           {loading && <p><img src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif" alt="Loading..."></img></p>}
//           {!loading && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <p>We couldn't find any results for your search. 😊</p>}
//                 </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);

//   // Filters
//   const [categoryFilter, setCategoryFilter] = useState("all");
//   const [subjectFilter, setSubjectFilter] = useState("");
//   const [mediumFilter, setMediumFilter] = useState("");
//   const [ratingFilter, setRatingFilter] = useState(null);
//   const [editionFilter, setEditionFilter] = useState(null);

//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trendingSearches, setTrendingSearches] = useState([]);

//   const navigate = useNavigate();

//   // Fetch recent searches from localStorage
//   useEffect(() => {
//     const storedSearches =
//       JSON.parse(localStorage.getItem("recentSearches")) || [];
//     setRecentSearches(storedSearches);
//   }, []);

//   // Save and update recent searches in localStorage
//   const updateRecentSearches = (query) => {
//     const searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
//     const updatedSearches = [
//       query,
//       ...searches.filter((item) => item !== query),
//     ].slice(0, 5);
//     localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
//     setRecentSearches(updatedSearches);
//   };

//   // Fetch trending searches from the backend
//   useEffect(() => {
//     const fetchTrendingSearches = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/trending-searches"
//         );
//         const data = await response.json();
//         setTrendingSearches(data.map((item) => item.query));
//       } catch (error) {
//         console.error("Error fetching trending searches:", error);
//       }
//     };

//     fetchTrendingSearches();
//   }, []);

//   // Fetch search results based on query and filters
//   useEffect(() => {
//     const fetchResults = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           // `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//           `https://auth.ssccglpinnacle.com/api/search?query=${encodeURIComponent(
//             query || ""
//           )}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${
//             ratingFilter || ""
//           }&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();
//         setResults(data || []); // Ensure fallback to an empty array if no results
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setResults([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (query?.trim()) {
//       updateRecentSearches(query);
//     }
//     fetchResults();
//   }, [
//     query,
//     categoryFilter,
//     subjectFilter,
//     mediumFilter,
//     ratingFilter,
//     editionFilter,
//   ]);

//   // Handle filter changes
//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   // Navigate to details page
//   const navigateToDetails = (type, id) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       navigate(`/course/description/${id}`);
//     }
//   };

//   const renderCard = (item) => {
//     const tag =
//       item.type === "book"
//         ? "Book"
//         : item.type === "ebook"
//         ? "E-book"
//         : "Video";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(item.type, item._id)}
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {item.title || item.courseTitle}
//         </h4>
//         {item.author && (
//           <p className={styles.globalSearchCardAuthor}>{item.author}</p>
//         )}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {item.instructorName}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         {/* Sidebar Filters */}
//         <div className={styles.globalSearchSidebar}>
//           <h3>Recent Searches</h3>
//           <ul>
//             {recentSearches.map((search, index) => (
//               <li key={index} onClick={() => updateRecentSearches(search)}>
//                 {search}
//               </li>
//             ))}
//           </ul>

//           <h3>Trending Searches</h3>
//           <ul>
//             {trendingSearches.map((search, index) => (
//               <li key={index}>{search}</li>
//             ))}
//           </ul>

//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>
//         </div>

//         {/* Main Results */}
//         <div className={styles.globalSearchMain}>
//           {loading && (
//             <p>
//               <img
//                 src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                 alt="Loading..."
//               ></img>
//             </p>
//           )}
//           {!loading && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <p>No results found</p>}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [loading, setLoading] = useState(false);

//   // Filters
//   const [categoryFilter, setCategoryFilter] = useState("all"); // Books, E-Books, Videos
//   const [subjectFilter, setSubjectFilter] = useState(""); // Subject
//   const [mediumFilter, setMediumFilter] = useState(""); // Medium
//   const [ratingFilter, setRatingFilter] = useState(null); // Rating
//   const [editionFilter, setEditionFilter] = useState(null); // Edition
//   const [sortBy, setSortBy] = useState("relevant"); // Sort By filter

//   const navigate = useNavigate();

//   // Fetch results
//   useEffect(() => {
//     const fetchResults = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/search?query=${query || ""}&category=${categoryFilter}&subject=${subjectFilter}&medium=${mediumFilter}&rating=${ratingFilter || ""}&edition=${editionFilter || ""}`
//         );
//         const data = await response.json();
//         setResults(data);
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchResults();
//   }, [query, categoryFilter, subjectFilter, mediumFilter, ratingFilter, editionFilter]);

//   const handleFilterChange = (type, value) => {
//     if (type === "category") setCategoryFilter(value);
//     if (type === "subject") setSubjectFilter(value);
//     if (type === "medium") setMediumFilter(value);
//     if (type === "rating") setRatingFilter(value);
//     if (type === "edition") setEditionFilter(value);
//   };

//   const renderAppliedConditions = () => {
//     const conditions = [
//       categoryFilter !== "all" && `Category: ${categoryFilter}`,
//       subjectFilter && `Subject: ${subjectFilter}`,
//       mediumFilter && `Medium: ${mediumFilter}`,
//       ratingFilter && `Rating: ${ratingFilter} & Up`,
//       editionFilter && `Edition: ${editionFilter}`,
//     ].filter(Boolean);

//     return conditions.length > 0 ? (
//       <div className={styles.appliedConditions}>
//         <h4>Applied Filters:</h4>
//         {conditions.map((condition, index) => (
//           <span key={index} className={styles.appliedConditionItem}>
//             {condition}
//           </span>
//         ))}
//       </div>
//     ) : (
//       <div className={styles.appliedConditions}>
//         <h4>No Filters Applied</h4>
//       </div>
//     );
//   };

//   const navigateToDetails = (type, id) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       navigate(`/course/description/${id}`);
//     }
//   };

//   const renderCard = (item) => {
//     const tag = item.type === "book" ? "Book" : item.type === "ebook" ? "E-book" : "Video";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(item.type, item._id)}
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         <img
//           src={item.image1 || item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {item.title || item.courseTitle}
//         </h4>
//         {item.author && <p className={styles.globalSearchCardAuthor}>{item.author}</p>}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {item.instructorName}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header />
//       {renderAppliedConditions()}
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Category</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "all"}
//                 onChange={() => handleFilterChange("category", "all")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "book"}
//                 onChange={() => handleFilterChange("category", "book")}
//               />
//               <label> Books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "ebook"}
//                 onChange={() => handleFilterChange("category", "ebook")}
//               />
//               <label> E-books</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="category"
//                 checked={categoryFilter === "video"}
//                 onChange={() => handleFilterChange("category", "video")}
//               />
//               <label> Videos</label>
//             </li>
//           </ul>

//           <h3>Subject</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="subject"
//                 checked={subjectFilter === ""}
//                 onChange={() => handleFilterChange("subject", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="subject"
//                 checked={subjectFilter === "maths"}
//                 onChange={() => handleFilterChange("subject", "maths")}
//               />
//               <label> Maths</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="subject"
//                 checked={subjectFilter === "science"}
//                 onChange={() => handleFilterChange("subject", "science")}
//               />
//               <label> Science</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="subject"
//                 checked={subjectFilter === "english"}
//                 onChange={() => handleFilterChange("subject", "english")}
//               />
//               <label> English</label>
//             </li>
//           </ul>

//           <h3>Medium</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === ""}
//                 onChange={() => handleFilterChange("medium", "")}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "english"}
//                 onChange={() => handleFilterChange("medium", "english")}
//               />
//               <label> English</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="medium"
//                 checked={mediumFilter === "hindi"}
//                 onChange={() => handleFilterChange("medium", "hindi")}
//               />
//               <label> Hindi</label>
//             </li>
//           </ul>

//           <h3>Rating</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="rating"
//                 checked={ratingFilter === null}
//                 onChange={() => handleFilterChange("rating", null)}
//               />
//               <label> All</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="rating"
//                 checked={ratingFilter === 4}
//                 onChange={() => handleFilterChange("rating", 4)}
//               />
//               <label> ⭐ 4.0 & Up</label>
//             </li>
//             <li>
//               <input
//                 type="radio"
//                 name="rating"
//                 checked={ratingFilter === 3}
//                 onChange={() => handleFilterChange("rating", 3)}
//               />
//               <label> ⭐ 3.0 & Up</label>
//             </li>
//           </ul>

//           <h3>Edition</h3>
//           <ul>
//             <li>
//               <input
//                 type="radio"
//                 name="edition"
//                 checked={editionFilter === null}
//                 onChange={() => handleFilterChange("edition", null)}
//               />
//               <label>All</label>
//             </li>
//             {[1, 2, 3, 4, 5, 6].map((edition) => (
//               <li key={edition}>
//                 <input
//                   type="radio"
//                   name="edition"
//                   checked={editionFilter === edition}
//                   onChange={() => handleFilterChange("edition", edition)}
//                 />
//                 <label> Edition {edition}</label>
//               </li>
//             ))}
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && <p><img src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif" alt="Loading..."></img></p>}
//           {!loading && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <p>No results found</p>}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom"; // Import for navigation
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [filter, setFilter] = useState("all"); // Filter by category
//   const [ratingFilter, setRatingFilter] = useState(null); // Filter by rating
//   const [editionFilter, setEditionFilter] = useState(null); // Filter by edition
//   const [sortBy, setSortBy] = useState("relevant"); // Sort By filter
//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trending, setTrending] = useState([
//     "SSC Maths 6800 TCS MCQ chapter wise 4th edition book Video course",
//     "SSC Maths 6800 TCS MCQ Chapter Wise",
//     "SSC Maths 6800 TCS MCQ Chapter wise 6th edition english medium",
//   ]);
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate(); // Initialize useNavigate

//   // Data states
//   const [books, setBooks] = useState([]);
//   const [ebooks, setEbooks] = useState([]);
//   const [videoCourses, setVideoCourses] = useState([]);

//   useEffect(() => {
//     // Fetch data from APIs
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const [booksRes, ebooksRes, videoCoursesRes] = await Promise.all([
//           fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true").then((res) =>
//             res.json()
//           ),
//           fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true").then((res) =>
//             res.json()
//           ),
//           fetch("https://auth.ssccglpinnacle.com/api/active-courses").then((res) =>
//             res.json()
//           ),
//         ]);

//         setBooks(booksRes);
//         setEbooks(ebooksRes);
//         setVideoCourses(videoCoursesRes);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (query || ratingFilter || editionFilter || sortBy) {
//       const allData = [...books, ...ebooks, ...videoCourses];
//       let filteredResults = allData.filter((item) => {
//         const matchesQuery = query
//           ? item.title && item.title.toLowerCase().includes(query.toLowerCase())
//           : true;
//         const matchesFilter =
//           filter === "all" ||
//           (filter === "book" && books.includes(item)) ||
//           (filter === "ebook" && ebooks.includes(item)) ||
//           (filter === "video" && videoCourses.includes(item));

//         const matchesRating =
//           ratingFilter === null || (item.rating && item.rating >= ratingFilter);

//         const matchesEdition =
//           editionFilter === null || item.edition === `${editionFilter}`;

//         return matchesQuery && matchesFilter && matchesRating && matchesEdition;
//       });

//       // Sort results based on sortBy filter
//       if (sortBy === "reviewed") {
//         filteredResults.sort((a, b) => b.reviews - a.reviews);
//       } else if (sortBy === "rated") {
//         filteredResults.sort((a, b) => b.rating - a.rating);
//       } else if (sortBy === "newest") {
//         filteredResults.sort((a, b) => new Date(b.publishingDate) - new Date(a.publishingDate));
//       }

//       setResults(filteredResults);

//       if (query && !recentSearches.includes(query)) {
//         setRecentSearches((prev) => [query, ...prev.slice(0, 4)]);
//       }
//     } else {
//       setResults([]);
//     }
//   }, [query, filter, ratingFilter, editionFilter, sortBy, books, ebooks, videoCourses]);

//   const handleFilterChange = (newFilter) => {
//     setFilter(newFilter);
//   };

//   const handleRatingFilterChange = (rating) => {
//     setRatingFilter(rating);
//   };

//   const handleEditionFilterChange = (edition) => {
//     setEditionFilter(edition);
//   };

//   const handleSortByChange = (sortOption) => {
//     setSortBy(sortOption);
//   };

//   const navigateToDetails = (type, id) => {
//     if (type === "book") {
//       navigate(`/intro-book/${id}`);
//     } else if (type === "ebook") {
//       navigate(`/intro/${id}`);
//     } else if (type === "video") {
//       navigate(`/course/description/${id}`);
//     }
//   };

//   const renderCard = (item, type) => {
//     const tag = type === "book" ? "Book" : type === "ebook" ? "E-book" : "Video";

//     return (
//       <div
//         key={item._id}
//         className={styles.globalSearchCard}
//         onClick={() => navigateToDetails(type, item._id)}
//       >
//         <div className={styles.globalSearchTag}>{tag}</div>
//         <img
//           src={type === "book" ? item.image1 : item.image || item.englishCoverImage}
//           alt={item.title || item.courseTitle}
//           className={styles.globalSearchCardImage}
//         />
//         <h4 className={styles.globalSearchCardTitle}>
//           {item.title || item.courseTitle}
//         </h4>
//         {item.author && <p className={styles.globalSearchCardAuthor}>{item.author}</p>}
//         {item.instructorName && (
//           <p className={styles.globalSearchCardInstructor}>
//             Instructor: {item.instructorName}
//           </p>
//         )}
//         <p className={styles.globalSearchCardPrice}>
//           {item.BookSellingPrice || item.price
//             ? `₹${item.BookSellingPrice || item.price}`
//             : "Free"}
//         </p>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Category</h3>
//           <ul>
//             <li
//               onClick={() => handleFilterChange("all")}
//               className={filter === "all" ? styles.active : ""}
//             >
//               All
//             </li>
//             <li
//               onClick={() => handleFilterChange("book")}
//               className={filter === "book" ? styles.active : ""}
//             >
//               Books
//             </li>
//             <li
//               onClick={() => handleFilterChange("ebook")}
//               className={filter === "ebook" ? styles.active : ""}
//             >
//               E-books
//             </li>
//             <li
//               onClick={() => handleFilterChange("video")}
//               className={filter === "video" ? styles.active : ""}
//             >
//               Video Courses
//             </li>
//           </ul>

//           <h3>Sort By</h3>
//           <ul>
//             <li onClick={() => handleSortByChange("relevant")}>Most Relevant</li>
//             <li onClick={() => handleSortByChange("reviewed")}>Most Reviewed</li>
//             <li onClick={() => handleSortByChange("rated")}>Highest Rated</li>
//             <li onClick={() => handleSortByChange("newest")}>Newest</li>
//           </ul>

//           <h3>Rating</h3>
//           <ul>
//             <li onClick={() => handleRatingFilterChange(4)}>⭐ 4.0 & Up</li>
//             <li onClick={() => handleRatingFilterChange(3)}>⭐ 3.0 & Up</li>
//           </ul>

//           <h3>Edition</h3>
//           <ul>
//             {[1, 2, 3, 4, 5, 6].map((edition) => (
//               <li key={edition} onClick={() => handleEditionFilterChange(edition)}>
//                 Edition {edition}
//               </li>
//             ))}
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && <p>Loading...</p>}
//           {!loading && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) =>
//                   books.includes(item)
//                     ? renderCard(item, "book")
//                     : ebooks.includes(item)
//                     ? renderCard(item, "ebook")
//                     : renderCard(item, "video")
//                 )}
//                 {results.length === 0 && <p>No results found</p>}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [filter, setFilter] = useState("all");
//   const [ratingFilter, setRatingFilter] = useState(null); // Filter by rating
//   const [editionFilter, setEditionFilter] = useState(null); // Filter by edition
//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trending, setTrending] = useState([
//     "SSC Maths 6800 TCS MCQ chapter wise 4th edition book Video course",
//     "SSC Maths 6800 TCS MCQ Chapter Wise",
//     "SSC Maths 6800 TCS MCQ Chapter wise 6th edition english medium",
//   ]);
//   const [loading, setLoading] = useState(false);

//   // Data states
//   const [books, setBooks] = useState([]);
//   const [ebooks, setEbooks] = useState([]);
//   const [videoCourses, setVideoCourses] = useState([]);

//   useEffect(() => {
//     // Fetch data from APIs
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const [booksRes, ebooksRes, videoCoursesRes] = await Promise.all([
//           fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true").then((res) =>
//             res.json()
//           ),
//           fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true").then((res) =>
//             res.json()
//           ),
//           fetch("https://auth.ssccglpinnacle.com/api/active-courses").then((res) =>
//             res.json()
//           ),
//         ]);

//         setBooks(booksRes);
//         setEbooks(ebooksRes);
//         setVideoCourses(videoCoursesRes);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (query || ratingFilter || editionFilter) {
//       const allData = [...books, ...ebooks, ...videoCourses];
//       const filteredResults = allData.filter((item) => {
//         const matchesQuery = query
//           ? item.title && item.title.toLowerCase().includes(query.toLowerCase())
//           : true;
//         const matchesFilter =
//           filter === "all" ||
//           (filter === "book" && books.includes(item)) ||
//           (filter === "ebook" && ebooks.includes(item)) ||
//           (filter === "video" && videoCourses.includes(item));

//         const matchesRating =
//           ratingFilter === null || (item.rating && item.rating >= ratingFilter);

//         const matchesEdition =
//           editionFilter === null || item.edition === `${editionFilter}`;

//         return matchesQuery && matchesFilter && matchesRating && matchesEdition;
//       });
//       setResults(filteredResults);

//       if (query && !recentSearches.includes(query)) {
//         setRecentSearches((prev) => [query, ...prev.slice(0, 4)]);
//       }
//     } else {
//       setResults([]);
//     }
//   }, [query, filter, ratingFilter, editionFilter, books, ebooks, videoCourses]);

//   const handleFilterChange = (newFilter) => {
//     setFilter(newFilter);
//   };

//   const handleRatingFilterChange = (rating) => {
//     setRatingFilter(rating);
//   };

//   const handleEditionFilterChange = (edition) => {
//     setEditionFilter(edition);
//   };

//   const renderCard = (item) => {
//     if (item.BookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.globalSearchBookCard}>
//           <img src={item.image1} alt={item.title} className={styles.globalSearchCardImage} />
//           <h4 className={styles.globalSearchCardTitle}>{item.title}</h4>
//           <p className={styles.globalSearchCardAuthor}>{item.author}</p>
//           <p className={styles.globalSearchCardPrice}>
//             ₹{item.BookSellingPrice} (Save ₹{item.BookPrintingPrice - item.BookSellingPrice})
//           </p>
//           <div className={styles.globalSearchCardLinks}>
//             <a href={item.amazonLink} target="_blank" rel="noopener noreferrer">
//               Amazon
//             </a>
//             <a href={item.flipkartLink} target="_blank" rel="noopener noreferrer">
//               Flipkart
//             </a>
//           </div>
//         </div>
//       );
//     } else if (item.ebookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.globalSearchEbookCard}>
//           <img src={item.image} alt={item.title} className={styles.globalSearchCardImage} />
//           <h4 className={styles.globalSearchCardTitle}>{item.title}</h4>
//           <p className={styles.globalSearchCardAuthor}>{item.author}</p>
//           <p className={styles.globalSearchCardPrice}>₹{item.price}</p>
//         </div>
//       );
//     } else if (item.courseTitle) {
//       return (
//         <div key={item._id} className={styles.globalSearchVideoCard}>
//           <img src={item.englishCoverImage} alt={item.courseTitle} className={styles.globalSearchCardImage} />
//           <h4 className={styles.globalSearchCardTitle}>{item.courseTitle}</h4>
//           <p className={styles.globalSearchCardInstructor}>Instructor: {item.instructorName}</p>
//           <p className={styles.globalSearchCardPrice}>
//             ₹{item.price} (MRP: ₹{item.mrp})
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h4>Trending Searches</h4>
//           <ul>
//             {trending.map((item, index) => (
//               <li key={index}>{item}</li>
//             ))}
//           </ul>

//           <h3>Filters</h3>
//           <ul>
//             <li
//               onClick={() => handleFilterChange("all")}
//               className={filter === "all" ? styles.active : ""}
//             >
//               All
//             </li>
//             <li
//               onClick={() => handleFilterChange("book")}
//               className={filter === "book" ? styles.active : ""}
//             >
//               Books
//             </li>
//             <li
//               onClick={() => handleFilterChange("ebook")}
//               className={filter === "ebook" ? styles.active : ""}
//             >
//               E-books
//             </li>
//             <li
//               onClick={() => handleFilterChange("video")}
//               className={filter === "video" ? styles.active : ""}
//             >
//               Video Courses
//             </li>
//           </ul>

//           <h3>Rating</h3>
//           <ul>
//             <li onClick={() => handleRatingFilterChange(4)}>Above 4</li>
//             <li onClick={() => handleRatingFilterChange(3)}>Above 3</li>
//           </ul>

//           <h3>Edition</h3>
//           <ul>
//             {[1, 2, 3, 4, 5, 6].map((edition) => (
//               <li key={edition} onClick={() => handleEditionFilterChange(edition)}>
//                 Edition {edition}
//               </li>
//             ))}
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && <p>Loading...</p>}
//           {!loading && query === "" && (
//             <div>
//               <h4>Search Results</h4>
//               <p>Type to search for results...</p>
//             </div>
//           )}
//           {!loading && query && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <p>No results found</p>}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [filter, setFilter] = useState("all");
//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trending, setTrending] = useState([
//     "React for Beginners",
//     "Full-Stack Development with MERN",
//     "JavaScript Basics Quiz",
//   ]);
//   const [loading, setLoading] = useState(false);

//   // Data states
//   const [books, setBooks] = useState([]);
//   const [ebooks, setEbooks] = useState([]);
//   const [videoCourses, setVideoCourses] = useState([]);

//   useEffect(() => {
//     // Fetch data from APIs
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const [booksRes, ebooksRes, videoCoursesRes] = await Promise.all([
//           fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true").then((res) => res.json()),
//           fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true").then((res) => res.json()),
//           fetch("https://auth.ssccglpinnacle.com/api/active-courses").then((res) => res.json()),
//         ]);

//         setBooks(booksRes);
//         setEbooks(ebooksRes);
//         setVideoCourses(videoCoursesRes);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (query) {
//       const allData = [...books, ...ebooks, ...videoCourses];
//       const filteredResults = allData.filter((item) => {
//         const matchesQuery = item.title && item.title.toLowerCase().includes(query.toLowerCase());
//         const matchesFilter =
//           filter === "all" ||
//           (filter === "book" && books.includes(item)) ||
//           (filter === "ebook" && ebooks.includes(item)) ||
//           (filter === "video" && videoCourses.includes(item));
//         return matchesQuery && matchesFilter;
//       });
//       setResults(filteredResults);

//       if (!recentSearches.includes(query)) {
//         setRecentSearches((prev) => [query, ...prev.slice(0, 4)]);
//       }
//     } else {
//       setResults([]);
//     }
//   }, [query, filter, books, ebooks, videoCourses]);

//   const handleFilterChange = (newFilter) => {
//     setFilter(newFilter);
//   };

//   const renderCard = (item) => {
//     if (item.BookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.bookCard}>
//           <img src={item.image1} alt={item.title} className={styles.cardImage} />
//           <h4 className={styles.cardTitle}>{item.title}</h4>
//           <p className={styles.cardAuthor}>{item.author}</p>
//           <p className={styles.cardPrice}>
//             ₹{item.BookSellingPrice} (Save ₹{item.BookPrintingPrice - item.BookSellingPrice})
//           </p>
//           <div className={styles.cardLinks}>
//             <a href={item.amazonLink} target="_blank" rel="noopener noreferrer">
//               Amazon
//             </a>
//             <a href={item.flipkartLink} target="_blank" rel="noopener noreferrer">
//               Flipkart
//             </a>
//           </div>
//         </div>
//       );
//     } else if (item.ebookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.ebookCard}>
//           <img src={item.image} alt={item.title} className={styles.cardImage} />
//           <h4 className={styles.cardTitle}>{item.title}</h4>
//           <p className={styles.cardAuthor}>{item.author}</p>
//           <p className={styles.cardPrice}>₹{item.price}</p>
//         </div>
//       );
//     } else if (item.courseTitle) {
//       return (
//         <div key={item._id} className={styles.videoCard}>
//           <img src={item.englishCoverImage} alt={item.courseTitle} className={styles.cardImage} />
//           <h4 className={styles.cardTitle}>{item.courseTitle}</h4>
//           <p className={styles.cardInstructor}>Instructor: {item.instructorName}</p>
//           <p className={styles.cardPrice}>
//             ₹{item.price} (MRP: ₹{item.mrp})
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.container}>
//         <div className={styles.sidebar}>
//           <h3>Filters</h3>
//           <ul>
//             <li
//               onClick={() => handleFilterChange("all")}
//               className={filter === "all" ? styles.active : ""}
//             >
//               All
//             </li>
//             <li
//               onClick={() => handleFilterChange("book")}
//               className={filter === "book" ? styles.active : ""}
//             >
//               Books
//             </li>
//             <li
//               onClick={() => handleFilterChange("ebook")}
//               className={filter === "ebook" ? styles.active : ""}
//             >
//               E-books
//             </li>
//             <li
//               onClick={() => handleFilterChange("video")}
//               className={filter === "video" ? styles.active : ""}
//             >
//               Video Courses
//             </li>
//           </ul>
//         </div>
//         <div className={styles.main}>
//           {loading && <p>Loading...</p>}
//           {!loading && query === "" && (
//             <div>
//               <h4>Trending</h4>
//               <ul>
//                 {trending.map((item, index) => (
//                   <li key={index}>{item}</li>
//                 ))}
//               </ul>
//               <h4>Recent Searches</h4>
//               <ul>
//                 {recentSearches.map((item, index) => (
//                   <li key={index}>{item}</li>
//                 ))}
//               </ul>
//             </div>
//           )}
//           {!loading && query && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.cardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <p>No results found</p>}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect } from "react";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";

// const GlobalSearch = () => {
//   const [query, setQuery] = useState("");
//   const [results, setResults] = useState([]);
//   const [filter, setFilter] = useState("all");
//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trending, setTrending] = useState([
//     "React for Beginners",
//     "Full-Stack Development with MERN",
//     "JavaScript Basics Quiz",
//   ]);
//   const [loading, setLoading] = useState(false);

//   // Data states
//   const [books, setBooks] = useState([]);
//   const [ebooks, setEbooks] = useState([]);
//   const [videoCourses, setVideoCourses] = useState([]);

//   useEffect(() => {
//     // Fetch data from APIs
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const [booksRes, ebooksRes, videoCoursesRes] = await Promise.all([
//           fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true").then((res) => res.json()),
//           fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true").then((res) => res.json()),
//           fetch("https://auth.ssccglpinnacle.com/api/active-courses").then((res) => res.json()),
//         ]);

//         setBooks(booksRes);
//         setEbooks(ebooksRes);
//         setVideoCourses(videoCoursesRes);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (query) {
//       const allData = [...books, ...ebooks, ...videoCourses];
//       const filteredResults = allData.filter((item) => {
//         const matchesQuery = item.title && item.title.toLowerCase().includes(query.toLowerCase());
//         const matchesFilter =
//           filter === "all" ||
//           (filter === "book" && books.includes(item)) ||
//           (filter === "ebook" && ebooks.includes(item)) ||
//           (filter === "video" && videoCourses.includes(item));
//         return matchesQuery && matchesFilter;
//       });
//       setResults(filteredResults);

//       if (!recentSearches.includes(query)) {
//         setRecentSearches((prev) => [query, ...prev.slice(0, 4)]);
//       }
//     } else {
//       setResults([]);
//     }
//   }, [query, filter, books, ebooks, videoCourses]);

//   const handleSearch = (e) => {
//     setQuery(e.target.value);
//   };

//   const handleFilterChange = (newFilter) => {
//     setFilter(newFilter);
//   };

//   const renderCard = (item) => {
//     if (item.BookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.bookCard}>
//           <img src={item.image1} alt={item.title} className={styles.cardImage} />
//           <h4 className={styles.cardTitle}>{item.title}</h4>
//           <p className={styles.cardAuthor}>{item.author}</p>
//           <p className={styles.cardPrice}>₹{item.BookSellingPrice} (Save ₹{item.BookPrintingPrice - item.BookSellingPrice})</p>
//           <div className={styles.cardLinks}>
//             <a href={item.amazonLink} target="_blank" rel="noopener noreferrer">Amazon</a>
//             <a href={item.flipkartLink} target="_blank" rel="noopener noreferrer">Flipkart</a>
//           </div>
//         </div>
//       );
//     } else if (item.ebookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.ebookCard}>
//           <img src={item.image} alt={item.title} className={styles.cardImage} />
//           <h4 className={styles.cardTitle}>{item.title}</h4>
//           <p className={styles.cardAuthor}>{item.author}</p>
//           <p className={styles.cardPrice}>₹{item.price}</p>
//         </div>
//       );
//     } else if (item.courseTitle) {
//       return (
//         <div key={item._id} className={styles.videoCard}>
//           <img src={item.englishCoverImage} alt={item.courseTitle} className={styles.cardImage} />
//           <h4 className={styles.cardTitle}>{item.courseTitle}</h4>
//           <p className={styles.cardInstructor}>Instructor: {item.instructorName}</p>
//           <p className={styles.cardPrice}>₹{item.price} (MRP: ₹{item.mrp})</p>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.container}>
//         <div className={styles.sidebar}>
//           <h3>Filters</h3>
//           <ul>
//             <li
//               onClick={() => handleFilterChange("all")}
//               className={filter === "all" ? styles.active : ""}
//             >
//               All
//             </li>
//             <li
//               onClick={() => handleFilterChange("book")}
//               className={filter === "book" ? styles.active : ""}
//             >
//               Books
//             </li>
//             <li
//               onClick={() => handleFilterChange("ebook")}
//               className={filter === "ebook" ? styles.active : ""}
//             >
//               E-books
//             </li>
//             <li
//               onClick={() => handleFilterChange("video")}
//               className={filter === "video" ? styles.active : ""}
//             >
//               Video Courses
//             </li>
//           </ul>
//         </div>
//         <div className={styles.main}>
//           <input
//             type="text"
//             placeholder="Search here..."
//             value={query}
//             onChange={handleSearch}
//             className={styles.searchBar}
//           />
//           {loading && <p>Loading...</p>}
//           {!loading && query === "" && (
//             <div>
//               <h4>Trending</h4>
//               <ul>
//                 {trending.map((item, index) => (
//                   <li key={index}>{item}</li>
//                 ))}
//               </ul>
//               <h4>Recent Searches</h4>
//               <ul>
//                 {recentSearches.map((item, index) => (
//                   <li key={index}>{item}</li>
//                 ))}
//               </ul>
//             </div>
//           )}
//           {!loading && query && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.cardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <p>No results found</p>}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

// import React, { useState, useEffect, useContext } from "react";
// import styles from "./GlobalSearch.module.css";
// import Header from "../component/HeaderFiles/Header";
// import Footer from "../FooterMain/Footer";
// import { SearchContext } from "../Context/SearchContext";

// const GlobalSearch = () => {
//   const { query } = useContext(SearchContext);
//   const [results, setResults] = useState([]);
//   const [filter, setFilter] = useState("all"); // Filter by category
//   const [ratingFilter, setRatingFilter] = useState(null); // Filter by rating
//   const [editionFilter, setEditionFilter] = useState(null); // Filter by edition
//   const [sortBy, setSortBy] = useState("relevant"); // Sort By filter
//   const [recentSearches, setRecentSearches] = useState([]);
//   const [trending, setTrending] = useState([
//     "SSC Maths 6800 TCS MCQ chapter wise 4th edition book Video course",
//     "SSC Maths 6800 TCS MCQ Chapter Wise",
//     "SSC Maths 6800 TCS MCQ Chapter wise 6th edition english medium",
//   ]);
//   const [loading, setLoading] = useState(false);

//   // Data states
//   const [books, setBooks] = useState([]);
//   const [ebooks, setEbooks] = useState([]);
//   const [videoCourses, setVideoCourses] = useState([]);

//   useEffect(() => {
//     // Fetch data from APIs
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const [booksRes, ebooksRes, videoCoursesRes] = await Promise.all([
//           fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true").then((res) =>
//             res.json()
//           ),
//           fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true").then((res) =>
//             res.json()
//           ),
//           fetch("https://auth.ssccglpinnacle.com/api/active-courses").then((res) =>
//             res.json()
//           ),
//         ]);

//         setBooks(booksRes);
//         setEbooks(ebooksRes);
//         setVideoCourses(videoCoursesRes);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (query || ratingFilter || editionFilter || sortBy) {
//       const allData = [...books, ...ebooks, ...videoCourses];
//       let filteredResults = allData.filter((item) => {
//         const matchesQuery = query
//           ? item.title && item.title.toLowerCase().includes(query.toLowerCase())
//           : true;
//         const matchesFilter =
//           filter === "all" ||
//           (filter === "book" && books.includes(item)) ||
//           (filter === "ebook" && ebooks.includes(item)) ||
//           (filter === "video" && videoCourses.includes(item));

//         const matchesRating =
//           ratingFilter === null || (item.rating && item.rating >= ratingFilter);

//         const matchesEdition =
//           editionFilter === null || item.edition === `${editionFilter}`;

//         return matchesQuery && matchesFilter && matchesRating && matchesEdition;
//       });

//       // Sort results based on sortBy filter
//       if (sortBy === "reviewed") {
//         filteredResults.sort((a, b) => b.reviews - a.reviews);
//       } else if (sortBy === "rated") {
//         filteredResults.sort((a, b) => b.rating - a.rating);
//       } else if (sortBy === "newest") {
//         filteredResults.sort((a, b) => new Date(b.publishingDate) - new Date(a.publishingDate));
//       }

//       setResults(filteredResults);

//       // Log selected filters
//       console.log("Query:", query);
//       console.log("Category Filter:", filter);
//       console.log("Rating Filter:", ratingFilter);
//       console.log("Edition Filter:", editionFilter);
//       console.log("Sort By:", sortBy);

//       if (query && !recentSearches.includes(query)) {
//         setRecentSearches((prev) => [query, ...prev.slice(0, 4)]);
//       }
//     } else {
//       setResults([]);
//     }
//   }, [query, filter, ratingFilter, editionFilter, sortBy, books, ebooks, videoCourses]);

//   const handleFilterChange = (newFilter) => {
//     setFilter(newFilter);
//   };

//   const handleRatingFilterChange = (rating) => {
//     setRatingFilter(rating);
//   };

//   const handleEditionFilterChange = (edition) => {
//     setEditionFilter(edition);
//   };

//   const handleSortByChange = (sortOption) => {
//     setSortBy(sortOption);
//   };

//   const renderCard = (item) => {
//     if (item.BookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.globalSearchBookCard}>
//           <img src={item.image1} alt={item.title} className={styles.globalSearchCardImage} />
//           <h4 className={styles.globalSearchCardTitle}>{item.title}</h4>
//           <p className={styles.globalSearchCardAuthor}>{item.author}</p>
//           <p className={styles.globalSearchCardPrice}>
//             ₹{item.BookSellingPrice} (Save ₹{item.BookPrintingPrice - item.BookSellingPrice})
//           </p>
//           <div className={styles.globalSearchCardLinks}>
//             <a href={item.amazonLink} target="_blank" rel="noopener noreferrer">
//               Amazon
//             </a>
//             <a href={item.flipkartLink} target="_blank" rel="noopener noreferrer">
//               Flipkart
//             </a>
//           </div>
//         </div>
//       );
//     } else if (item.ebookSellingPrice) {
//       return (
//         <div key={item._id} className={styles.globalSearchEbookCard}>
//           <img src={item.image} alt={item.title} className={styles.globalSearchCardImage} />
//           <h4 className={styles.globalSearchCardTitle}>{item.title}</h4>
//           <p className={styles.globalSearchCardAuthor}>{item.author}</p>
//           <p className={styles.globalSearchCardPrice}>₹{item.price}</p>
//         </div>
//       );
//     } else if (item.courseTitle) {
//       return (
//         <div key={item._id} className={styles.globalSearchVideoCard}>
//           <img src={item.englishCoverImage} alt={item.courseTitle} className={styles.globalSearchCardImage} />
//           <h4 className={styles.globalSearchCardTitle}>{item.courseTitle}</h4>
//           <p className={styles.globalSearchCardInstructor}>Instructor: {item.instructorName}</p>
//           <p className={styles.globalSearchCardPrice}>
//             ₹{item.price} (MRP: ₹{item.mrp})
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <>
//       <Header />
//       <div className={styles.globalSearchContainer}>
//         <div className={styles.globalSearchSidebar}>
//           <h3>Filters</h3>
//           <ul>
//             <li
//               onClick={() => handleFilterChange("all")}
//               className={filter === "all" ? styles.active : ""}
//             >
//               All
//             </li>
//             <li
//               onClick={() => handleFilterChange("book")}
//               className={filter === "book" ? styles.active : ""}
//             >
//               Books
//             </li>
//             <li
//               onClick={() => handleFilterChange("ebook")}
//               className={filter === "ebook" ? styles.active : ""}
//             >
//               E-books
//             </li>
//             <li
//               onClick={() => handleFilterChange("video")}
//               className={filter === "video" ? styles.active : ""}
//             >
//               Video Courses
//             </li>
//           </ul>

//           <h3>Rating</h3>
//           <ul>
//             <li onClick={() => handleRatingFilterChange(4)}>Above 4</li>
//             <li onClick={() => handleRatingFilterChange(3)}>Above 3</li>
//           </ul>

//           <h3>Edition</h3>
//           <ul>
//             {[1, 2, 3, 4, 5, 6].map((edition) => (
//               <li key={edition} onClick={() => handleEditionFilterChange(edition)}>
//                 Edition {edition}
//               </li>
//             ))}
//           </ul>

//           <h3>Sort By</h3>
//           <ul>
//             <li onClick={() => handleSortByChange("relevant")}>Most Relevant</li>
//             <li onClick={() => handleSortByChange("reviewed")}>Most Reviewed</li>
//             <li onClick={() => handleSortByChange("rated")}>Highest Rated</li>
//             <li onClick={() => handleSortByChange("newest")}>Newest</li>
//           </ul>
//         </div>

//         <div className={styles.globalSearchMain}>
//           {loading && <p>Loading...</p>}
//           {!loading && (
//             <div>
//               <h4>Search Results</h4>
//               <div className={styles.globalSearchCardContainer}>
//                 {results.map((item) => renderCard(item))}
//                 {results.length === 0 && <p>No results found</p>}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default GlobalSearch;

import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { Box, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./EditEbookForm.css";
import ReviewForm from "../BlogProject/BlogContainer/ReviewForm"; // Adjust the import path based on your file structure

function EditBook() {
  const [loading, setLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [error, setError] = useState("");
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedBookimage, setSelectedBookimage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imagePreviews, setImagePreviews] = useState({
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
  });
  const [generating, setGenerating] = useState({
    seoTitle: false,
    seoKeywords: false,
    seoDescription: false,
    jsonLd: false,
  });
  const [seoLimits] = useState({
    title: 60,
    // keywords: 120,
    description: 160,
  });
  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    fetchBooks();
  }, []);

  async function fetchBooks() {
    setLoading(true);
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
      );
      if (!response.ok) {
        throw new Error(
          `Failed to fetch active book data. Status: ${response.status}`
        );
      }
      const activeBooksData = await response.json();
      setBooks(activeBooksData);
      // setSelectedBookimage(activeBooksData)
    } catch (error) {
      console.error("Error fetching active book data:", error);
      setError("Error fetching active book data.");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedBook) {
      setImagePreviews({
        image1: selectedBook.image1 || "",
        image2: selectedBook.image2 || "",
        image3: selectedBook.image3 || "",
        image4: selectedBook.image4 || "",
        image5: selectedBook.image5 || "",
      });
    }
  }, [selectedBook]);

  // const columns = useMemo(
  //   () => [
  //     {
  //       accessorKey: '_id',
  //       header: 'ID',
  //     },
  //     {
  //       accessorKey: 'sku',
  //       header: 'SKU',
  //     },
  //     {
  //       accessorKey: 'title',
  //       header: 'Title',
  //     },
  //     {
  //       accessorKey: 'medium',
  //       header: 'Medium',
  //     },
  //     {
  //       id: 'actions',
  //       header: 'Actions',
  //       Cell: ({ row }) => (
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           onClick={() => handleEditClick(row.original)}
  //         >
  //           Edit
  //         </Button>
  //       ),
  //     },
  //   ],
  //   [],
  // );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id", // Ensure 'id' is the first column
        header: "ID",
      },
      {
        accessorKey: "sku",
        header: "SKU",
      },
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "medium",
        header: "Medium",
      },
      {
        id: "actions",
        header: "Actions",
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(row.original)}
          >
            Edit
          </Button>
        ),
      },
    ],
    [] // Ensure this array is properly defined and not being affected by other state
  );

  const table = useMaterialReactTable({
    columns,
    data: books,
    enableRowActions: true,
    renderRowActionMenuItems: () => [],
  });

  const handleEditClick = (book) => {
    setSelectedBook(book);
    setSelectedBookimage(book);
    setImagePreviews({
      image1: book.image1 || "",
      image2: book.image2 || "",
      image3: book.image3 || "",
      image4: book.image4 || "",
      image5: book.image5 || "",
    });
    setModalIsOpen(true);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setSelectedBook({ ...selectedBook, [name]: files[0] });

    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreviews((prevState) => ({
          ...prevState,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    setSelectedBook((prevBook) => ({ ...prevBook, image1: file }));
  };

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    setSelectedBook((prevBook) => ({ ...prevBook, image2: file }));
  };

  const handleImageChange3 = (e) => {
    const file = e.target.files[0];
    setSelectedBook((prevBook) => ({ ...prevBook, image3: file }));
  };

  const handleImageChange4 = (e) => {
    const file = e.target.files[0];
    setSelectedBook((prevBook) => ({ ...prevBook, image4: file }));
  };

  const handleImageChange5 = (e) => {
    const file = e.target.files[0];
    setSelectedBook((prevBook) => ({ ...prevBook, image5: file }));
  };

  const handlePdfChange1 = (e) => {
    const file = e.target.files[0];
    setSelectedBook((prevBook) => ({ ...prevBook, pdf1: file }));
  };

  const handlePdfChange2 = (e) => {
    const file = e.target.files[0];
    setSelectedBook((prevBook) => ({ ...prevBook, pdf2: file }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedBook({ ...selectedBook, [name]: value });
  };

  const handleContentChange = (value) => {
    setSelectedBook({ ...selectedBook, whytakecourse: value });
  };
  const handleGenerateSeoField = async (field) => {
    const { title, edition, medium, subjects, examCategory, examName } =
      selectedBook;

    if (
      !title ||
      !edition ||
      !medium ||
      !subjects ||
      !examCategory ||
      !examName
    ) {
      Swal.fire(
        "Error",
        "Please fill all required book details before generating metadata.",
        "error"
      );
      return;
    }

    setGenerating((prev) => ({ ...prev, [field]: true }));
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/seo/generate",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title,
            edition,
            medium,
            subjects,
            examCategory,
            examName,
            field,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok)
        throw new Error(data.error || "Failed to generate metadata.");

      setSelectedBook((prev) => ({ ...prev, [field]: data[field] }));
      Swal.fire("Success", `${field} generated successfully.`, "success");
    } catch (error) {
      Swal.fire(
        "Error",
        `Failed to generate ${field}. Please try again.`,
        "error"
      );
    } finally {
      setGenerating((prev) => ({ ...prev, [field]: false }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const formData = new FormData();
      for (const key in selectedBook) {
        if (selectedBook[key] && key !== "_id") {
          formData.append(key, selectedBook[key]);
        }
      }
      console.log("sending files", formData);

      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/books/${selectedBook._id}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to update book. Status: ${response.status}`);
      }

      const updatedBook = await response.json();
      console.log("Book updated:", updatedBook);

      closeModal();
      fetchBooks();
    } catch (error) {
      console.error("Error updating book:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     setLoading(true);

  //     const formData = new FormData();
  //     for (const key in selectedBook) {
  //       if (selectedBook[key] !== undefined && key !== '_id') {
  //         formData.append(key, selectedBook[key]);
  //       }
  //     }

  //     // Debugging: Log the formData content
  //     for (let pair of formData.entries()) {
  //       console.log(pair[0] + ': ' + pair[1]);
  //     }

  //     const response = await fetch(`https://auth.ssccglpinnacle.com/api/books/${selectedBook._id}`, {
  //       method: 'PUT',
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Failed to update book. Status: ${response.status}`);
  //     }

  //     const updatedBook = await response.json();
  //     console.log('Book updated:', updatedBook);

  //     closeModal();
  //     fetchBooks();
  //   } catch (error) {
  //     console.error('Error updating book:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <MaterialReactTable
        table={table}
        columns={columns}
        data={books}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
        }}
      />

      {error && <p style={{ color: "red" }}>{error}</p>}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Book Modal"
        className="modalsforedit"
        overlayClassName="modal-overlay"
      >
        <Box className="custom-modal-box">
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>

          {selectedBook && (
            <form onSubmit={handleSubmit} className="edit-book-form">
              <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                Edit Book
              </Typography>
              <div className="input-container">
                <label>SKU:</label>
                <input
                  type="text"
                  name="sku"
                  value={selectedBook.sku || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Title:</label>
                <input
                  type="text"
                  name="title"
                  value={selectedBook.title || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Edition:</label>
                <input
                  type="text"
                  name="edition"
                  value={selectedBook.edition || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Medium:</label>
                <input
                  type="text"
                  name="medium"
                  value={selectedBook.medium || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Exam Category:</label>
                <input
                  type="text"
                  name="examCategory"
                  value={selectedBook.examCategory || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Exam Name:</label>
                <input
                  type="text"
                  name="examName"
                  value={selectedBook.examName || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Subject:</label>
                <input
                  type="text"
                  name="subjects"
                  value={selectedBook.subjects || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Book Printing Price:</label>
                <input
                  type="number"
                  name="BookPrintingPrice"
                  value={selectedBook.BookPrintingPrice || 0}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Book Selling Price:</label>
                <input
                  type="number"
                  name="BookSellingPrice"
                  value={selectedBook.BookSellingPrice || 0}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>ISBN:</label>
                <input
                  type="text"
                  name="ISBN"
                  value={selectedBook.ISBN || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>

              <div className="input-container">
                <label>Pages:</label>
                <input
                  type="number"
                  name="pages"
                  value={selectedBook.pages || 0}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>

              <div className="input-container">
                <label>Amazon Link:</label>
                <input
                  type="text"
                  name="amazonLink"
                  value={selectedBook.amazonLink || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Flipkart Link:</label>
                <input
                  type="text"
                  name="flipkartLink"
                  value={selectedBook.flipkartLink || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
                     <div className="input-container">
                <label>Snapdeal Link:</label>
                <input
                  type="text"
                  name="snapdealLink"
                  value={selectedBook.snapdealLink || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Publishing Date:</label>
                <input
                  type="text"
                  name="publishingDate"
                  value={selectedBook.publishingDate || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Search:</label>
                <input
                  type="text"
                  name="search"
                  value={selectedBook.search || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Details:</label>
                <textarea
                  name="desc"
                  value={selectedBook.desc || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Author:</label>
                <input
                  type="text"
                  name="author"
                  value={selectedBook.author || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Para 1:</label>
                <input
                  type="text"
                  name="para1"
                  value={selectedBook.para1 || ""}
                  onChange={handleInputChange}
                  className="custom-input-para1"
                />
              </div>
              <div className="input-container">
                <label>Para 2:</label>
                <input
                  type="text"
                  name="para2"
                  value={selectedBook.para2 || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Para 3:</label>
                <input
                  type="text"
                  name="para3"
                  value={selectedBook.para3 || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Para 4:</label>
                <input
                  type="text"
                  name="para4"
                  value={selectedBook.para4 || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="form-group">
                <label htmlFor="whytakecourse" className="label">
                  Why should you take this book?
                </label>
                <div className="my-editor-for-book">
                  <ReviewForm
                    content={selectedBook.whytakecourse}
                    onContentChange={handleContentChange}
                    onImageUpload={(file) =>
                      handleFileChange({
                        target: { name: "image", files: [file] },
                      })
                    }
                    imagePreviews={imagePreviews}
                  />
                </div>
              </div>
              <div className="input-container">
                <label>SEO Title:</label>
                <textarea
                  name="seoTitle"
                  value={selectedBook.seoTitle || ""}
                  onChange={handleInputChange}
                  className={`book-form-textarea ${
                    (selectedBook.seoTitle?.length || 0) > seoLimits.title
                      ? "error"
                      : ""
                  }`}
                  placeholder="Enter or Generate SEO Title"
                />
                <span
                  className={`char-count ${
                    (selectedBook.seoTitle?.length || 0) > seoLimits.title
                      ? "error"
                      : ""
                  }`}
                >
                  {selectedBook.seoTitle?.length || 0} / {seoLimits.title}{" "}
                  characters
                </span>
                <Button
                  variant="outlined"
                  onClick={() => handleGenerateSeoField("seoTitle")}
                  disabled={generating.seoTitle}
                  className="book-form-generate-button"
                >
                  {generating.seoTitle ? "Generating..." : "Generate Title"}
                </Button>
              </div>

              <div className="input-container">
                <label>SEO Keywords:</label>
                <textarea
                  name="seoKeywords"
                  value={selectedBook.seoKeywords || ""}
                  onChange={handleInputChange}
                  className={`book-form-textarea ${
                    (selectedBook.seoKeywords?.length || 0) > seoLimits.keywords
                      ? "error"
                      : ""
                  }`}
                  placeholder="Enter or Generate SEO Keywords"
                />
                {/* <span
                  className={`char-count ${
                    (selectedBook.seoKeywords?.length || 0) > seoLimits.keywords
                      ? "error"
                      : ""
                  }`}
                >
                  {selectedBook.seoKeywords?.length || 0} / {seoLimits.keywords}{" "}
                  characters
                </span> */}
                <Button
                  variant="outlined"
                  onClick={() => handleGenerateSeoField("seoKeywords")}
                  disabled={generating.seoKeywords}
                  className="book-form-generate-button"
                >
                  {generating.seoKeywords
                    ? "Generating..."
                    : "Generate keywords"}
                </Button>
              </div>

              <div className="input-container">
                <label>SEO Description:</label>
                <textarea
                  name="seoDescription"
                  value={selectedBook.seoDescription || ""}
                  onChange={handleInputChange}
                  className={`book-form-textarea ${
                    (selectedBook.seoDescription?.length || 0) >
                    seoLimits.description
                      ? "error"
                      : ""
                  }`}
                  placeholder="Enter or Generate SEO Description"
                />
                <span
                  className={`char-count ${
                    (selectedBook.seoDescription?.length || 0) >
                    seoLimits.description
                      ? "error"
                      : ""
                  }`}
                >
                  {selectedBook.seoDescription?.length || 0} /{" "}
                  {seoLimits.description} characters
                </span>
                <Button
                  variant="outlined"
                  onClick={() => handleGenerateSeoField("seoDescription")}
                  disabled={generating.seoDescription}
                  className="book-form-generate-button"
                >
                  {generating.seoDescription
                    ? "Generating..."
                    : "Generate Description"}
                </Button>
              </div>

              <div className="input-container">
                <label>Permalink:</label>
                <input
                  type="text"
                  name="permalink"
                  value={selectedBook.permalink || ""}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>

              <label className="info-label-heading">PDF 1:</label>
              <div className="update-pdf-info">
                {selectedBookimage?.pdf1 && (
                  <div className="pdf-section-book-showing">
                    <p className="info-label-pdf">Current PDF 1</p>
                    <a
                      href={selectedBookimage.pdf1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedBookimage.pdf1.split("/").pop()}
                    </a>
                  </div>
                )}
                <input
                  type="file"
                  name="pdf1"
                  onChange={handlePdfChange1}
                  className="custom-input-pdf"
                />
                {selectedBook?.pdf1 instanceof File && (
                  <div className="pdf-section-book-showing">
                    <p className="info-label-pdf">Updated PDF 1</p>
                    <span>{selectedBook.pdf1.name}</span>
                  </div>
                )}
              </div>

              <label className="info-label-heading">PDF 2:</label>
              <div className="update-pdf-info">
                {selectedBookimage?.pdf2 && (
                  <div className="pdf-section-book-showing">
                    <p className="info-label-pdf">Current PDF 2</p>
                    <a
                      href={selectedBookimage.pdf2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedBookimage.pdf2.split("/").pop()}
                    </a>
                  </div>
                )}
                <input
                  type="file"
                  name="pdf2"
                  onChange={handlePdfChange2}
                  className="custom-input-pdf"
                />
                {selectedBook?.pdf2 instanceof File && (
                  <div className="pdf-section">
                    <p className="info-label-pdf">Updated PDF 2</p>
                    <span>{selectedBook.pdf2.name}</span>
                  </div>
                )}
              </div>

              <label className="info-label-heading">Image 1:</label>
              <div className="update-image-info">
                <div className="current-image">
                  <p className="info-label-image">Current Image 1</p>
                  {selectedBookimage.image1 && (
                    <img
                      src={selectedBookimage.image1}
                      alt="Previous Image 1"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  name="image1"
                  onChange={handleImageChange1}
                  className="custom-input-image"
                />
                {selectedBook?.image1 instanceof File && (
                  <div className="image-section">
                    <p className="info-label-image">Updated Image 1</p>
                    <img
                      src={URL.createObjectURL(selectedBook.image1)}
                      alt="New Image 1"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>
              <label className="info-label-heading">Image 2:</label>
              <div className="update-image-info">
                <div className="current-image">
                  <p className="info-label-image">Current Image 2</p>
                  {selectedBookimage.image2 && (
                    <img
                      src={selectedBookimage.image2}
                      alt="Previous Image 2"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  name="image2"
                  onChange={handleImageChange2}
                  className="custom-input-image"
                />
                {selectedBook?.image2 instanceof File && (
                  <div className="image-section">
                    <p className="info-label-image">Updated Image 2</p>
                    <img
                      src={URL.createObjectURL(selectedBook.image2)}
                      alt="New Image 2"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>
              <label className="info-label-heading">Image 3:</label>
              <div className="update-image-info">
                <div className="current-image">
                  <p className="info-label-image">Current Image 3</p>
                  {selectedBookimage.image3 && (
                    <img
                      src={selectedBookimage.image3}
                      alt="Previous Image 3"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  name="image3"
                  onChange={handleImageChange3}
                  className="custom-input-image"
                />
                {selectedBook?.image3 instanceof File && (
                  <div className="image-section">
                    <p className="info-label-image">Updated Image 3</p>
                    <img
                      src={URL.createObjectURL(selectedBook.image3)}
                      alt="New Image 3"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>

              <label className="info-label-heading">Image 4:</label>
              <div className="update-image-info">
                <div className="current-image">
                  <p className="info-label-image">Current Image 4</p>
                  {selectedBookimage.image4 && (
                    <img
                      src={selectedBookimage.image4}
                      alt="Previous Image 4"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  name="image4"
                  onChange={handleImageChange4}
                  className="custom-input-image"
                />
                {selectedBook?.image4 instanceof File && (
                  <div className="image-section">
                    <p className="info-label-image">Updated Image 4</p>
                    <img
                      src={URL.createObjectURL(selectedBook.image4)}
                      alt="New Image 4"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>

              <label className="info-label-heading">Image 5:</label>
              <div className="update-image-info">
                <div className="current-image">
                  <p className="info-label-image">Current Image 5</p>
                  {selectedBookimage.image5 && (
                    <img
                      src={selectedBookimage.image5}
                      alt="Previous Image 5"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  name="image5"
                  onChange={handleImageChange5}
                  className="custom-input-image"
                />
                {selectedBook?.image5 instanceof File && (
                  <div className="image-section">
                    <p className="info-label-image">Updated Image 5 </p>
                    <img
                      src={URL.createObjectURL(selectedBook.image5)}
                      alt="New Image 5"
                      style={{ maxHeight: "164px", marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>

              <div className="modal-footer">
                <Button type="submit" variant="contained" color="primary">
                  Update Book
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default EditBook;

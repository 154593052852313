// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../Context/AuthContext";
// import MyLearning from "./components/MyLearningPage/MyLearning";
// import MainContent from "./components/BodyContent/Main Content/MainContent";
// import Header from "../component/HomePage01/Header/Header";
// import Footer from "../component/Footer/Footer";
// import styles from "./MyLearningMain.module.css";
// import LearningTool from "./components/MyLearningPage/LearningTools";
// import CourseWishlist from "./components/MyLearningPage/CourseWishlist";
// import MyListContent from "./components/MyLearningPage/MyList";
// import ArchivedList from "./components/MyLearningPage/ArchievedList";

// function MyLearningMain() {
//   const { isLoggedIn, user } = useAuth();
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(true);
//   const [activeTab, setActiveTab] = useState("all-courses");

//   useEffect(() => {
//     if (!isLoggedIn) {
//       // Redirect to the home page if not logged in
//       navigate("/");
//     } else if (user) {
//       setIsLoading(false);
//     }
//   }, [isLoggedIn, user, navigate]);

//   const renderContent = () => {
//     switch (activeTab) {
//       case "all-courses":
//         return <MainContent />;
//       case "my-list":
//         return <MyListContent userId={user._id} />;
//       case "wishlist":
//         return <CourseWishlist userId={user._id} />;
//       case "archived":
//         return <ArchivedList userId={user._id} />;
//       case "learning-tools":
//         return <LearningTool userId={user._id} />;
//       default:
//         return <div>Select an option from the menu</div>;
//     }
//   };

//   if (isLoading) {
//     return <div className={styles.loaderContainer}>Loading...</div>;
//   }

//   return (
//     <div className="main-body-container">
//       <div className={styles.main_container}>
//         <Header />
//         <MyLearning setActiveTab={setActiveTab} activeTab={activeTab} />
//         <div className={styles.main_content}>{renderContent()}</div>
//         <Footer />
//       </div>
//     </div>
//   );
// }

// export default MyLearningMain;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import MyLearning from "./components/MyLearningPage/MyLearning";
import MainContent from "./components/BodyContent/Main Content/MainContent";
import Header from "../component/HomePage01/Header/Header";
import Footer from "../component/Footer/Footer";
import styles from "./MyLearningMain.module.css";
import LearningTool from "./components/MyLearningPage/LearningTools";
import CourseWishlist from "./components/MyLearningPage/CourseWishlist";
import MyListContent from "./components/MyLearningPage/MyList";
import ArchivedList from "./components/MyLearningPage/ArchievedList";

function MyLearningMain() {
  const { isLoggedIn, user, isAuthLoading } = useAuth(); // Ensure we use isAuthLoading
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("all-courses");

  useEffect(() => {
    // Wait until authentication state is fully resolved
    if (!isAuthLoading && !isLoggedIn) {
      navigate("/"); // Redirect to the home page if not logged in
    }
  }, [isAuthLoading, isLoggedIn, navigate]);

  if (isAuthLoading) {
    // Show a loader until authentication is resolved
    return <div className={styles.loaderContainer}>Loading...</div>;
  }

  const renderContent = () => {
    switch (activeTab) {
      case "all-courses":
        return <MainContent />;
      case "my-list":
        return <MyListContent userId={user?._id} />;
      case "wishlist":
        return <CourseWishlist userId={user?._id} />;
      case "archived":
        return <ArchivedList userId={user?._id} />;
      case "learning-tools":
        return <LearningTool userId={user?._id} />;
      default:
        return <div>Select an option from the menu</div>;
    }
  };

  return (
    <div className="main-body-container">
      <div className={styles.main_container}>
        <Header />
        <MyLearning setActiveTab={setActiveTab} activeTab={activeTab} />
        <div className={styles.main_content}>{renderContent()}</div>
        <Footer />
      </div>
    </div>
  );
}

export default MyLearningMain;

import React, { useState, useEffect } from 'react';
import "./Wishlist.css";
import { BsCart2, BsArrowRight } from "react-icons/bs";
import { AiFillStar, AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useAuth } from "../../Context/AuthContext";
import Rateab from "../../component/Star/Rateab";
import Cartheader from "../../component/CartHeader/Cartheader";
import Swal from "sweetalert2";
import Offer from "./Offer";
import HeaderBar from "../../component/HeaderBar"


function Wishlist() {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const { user, isLoggedIn } = useAuth();
  const [StarData, setStarData] = useState([]);
  const navigate = useNavigate();
  const [cookies] = useCookies(['token', 'email_id']);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  
  useEffect(() => {
    const checkAccess = async () => {
      if (cookies.token) {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/checkAccess', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${cookies.token}`
          }
        });

        if (response.ok) {
          const { access } = await response.json();
          if (access !== "access") {
            navigate('/ebooks');
            return false;
          }
          return true;
        } else {
          console.error("Access check error:", await response.json());
          navigate('/ebooks');
          return false;
        }
      } else {
        console.error("Token not found in cookies");
        navigate('/ebooks');
        return false;
      }
    };

    const fetchWishlist = async () => {
      const hasAccess = await checkAccess();
      if (hasAccess) {
        try {
          if (!user || !user._id) {
            console.error('User is null or _id is undefined.');
            return;
          }

          const wishlistResponse = await fetch(`https://auth.ssccglpinnacle.com/api/get-wishlist-ebook/${user.email_id}`, {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${cookies.token}`
            },
          });
          const wishlistData = await wishlistResponse.json();
          const wishlistIds = wishlistData.wishlist;

          const ebookResponse = await fetch('https://auth.ssccglpinnacle.com/api/ebooksforactive', {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${cookies.token}`
            },
          });
          const ebookData = await ebookResponse.json();
          const wishlistItemsData = ebookData.filter(ebook => wishlistIds.includes(ebook._id));

          setWishlistItems(wishlistItemsData);
        } catch (error) {
          console.error('Error fetching wishlist:', error);
        }
      }
    };

    const fetchCartItems = async () => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/cart-ebook/${user.email_id}`, {
          headers: {
            "Authorization": `Bearer ${cookies.token}`
          }
        });
        if (!response.ok) {
          throw new Error("Error fetching cart data");
        }
        const data = await response.json();
        setCartItems(data);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };

    fetchWishlist();
    fetchCartItems();
  }, [user, cookies.token, navigate]);

  // console.log(cartItems)

  const getRatingForCard = (cardId) => {
    const card = wishlistItems.find((card) => card._id === cardId);

    if (!card || !card.rating || card.rating.length === 0) {
      return 0;
    }

    return calculateAverageRating(card.rating);
  };

  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce((sum, rating) => sum + rating.starRate, 0);
    return totalRating / ratings.length;
  };

  const handleMouseOver = (_id) => {
    setHoveredId(_id);
  };

  const handleMouseOut = () => {
    setHoveredId(null);
  };

  const info = (cardId) => {
    navigate(`/intro/${cardId}`);
  };

  const handleAddToCart = async (ebookId) => {
    if (!isLoggedIn || !user || !user._id) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to add items to your cart.",
      });
      return;
    }

    setIsAddingToCart(true);

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
        body: JSON.stringify({ ebookId, fullName: user.email_id }),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        navigate("/ebooks/cart");
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          navigate("/ebooks/cart");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to add item to cart. Error: ${errorMessage.error}`,
          });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleRemoveFromWishlist = async (cardId) => {
    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/remove-from-wishlist-ebook/${user.email_id}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
        body: JSON.stringify({ cardId }),
      });

      if (!response.ok) {
        console.error('Failed to remove from wishlist. Server response:', await response.text());
      } else {
        setWishlistItems(wishlistItems.filter(item => item._id !== cardId));
      }
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  function truncateText(text, maxWords) {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  }

  return (
    <>
      <div className="main-body-container">
        <Offer />
        {/* <Cartheader /> */}
        <HeaderBar />
        <div className="my-learning-row3-wishlist">
          <div className="inprogress-header-new-wishlist">
            <h2>Wishlist</h2>
          </div>

          <div className="card-for-progress-info-wishlist">
            {wishlistItems.length === 0 ? (
              <div className="empty-wishlist-message">
                <h3>Your wishlist is currently empty.</h3>
                <p>Explore our catalog and add items to your wishlist.</p>
                <Link to="/ebooks" className="continue-shopping-button">Continue Shopping</Link>
              </div>
            ) : (
              wishlistItems.map((item) => {
                const isInCart = cartItems.some(cartItem => cartItem.product._id === item._id);
                return (
                  <div className="custom-card-wishlist digital-content-card-progress-wishlist" key={item._id}>
                    <AiOutlineClose className="remove-wishlist-icon" onClick={() => handleRemoveFromWishlist(item._id)} />
                    <div className="class-for-img-progress-new-wishlist">
                      <div className="classes-for-pro-img-new-wishlist">
                        <img className="card-image-progress-new-wishlist" src={item.image} alt={item.title} />
                      </div>
                    </div>
                    <div className="card-content-progress-wishlist">
                      <div className="title-progress-report-wishlist">
                        <h6>{item.title}</h6>
                      </div>
                      <div className="lesson-completed-wishlist">
                        <div className="ebook-information-progress-wishlist">
                          <div
                            className="Rating"
                            onMouseOver={() => handleMouseOver(item._id)}
                            onMouseOut={handleMouseOut}
                          >
                            <div className="Star-Rating-wishlist">{getRatingForCard(item._id).toFixed(1)}
                              {Array.from({ length: 1 }).map((_, index) => (
                                <AiFillStar className="Star-Rating-1" />
                              ))}
                            </div>
                            <div className="Total-Rating">({item.rating.length})</div>
                          </div>
                          <div className="StarRating-wishlist">
                            {hoveredId === item._id && <Rateab cardData={item} />}
                          </div>
                        </div>

                        <div className="price-wishlist">
                          <p>₹</p><p className="printing-price">{item.subscriptionPrices[0].printingPrice}</p>
                          <p className="selling-price">{item.subscriptionPrices[0].sellingPrice}</p>
                        </div>
                      </div>
                      <div className="read-more-process-wishlist">
                        {isInCart ? (
                          <button className="buy-now-button-wishlist">Already in the cart</button>
                        ) : (
                          <button className="buy-now-button-wishlist" onClick={() => handleAddToCart(item._id)}>Buy Now</button>
                        )}
                        <p className="read-more-para-wishlist" onClick={() => info(item._id)}>Read more</p>
                        <BsArrowRight className="arrow-process-wishlist" size={18} color="black" />
                      </div>
                      <div className="wishlist-actions">
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Wishlist;

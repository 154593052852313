import axios from "axios";

const captureLink = async (linkData) => {
  try {
    const fullUrl = `${linkData.url}`;
    const linkDataWithFullUrl = { ...linkData, url: fullUrl };

    const response = await axios.post(
      "https://auth.ssccglpinnacle.com/api/product/capture-url",
      linkDataWithFullUrl,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.status === 201) {
      console.log("Link saved successfully:", response.data);
      return response.data.data._id; // Return the _id field from the response
    } else if (
      response.status === 200 &&
      response.data.message === "Link already exists"
    ) {
      console.log("Link already exists:", response.data);
      return response.data.data._id; // Return the existing _id field
    } else {
      console.error("Unexpected response:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Error capturing link:", error);
    return null;
  }
};

export default captureLink;

import React, { lazy, Suspense } from "react";

import { useEffect } from "react";

import Home from "./component/Home";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";
import HomePageBody from "./Pages/WebsiteBody/Body";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ViewVideoPDF from "./YOUTUBE VIDEO PLAYER/ViewVideoPdf.js";
import Cart from "./component/Page02/Cart.js";

//Ebook
import DigitalBody from "./Ebook/Pages/DigitalContentBody/Body";
import { ThemeProvider } from "./Context/ThemeContext";
import Page1 from "./Ebook/DigitalContentPages/Ebook-Page/Page1";
import DigitalMainPage from "./Ebook/DigitalContentPages/DigitalMainPage";
import EbookMyOrder from "./Ebook/DigitalContentPages/Quiz/EbookMyOrder.js";
import EbookNewForm from "./Ebook/Forms/EbookNewForm.js";

// import Home from "./DigitalContentPages/Ebook-Page/Home";
import Quiz1 from "./Ebook/DigitalContentPages/Quiz/Quiz1";
import HomePage from "./Ebook/DigitalContentPages/Quiz/HomePage";
import Exercise from "./Ebook/DigitalContentPages/Exercise/Exercise";
import { DataTransferProvider } from "./DataTransferContext";
import { AuthProvider } from "./Context/AuthContext";
import RegisterAdminEbook from "./Ebook/login/Register";
import LoginAdminEbook from "./Ebook/login/Login";
import ProtectedContent from "./Context/ProtectedContent";
import { ProductProvider } from "./Context/ProductContext";
import BuyNow from "./Ebook/BuyNow/BuyNow";
import { CartProvider } from "./Context/CartContext";
import Intro from "./Ebook/Info/Intro";
import Intro2 from "./Ebook/Info/Intro2.js";
import Mylearn from "./Ebook/User/Mylearn";
import Progress from "./Ebook/User/ProgressReport/Progress";
import Profile1 from "./Ebook/UserProfile/Profile1";
import AllEbook from "./Ebook/Pages/DigitalContentBody/AllEbooks/AllEbook";
import PdfShowing from "./Ebook/Ab";
import Showall from "./Ebook/User/Showall";
import Wishlist from "./Ebook/BuyNow/Wishlist";

//book
import BookNewForm from "./FormsBook/BookNewFormBook.js";
// import CartPage from "./component/HomePage01/Header/CartPage";
import HomePage01 from "./component/HomePage01/HomePage01";
import Page02 from "./component/Page02/Page02";
import Share from "./component/Page02/ShareComponent/Share";
import Language from "./component/HomePage01/Language/Languages";
import CartPage from "./component/HomePage01/Header/CartPage";
import WishlistPage from "./component/HomePage01/Header/WishlistPage";
import PageNotFound from "./PageNotFound/PageNotFound";
import GiftACourse from "./component/Page02/GiftACourse/GiftACourse";
import Checkout from "./component/Page02/GiftACourse/Checkout";
import Profile from "./Profile/Profile";
import Tools from "./Profile/Tools";
import Resources from "./Profile/Resources";
import Commmunication from "./Profile/Commmunication";
import PersonalPlane from "./component/Page02/PersonalPlane";
import PurchaseHistory from "./Purchase history/PurchaseHistory";
import PaymentSteps from "./HelpDeskBP/PaymentSteps";
import Notification from "./Notifications/Notification";
import CourseRating from "./CourseRating.js/CourseRating";
import ReportAbousenew from "./ReportAbouse/ReportAbouse";
import MyOrders from "./VIDEOPLAYER COMPONENTS/MyOrders.js";
import MyLearningMain from "./VIDEOPLAYER COMPONENTS/MyLearningMain";
import VideoPlayerPageLayout from "./layouts/VideoPlayerPageLayout";
import PinnacleProfile from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/PublicProfile";
import EditPhoto from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/EditPhoto";
import AccountSecurity from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/AccountSecurity";
import Subscriptions from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Subscriptions";
import PaymentMethod from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/PaymentMethod";
import Privacy from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Privacy";
import AccountNotification from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/AccountNotification";
import AccountClose from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/AccountClose";
import AccountMain from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/AccountMain";
import GiftACourseD from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/VideoPageHeaderComponents/GiftCourse";
import Messages from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Messages";
import PinnacleHelp from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/PinnacleHelp";
import SignUpHelp from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Messages/SignUpHelp";
import TroubleshootingHelp from "./VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Messages/TroubleshootingHelp";
import { VideoProvider } from "./videoContext/VideoContext";
import ChapterAccordion from "./component/Page02/ChapterAccordion";
import PDFViewer from "./layouts/PdfViewer";
import PaymentSuccessHandler from "./component/Page02/PaymentSuccessHandler";
import StudentList from "./VideoCourse_AdminPanel/studentFrom/StudentUpdateForm.js";
import SSCCategory from "./VcCategory/SSCCategory";
import RailwayCategory from "./VcCategory/RailwayCategory";
import UpPoliceCategory from "./VcCategory/UpPoliceCategory";
import CtetCategory from "./VcCategory/CtetCategory";
import DelhiPoliceCategory from "./VcCategory/DelhiPoliceCategory.js";
import AddCourseChapters from "./VideoCourse_AdminPanel/AddCourseChapters";
import YTLearning from "./YOUTUBE VIDEO PLAYER/YT_Layouts/YT_Learning";
import StudentUpdateForm from "./VideoCourse_AdminPanel/studentFrom/StudentUpdateForm.js";
import VideoSearchResults from "./component/HomePage01/Header/SearchResults.js";
import AddCourseForm from "./VideoCourse_AdminPanel/AddCourseForm.js";
import ManageMetaDataForm from "./VideoCourse_AdminPanel/ManageMetaDataForm.js";
import AddMetaDataForm from "./VideoCourse_AdminPanel/AddMetaDataForm.js";
import AddOgDataForm from "./VideoCourse_AdminPanel/AddOGDataForm.js";
import ManageOgDataForm from "./VideoCourse_AdminPanel/ManageOgData";

//Book

import StudentDetail from "./Ebook/Forms/Student/StudentDetail.js";
import BodyBook from "./PagesBook/DigitalContentBody/BodyBook.js";
import CartPageBook from "./BuyNowBook/AddToCartBook.js";
import WishlistBook from "./BuyNowBook/WishlistBook.js";
import IntroBook from "./InfoBook/IntroBook.js";
import AllBook from "./PagesBook/DigitalContentBody/AllEbooks/AllBook.js";
import PurchaseBook from "./PagesBook/PurchaseBook.js";

//Blog

import AdminEbook from "./Ebook/AdminPanel/AdminEbook.js";
import BlogForm from "./Ebook/Forms/BlogForm";
import NextPage from "./BlogProject/Blog/NextPage/NextPage";
import Blogmain from "./BlogProject/Blog/Blogmain";
import BlogList1 from "./BlogProject/Blog/BlogList/BlogList1";
import SearchResults from "./BlogProject/Blog/BlogList/SearchResults";
import VerifyAddress from "./PagesBook/DigitalContentBody/VerifyAddress";
import PaymentSuccess from "./PagesBook/DigitalContentBody/PaymentSuccess.js";
// import { initializeWebSocket } from "./WebSocket.js";
import CartPageEbook from "./Ebook/BuyNow/AddToCart";

//Typing
// import AddTypingParagraph from "./AddTypingParagraph";
import TypingMyOrders from "./Typing/TypingMyOrders";
import Typing1 from "./Typing/Typing1";
import TypingModule from "./Typing/TypingModule";
import TypingPerformance from "./Typing/TypingPerformance";
import TypingPerformanceTest from "./Typing/TypingPerformanceTest";
import TypingParagraph from "./Typing/TypingParagraph";
import TypingExamSelection from "./Typing/TypingExamSelection";

//categories for Video Courses
import AnnouncementList from "./VideoCourse_AdminPanel/AnnouncementList.js";
import VideoTimer from "./component/HomePage01/Header/Timer";
import VisitorsTable from "./VideoCourse_AdminPanel/VisitorsTable.js";
import ViewAllCourses from "./component/HomePage01/Body/ViewAllCourses.js";
import UpdateChapterOrder from "./VideoCourse_AdminPanel/UpdateChapterOrder.js";
import AddExamForm from "./VideoCourse_AdminPanel/AddExamForm.js";
import ListExams from "./VideoCourse_AdminPanel/ListExam.js";
import VCCategory from "./VcCategory/VCCategory.js";
import ShowAllEbooks from "./Ebook/User/ShowAllEbooks.js";

import NotesContainer from "./Ebook/DigitalContentPages/Ebook-Page/Notes/NotesContainer";
import InstructorDetails from "./YOUTUBE VIDEO PLAYER/YT_Navigation Components/InstructorDetails.js";
import VideoCourseAccess from "./VideoCourse_AdminPanel/VideoCourseAccess.js";

//Footer Pages
import AboutUs from "./FooterMain/AboutUs.js";
import TermAndCondition from "./FooterMain/TermsConditions";
import PrivacyPolicy from "./FooterMain/PrivacyPolicy.js";
import RefundPolicy from "./FooterMain/RefundPolicy.js";
import ContactUs from "./FooterMain/ContactUs";
//ChatBot
import Chatbot from "./chatBot/Chatbot.js";
//Payment Status
import BookTransactionModal from "./TransactionStatus/BookTransactionModal.js";
import TypingTransactionModal from "./TransactionStatus/TypingTransactionModal.js";
import EbookTransactionModal from "./TransactionStatus/EbookTransactionModal.js";
import VideosTransactionModal from "./TransactionStatus/VideosTransactionModal.js";
import GlobalSearch from "./GlobalSearch/GlobalSearch.js";
import { SearchProvider } from "./Context/SearchContext.js";
import BookMyOrder from "./PagesBook/DigitalContentBody/BookMyOrder.js";

import SitemapPage from "./component/Footer/sitemap/Sitemap.js";
import CourseDetails from "./component/Page02/CourseDetails.js";
import Description from "./component/Page02/Description.js";
import AddSubjectForm from "./VideoCourse_AdminPanel/AddSubjectForm.js";
import ManageCourseList from "./VideoCourse_AdminPanel/ManageCourseList.js";
import ManagePDFs from "./VideoCourse_AdminPanel/ManagePdfListing.js";
import TrackPageView from "./TrackPageView.js";
import EditCourse from "./VideoCourse_AdminPanel/EditCourse.js";
import CourseStatusChecker from "./VideoCourse_AdminPanel/CourseStatusChecker.js";
// import SaveLinks from "./component/SaveLinks.js";
// import DisplayLinks from "./component/DisplayLinks.js";
import CoinsPage from "./Coinpage/coinpage.js";
import VideoBanner from "./VideoCourse_AdminPanel/VideoBanner.js";
import HeaderBar from "./component/HeaderBar.js";

import EditBlogForm from "./Ebook/Forms/EditBlogForm.js";

function App() {
  // useEffect(() => {
  //   const socket = initializeWebSocket();

  //   // Clean up the WebSocket connection when the component unmounts
  //   return () => {
  //     if (socket.readyState === WebSocket.OPEN) {
  //       socket.close();
  //     }
  //   };
  // }, []);

  return (
    <GoogleOAuthProvider clientId="991105793073-1dikvgcrhdiqahsm2056906unah9tpnb.apps.googleusercontent.com">
      <BrowserRouter>
        <AuthProvider>
          <ProductProvider>
            <ThemeProvider>
              <DataTransferProvider>
                <VideoProvider>
                  <CartProvider>
                    <SearchProvider>
                      <TrackPageView />
                      {/* <Suspense
                        fallback={
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <img src="/Bubble-Preloader.gif" alt="Loading..." />
                          </div>
                        }
                      > */}
                      <Routes>
                        {/* <Route path="/" element={<HomePageBody />} /> */}
                        <Route path="/ebooks" element={<DigitalBody />} />
                        <Route
                          path="/ebook/:category/:subject/:title"
                          element={<Intro2 />}
                        />
                        <Route
                          path="/notes/:chapterId/:chapterTitle/:cardId/:email"
                          element={<NotesContainer />}
                        />
                        <Route index element={<HomePageBody />} />
                        <Route
                          exact
                          path="/HomePageBody"
                          element={<HomePageBody />}
                        />
                        <Route exact path="/home" element={<Home />} />
                        {/* //Ebook */}
                        <Route
                          path="/students-for-purchase/:studentId"
                          element={<StudentDetail />}
                        />
                        {/* <Route path="/student-table" element={<StudentTable />} /> */}
                        <Route
                          path="/admin_dashboard"
                          element={<AdminEbook />}
                        />
                        <Route
                          path="/register-admin-ebook"
                          element={<RegisterAdminEbook />}
                        />
                        <Route
                          path="/login-admin-ebook"
                          element={<LoginAdminEbook />}
                        />
                        <Route
                          path="/ebooks/wish-list"
                          element={<Wishlist />}
                        />
                        <Route path="/ebooks/show-all" element={<Showall />} />
                        <Route path="/ab" element={<PdfShowing />} />
                        <Route path="/ebooks/view-all" element={<AllEbook />} />
                        <Route path="/books/view-all" element={<AllBook />} />
                        <Route path="/profile" element={<Profile1 />} />
                        <Route
                          path="/ebooks/cart"
                          element={<CartPageEbook />}
                        />
                        {/* <Route index element={<DigitalBody />} /> */}
                        <Route
                          path="/ebook/:cardId/digitalMainPage"
                          element={<DigitalMainPage />}
                        />
                        <Route
                          path="/ebook/:cardId/digitalPage1"
                          element={<Page1 />}
                        />
                        <Route
                          path="/ebook/myorder"
                          element={<EbookMyOrder />}
                        />
                        <Route
                          path="/ebooks/showallebook"
                          element={<ShowAllEbooks />}
                        />
                        <Route path="quiz" element={<Quiz1 />} />
                        <Route path="homequiz" element={<HomePage />} />
                        <Route path="exer-page" element={<Exercise />} />
                        <Route path="" element={<DigitalBody />} />
                        <Route path="/buy" element={<BuyNow />} />
                        <Route
                          path="/ebooks/mylearning"
                          element={<Mylearn />}
                        />
                        <Route
                          path="/:cardId/mylearning"
                          element={<Mylearn />}
                        />
                        <Route
                          path="/Progress/:cardId"
                          element={<Progress />}
                        />
                        <Route path="/videos" element={<HomePage01 />} />
                        {/* <Route
                          path="/course/description/:courseId"
                          element={<Page02 />}
                        /> */}
                        <Route
                          path="/videos/:category/:subject/:seoPermalink*"
                          element={<Page02 />}
                        />
                        <Route
                          path="/add-subject"
                          element={<AddSubjectForm />}
                        />
                        <Route path="/add-course" element={<AddCourseForm />} />
                        <Route
                          path="/update-course"
                          element={<ManageCourseList />}
                        />
                        <Route
                          path="/edit-course/:id"
                          element={<EditCourse />}
                        />
                        <Route
                          path="/course-status"
                          element={<CourseStatusChecker />}
                        />
                        <Route path="/update-pdf" element={<ManagePDFs />} />
                        {/* <Route
                          path="/course-detail"
                          element={<CourseDetails />}
                        />
                        <Route path="/description" element={<Description />} />
                        <Route path="/videocart" element={<Cart />} /> */}
                        <Route path="/share" element={<Share />} />
                        <Route path="/language" element={<Language />} />
                        <Route
                          path="/login"
                          element={() => {
                            window.location.href =
                              "https://testportal.ssccglpinnacle.com/login";
                            return null;
                          }}
                        />
                        <Route
                          path="/signup"
                          element={() => {
                            window.location.href =
                              "https://testportal.ssccglpinnacle.com/login";
                            return null;
                          }}
                        />
                        <Route
                          path="/register"
                          element={() => {
                            window.location.href =
                              "https://testportal.ssccglpinnacle.com/login";
                            return null;
                          }}
                        />
                        {/* <Route path="/cartHover" element={<AddToCart />} /> */}
                        <Route path="/videos/cart" element={<CartPage />} />
                        <Route
                          path="/videos/wishlist"
                          element={<WishlistPage />}
                        />
                        <Route path="/error" element={<PageNotFound />} />
                        <Route path="/gitACourse" element={<GiftACourse />} />
                        <Route path="/checkout" element={<Checkout />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/tools" element={<Tools />} />
                        <Route path="/resources" element={<Resources />} />
                        <Route
                          path="/communication"
                          element={<Commmunication />}
                        />
                        <Route
                          path="/personalPlane"
                          element={<PersonalPlane />}
                        />
                        <Route
                          path="/PurchaseHistory"
                          element={<PurchaseHistory />}
                        />
                        <Route
                          path="/PaymentSteps"
                          element={<PaymentSteps />}
                        />
                        <Route
                          path="/notification"
                          element={<Notification />}
                        />
                        <Route
                          path="/add-meta-data"
                          element={<AddMetaDataForm />}
                        />
                        <Route
                          path="/add-og-data"
                          element={<AddOgDataForm />}
                        />
                        <Route
                          path="/edit-blog/:id"
                          element={<EditBlogForm/>}
                        />
                        <Route
                          path="/manage-meta-data"
                          element={<ManageMetaDataForm />}
                        />
                        <Route
                          path="/manage-og-data"
                          element={<ManageOgDataForm />}
                        />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/contact-Us" element={<ContactUs />} />
                        <Route
                          path="/privacy-policy"
                          element={<PrivacyPolicy />}
                        />
                        <Route
                          path="/refund-Policy"
                          element={<RefundPolicy />}
                        />
                        <Route
                          path="/terms-and-condition"
                          element={<TermAndCondition />}
                        />
                        <Route
                          path="/CourseRating"
                          element={<CourseRating />}
                        />
                        <Route
                          path="/reportAbouse"
                          element={<ReportAbousenew />}
                        />
                        <Route path="/test" element={<InstructorDetails />} />
                        <Route
                          path="/provide_access"
                          element={<VideoCourseAccess />}
                        />
                        {/* <Route
                        path="/admin-dashboard"
                        element={<AdminDashboard />}
                      /> */}
                        {/* <Route path="/upload-video" element={<VideoUpload />} /> */}
                        {/* </Routes>
                    <Routes> */}
                        {/* <Route
                        path="/admin/instructorForm"
                        element={<InstructorForm />}
                      /> */}
                        <Route path="/videos/myorders" element={<MyOrders />} />
                        <Route
                          path="/videos/mylearning"
                          element={<MyLearningMain />}
                        />
                        <Route
                          path="/videos/payment-status"
                          element={<VideosTransactionModal />}
                        ></Route>
                        <Route
                          path="/user/username"
                          element={<PinnacleProfile />}
                        />
                        <Route
                          path="/user/edit-photo"
                          element={<EditPhoto />}
                        />
                        <Route
                          path="/user/edit-account"
                          element={<AccountSecurity />}
                        />
                        <Route
                          path="/user/manage-subscriptions"
                          element={<Subscriptions />}
                        />
                        <Route
                          path="/user/edit-payment-methods"
                          element={<PaymentMethod />}
                        />
                        <Route
                          path="/user/edit-privacy"
                          element={<Privacy />}
                        />
                        <Route
                          path="/user/edit-notifications"
                          element={<AccountNotification />}
                        />
                        <Route
                          path="/user/close-account"
                          element={<AccountClose />}
                        />
                        <Route
                          path="/edit/account-settings"
                          element={<AccountMain />}
                        />
                        <Route path="/giftcourse" element={<GiftACourseD />} />
                        <Route path="/user/messages" element={<Messages />} />
                        <Route
                          path="/pinnacle-help"
                          element={<PinnacleHelp />}
                        />
                        <Route path="/signuphelp" element={<SignUpHelp />} />
                        <Route path="/account-main" element={<AccountMain />} />
                        <Route
                          path="/troubleshooting"
                          element={<TroubleshootingHelp />}
                        />
                        <Route
                          path="/books/payment-status"
                          element={<BookTransactionModal />}
                        />
                        <Route
                          path="/typing/payment-status"
                          element={<TypingTransactionModal />}
                        />
                        <Route
                          path="/ebook/payment-status"
                          element={<EbookTransactionModal />}
                        />
                        <Route
                          path="/videos/category/SSC"
                          element={<SSCCategory />}
                        ></Route>
                        <Route
                          path="/videos/category/Railway"
                          element={<RailwayCategory />}
                        ></Route>
                        <Route
                          path="/videos/category/UpPolice"
                          element={<UpPoliceCategory />}
                        ></Route>
                        <Route
                          path="/videos/category/Ctet"
                          element={<CtetCategory />}
                        ></Route>
                        <Route
                          path="/videos/category/DelhiPolice"
                          element={<DelhiPoliceCategory />}
                        ></Route>
                        {/* YOUTUBE PLAYER ROUTES */}
                        <Route
                          path="/myplayer/:courseId"
                          element={<VideoPlayerPageLayout />}
                        />
                        <Route path="/video_timer" element={<VideoTimer />} />
                        {/* <Route path="/save-link" element={<SaveLinks />} />
                        <Route
                          path="/display-link"
                          element={<DisplayLinks />}
                        /> */}
                        <Route
                          path="/pdfviewer/:pdfId"
                          element={<PDFViewer />}
                        />
                        <Route
                          path="/chapterAccordion"
                          element={<ChapterAccordion />}
                        ></Route>
                        {/* <Route
                        path="/ucf/:courseId"
                        element={<UpdateCourseForm />}
                      ></Route> */}
                        <Route
                          path="/paymentSuccessHandler/:userId/:{courseId"
                          element={<PaymentSuccessHandler />}
                        ></Route>
                        <Route path="/stL" element={<StudentList />}></Route>
                        <Route
                          path="/stUL/:id"
                          element={<StudentUpdateForm />}
                        ></Route>
                        <Route
                          path="/editChapter"
                          element={<StudentUpdateForm />}
                        ></Route>
                        {/* ***********************Routes for VideoCourse_Admin_Panel************************ */}
                        {/* <Route
                          path="/addChapter"
                          element={<AddChapterForm />}
                        /> */}
                        <Route path="/add-course" element={<AddCourseForm />} />
                        {/* <Route path="/banner" element={<VideoBanner />} /> */}
                        {/* <Route path="/addTopic" element={<AddTopicForm />} /> */}
                        <Route
                          path="/addCourseContent"
                          element={<AddCourseChapters />}
                        />
                        {/* <Route path="/admin_panel" element={<AdminPanel />} /> */}
                        <Route
                          path="/videos/mylearning/:courseId"
                          element={<YTLearning />}
                        />
                        <Route
                          path="/classnotes/:pdfId"
                          element={<ViewVideoPDF />}
                        />
                        <Route
                          path="/announcementList"
                          element={<AnnouncementList />}
                        />
                        {/*Book */}
                        <Route
                          path="/payment-success"
                          element={<PaymentSuccess />}
                        />
                        <Route
                          path="/wish-list-book"
                          element={<WishlistBook />}
                        />
                        <Route path="books" element={<BodyBook />} />
                        <Route
                          path="/purchase-book"
                          element={<PurchaseBook />}
                        />
                        <Route path="/cart-book" element={<CartPageBook />} />
                        {/* <Route
                          path="/books/:examCategory/:subjects/:title"
                          element={<IntroBook />}
                        /> */}
                        <Route
                          path="/books/:category/:subject/:permalink*"
                          element={<IntroBook />}
                        />
                        <Route path="/book/myorder" element={<BookMyOrder />} />
                        {/* BLOG */}
                        <Route
                          path="/search/:query"
                          element={<SearchResults />}
                        />
                        <Route
                          path="/blog/:permalink"
                          element={<BlogList1 />}
                        />
                        <Route
                          path="/blogNext/:category"
                          element={<NextPage />}
                        />
                        <Route path="/blogform" element={<BlogForm />} />
                        <Route path="/blogs" element={<Blogmain />} />
                        <Route
                          path="/verify-address/:cardId"
                          element={<VerifyAddress />}
                        />
                        <Route
                          path="/myorders_typing"
                          element={<TypingMyOrders />}
                        />
                        {/* Typing */}
                        {/* <Route path="/add-typing-paragraph" element={<AddTypingParagraph />} /> */}
                        <Route
                          path="/typingExamSelection"
                          element={<TypingExamSelection />}
                        />
                        <Route path="/typing" element={<Typing1 />} />
                        <Route
                          path="/typingmodule/:testcode/:exam/:category"
                          element={<TypingModule />}
                        />
                        <Route
                          path="/typingparagraph/:exam/:category"
                          element={<TypingParagraph />}
                        />
                        <Route
                          path="/typingperformance/:accuracy/:wrongper/:actualdep/:speed/:testcode/:exam/:category"
                          element={<TypingPerformance />}
                        />
                        <Route
                          path="/typingperformancetest/:accuracy/:wrongper/:actualdep/:speed/:testcode/:exam/:category"
                          element={<TypingPerformanceTest />}
                        />
                        <Route path="/visitors" element={<VisitorsTable />} />
                        <Route
                          path="/view-all-courses"
                          element={<ViewAllCourses />}
                        />
                        <Route
                          path="/updateChapterOrder/:courseId"
                          element={<UpdateChapterOrder />}
                        />
                        <Route path="/AddExamForm" element={<AddExamForm />} />
                        <Route path="/ExamList" element={<ListExams />} />
                        <Route
                          path="/videos/category/:categoryId"
                          element={<VCCategory />}
                        />
                        <Route
                          path="/videoSearch"
                          element={<VideoSearchResults />}
                        />{" "}
                        <Route
                          path="/globalSearch"
                          element={<GlobalSearch />}
                        />
                        <Route path="/chatBot" element={<Chatbot />} />
                        <Route path="/sitemap" element={<SitemapPage />} />
                        <Route path="/add-ebook" element={<EbookNewForm />} />
                        <Route path="/add-book" element={<BookNewForm />} />
                        <Route path="/coins" element={<CoinsPage />} />
                        <Route path="/headerBar" element={<HeaderBar/>} />

                      </Routes>
                      {/* </Suspense> */}
                    </SearchProvider>
                  </CartProvider>
                </VideoProvider>
              </DataTransferProvider>
            </ThemeProvider>
          </ProductProvider>
        </AuthProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import classes from "./description.module.css";
import { useParams } from "react-router-dom";

export default function Description() {
  const [courseDetails, setCourseDetails] = useState(null);
  const { category, subject, seoPermalink } = useParams(); // Extracts the full path after "/videos/"

  // const seoPermalink =
  //   "general-studies-portion-video-course-ssc-cgl-2024-39-sets-solved-paper-book";
  // console.log("seoPermalink", seoPermalink);

  useEffect(() => {
    getCourseDetails();
  }, [seoPermalink]); // Add seoPermalink as a dependency

  const getCourseDetails = async () => {
    try {
      let result = await fetch(
        `https://auth.ssccglpinnacle.com/get-seo/${category}/${subject}/${seoPermalink}`
      );
      if (!result.ok) {
        throw new Error("Failed to fetch course details");
      }
      result = await result.json();
      setCourseDetails(result.longDescription);
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  return (
    <div className={classes["description-fullpage"]}>
      <div className={classes["description-heading"]}>Description</div>
      {courseDetails ? (
        <div
          className={classes["description-content"]}
          dangerouslySetInnerHTML={{ __html: courseDetails }}
        ></div>
      ) : (
        <div className={classes["description-content"]}>
          <img
            src="https://d3m4h509gttb94.cloudfront.net/Image/loading-walk.gif"
            alt="loading"
          />
        </div>
      )}
    </div>
  );
}
